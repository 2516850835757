import { NavLink } from "react-router-dom";

const Commonfeature = (props) => {
  return (
    <section className="common-section">
      <div className="container">
        <h1 className="common-header">
          <b>Why to Partner with Amealio?</b>
        </h1>
        <div className="row common-row">
          <div className="col-md-4 col-sm-6 common-col">
            <div className="common-align">
              <div className="common-box">
                <img
                  src="../assets/images/fastfood/swap.png"
                  alt="img"
                  className="common-img"
                ></img>
              </div>
              <h5 className="common-h5 ">Manage Customer Flow</h5>
            </div>
            <p className="common-p">
              Be it dine-in, curbside takeaway, food <br />
              delivery or more, managing food orders of all
              <br /> forms at a single place, made easy. Retain <br />
              syour business using Voice and AI.
            </p>
          </div>
          <div className="col-md-4 col-sm-6 common-col">
            <div className="common-align">
              <div className="common-box">
                <img
                  src="../assets/images/fastfood/analysis(1).png"
                  alt="img"
                  className="common-img"
                ></img>
              </div>
              <h5 className="common-h5">Data Analytics</h5>
            </div>
            <p className="common-p">
              Collect user behavior data that helps you
              <br /> determine the intent of your customers. Via
              <br /> scalable metrics, take actions for your business that
              drives engagement, and conversion
            </p>
          </div>
          <div className="col-md-4 col-sm-6 common-col">
            <div className="common-align">
              <div className="common-box">
                <img
                  src="../assets/images/fastfood/nodes.png"
                  alt="img"
                  className="common-img"
                ></img>
              </div>
              <h5 className="common-h5">Easily Accessible</h5>
            </div>
            <p className="common-p">
              Using a broad range of devices to interact with through your
              smartphones, tablets, desktops, smartwatches and more. We make
              your business accessible to you on the go.
            </p>
          </div>

          <div className="col-md-4 col-sm-6 common-col">
            <div className="common-align">
              <div className="common-box">
                <img
                  src="../assets/images/fastfood/catalogue.png"
                  alt="img"
                  className="common-img"
                  style={{ width: "35px" }}
                ></img>
              </div>
              <h5 className="common-h5">Catalogue Setup</h5>
            </div>
            <p className="common-p">
              Customize your menu, rate lists and other food-related offerings
              without any hassle. Yes, it’s that easy! Think of a change and we
              will make it happen to your catalogue in a jiffy.
            </p>
          </div>
          <div className="col-md-4 col-sm-6 common-col">
            <div className="common-align">
              <div className="common-box">
                <img
                  src="../assets/images/fastfood/group68769.png"
                  alt="img"
                  className="common-img"
                  style={{ width: "30px" }}
                ></img>
              </div>
              <h5 className="common-h5">Staff Management</h5>
            </div>
            <p className="common-p">
              Manage your employees' presence, work schedules, and
              responsibilities throughout the day in a central location. Amealio
              simplifies personnel management for you, whether you have a full
              house or an emergency.
            </p>
          </div>
          <div className="col-md-4 col-sm-6 common-col">
            <div className="common-align">
              <div className="common-box">
                <img
                  src="../assets/images/fastfood/calendar.png"
                  alt="img"
                  className="common-img"
                ></img>
              </div>
              <h5 className="common-h5">Handle Seat Availablity</h5>
            </div>
            <p className="common-p">
              "On days of Chef's special, your patent (everyone's favourite), or
              in general; plan your restaurants' seats for big days, small
              events, or as required. Always be <br /> ready!
            </p>
          </div>
        </div>
        <NavLink to={`/${props.lang}/demo`}>
          <center>
            <button className="btn common-btn">Get Onboarded </button>
          </center>
          <br />
          <br />
          <br />
        </NavLink>
      </div>
    </section>
  );
};

export default Commonfeature;
