import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import i18next from "i18next";
import cookies from "js-cookie";
import { LANGUAGES } from "../../AllEnum/LanguageEnum";

const Header = (props) => {
  const navigate = useNavigate();
  const pathFind = useLocation();

  // let headColor = [
  //   "/enhanced",
  //   "/",
  //   "/aboutAmealia",
  //   "/microphone",
  //   "/amealiarestaurant",
  //   "/features",
  //   "/amealio",
  //   "/bussiness",
  //   "/social",
  //   "/help",
  //   "/about",
  //   "/explore",
  //   "/refunds-and-cancellations",
  //   "/terms-conditions",
  //   "/businessterms",
  //   "/walletterms",
  //   "/ususerterms",
  //   "/usprivacy",
  //   "/usbusinessterms",
  //   "/privacy-policy",
  //   "/cookie-policy",
  //   "/other-policy",
  //   "/offers-rewards-referral",
  //   "/businessreferral",
  //   "/programs",
  // ];

  // let businessMenu = [
  //   "/bussiness",
  //   "/fastfood",
  //   "/fastcasual",
  //   "/driveindrivethru",
  //   "/casualdining",
  //   "/finedining",
  //   "/hotelsandresorts",
  //   "/barsandclubs",
  //   "/institutional",
  // ];
  //   const [currentPath, setCurrentPath] = useState(pathFind.pathname.substring(1));
  // const currentPath = pathFind.pathname.substring(1);

  // const [businesstype, setBusinesstype] = useState(false);

  // const [logo, setlogo] = useState(true);

  // const [openDropDown, setOpenDropDown] = useState(false);

  // useEffect(() => {
  //   if (businessMenu.includes(props.type?.pathname)) {
  //     setBusinesstype(true);
  //   } else {
  //     setBusinesstype(false);
  //   }

  //   if (headColor.includes(props.type?.pathname)) {
  //     setlogo(false);
  //   } else {
  //     setlogo(true);
  //   }
  // }, [props.type]);

  // function setDropDownName(currentPath) {
  //   if (currentPath === "fastfood") {
  //     return "Fast Food and Others";
  //   } else if (currentPath === "fastcasual") {
  //     return "Fast Casual";
  //   } else if (currentPath === "driveindrivethru") {
  //     return "Drive-In/Drive-Thru";
  //   } else if (currentPath === "casualdining") {
  //     return "Casual Dining";
  //   } else if (currentPath === "finedining") {
  //     return "Fine Dining";
  //   } else if (currentPath === "hotelsandresorts") {
  //     return "Hotels and Resorts";
  //   } else if (currentPath === "barsandclubs") {
  //     return "Bars and Clubs";
  //   } else if (currentPath === "institutional") {
  //     return "Institutional";
  //   } else {
  //     return "Business Type";
  //   }
  // }

  const currentLanguageCode = cookies.get("i18next") || "in";
  const currentLanguage = LANGUAGES.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    setCurrentLang(currentLanguage.name);
    // navigate(
    //   `/lang=${currentLanguage.country_code_iso3 + "-" + currentLanguage.code.toUpperCase()}`
    // );
    // window.location.reload();
  }, [currentLanguageCode, currentLanguage]);

  const [currentLang, setCurrentLang] = useState(currentLanguage.name);
  const onChangeLanguage = (data) => {
    i18next.changeLanguage(data.code);
    setCurrentLang(data.name);
    document.body.dir = data.dir || "ltr";
    navigate(`/${data.code.toUpperCase()}`);
  };

  /*********** Download Now Action ************/
  const onClickRedirectPage = () => {
    window.open("https://amealio.page.link/oHnQ88HYijeUaXiPA", "_blank");
  };

  let url = `/${currentLanguage.code.toUpperCase()}`;

  return (
    <div className="container px-0">
      <nav className="navbar navbar-expand-lg navbar-light bg-white custom-header mb-0">
        <div
              className="mobile-hamburger row"
              style={{ display: "contents" }}
            >
              <div className="col-4 col-xs-6 col-md-3">
                <NavLink
                  className="navbar-brand h-auto"
                  to={`/${currentLanguage.code.toUpperCase()}`}
                >
                  <img
                    alt="logo"
                    title="Amealio"
                    className="img-fluid "
                    width="400px"
                    src="../assets/images/amealio/amealio1.png"
                  />
                </NavLink>
              </div>
              <div className="col-5 col-xs-3 col-md-5"></div>

              {/* Desktop Menu */}
              <div className="col-3 col-xs-3 col-md-4">
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mr-auto align-items-md-center">
                    <li className="nav-item active">
                      <NavLink
                        className="nav-link all-menu-link amealiocolor"
                        to={`${url}/aboutamealio`}
                      >
                        Learn more <span className="sr-only">(current)</span>
                      </NavLink>
                    </li>

                    <li className="nav-item active">
                      <NavLink
                        className="nav-link all-menu-link amealiocolor"
                        to={`${url}/becomemerchant`}
                      >
                        Become a Merchant
                      </NavLink>
                    </li>
                    {/* <li className="nav-item active">
                      <NavLink className="nav-link all-menu-link amealiocolor" to={`#`}>
                        <i
                          style={{ fontSize: "26px" }}
                          class="fa fa-bars"
                          aria-hidden="true"
                        ></i>
                      </NavLink>
                    </li> */}
                  </ul>
                </div>
              </div>

              {/* Mobile Menu */}
              <div className="hamburger-menu" style={{zIndex:"200"}}>
                <input
                  id="menu__toggle"
                  type="checkbox"
                  className="d-block d-block d-md-none"
                />
                <label
                  className="menu__btn d-block d-block d-md-none"
                  htmlFor="menu__toggle"
                >
                  <span></span>
                </label>
                <ul
                  className="menu__box"
                  style={{
                    backgroundImage:
                      "url(/assets/images/business/backgroundwhiteicons.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <li
                    className={`${
                      pathFind.pathname ===
                      `/${currentLanguage.code.toUpperCase()}`
                        ? "nav-item active-menu-item"
                        : "nav-item"
                    }`}
                  >
                    <NavLink className="menu__item"
                     to={`/${currentLanguage.code.toUpperCase()}`}>
                      Home
                    </NavLink>
                  </li>
                  <li
                    className={`${
                      pathFind.pathname ===
                      `${currentLanguage.code.toUpperCase()}/aboutamealio`
                        ? "active-menu-item"
                        : ""
                    }`}
                    htmlFor="menu__toggle"
                  >
                    <NavLink
                      className="menu__item"
                      htmlFor="menu__toggle"
                      to={`${url}/aboutamealio`}
                    >
                      Learn more <span className="sr-only">(current)</span>
                    </NavLink>
                  </li>

                  <li
                    className={`${
                      pathFind.pathname ===
                      `/${currentLanguage.code.toUpperCase()}/becomemerchant`
                        ? "nav-item active-menu-item"
                        : "nav-item"
                    }`}
                  >
                    <NavLink
                      className="menu__item"
                      to={`${url}/becomemerchant`}
                    >
                      Become a Merchant
                    </NavLink>
                  </li>
                  {/* <li
                    className={`${
                      pathFind.pathname ===
                      `/${currentLanguage.code.toUpperCase()}/becomemerchant`
                        ? "nav-item active-menu-item"
                        : "nav-item"
                    }`}
                  >
                    <NavLink className="menu__item" to={`#`}>
                      Contact us
                    </NavLink>
                  </li> */}
                  <li>
                <div
                  className="position-absolute"
                  style={{ left: 0, bottom: "12%", right: 0 }}
                >
                  <img
                    src="../assets/images/header/amealiologo.png"
                    alt="logo"
                    title="logo"
                    className="img-fluid w-50"
                  />
                </div>
              </li>
                  <li
                    className={`${
                      pathFind.pathname ===
                      `/${currentLanguage.code.toUpperCase()}/help`
                        ? "nav-item active-menu-item"
                        : "nav-item"
                    }`}
                  >
                    {/* <NavLink className="menu__item" to={`#`}>
                      <i class="fa fa-bars"></i>
                    </NavLink> */}
                  </li>
                </ul>
              </div>
            </div>
      </nav>
    </div>
  );
};

export default Header;
