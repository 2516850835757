import React, { useState } from "react";
// import Header from "../templates/header";
import Footer from "../templates/footer";
import amealioRedefine from "./../../newAssets/images/about/about-redfine.png";
import amealioRevolution from "./../../newAssets/images/about/revolution.png";
import ourTimeline from "./../../newAssets/images/HomePage/Banner/outTimeline.png";

import axios from "axios";

const About = () => {
  const [Check, Uncheck] = useState(true);
  const [loading, setLoading] = useState(false);

  const [mobileNumber, setMobileNumber] = useState("");

  const onChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const changeMeassgeClick = () => {
    let numbers = /^[0-9]+$/;
    if (mobileNumber.length <= 9) {
      alert("Mobile number is not correct, Please check again");
    } else {
      if (mobileNumber.match(numbers)) {
        setLoading(true);
        let formData = {
          mobile_number: mobileNumber,
        };
        axios
          .post(`https://merchantapi.amealio.com/non-user-sms`, formData)
          .then((res) => {
            if (res.status === 201) {
              setLoading(false);
              Uncheck(false);
              setMobileNumber("");
            }
          })
          .catch((err) => {
            setLoading(false);
            alert(`<h4>${err}</h4>`);
          });
      } else {
        alert("Mobile number is not correct, Please check again");
      }
    }
  };

  const closedMessage = () => {
    Uncheck(true);
    setMobileNumber("");
  };

  const goToWebsite = () => {
    window.open("https://envisionard.com", "_blank");
  };

  return (
    <>
      {/**********************
       * @desc First Section
       **********************/}
      <section className="home-section-main about-top-main">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-7 about-top-main-left">
              <h1 className="f50-h2">Welcome to Amealio!</h1>
              <h2 className="f24-h5 mb-4">
                A platform developed keeping food lovers in mind. It’s all about
                food
              </h2>
              <p
                className="f18-p2 mb-5 pb-4 m-auto m-sm-auto m-md-0"
                style={{ color: "#707070" }}
              >
                Amealio - is the world’s first Social Engagement platform for
                foodies and a Marketplace for Personalized Food Experiences. 
                Our vision is to build a community and a platform for people to
                Enjoy, Celebrate and Socialize around food. Our mission to help
                millions of food lovers seeking experience and convenience while
                going out and on the go.
              </p>
              {/* <div className="box-line my-4"></div> */}
              {Check ? (
                <div className="check">
                  <div className="toptitle">
                    <h4 className="f18-p2" style={{ color: "#EEB84E" }}>
                      <b>Send Downlaod Link</b>
                    </h4>
                  </div>
                  <form action="#">
                    <div className="user-details">
                      <div className="input">
                        <div className="numSec register-width-style">
                          <p className="already-add-number">+91</p>
                          <input
                            type="text"
                            maxLength="10"
                            pattern="\d{10}"
                            title="Please enter exactly 10 digits"
                            placeholder="Phone Number"
                            onChange={(e) => onChange(e)}
                            className="px-3"
                            required
                          />
                          <i
                            className="fa fa-chevron-right arrowCss"
                            style={{ cursor: "pointer" }}
                            aria-hidden="true"
                            onClick={changeMeassgeClick}
                          ></i>
                        </div>
                        <div className="checkout-below">
                          {loading ? <h6>Please wait...</h6> : null}
                          <div className="checkoutlogo">
                            <div className="bottomicon-home w-100 w-sm-100 w-md-50 mb-4 mb-sm-4 mb-md-0">
                              <a
                                href="https://play.google.com/store/apps/details?id=com.envisionard.amealio"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="../assets/images/footer/googleplay.png"
                                  alt="get it on Google Play"
                                  title="get it on Google Play"
                                  className="img-fluid icon-play-app-store"
                                />
                              </a>
                              <a
                                href="https://apps.apple.com/in/app/amealio/id1516233942"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="../assets/images/footer/apple-app-store-icon.png"
                                  alt="get it on Apple Store"
                                  title="get it on Apple Store"
                                  className="img-fluid icon-play-app-store"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="message-sent__box">
                  <div className="message">
                    <i
                      className="fa fa-times checkout-close message-sent__close"
                      aria-hidden="true"
                      onClick={closedMessage}
                    ></i>
                    <div className="message-sent__title">
                      <h4>
                        <i
                          className="fa fa-check-circle message-sent__circle"
                          aria-hidden="true"
                        ></i>
                        Message Sent
                      </h4>
                    </div>
                    <div className="message-sent__subtitle">
                      <p>
                        The Message has been successfully sent to the registered
                        mobile number {mobileNumber}
                      </p>
                      <p>
                        Kindly Check on the given link in the SMS to enjoy the
                        features of Amealio App.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 col-sm-12 col-md-5 d-flex align-items-center justify-content-center top-about-right-image">
              <img
                className="img-fluid"
                src="../assets/images/aboutamealio/about-top-left.png"
                alt="About"
                title="About"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="position-of-mouse-about text-center my-5 py-2">
              <div id="scrolling-mouse"></div>
            </div>
          </div>
        </div>
      </section>

      {/**********************
       * @desc Two Section
       **********************/}
      <section
        className="about-amealio-section-two"
        style={{ backgroundImage: "url(" + amealioRedefine + ")" }}
      >
        <div className="top-sprial-image">
          <img
            src="../assets/images/aboutamealio/redifine-sprial.png"
            alt="Redefining"
            title="Redefining"
            className="img-fluid"
          />
        </div>
        {/* <div className="top-left-image">
          <img
            src="../assets/images/aboutamealio/mash.png"
            alt="Redefining"
            title="Redefining"
            className="img-fluid"
          />
        </div> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 about-amealio-col-right d-block d-sm-block d-md-none">
              <div className="right-mobile-image-redefine position-static">
                <img
                  src="../assets/images/aboutamealio/redefine-right.png"
                  alt="Redefining"
                  title="Redefining"
                  className="img-fluid pt-0 pb-5"
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 about-amealio-col-left">
              <h1 className="f50-h2 text-white">
                <b>
                  Redefining the Future
                  <br />
                  of Food Experience
                </b>
              </h1>
              <h2 className="f32-h4 text-white pt-4">
                <b>
                  Join us and leap forward to
                  <br />
                  experience food like never before!
                </b>
              </h2>
              <p className="f18-p2 mt-4 text-white">
                Over the last decade, the food-tech industry was mainly focused
                on delivering food to our homes, which is less than 15% of per
                capita food spending on eating out. While eating out has not
                been addressed as a positive experience, many other food
                interactions haven’t been aggregated in a marketplace either,
                i.e. Dine-In (Pre-Order), Curb-side, Catering, DriveThru, and
                Drive-In.
                {/* Over the last decade, the food-tech industry was mainly focused
                <br /> on delivering food to our homes, which is less than 15%
                of per
                <br /> capita food spending on eating out. While eating out has
                not been
                <br />
                addressed as a positive experience, many other food interactions
                <br />
                haven’t been aggregated in a marketplace either, i.e. Dine-In
                <br />
                (Pre-Order), Curbside, Catering, Drive-Thru, and Drive-In */}
              </p>
              <p className="f18-p2 mt-4 text-white">
                The Amealio team is on a life mission to help food lovers
                redefine their experiences. We feel that eating is not just an
                act at the moment, it is an impression of happiness, and
                contentment that is to be enjoyed, celebrated, and shared.
              </p>
              <p className="f18-p2 mt-4 text-white">
                Our ultimate goal is to make Amealio one place where you can
                come with a thought of food and go with experience. By this, we
                aim to enhance your eating patterns and let you ameliorate
                (making it better) your lives with the power of technology.
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-6 about-amealio-col-right d-none d-sm-none d-md-block">
              <div className="right-mobile-image-redefine">
                <img
                  src="../assets/images/aboutamealio/redefine-right.png"
                  alt="Redefining"
                  title="Redefining"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="ourtimeline-bg"
        style={{ backgroundImage: "url(" + ourTimeline + ")" }}
      >
        <div className="top-sprial-image-right">
          <img
            src="../assets/images/aboutamealio/outTimelineSprial.png"
            alt="Redefining"
            title="Redefining"
            className="img-fluid"
          />
        </div>
        {/* <div className="top-our-timeline-img">
          <img
            className="img-fluid"
            src="../assets/images/aboutamealio/burgur.png"
            alt="Location"
            title="Location"
          />
        </div> */}
        <div className="aboutbanner4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-4 col-sm-4 col-md-2 offset-md-2 passingmaintane align-items-center">
                <div className="mb-5 pb-5">
                  <h2>
                    Everyday
                    <br />
                    Experience
                  </h2>
                  {/* <h4>
                    Q2
                    <br />
                    2022
                  </h4> */}
                  <img
                    className="img-fluid img-ourtimeline-two"
                    src="../assets/images/aboutamealio/locationMap.png"
                    alt="Location"
                    title="Location"
                  />
                </div>
              </div>
              <div className="col-4 col-sm-4 col-md-2 offset-md-1 passingmaintane align-items-center">
                <div className="mt-5 pt-5">
                  <img
                    className="img-fluid img-ourtimeline-two"
                    src="../assets/images/aboutamealio/locationMap.png"
                    alt="Location"
                    title="Location"
                  />
                  <h2>
                    Curated Experience
                    <br />
                    Launch
                  </h2>
                  {/* <h4>
                    Q3
                    <br />
                    2022
                  </h4> */}
                </div>
              </div>
              <div className="col-4 col-sm-4 col-md-2 offset-md-1 passingmaintane align-items-center">
                <div className="mt-5 pt-5">
                  <img
                    className="img-fluid img-ourtimeline-three px-3"
                    src="../assets/images/aboutamealio/locationMap.png"
                    alt="Location"
                    title="Location"
                  />
                  <h2>
                    Socializing
                    <br />
                    Experiences
                  </h2>
                  {/* <h4>
                    Q4
                    <br />
                    2022
                  </h4> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-sm-4 col-md-2 passingmaintane align-items-end">
                <div className="mb-0 mb-sm-0 mb-md-5 pb-0 pb-sm-0 pb-md-5">
                  <h2>
                    Beta India
                    <br />
                    Pune, Ordering
                  </h2>
                  {/* <h4>
                    Q2
                    <br />
                    2021
                  </h4> */}
                  <img
                    className="img-fluid img-ourtimeline-two"
                    src="../assets/images/aboutamealio/locationMap.png"
                    alt="Location"
                    title="Location"
                  />
                </div>
              </div>
              <div className="col-4 col-sm-4 col-md-2 offset-md-1 passingmaintane align-items-start align-items-sm-start align-items-md-end">
                <div className="mb-3 pb-3">
                  <img
                    className="img-fluid img-ourtimeline-two"
                    src="../assets/images/aboutamealio/locationMap.png"
                    alt="Location"
                    title="Location"
                  />
                  <h2>
                    Team Ramp Up -
                    <br />
                    Prototyping
                  </h2>
                  {/* <h4>
                    Q4
                    <br />
                    2021
                  </h4> */}
                </div>
              </div>
            </div>
            <div className="bottom-our-timeline">
              <h2>
                <b>Our Timeline</b>
              </h2>
              <h4>Socializing Experiences</h4>
              <p>Scaling Up Operations Q1 2023</p>
            </div>
          </div>
        </div>
        {/* <div className="bottom-our-timeline-img">
          <img
            className="img-fluid"
            src="../assets/images/aboutamealio/tomato.png"
            alt="Location"
            title="Location"
          />
        </div> */}
      </section>

      <section
        className="about-revolution-banner"
        style={{ backgroundImage: "url(" + amealioRevolution + ")" }}
      >
        <div className="top-sprial-image">
          <img
            src="../assets/images/aboutamealio/revolution.png"
            alt="Redefining"
            title="Redefining"
            className="img-fluid"
          />
        </div>
        <div className="aboutbanner5 d-flex align-items-center h-100">
          <div className="container-fluid">
            <div className="row text-white">
              <h1 className="text-white">
                Witness a Revolution with Envisionard
              </h1>
              <div className="col-md-6 res-order">
                <p className="aboutbanner5-small">
                  Envisionard is the epicentre of innovation in the F&B and
                  Hospitality Industry journey and provides the best experience
                  across ubiquitous platforms by leveraging the latest
                  technology like Voice, AI, ML and Mobile
                  {/* Envisionard is the epicenter of innovation in F&B and
                  <br />
                  Hospitality Industry. Our products are focused along the user
                  <br />
                  journey and provide the best experiences across ubiquitous
                  <br />
                  platforms by leveraging latest technologies like Voice, AI,
                  ML,
                  <br />
                  and Mobile. */}
                </p>
                <div className="mt-5 pt-5 center-go-web-btn">
                  {/* <a
                    href="https://envisionard.com/"
                    target="_blank"
                    rel="noreferrer"
                  > */}
                  <button
                    className="btn"
                    style={{ backgroundColor: "#42B2B5" }}
                    onClick={goToWebsite}
                  >
                    Go to Website
                  </button>
                  {/* </a> */}
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src="../assets/images/aboutamealio/envisionardlogo.png"
                  alt="Envinsion"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section-customized">
        <div className="topaboutbanner">
          <div className="about-amealio">
            <img
              src="../assets/images/aboutamealio/aboutamealiobanner.png"
              alt="About Banner"
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 amealiodescribe">
                <h1>A Customized Experience Built Just for You!</h1>
                <p>
                  An experience developed keeping food lovers in mind, the
                  Amealio mobile application is the answer to everything related
                  to food. Amealio aims to build a better-connected world for
                  users and businesses alike and become the first food-related
                  name that comes to mind.
                </p>
              </div>
              <div className="col-md-6 amealioimage">
                <img
                  src="../assets/images/aboutamealio/burger.png"
                  alt="Cookies"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section>
        <div className="aboutbanner2">
          <div className="container">
            <div className="banner2img">
              <img
                src="../assets/images/backup/group-67503.png"
                alt="About Banner Two"
                width="200"
              />
            </div>
            <h2>Speak & Eat with Amealia</h2>
            <div className="row amelia-wave">
              <div className="col-md-7 amealio-res">
                <div className="ressimg">
                  <img
                    className="ameimg"
                    src="../assets/images/aboutamealio/wave.png"
                    alt="Wave"
                  />
                </div>
                <h3>
                  Our Virtual Voice Assistance that understands your cravings
                  and those hunger pangs just as well as your mother does!{" "}
                </h3>
                <p>
                  Amealia is our smart voice assistant designed to cater to
                  every need of a foodie. Accessible via mobile, voice enabled
                  devices with Google Nest Hub and Alexa, it makes the user
                  experience seamless and perfect. Make your command and Amealia
                  will manage the rest.
                </p>
                <span>No seriously! Try us to believe us.</span>
                <div className="button2 learn-butn">
                  <NavLink to="/explore">
                    <button className="btn btn-warning btn2">Learn More</button>
                  </NavLink>
                </div>
              </div>
              <div className="col-md-5">
                <div className="banner2image">
                  <img
                    src="../assets/images/aboutamealio/googlemeet-x-google-nest-hub-max_overflow.png"
                    alt="Google Nest"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="amealiosonic imghide">
            <img src="../assets/images/aboutamealio/wave.png" alt="Wave" />
          </div>
        </div>
      </section> */}

      {/* <section className="about-section-margin">
        <div className="aboutbanner3">
          <div className="container">
            <h1>We are on a Mission To Redefine The Future</h1>
            <p>
              We at Envisionard Corporation are on a mission to help you
              redefine the way you eat and experience food. We feel that dining
              is not just an act of the moment; it is an impression of
              happiness, contentment and joy.
            </p>
            <p>
              Our ultimate goal is to make Amealio, your Home to Everything Food
              through a hyper-personalized intuitive and interactive Voice and
              AI engagement. By this, we aim to help you enhance your eating
              patterns and let you ameliorate your business via technology.
            </p>
            <p>
              {" "}
              Join us and leap forward to experience Food like never before!
            </p>
          </div>
        </div>
      </section> */}
      {/*       
      <section>
          <img src="../assets/images/aboutamealio/pastedgraphics-3.png" alt="image" width="100%" />
      </section>
      {/* <section>
          <img src="../assets/images/aboutamealio/PastedGraphic-3.png" alt="image" width="100%" />
      </section> */}

      {/* //////// OUR PARTNERS //////// */}
      <section className="food-partners-main bg-white">
        <div className="container-fluid">
          <div className="col-12">
            <div className="top-image-right-sprial">
              <img
                src="../assets/images/aboutamealio/FoodPartnersSprialOne.png"
                alt="Footer Sprial"
                title="Footer Sprial"
                className="img-fluid"
              ></img>
            </div>
          </div>
        </div>
        <div className="aboutbanner6">
          <div className="container">
            <div>
              <h1 className="f42-h3">
                <b>Our Esteemed Partners</b>
              </h1>
            </div>
            <div className="banner6navbar">
              <ul className="nav nav-pills wallet">
                <li className="active">
                  <a data-toggle="pill" className="f18-p2" href="#home">
                    Delivery <span>Partner</span>
                  </a>
                </li>
                <li className="get">
                  <a data-toggle="pill" className="f18-p2" href="#menu1">
                    Customer <span>Business</span>
                  </a>
                </li>
                <li>
                  <a data-toggle="pill" className="f18-p2" href="#menu2">
                    NGO <span>Partners</span>
                  </a>
                </li>
                <li>
                  <a data-toggle="pill" className="f18-p2" href="#menu3">
                    Other <span>Partners</span>
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div id="home" className="tab-pane fade in active">
                  <ul className="ngo-aline5">
                    <li>
                      <img
                        className="ngo-aline3"
                        src="../assets/images/ourpartners/part/Delivery Partner/Dunzo.png"
                        alt="Dunzo"
                      />
                    </li>
                    <li>
                      <img
                        className="ngo-aline4"
                        src="../assets/images/ourpartners/part/Delivery Partner/Shadowfax.png"
                        alt="Shadowfax"
                      />
                    </li>
                    <li>
                      <img
                        className="ngo-aline6"
                        src="../assets/images/ourpartners/part/Delivery Partner/rapido.png"
                        alt="Rapido"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Delivery Partner/borzo.png"
                        alt="Borzo"
                      />
                    </li>
                    {/* <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li> */}
                  </ul>
                </div>

                <div id="menu1" className="tab-pane fade">
                  <ul>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/elephant&co.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/springonion.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/monkey.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/creamcraver.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/khs.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/bigburger.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/yummy.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/hindi.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/chaats&wraps.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/barista.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/cafe.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/hindi1.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/rocomamas.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/sports.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/slaycity.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/high.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/huberholly.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Customer Business/iraniocafe.png"
                        alt="Partners"
                      />
                    </li>
                  </ul>
                </div>

                <div id="menu2" className="tab-pane fade">
                  <ul>
                    <li>
                      <img
                        className="ngo-aline"
                        src="../assets/images/ourpartners/part/NGO Partner/aphoorthifoundation.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        className="ngo-aline1"
                        src="../assets/images/ourpartners/part/NGO Partner/sofosh.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        className="ngo-aline2"
                        src="../assets/images/ourpartners/part/NGO Partner/akshayapatra.png"
                        alt="Partners"
                      />
                    </li>
                    {/* <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li>
                    <li><img src="../assets/images/backup/navbar.png" alt="image" /></li> */}
                  </ul>
                </div>

                <div id="menu3" className="tab-pane fade">
                  <ul>
                    <li className="tab-box">
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/uber.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/paytm.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/lyft.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/ola.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/clover.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/square.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/petpooja.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/posist.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/alexa.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/amazonalexa.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/nest.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/jovo.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/aws.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/twilio.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/rasa.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/paypal.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/razorpay.png"
                        alt="Partners"
                      />
                    </li>
                    <li>
                      <img
                        src="../assets/images/ourpartners/part/Other Partners/stripe.png"
                        alt="Partners"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="navbar"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
