import React from "react";
// import Header from '../templates/header';
import Footer from "../templates/footer";

const Refundcancellation = () => {
  return (
    <div className="app">
      <section className="refund-section">
        <div className="container">
          <div className="refund-heading">
            <h2>
              <b>REFUNDS & CANCELLATION</b>
            </h2>
          </div>
          <div className="refund-paragraph">
            <p>
              <b>CANCELLATION BY CUSTOMER:</b>
            </p>
            <p>
              <b>A.) WALK-IN /WAITLIST/RESERVATION</b> <br />
              1. The Customer may choose to cancel the Walk-in/Waitlist/
              Reservation request once placed and accepted by the Merchant at
              any time before the status changes to seated, without any
              cancellation fee. However, subject to the Customer’s previous
              cancellation history; Amealio reserves the right to Flag, Block
              and Suspension of account of the Customer
            </p>
            <p>
              2. If the Customer makes a Walk-in/Waitlist/Reservation request
              and does not reach the Restaurant until the expiry of ___ mins
              from the ETA (Expected time of arrival) nor cancels the
              Walk-in/Waitlist/Reservation request, the
              Walk-in/Waitlist/Reservation request shall be automatically
              cancelled at the instance of the Merchant. For the first time the
              Customer does not show up at the Restaurant without cancelling it,
              Amealio will give intimation. If the Customer does not show for
              the second time, Amealio will Flag the Customer and in case of
              continuous 3 no shows Amealio will block the Customer’s Account.
            </p>
            <p>
              3. In the event of request for cancellation of Walk-in/
              Waitlist/Reservation request, wherein the Customer has already
              placed an Order Ahead and made full payment through the Amealio
              Platform:
            </p>
            <p>
              a) The Customer may choose to cancel the Order only before 30
              minutes of the Expected time of arrival (ETA), without any
              cancellation fee and in such an event, the Order Value including
              Tips (if any paid) shall be refunded in the Amealio Wallet.
            </p>
            <p>
              b) In the event of request for cancellation of the order after the
              expiry of the ETA, Amealio may accept the request for cancellation
              of the Order and refund the amount up to the Order Value after
              deduction of 20% of the Order Value to the Customer within a
              reasonable period of time, provided that it shall be the sole
              discretion of Amealio to accept or reject the request for
              cancellation of Order. However, if any amount is paid by the
              Customer towards Tips, shall not be refunded.
            </p>
            <p>
              c) In the event, the Customer does not reach the Restaurant until
              the expiry of 30 minutes from the ETA nor cancels the
              Walk-in/Walklist request, the Walk-in/Waitlist request shall be
              automatically cancelled at the instance of the Merchant, Customer
              shall be liable to pay the liquidated damages of an amount
              equivalent to the Order Value. Amealio is authorized to deduct or
              collect the amount payable as liquidated damages by either not
              refunding the Order Value or deducting such amount from any
              payment made towards the next order. In such an event, the Tips
              (if any paid) shall be non-refundable.
            </p>
            <p>
              d) In any of the above circumstances, if any amount is paid by the
              Customer towards Donations, the same shall be non-refundable.
            </p>
            <p>
              <b>
                B.) TAKEAWAY/CURBSIDE/SKIP THE LINE/DINE-IN ORDER/HOME DELIVERY
              </b>{" "}
              <br />
              1. The Customer cannot edit the Order once placed and accepted by
              the Merchant and shall not be entitled to cancel the Order after
              the food processing starts.
            </p>
            <p>
              2. If the event, the Order is cancelled by the Merchant after the
              food processing starts for any of the reasons not attributable to
              the Customer, the Customer is eligible for the refund of the Order
              Value including Tips (if any paid) or any part thereof and the
              said amount will be reversed in the Amealio Wallet .
            </p>
            <p>
              3. In any of the above circumstances, if any amount is paid by the
              Customer towards Donations, the same shall be non –refundable.
            </p>
            <p>
              <b>CANCELLATION BY MERCHANT:</b> <br />
              1. The Merchant shall not be entitled to cancel the Request after
              acceptance and confirmation of the same. However, in the event the
              Merchant is either unable to honor the request of the Customer or
              cancels the Order, due to reasons including without limitation,
              technical errors, non-availability of food items ordered by the
              Customer, non – availability of the service, or any other reasons
              attributable to the Merchant, the Customer is eligible for the
              refund of the Order Value including Tips (if any paid) or any part
              thereof and the said amount will be reversed in the Amealio
              Wallet.
            </p>
            <p>
              2. In case of cancellation for any of the reasons attributable to
              the Merchant, Amealio shall not charge any liquidated damages from
              the Customer. However, if any amount is paid by the Customer
              towards Donations, the same shall be non-refundable.
            </p>
            <p>
              <b>CANCELLATION BY AMEALIO:</b> <br />
              1. There may be circumstances due to which Amealio is either
              unable to accept the order, due to any reasons including without
              limitation, technical errors, non-availability of the service,
              inaccurate particulars such as contact number, address, etc.
              provided by the Customer, unresponsive Customer, etc. In such
              circumstances, Amealio reserves the right, at its sole discretion,
              to refuse or cancel any order/booking for any reason whatsoever,
              without assigning any reason to the Customer and the Customer
              accepts and undertakes to not raise any dispute on the same.
            </p>
            <p>
              2. If the order is cancelled after payment has been made and the
              Customer is eligible for the refund of the Order Value or any part
              thereof in accordance with the policies of Amealio, the said
              amount including Tips (if any paid) will be reversed in the
              Customer’s Amealio Wallet. In the event the Customer has provided
              incorrect particulars, e.g., contact number, delivery address,
              etc., or that the Customer was unresponsive, not reachable or
              unavailable for fulfillment of the services offered to them, the
              Customer will not be eligible for any refunds.
            </p>
            <p>
              3. Amealio's decision of cancellation of order shall be final and
              Amealio shall not be liable for any such cancellation whatsoever.
              If any amount is paid by the Customer towards Donations, the same
              shall be non-refundable.
            </p>
            <p>
              <b>CANCELLATION AND REFUND FOR DONATIONS: </b>
            </p>
            <p>
              Any amount contributed by the User while placing an order /booking
              through the Amealio Platform towards donations to M/s Envisionard
              Udbhav Foundation (a Non-Profit organization) is voluntary and
              optional and the Donations once made cannot be cancelled and/or is
              non-refundable, even in case of cancellation of orders/bookings by
              the User.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Refundcancellation;
