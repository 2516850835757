import React from "react";
import Slider from "react-slick";
import WhyChooseAmealio from "./SliderComponent/whyChooseAmealio";

const customerfocused = (props) => {
  var settings = {
    dots: true,
    arrows: false,
    // className: "center",
    // centerMode: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 3,
    speed: 2000,
    autoplaySpeed: 6000,
    rows: 2,
    slidesPerRow: 1,
    swipeToSlide: true,
    mobileFirst: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          rows: 2,
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          rows: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          rows: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageSliderData = [
    {
      id: 1,
      title: "Grow your sales and profit margins",
      paragraph: "With increased visibility and amazing features,",
      paragraphTwo: "customers are surely going to be lining up",
      imgTitle: "Grow your sales and profit margins",
      imgOne: "Grow.png",
    },
    {
      id: 2,
      title: "Delivery powered by your own staff",
      paragraph: "Use your own delivery services and avoid",
      paragraphTwo: "additional expenses",
      imgTitle: "Delivery powered by your own staff",
      imgOne: "Delivery.png",
    },
    {
      id: 3,
      title: "Increase your Brand outreach",
      paragraph: "Show up and get discovered by many",
      paragraphTwo: "more customers with increased visibility",
      imgTitle: "Increase your Brand outreach",
      imgOne: "Brand.png",
    },
    {
      id: 4,
      title: "Control offers & events like a pro",
      paragraph: "Take charge of our features and manage",
      paragraphTwo: "your upcoming offers and events.",
      imgTitle: "Control offers & events",
      imgOne: "offers.png",
    },
    {
      id: 5,
      title: "Gain customer trust with live streaming.</b>",
      paragraph: "Showcase your culinary skills and cleanliness",
      paragraphTwo: "with live streaming.",
      imgTitle: "Live Streaming Gain",
      imgOne: "Gain.png",
    },
    {
      id: 6,
      title: "Garner more sales with less effort.</b>",
      paragraph: "Let our features get you the business",
      paragraphTwo: "you need to grow and expand.",
      imgTitle: "Business effort",
      imgOne: "effort.png",
    },
  ];

  return (
    <section className="partnerup-section">
      {/* <img src="../assets/images/business/Background Red Icons (Opacity 6%).png" width="100%"></img> */}
      <img
        src="../assets/images/business/backgroundwhiteicons.png"
        alt="partner-Icon"
        title="Partner Icon"
        className="partnerup-boundary"
      ></img>
      <div className="container text-center">
        {/************** DeskTop ************/}
        <h2 className="f42-h3 text-white d-none d-sm-none d-md-block mb-5 pb-4">
          <b>Why Choose Amealio to Partner?</b>
        </h2>
        {/************** Mobile ************/}
        <h2 className="f24-h5 text-white d-block d-sm-block d-md-none mb-5 pb-4">
          <b>Why Choose Amealio to Partner?</b>
        </h2>
        <div className="w-100 h-auto partner-new-slider">
          <Slider {...settings}>
            {imageSliderData &&
              imageSliderData.map((data, index) => {
                return <WhyChooseAmealio keys={index} data={data} />;
              })}
          </Slider>
        </div>
        {/* <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="partnerup-center">
              <div className="partnerup-round">
                <img
                  src="../assets/images/business/Grow.png"
                  alt="Grow"
                  title="Grow"
                  width="60%"
                  className="partnerup-img"
                ></img>
              </div>
              <h4 className="f18-p2 text-white">
                <b>Grow your sales and profit margins</b>
              </h4>
              <p className="f12-p5 text-white">
                With increased visibility and amazing features,
                <br />
                customers are surely going to be lining up
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="partnerup-center">
              <div className="partnerup-round">
                <img
                  src="../assets/images/business/Delivery.png"
                  alt="Delivery"
                  title="Delivery-Partners"
                  width="60%"
                  className="partnerup-img"
                ></img>
              </div>
              <h4 className="f18-p2 text-white">
                <b>Delivery powered by your own staff</b>
              </h4>
              <p className="f12-p5 text-white">
                Use your own delivery services and avoid
                <br />
                additional expenses
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="partnerup-center">
              <div className="partnerup-round">
                <img
                  src="../assets/images/business/Brand.png"
                  alt="Brand"
                  title="Brand"
                  width="60%"
                  className="partnerup-img"
                ></img>
              </div>
              <h4 className="f18-p2 text-white">
                <b>Increase your Brand outreach</b>
              </h4>
              <p className="f12-p5 text-white">
                Show up and get discovered by many
                <br />
                more customers with increased visibility
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="partnerup-center">
              <div className="partnerup-round">
                <img
                  src="../assets/images/business/offers.png"
                  alt="Offers"
                  title="Offers"
                  width="60%"
                  className="partnerup-img"
                ></img>
              </div>
              <h4 className="f18-p2 text-white">
                <b>Control offers & events like a pro</b>
              </h4>
              <p className="f12-p5 text-white">
                {" "}
                Take charge of our features and manage
                <br />
                your upcoming offers and events.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="partnerup-center">
              <div className="partnerup-round">
                <img
                  src="../assets/images/business/Gain.png"
                  alt="Gain"
                  title="Gain"
                  width="60%"
                  className="partnerup-img"
                ></img>
              </div>
              <h4 className="f18-p2 text-white">
                <b>Gain customer trust with live streaming.</b>
              </h4>
              <p className="f12-p5 text-white">
                Showcase your culinary skills and cleanliness
                <br />
                with live streaming.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="partnerup-center">
              <div className="partnerup-round">
                <img
                  src="../assets/images/business/effort.png"
                  alt="Effort"
                  title="Effort"
                  width="60%"
                  className="partnerup-img1"
                ></img>
              </div>
              <h4 className="f18-p2 text-white">
                <b>Garner more sales with less effort.</b>
              </h4>
              <p className="f12-p5 text-white">
                Let our features get you the business
                <br />
                you need to grow and expand.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};
export default customerfocused;
