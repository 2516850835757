import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import Header from '../templates/header';
import Footer from "../templates/footer";

const WalletTerms = () => {
  let location = useLocation();
  const [displayHF, setDisplayHF] = useState(0);

  /****************************
   * @DESC SET PAGE PATH NAME
   ***************************/
  useEffect(() => {
    setDisplayHF(location?.pathname === "/walletterms" ? 1 : 0);
  }, [location]);

  return (
    <div className="app">
      <section>
        <div className="container">
          <div className="business">
            <h2 className={`${displayHF === 0 ? "pt-0" : ""}`}>
              <b>Amealio Wallet - Terms and Conditions</b>
            </h2>
          </div>
          <div className="tandc">
            <p>
              Please read the following terms and conditions (“T&Cs”) carefully
              before registering for, accessing or using the Amealio Wallet (as
              defined below), located at www.amealio.com or the Amealio mobile
              application or any similar platform (hereinafter referred to as
              “Amealio Platform”). The Amealio Wallet is issued by and the
              Amealio Wallet Services are provided by Envisionard Ads and
              Marketing Private Limited, a private limited company registered
              under the Companies Act, 2013 having its registered office at Plot
              No.179, NCL North Avenue, Kompally, Near Sai Baba Temple,
              Qutubullapur, Hyderabad, Telangana – 500014 (“Envisionard”). By
              registering for or using Amealio Wallet, you represent and warrant
              that you have the right, authority and capacity to enter into
              these T&Cs and to abide by all the T&Cs.
            </p>
            <p>
              <b>Eligibility:</b>
              <br />
              a.) The Amealio Wallet Services are not available to persons under
              the age of 18 or to anyone previously suspended or removed by
              Envisionard from availing the Amealio Wallet Services or accessing
              the Amealio Platform. By accepting the T&Cs or by otherwise using
              the Amealio Wallet Services on the Amealio Platform, you represent
              that you are at least 18 years of age and have not been previously
              suspended by Envisionard, or disqualified for any other reason,
              from availing the Amealio Wallet Services or using the Amealio
              Platform. Finally, in the event of any violation of the T&Cs,
              Envisionard reserves the right to suspend or permanently prevent
              you from availing Amealio Wallet Services or using the Amealio
              Platform.
            </p>
            <p>
              <b>1. Definitions:</b>
            </p>
            <p>
              <b>i.) Customer or You</b>
              <br />
              Customer or You means a person who has registered with Amealio
              Platform and avails the Amealio Wallet, and who has accepted the
              T&Cs, and a person who owns, operates, or has access to an
              internet compatible device that supports the Amealio Wallet and
              the Amealio Platform.{" "}
            </p>
            <p>
              <b>ii.) Amealio Wallet</b>
              <br />
              Amealio Wallet means the closed system pre-paid payment instrument
              issued by Envisionard.
            </p>
            <p>
              <b>iii.) Amealio Wallet Account</b>
              <br />
              Amealio Wallet Account means the Customer Amealio Wallet account
              classified as closed system payment instruments issued by
              accepting minimum customer details being Customer name, mobile
              number, minimum KYC details, which permit payment for purchase of
              eligible products from Amealio Platform.{" "}
            </p>
            <p>
              <b>iv.) KYC</b> <br />
              KYC stands for know your customer and refers to the personal
              identification and other information required by Envisionard from
              you before any Amealio Wallet Services can be delivered. KYC
              documents/ information may be required by Envisionard from the
              Customer at the time of registration and/or on a later date, for
              availing and/or continuation of the Amealio Wallet.
            </p>
            <p>
              <b>v.) Password</b> <br />
              Password means the secret password used to secure Amealio Wallet
              applications, without knowledge of which your Amealio Wallet will
              not be operable.
            </p>
            <p>
              <b>vi.) Transaction</b> <br />
              Transaction means purchase of eligible products on the Amealio
              Platform.
            </p>
            <p>
              <b>2. Documentation:</b> <br />
            </p>
            <p>
              i.) The collection, verification, audit and maintenance of correct
              and updated Customer information is a continuous process and
              Envisionard reserves the right, at any time, to take steps
              necessary to ensure compliance with all relevant and applicable
              KYC requirements. Envisionard reserves the right to discontinue
              services or reject applications for Amealio Wallet at any time if
              there are discrepancies in information and/or documentation by
              you.
            </p>
            <p>
              ii.) You may be required to complete a verification process by
              furnishing your details (such as your name and date of birth) to
              comply with the KYC requirement under applicable laws.
            </p>
            <p>
              iii.) Any information provided to Envisionard with the intention
              of securing Amealio Wallet services shall vest with Envisionard,
              and may be used by Envisionard, for any purpose that is not
              inconsistent with any applicable law or regulations, at its
              discretion.
            </p>
            <p>
              <b>3. General Conditions for Amealio Wallet:</b> <br />
            </p>
            <p>
              i.) You will be issued a Amealio Wallet Account immediately after
              successful registration.
            </p>
            <p>
              ii.) The Customers using the Amealio Wallet may load amounts in
              the wallet through their credit or debit cards, netbanking and the
              amounts in the Amealio Wallet may be used for making payments
              towards transactions done by customers on the Amealio Platform.
              Except as provided hereunder, amounts in your Amealio Wallet will
              not be refunded to you under any circumstances.
            </p>
            <p>
              iii.) Customers shall not be permitted to make any cash
              withdrawals from the Amealio Wallet. Any outstanding credit in the
              Amealio Wallet must be utilized only to make payments for bona
              fide Transactions. No interest will be payable by Envisionard on
              the available balance reflected on the Amealio Wallet.
            </p>
            <p>
              iv.) Amealio Wallet is available only to resident Indians who have
              attained the age of 18 years and are competent to contract.
            </p>
            <p>v.) Amealio Wallets are not transferable.</p>
            <p>
              vi.) You may add money into your Amealio Wallet upto a maximum of
              INR 10,000 (Rupees ten thousand only) per month. This limit will
              be inclusive of any credits made by you and any refunds received
              by you for any Transactions in your Amealio Wallet. Additionally,
              the balance in your Amealio Wallet shall not exceed INR 10,000
              (Rupees ten thousand only) at any relevant time of determination.
              These limits may be reviewed and modified at the discretion of
              Envisionard without prior intimation to the Customer.
            </p>
            <p>
              vii.) Envisionard reserves the right to suspend/discontinue
              Amealio Wallet services to you at any time, for any cause,
              including, but not limited, to the following -
            </p>
            <div className="px-3">
              <p>a.) For any suspected violation of the T&Cs;</p>
              <p>
                b.) For any suspected discrepancy in the particular(s),
                documentation provided by you;
              </p>
              <p>
                c.) To combat potential fraud, sabotage, wilful destruction,
                threat to national security or for any other force majeure
                event;
              </p>
              <p>
                d.) If the same is due to technical failure, modification,
                upgradation, variation, relocation, repair, and/or maintenance
                due to any emergency or for any technical reasons;
              </p>
              <p>
                e.) If the same is due to any transmission deficiencies caused
                by topographical and geographical constraints/limitations;
              </p>
              <p>
                f.) If the mobile connection with which your Amealio Wallet is
                related ceases to be operational or in your possession or
                control;
              </p>
              <p>
                g.) If Envisionard believes, in its reasonable opinion, that
                cessation/suspension is necessary for any other legitimate
                purpose.
              </p>
            </div>
            <p>
              viii.) You are permitted to maintain and operate only one Amealio
              Wallet. Any suspected non-conformity with this requirement shall
              constitute grounds for the suspension/discontinuation by
              Envisionard of any of all Amealio Wallets associated with you on
              the Amealio Platform. Any operation of or continued availability
              of any facility respect to the Amealio Wallets from time to time
              shall be subject to any requirements under applicable laws, and
              any new regulations or directions from any regulatory authorities
              in India.
            </p>
            <p>
              ix.) Any amount transferred erroneously by the Customer, while
              adding money to or recharging the Amealio Wallet or while
              executing Transaction on Amealio Platform, shall not be refunded
              to the Customer by Envisionard in any circumstances.
            </p>
            <p>
              <b> 4. Amealio Wallet Validity:</b> <br />
            </p>
            <p>
              i.) Any value in your Amealio Wallet that is utilized towards
              making payments for any Transaction shall be automatically debited
              from your Amealio Wallet. Envisionard does not endorse, promote or
              warrant any goods and/or services that might be bought/availed or
              proposed to be bought/availed using Amealio Wallet.
            </p>
            {/* <p>
              ii.) Your Amealio Wallet account stays active and there is no
              expiry date for your wallet balance.
            </p> */}
            <p>
              <b>5. Customer Obligations:</b>
              <br />
            </p>
            <p>
              i.) Amealio Wallet availability is subject to the maintenance of
              an active mobile phone or internet connection with an associated
              telecom provider. Amealio Wallet availability is subject to the
              maintenance of a mobile phone handset and other application on
              which Amealio Platform can run and the Customer is solely
              responsible for all liability arising from unavailability of the
              Amealio Platform due to a deficient mobile handset or the internet
              service provider not being able to support any Amealio Wallet
              application.
            </p>
            <p>
              ii.) You must ensure the availability of sufficient funds in your
              Amealio Wallet before executing any Transaction from your Amealio
              Wallet.
            </p>
            <p>
              iii.) You shall be solely responsible for the confidentiality,
              safety and security of the Password. You shall be the sole owner
              of the Password and shall be responsible for the consequences
              arising out of disclosure of the Password and/or the unauthorized
              use of Amealio Wallet. In case the Password is lost or misplaced,
              you shall promptly inform Envisionard by calling on the customer
              care numbers where after the existing Password will be barred and
              a new Password will be issued to you after necessary validation.
              In case the mobile phone/SIM card/mobile number associated with
              your Amealio Wallet is lost/stolen/misplaced/no longer in your
              control, you shall promptly inform Envisionard. Envisionard will,
              upon receipt of such information, block the relevant account.
            </p>
            <p>
              iv.) You acknowledge and understand that Amealio Wallet is linked
              to your mobile phone number and you shall be solely responsible
              for any liability arising out of the loss/theft/misuse of the
              mobile phone number or deactivation of mobile connection by the
              concerned telecom service provider.
            </p>
            <p>
              v.) Information submitted by you for availing Amealio Wallet
              and/or information submitted while using Amealio Wallet may be
              shared by Envisionard with any affiliate of Envisionard or any
              third party, inter alia, to facilitate the provision of Amealio
              Wallet or for the purposes specified in the T&Cs.
            </p>
            <p>
              <b>6. Indemnification:</b>
              <br />
            </p>
            <p>
              i.) You agree to indemnify, save, and hold Envisionard, its
              affiliates, contractors, employees, officers, directors, agents
              and its third party suppliers, licensors, and partners harmless
              from any and all claims, losses, damages, and liabilities, costs
              and expenses, including without limitation legal fees and
              expenses, arising out of or related to : (i) your use or misuse of
              the Amealio Wallet; (ii) any violation by you of the T&Cs,
              including the Amealio Wallet Terms; or (iii) any breach of the
              representations, warranties, and covenants made by you herein.
              Envisionard reserves the right, at your expense, to assume the
              exclusive defense and control of any matter for which you are
              required to indemnify Envisionard, including rights to settle, and
              you agree to cooperate with Envisionard’s defense and settlement
              of these claims. Envisionard will use reasonable efforts to notify
              you of any claim, action, or proceeding brought by a third party
              that is subject to the foregoing indemnification upon becoming
              aware of it. This paragraph shall survive termination of these
              T&Cs, including the Amealio Wallet Terms.
            </p>
            <p>
              <b>7. Additional T&C:</b>
              <br />
            </p>
            <p>
              i.) In the event of any Dispute (defined below), Envisionard
              records shall be binding as the conclusive evidence of the
              Transactions carried out through the use of Amealio Wallet.
            </p>
            <p>
              ii.) Envisionard shall send all customer communications by SMS
              and/or e-mail and such SMS shall be deemed to have been received
              by you after they have been submitted for delivery to the mobile
              phone operator.
            </p>
            <p>
              iii.) You agree to receive all commercial messages including
              transactional messages from Amealio.
            </p>
            <p>
              iv.) The parties are independent contractors and nothing in these
              Amealio Wallet Terms shall be deemed to create an agency or
              employment relationship, a franchisor-franchisee relationship,
              joint venture or partnership
            </p>
            <p>
              <b>8. Amendment of Terms:</b>
              <br />
            </p>
            <p>
              i.) Envisionard may modify, terminate and/or suspend Amealio
              Wallet to the Customer anytime with or without prior notice, due
              to any changes in internal policies, rules, regulations and laws
              set by relevant authorities/regulators. Envisionard reserves the
              right to change, modify, add, or remove portions of these T&Cs at
              any time by posting notification on the Amealio Platform or
              otherwise communicating the notification to you. If you do not
              agree with any such change, your sole and exclusive remedy is to
              terminate your use of the Amealio Platform/ Amealio Wallet. Your
              continued use of the Amealio Platform and the Amealio Wallet
              following the posting of changes will mean that you accept and
              agree to the changes.
            </p>
            <p>
              <b>9. Confidentiality:</b>
              <br />
            </p>
            <p>
              i.) You specifically agree that in order to facilitate the
              provision of Amealio Wallet, Envisionard may be required to
              disclose any information or particulars pertaining to you to any
              authority, statutory or otherwise or any third party.
            </p>
            <p>
              <b>10. Disclaimer of Warranties:</b>
              <br />
            </p>
            <p>
              i.) The Customer expressly agrees that the access / use of the
              Amealio Wallet and/or availment of Services are at the Customer’s
              sole risk. The Wallet facility is provided on an "as is" and "as
              available" basis. Except as warranted in the Terms, Envisionard
              expressly disclaims all warranties of any kind, whether express or
              implied or statutory and including, but not limited to the implied
              warranties of merchantability, fitness for a particular purpose,
              data accuracy, completeness and any warranties relating to the
              non-intrusion by hackers into the Amealio Wallet / Services.
            </p>
            <p>
              ii.) Envisionard does not warrant that access to the Amealio
              Wallet and/or Services shall be uninterrupted, timely, secure, or
              error free nor does it make any warranty as to the results that
              may be obtained from the use of the Wallet, accuracy or
              reliability of the Amealio Wallet and/or Services. Envisionard
              does not warrant and shall not be responsible for any delay in
              carrying on the instructions due to any reason whatsoever,
              including due to failure of operational systems, technical issues
              or due to any requirement of law.
            </p>
            <p>
              iii.) Envisionard will not be liable for any malware/ virus that
              may enter the Customer's system as a result of the Customer
              using/accessing the Amealio Wallet and/or during availment of the
              Services. Envisionard does not guarantee to the Customer or any
              other third party that the Amealio Wallet would be virus/ malware
              free.
            </p>
            <p>
              <b>11. Consent to Electronic Communications and Transactions:</b>
              <br />
            </p>
            <p>
              i.) By registering to use the Amealio Wallet, you consent to
              conduct Transactions and receive communications, notices and
              information from us electronically, whether sent by e-mail or
              other electronic means. Electronic communications shall be deemed
              to have been received by you when Envisionard sends an electronic
              communication to your e-mail address/mobile number as per our
              records, or when Envisionard posts an electronic communication on
              the Amealio Platform. The pre-requisites for effective electronic
              Transactions and communications are as follows: (a) you must have
              access to the Internet through a broadband connection or mobile
              data or through any other applications; and (b) you must have a
              valid e-mail address and mobile number. You can withdraw your
              consent for electronic communication at any time by contacting
              Envisionard, but Envisionard reserves the right to terminate your
              Amealio Wallet Account, as the case may be, upon such withdrawal.
              Withdrawal of your consent for electronic communication will not
              affect the legal validity and enforceability of any notice,
              statement or disclosure previously received electronically. You
              agree to notify Envisionard promptly if your email address, mobile
              number or other contact information changes by updating your
              account information or contacting Envisionard.
            </p>
            <p>
              <b>12. Limitation of Liability:</b>
              <br />
            </p>
            <p>
              i.) Neither Envisionard nor its Affiliates shall be liable for any
              unauthorized transactions occurring through the access / use of
              the Wallet and/or availment of Services and the Customer hereby
              fully indemnifies and holds Envisionard and the Affiliates
              harmless against any action, suit, proceeding initiated against it
              or any loss, cost or damage incurred by it as a result thereof.
              Envisionard shall under no circumstance be held liable to the
              Customer if the access to the Wallet and/or Services is not made
              available in the desired manner for reasons including but not
              limited to natural calamities, legal restraints, faults in the
              telecommunication network or network failure or any other reason
              beyond the control of Envisionard. Under no circumstances shall
              Envisionard be liable for any damages whatsoever.
            </p>
            <p>
              ii.) whether such damages are direct, indirect, incidental
              consequential and irrespective of whether any claim is based on
              loss of revenue, interruption of business or any loss of any
              character or nature whatsoever and whether sustained by the
              Customer or by any other person. Illegal or improper use of the
              Amealio Wallet and/or Services shall render the Customer liable
              for payment of financial charges as decided by Envisionard and/or
              will result in suspension of the operations through the Wallet.
            </p>
            <p>
              <b>13. Dispute Resolution :</b>
              <br />
            </p>
            <p>
              i.) If any dispute, controversy or claim arises under these T&Cs
              or in relation to a Amealio Wallet or the Amealio Platform,
              including any question regarding the existence, validity or
              termination of the Amealio Wallet Terms or the T&Cs in general or
              these T&Cs, the parties shall use all reasonable endeavours to
              resolve such Dispute amicably. If the parties are unable to
              resolve the Dispute amicably within 30 days of the notice of such
              Dispute, Envisionard may elect to resolve any Dispute by a binding
              arbitration in accordance with the provisions of the Indian
              Arbitration & Conciliation Act, 1996 (hereinafter Act). Such
              Dispute shall be arbitrated on an individual basis and shall not
              be consolidated in any arbitration with any claim or controversy
              of any other party. The Dispute shall be resolved by a sole
              arbitrator, appointed in accordance with the Act. The seat of the
              arbitration shall be Pune and the language of this arbitration
              shall be English. This paragraph shall survive termination of
              these T&Cs.
            </p>
            <p>
              <b>14. Governing Law:</b>
              <br />
            </p>
            <p>
              i.) Subject to the Dispute Resolution section above, you agree
              that any claim or dispute you may have against Envisionard must be
              resolved by a court having jurisdiction in Pune, India. You agree
              to submit to the personal jurisdiction of the courts located
              within Pune, India, for the purpose of litigating all such claims
              or disputes. These T&Cs shall be governed by Indian law. This
              paragraph shall survive termination of these T&Cs.
            </p>
            <p>
              <b>15. Severability:</b>
              <br />
            </p>
            <p>
              i.) If any provision of the T&Cs in general or as a whole is held
              to be unlawful, void, invalid or otherwise unenforceable, then
              that provision will be limited or eliminated from the T&Cs to the
              minimum extent required, and the remaining provisions will remain
              valid and enforceable.
            </p>
            <p>
              <b>16. Customer Service:</b>
              <br />
            </p>
            <p>
              i.) Any questions, comments or complaints related to your Amealio
              Wallet should be referred to Amealio customer care by email to
              support@amealio.com, or by calling our customer service number at
              8575509999.
            </p>
          </div>
        </div>
      </section>
      {displayHF === 0 ? <></> : <Footer />}
    </div>
  );
};
export default WalletTerms;
