import React from "react";
import Slider from "react-slick";
import serviceSprial from "./../../newAssets/images/HomePage/sprial/FoodPartnersSprial.png";
import FoodPartnersSlider from "./HomePageSlider/foodPartnersSlider";

const Service = (props) => {
  var settings = {
    dots: true,
    arrows: false,
    // className: "center",
    // centerMode: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 6,
    speed: 2000,
    autoplaySpeed: 6000,
    rows: 2,
    slidesPerRow: 1,
    swipeToSlide: true,
    mobileFirst: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageSliderData = [
    {
      id: 1,
      imgTitle: "Rocomama's",
      imgSrc: "Rocomamas",
    },
    {
      id: 2,
      imgTitle: "Elephant & Co",
      imgSrc: "Elephant-&-Co",
    },
    {
      id: 3,
      imgTitle: "Biggies Burger",
      imgSrc: "Biggies-Burger",
    },
    {
      id: 4,
      imgTitle: "Drunken Monkey",
      imgSrc: "Drunken-Monkey",
    },
    {
      id: 5,
      imgTitle: "Barista",
      imgSrc: "Barista",
    },
    {
      id: 6,
      imgTitle: "Crazy Noodles",
      imgSrc: "Crazy-Noodles",
    },
    {
      id: 7,
      imgTitle: "Cream Craver",
      imgSrc: "Cream-Craver",
    },

    {
      id: 8,
      imgTitle: "Hennys Gourmet",
      imgSrc: "Hennys-Gourmet",
    },
    {
      id: 9,
      imgTitle: "High spirits cafe",
      imgSrc: "High-spirits-cafe",
    },
    {
      id: 10,
      imgTitle: "Huber And Holly",
      imgSrc: "Huber-And-Holly",
    },
    {
      id: 11,
      imgTitle: "The 3 Muskeeters",
      imgSrc: "The-3-Muskeeters",
    },
    {
      id: 12,
      imgTitle: "The Chocolate Room",
      imgSrc: "The-Chocolate-Room",
    },
    {
      id: 13,
      imgTitle: "Yummy Q",
      imgSrc: "Yummy-Q",
    },
    {
      id: 14,
      imgTitle: "Monks And Blue",
      imgSrc: "Monks-and-blue",
    },
    {
      id: 15,
      imgTitle: "Irani Cafe",
      imgSrc: "Irani-Cafe",
    },
    {
      id: 16,
      imgTitle: "Jimmy's",
      imgSrc: "Jimmys",
    },
    {
      id: 17,
      imgTitle: "Kaka Halwai Sweets",
      imgSrc: "Kaka-Halwai-Sweets",
    },
    {
      id: 18,
      imgTitle: "Akhtars Samosa",
      imgSrc: "Akhtars-Samosa",
    },
    {
      id: 19,
      imgTitle: "Shiv Sagar Veg Restaurant",
      imgSrc: "Shiv-Sagar-Veg-Restaurant",
    },
    {
      id: 20,
      imgTitle: "Savlahari",
      imgSrc: "Savlahari",
    },
  ];

  return (
    <>
      <section
        className="food-partners-main"
        style={{
          backgroundColor: props.displaySprialImg
            ? "rgba(136, 132, 236, 0.24)"
            : "#f3f3f3",
        }}
      >
        <div className="container-fluid">
          {props.displaySprialImg ? (
            <div className="col-12">
              <div className="top-image-right-sprial">
                <img
                  src={serviceSprial}
                  alt="Footer Sprial"
                  title="Footer Sprial"
                  className="img-fluid"
                ></img>
              </div>
            </div>
          ) : null}
          <h1 className="f42-h3 text-center d-none d-sm-none d-md-block">
            500+ Food Partners & Counting...
          </h1>
          <h1 className="f42-h3 text-center d-block d-sm-block d-md-none">
            500+
            <br />
            Food Partners
            <br />& Counting...
          </h1>
          <div className="w-100 h-auto my-0 my-sm-0 my-md-5 pt-0 pt-sm-0 pt-md-5">
            <Slider {...settings}>
              {imageSliderData &&
                imageSliderData.map((data) => {
                  return (
                    <React.Fragment key={data.id}>
                      <FoodPartnersSlider
                        title={data.imgTitle}
                        imgSrc={data.imgSrc}
                      />
                    </React.Fragment>
                  );
                })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
