import React from "react";
// import Header from '../templates/header';
import Footer from "../templates/footer";
const Businessterms = () => {
  return (
    <div className="app">
      <section>
        <div className="container">
          <div className="business">
            <h2>
              <b>BUSINESS TERMS AND CONDITIONS</b>
            </h2>
          </div>
          <div className="tandc">
            <p>
              These Terms of Service (Terms of Service) is an electronic record
              in terms of Information Technology Act, 2000 and rules there under
              as applicable and the amended provisions pertaining to electronic
              records in various statutes as amended by the information
              Technology Act, 2000. This document is published in accordance
              with the provisions of Rule 3 (1) of the Information Technology
              (Intermediaries guidelines) Rules, 2021 that require publishing
              the rules and regulations, privacy policy and Terms of Use for
              access or usage of www.amealio.com website and/or Amealio
              application for mobile and handheld devices ("Amealio Platform)
            </p>
            <p>
              This Platform is operated by Envisionard Ads and Marketing Private
              Limited: a Company incorporated under the Companies Act, 2013 and
              having its office address at T-Hub, IT Campus, Gachibowli, i,
              Hyderabad, Telangana-500032. These Terms of Service constitute a
              legally binding agreement between Merchants registered on the
              Amealio for Business Platform ("Merchant" or "You" or "Your) and
              Envisionard Ads and Marketing Private Limited ("Amealio","we",
              "us" or "our"), regarding use of Amealio's Platform for the
              provision of Restaurant Services by the Merchant to the Customers.
            </p>
            <p>
              <b>
                To use the Services, you must first accept the Terms. You can
                accept the Terms by either:
              </b>{" "}
              <br />
              a.) By Clicking to accept or agree to the Terms, when provided by
              Amealio in the user interface for any specific Service; or
            </p>
            <p>
              b) By actually using the Services In event of which you
              acknowledge and agree that Amealio will regard your continued use
              of the Services as acceptance of the Terms.
            </p>
            <p>
              Your use of the Platform is at your own risk, including the risk
              that you might be exposed to content that is objectionable, or
              otherwise inappropriate.
            </p>
            <p>
              <b>1. Definitions:</b>
            </p>
            <p>
              <b>i.) Customer</b> <br />
              "Customer is referred as a customer of the Services and who places
              the Orders/Bookings through the Amealio's Platform. A customer is
              someone who accesses or uses the Services for the purpose of
              sharing, displaying hosting, publishing, transacting, or uploading
              information of views or pictures and includes other persons
              jointly participating in using the Services.{" "}
            </p>
            <p>
              <b>ii.) Content</b> <br />
              "Content will include (but is not limited to) reviews, images,
              photos, audio, video, location data, nearby places, and all other
              forms of information or data Customer Content means content that
              the customer who uploads, shares or transmits to through or in
              connection with the Services, such as likes, ratings, reviews,
              images, photos, messages, profile information, and any other
              materials that you publicly display or displayed in your account
              profile "Amealio Content' means content that Amealio creates and
              make available in connection with the Services including, but not
              limited to, visual interfaces, interactive features, graphics,
              design, compilation computer code, products software, aggregate
              ratings, reports and other usage-related data in connection with
              activities associated with your account and all other elements and
              components of the Services excluding Your Content and Third Party
              Content, Third Party Content means content that comes from parties
              other than Amealio or its Customers and is available on the
              Services.
            </p>
            <p>
              <b>iii.) Restaurant(s)</b> <br />
              "Restaurant" means a commercial establishment(s) from where the
              Restaurant Services are made available to the Customer, moreover
              where in the food and beverage items are prepared and/or delivered
              to the Customer.{" "}
            </p>
            <p>
              <b>iv.) Merchant(s)</b> <br />
              Merchant means the entity/individual being the legal
              owner/operator of the Restaurant and includes the merchant(s)
              listed on the Amealio Platform.
            </p>
            <p>
              <b>2. Amealio's Obligations:</b> <br />
            </p>
            <p>
              i.) Amealio will (a) list the Menu /Food items along with the
              price list on the Platform: (b) display photos of Food items and
              Business establishment and (c) display allergy and/or nutrition
              information if the Merchant has agreed to provide the same.
            </p>
            <p>
              ii.) All necessary information provided by the Merchant will
              displayed by Amealio on the Platform, an best effort basis.
              However, Amealio is not obliged to display any information until
              the Merchant provides all required information and such
              information is in compliance with Amealio's policies and
              guidelines.
            </p>
            <p>
              iii.) Amealio shall have the right to collect the Payment on
              behalf of the Merchant in respect of the Orders/Events/Bookings
              received through the Platform from the Customers and remit the
              same to the bank account of the Merchant in accordance with the
              Payment Terms after deducting therefrom (1) Service Fee (1) Other
              Charges () GST and (v) Other applicable taxes thereon at the
              prevailing rates.
            </p>
            <p>
              iv.) Amealio will transmit the Orders placed by the Customer with
              the Merchant on the Merchant dash board on the Application and
              such order transmission will be on a real time basis.
            </p>
            <p>
              v.) Amealio reserves the right to automatically cancel the request
              for Orders/Bookings received through the Platform, if the Merchant
              does not accept the request within a time span of 5 (Five) minutes
              of receipt of request from the Customer. In case of request for
              Orders/Bookings received from the Customer through the Platform
              for a future date, Amealio reserves the right to automatically
              cancel the request for Orders/Bookings if the Merchant does not
              accept the request within 24 hours of the receipt of request for
              Orders/Bookings from the Customers through the Platform.
            </p>
            <p>
              vi.) Amealio will redress the Customer and the Merchant's
              complaints in respect of the functioning of the Platform
            </p>
            <p>
              vii.) For the avoidance of doubt it is hereby expressly clarified
              that Amealio will only be responsible for providing () a Platform
              to the Merchant to list, offer and sell the Restaurant Services to
              the Customers and/ or () Logistics Services (if applicable) and
              that Amealio will not be responsible or liable for (i) the quality
              of the Restaurant Services listed and advertised on the Platform,
              and/or (ii) the processing of the Orders which have been placed by
              the Customers with the Merchant on the Platform and/or (i) any
              delay in preparation or delivery of the Order by Merchant.
            </p>
            <p>
              viii.) if the Merchant is found to be non-compliant with the Food
              Safety and Standards Act 2006 and the rules, regulations,
              licenses, standards and guidelines issued thereunder from time to
              time, Amealio may suspend the merchant's account.
            </p>
            <p>
              xi.) in the event, the Merchant opts for Logistics Services: (a)
              Merchant agrees to pay the delivery charges as may be levied by
              Amealio. (b) The Merchant acknowledges and agrees that delivery
              will be facilitated by Amealio through a Logistics Personnel.
              Merchant understands and agrees that Amealio shall not be
              responsible if the logistics personnel indulges in theft of the
              Order or indulges in any illegal activity or misconduct
              against/with the Customer(s) and / or the staff of Merchant or
              indulges in any vandalism activity against the Merchant. (c) The
              Merchant shall not hold Amealio liable for any claims, raised by
              the Customer where the delay in handing over the Order to the
              Logistics Personnel is solely attributable to the Merchant and its
              staff. (d) Amealio may at its sole discretion charge Delivery
              Charges from Customer for delivery of the Order.
            </p>
            <p>
              <b>3. Merchant's Obligations:</b> <br />
              i.) The Merchant shall accept the request for Orders/Bookings
              received through the Platform within a time span of 5 (Five)
              minutes of receipt of request from the Customer, failing which the
              request for Orders/Bookings shall be automatically cancelled. In
              case of request for Orders/Bookings received from the Customer
              through the Platform for a future date, the Merchant shall accept
              the order at the earliest possible but not later than 24 hours of
              the receipt of request from the Customer. After the expiry of 24
              hours, the request for Orders/Bookings shall stand automatically
              cancelled.
            </p>
            <p>
              ii.) While servicing Orders/Bookings received from Customer
              through the Platform and/or providing Restaurant Services to
              Customers, the Merchant will not discriminate in any manner.
            </p>
            <p>
              iii.) Merchant shall ensure that all mandatory information
              pertaining to taxes levies, and charges applicable on the
              Order(s)/Booking (s) are clearly visible to the Customers on their
              invoice as per the applicable laws.
            </p>
            <p>
              iv.) Merchant will ensure that the information provided to Amealio
              is current and accurate, including but not limited to the
              Merchant's name, address, contact number, email, manager/contact
              person details, delivery times, opening hours, menus, price lists,
              allergy and nutrition information, taxes, service addresses, and
              other relevant information.
            </p>
            <p>
              v.) Merchant shall ensure that it is the sole owner of, or
              otherwise controls all the content/material, including but not
              limited to the Merchant's name. Restaurant/business establishment
              name, logo, menu items, images of the food and beverages/menu
              items, etc. transmitted or submitted by the Merchant to Amealio
              either through the Platform or otherwise ("Content"), or has been
              granted the right to use such Content from the rights holder of
              such Content and that it does not violate or infringe the rights
              of any third party or applicable laws.
            </p>
            <p>
              vi.) Merchant shall process and execute the Order(s)/Booking(s)
              promptly.
            </p>
            <p>
              vii.) Merchant shall not cancel any order/booking after acceptance
              of the order through the Platform in the event the Merchant
              cancels any order, Amealio reserves the right to levy or recover a
              charge of an amount up to the Order Value of the order/booking
              that is cancelled.
            </p>
            <p>
              viii.) it is the responsibility of the Merchant to inform Amealio
              about any change or modification made to the Order/Booking by the
              Customer, directly with the Merchant.
            </p>
            <p>
              ix.) The Merchant warrants that the food and beverages provided to
              Customers under the Restaurant Services are (a) of high quality
              and fit for human consumption; (b) compliant with Food Safety and
              Standards Act, 2006 and the rules, regulations licenses, standards
              and guidelines issued thereunder, and (c) complaint with all other
              relevant Iindian legislation, including all applicable food
              industry regulations.
            </p>
            <p>
              x.) The Merchant shall be solely responsible for the accuracy and
              veracity of the Allergy and Nutrition Information provided by the
              Merchant and displayed by the Amealio on its Platform. The
              Merchant shall make all reasonable efforts for strict adherence of
              the same and ensure that the food items are in consonance with the
              Allergy and Nutrition information as provided by them. In the
              event, any mishap takes place or any claim arises from the
              User/Buyer due to misrepresentation of the Allergy and Nutrition
              information, the Merchant shall be solely liable for the same and
              shall at no point of time hold Amealio liable for the same. The
              Merchant further agrees to indemnity and keep indemnified the
              Amealio for any claims, losses, costs, damages, expenses and
              liabilities of any nature whatsoever that may be sustained by
              Amealio due to any such misrepresented or misleading Allergy and
              Nutrition information.
            </p>
            <p>
              xi.) The Merchant shall maintain and procure all authorized users
              to maintain the confidentiality of the username ("Username") and
              password (Password") required access the Amealio Platform.
            </p>
            <p>
              xii.) The Merchant is solely responsible for any use of action
              taken and transactions that occur under the Username and the
              Password.
            </p>
            <p>
              xiii.) The Merchant acknowledges and agrees to be bound by
              Amealio's privacy policy. The Merchant will immediately notify
              Amealio if it becomes aware of or suspects any unauthorized use or
              access to the Customer data or any other Confidential Information
              of Amealio, and shall co-operate with Amealio in investigation of
              such breach and the mitigation of any damage.
            </p>
            <p>
              <b>
                {" "}
                4. Merchant Menu, Price List and Allergy/Nutrition information
              </b>{" "}
              <br />
              i.) Amealio will display on the Platform the menu and price list
              for all of its Merchants. The Merchant agrees that Amealio
              reserves the right to modify and delete certain items from the
              Merchant's menu list at its sole discretion, listed on the
              Platform, to ensure compliance with Food Safety and Standards Act,
              2006, requirements or applicable law in the relevant State or
              Territory and all other applicable legislation, regulations or
              standards. Amealio shall endeavour to update price lists within 24
              hours of being notified of changes (in writing) by a Merchant.
            </p>
            <p>
              ii.) The Merchant will, at all times, maintain parity in the
              pricing for all products offered for sale to the Customers via the
              Platform as against that made available for sale otherwise,
              including but not limited to any online aggregator and at its
              Restaurant locations. For the purpose of parity, such party is
              required to be maintained by the Merchant in food and beverage
              items sold via the Platform and its quality. quantity and other
              charges that may be levied by the Merchant.
            </p>
            <p>
              iii.) The Merchant shall not charge an amount over and above the
              maximum retail price (MRP) for all such food and beverage items
              which have MRP mentioned on them.
            </p>
            <p>
              iv.) Amealio may perform a variety of marketing activities to
              promote the Merchant and the Merchant's menu; provided, however,
              that all such marketing activities will be determined in Amealio's
              sole and absolute discretion and the Platform may be modified or
              updated from time to time to reflect any such changes.
            </p>
            <p>
              v.) Allergy and Nutrition Information: If the Merchant has agreed
              to provide the Allergy, Personalization and/or Nutrition Info,
              reasonable efforts should be made to describe the ingredients and
              allergy Information on each of the menu items in detail and items
              are property tagged with the properties to provide information
              related to it. Personalization items are served as per the
              requests stipulated by the Customer, Merchant shall make
              reasonable items to substitute or make choice available as per
              choice of Customer. The Merchant shall be solely responsible for
              the accuracy and conformity to facts of the Allergy,
              Personalization and Nutrition information provided by the Merchant
              and displayed by the Amealio on its Platform.
            </p>
            <p>
              <b>
                5. Additional Terms and Conditions For The Merchants Providing
                Online Ordering and Seating Services through the Amealio
                Platform:
              </b>
            </p>
            <p>
              <b> A. Walk-in/Waitlist:</b> <br />
              a.) The Customer can make a Walk-in /Wait List request for booking
              a table for specific number of persons, for specific needs (such
              as handicap and high chair requirements) at a Restaurant accepting
              Walk-in/Wait List request through the Amealio Platform. The
              Customer may also select seating area as per his preference as
              well as select special occasion. After the Customer makes a
              Walk-in /Wait List request, the Merchant must ascertain the
              availability of a booking and accordingly accept and confirm the
              booking within 1 minute of the receipt of request; else the
              Walk-in /Wait List request shall be auto cancelled. If the
              Merchant has activated "Auto-accept", the Walk-in request will get
              confirmed automatically. Once, the Merchant accepts the Walk-in
              request, the Customer will be notified through Push Notification
              and short message service ("SMS").
            </p>
            <p>
              b.) The Customer may edit/modify the Walk-in /Walt List request
              any time before the status changes to Seated, however, any request
              for modification of the confirmed booking will be subject to
              acceptance of the same by the Merchant. The Merchant will use its
              best endeavours to accept the request for modification of the
              Walk-in /Wait List request.
            </p>
            <p>
              c) Order Ahead: The Customer has the option to place an Order
              through the Amealio Platform before reaching the Restaurant for
              which he has received a confirmation for the Walk-in /Walt List
              request.
            </p>
            <p>
              d.) The Customer will provide Expected Time of Arrival (ETA) to
              the Merchant and shall be allowed to change the ETA three times
              and the Merchant must reconfirm the ETA five minutes before the
              expiry of ETA Even after reconfirming the ETA for the third time,
              if the Customer does not show up, the Merchant may cancel the
              Walk-in/Wait List request.
            </p>
            <p>
              e.) When the Customer arrives at the Restaurant, the Merchant
              should get the Customer seated at the earliest as per the selected
              seating preference and in conformity with the specific Walk-in
              /Wait List request. In the event, the Merchant fails to get the
              Customer upon his arrival as per the ETA, the Merchant shall be
              solely liable to the Customer for such delay and Amealio shall in
              no manner be held liable.
            </p>
            <p>
              f.) in the event, the Merchant fails to honour the Walk-in /Wait
              List request on in case of any other complaint raised by the
              Customer in relation to the Booking. Amealio will make reasonable
              efforts to verify the facts of such complaint with the Restaurant
              and may at its sole discretion initiate the refund (if any) to the
              Customer and Amealio shall be entitled to deduct such Refund
              Amount from any amount payable to the Restaurant by Amealio.
            </p>
            <p>
              <b> B. Reservation:</b> <br />
              a.) The Customer can make a request for "Reservation for the same
              day or for a future date for specific number of persons, for
              specific needs (such as handicap and high chair requirements) at a
              Restaurant accepting Reservation request through the Amealio
              Platform. The Customer may also select seating area as per his
              preference as well as select special occasion. After the Customer
              makes a request for Reservation for the same day, the Merchant
              must ascertain the availability of a booking and accordingly
              accept and confirm the booking within 1 minute of the receipt of
              request; else the Reservation request shall be auto-cancelled. If
              the Restaurant has activated Auto-accept", the Reservation request
              will get confirmed automatically. If the Customer makes a request
              for Reservation for a future day, the Merchant must ascertain the
              availability of a booking and accordingly accept and confirm the
              booking within 5 minutes of the receipt of request; if received
              during business hours and if received outside the business hours,
              the Merchant shall accept the Order within 5 minutes of the start
              of the business hours, failing which the request for
              Orders/Bookings shall be auto-cancelled. Once, the Restaurant
              accepts the request for Reservation, the Customer will be notified
              through Push Notification and short message service ("SMS").
            </p>
            <p>
              b.) The Customer may edit/modify the Reservation request any time
              with regards to the date/time reservation, seating preference,
              special occasion, special needs requirement before the status
              changes to Seated, however, any request for modification of the
              confirmed Reservation request will be subject to acceptance of the
              same by the Merchant. The Merchant will use its best endeavours to
              accept the request for modification of the Reservation request.
            </p>
            <p>
              c.) Order Ahead: The Customer has the option to place an Order
              through the Amealio Platform before reaching the Restaurant for
              which he has received a confirmation for the Reservation request.
            </p>
            <p>
              d.) The Customer may enable location tracking or provide Expected
              Time of Arrival (ETA) to the Merchant and shall be allowed to
              change the ETA three times and the Merchant must reconfirm the ETA
              five minutes before the expiry of ETA Even after reconfirming the
              ETA for the third time, if the Customer does not show up until the
              expiry of 30 minutes from the ETA, the Merchant may cancel the
              Reservation request.
            </p>
            <p>
              e.) When the Customer arrives at the Restaurant the Merchant
              should get the Customer seated at the earliest as per the selected
              seating preference and in conformity with the specific Reservation
              request. in the event, the Merchant fails to get the Customer
              seated upon his arrival as per the ETA, the Merchant shall be
              solely liable to the Customer for such delay and Amealio shall in
              no manner be held liable.
            </p>
            <p>
              f.) In the event, the Merchant fails to honour the Reservation
              request or in case of any other complaint raised by the Customer
              in relation to the Reservation, Amealio will make reasonable
              efforts to verify the facts of such complaint with the Merchant
              and may at its sole discretion initiate the refund of any) to the
              Comer and Amealio shall be entitled to deduct such Refund Amount
              from any amount payable to the Merchant by Amealio.
            </p>
            <p>
              <b>C. Takeaway:</b> <br />
              The Merchant agrees to the following terms and conditions if the
              Merchant has accepted to provide an option to the Customers who
              have placed Order through the Amealio Platform and opted to
              collect the Order themselves from the Restaurants (Takeaway):
            </p>
            <p>
              a) After the Order is accepted by the Merchant and the preparation
              time is communicated to the Customer on the Platform (Takeaway
              Time"), the Merchant shall ensure that the Order is ready by the
              Takeaway Time.
            </p>
            <p>
              b.) in the event, the Merchant fails to deliver the Order to
              Customer by the Takeaway Time, the Merchant shall be liable to the
              Customer for such delay.
            </p>
            <p>
              c.) In the event the Customers raises any complaint on account of
              any delay beyond the Takeaway Time or due to any fault
              attributable to the Merchant Amealio shall be entitled t refund to
              the Customer an amount up to the Order Value at its sole
              discretion and Amealio shall be entitled to deduct such Refund
              Amount from any amount payable to the Merchant by Amealio.
            </p>
            <p>
              <b> D. Curb-side:</b> <br />
              The Merchant agrees to the following terms and conditions if the
              Merchant has accepted to provide the option of Curb-side Delivery
              to the Customers who have placed Order through the Amealio
              Platform and opted for Curb side Delivery from the select
              Restaurants ("Curb-side").
            </p>
            <p>
              a.) Merchant shall ensure that the Order is prepared and ready by
              the Curb-side Delivery Time communicated to the Customer on the
              Platform (Curb side Time).
            </p>
            <p>
              b.) The Merchant shall provide Curb side Delivery to the Vehicle
              of the Customer (details of the Vehicle such as Vehicle Type,
              Colour, Model No.) which are provided by the Customer placing the
              Order on the Platform).
            </p>
            <p>
              c.) The Customer may enable location tracking or enter Expected
              Time of Aval (ETA) to the Merchant. The Customer shall be allowed
              to change the ETA three times and the Merchant must reconfirm the
              ETA five minutes before the expiry of ETA Even after reconfirming
              the ETA for the third time, if the Customer does not show up, the
              Merchant may cancel the Order.
            </p>
            <p>
              d.) In the event, the Merchant fails to deliver the Order to
              Customer by the Curb-side Time, the Merchant shape liable to the
              Customer for such delay.
            </p>
            <p>
              e.) In the event the Customers raises any complaint on account of
              any delay beyond the Curb-side Time or due to any fault
              attributable to the Merchant, Amealio shall be entitled to refund
              to the Customer an amount up to the Order Value at its sole
              discretion and Amealio shall be entitled to deduct such Refund
              Amount from any amount payable to the Merchant by Amealio.
            </p>
            <p>
              <b>E. Skip the Line: </b> <br />
              The Merchant agrees to the following terms and conditions if the
              Merchant has accepted to provide an option to the Customers to
              place the Order through the Amealio Platform and collect Order
              themselves from the Restaurants when the Order is ready without
              standing in the queue "Skip The Line"):
            </p>
            <p>
              a.) Merchant shall ensure the Order is ready by the preparation
              time communicated to the Customer on the Platform ("Skip the Line
              Time").
            </p>
            <p>
              b.) In the event, the Merchant fails to handover the Order to
              Customer by the Skip the Line Time, the Merchant shall be liable
              to the Customer for such delay.
            </p>
            <p>
              c.) In the event, the Merchant fails to deliver the Order to
              Customer by the Skip the Line Time, the Merchant shall be liable
              to the Customer for such complaint or due to any fault
              attributable to the Merchant, Amealilo shall be entitled to refund
              to the Customer an amount up to the Order Value at its sole
              discretion and Amealilo shall be entitled to deduct such Refund
              Amount from any amount payable to the Merchant by Amealio.
            </p>
            <p>
              <b> 6. License: </b> <br />
              i.) Merchant grants to Amealio an unrestricted, non-exclusive,
              royalty free license in respect of all Content (defined
              hereinabove) and Information provided to Amealio by the Merchant
              for the purposes of inclusion on the Platform. This includes, but
              is not limited to, (a) use of the merchant's name in the context
              of Google AdWords to support advertising and promotional campaigns
              to promote online ordering on internet which may be undertaken by
              Amealio (b) preparation of derivative works of, or incorporate
              into other works, all or any portion of the marketing materials
              which will be made by Amealio for the purposes of its business.
            </p>
            <p>
              ii.) Any Content, information or material that the Merchant
              transmits or submits to Amealio either through the Platform or
              otherwise shall be considered and may be treated by Amealio as
              non-confidential, subject to Amealio's obligations under relevant
              data protection legislation.
            </p>
            <p>
              iii.) The Merchant also grants to Amealio a royalty free,
              perpetual, irrevocable, non- exclusive license to use, copy,
              modify, adapt, translate, publish and distribute world- wide any
              Content, information or material for the purposes of providing
              Services under these Terms or to or for the purposes of
              advertising and promotion of the Platform. The Merchant agrees
              that all Content, information or material provided to Amealio that
              is published on the Platform, may be relied upon and viewed by
              Customers to enable them to make informed decisions.
            </p>
            <p>
              iv.) Any breach of the license provisions as mentioned in this
              Section by you may result in the immediate termination/suspension
              of your Account as well as potential responsibility for copyright
              and other IP Rights infringement, depending on the circumstances.
            </p>
            <p>
              <b> 7.Payment Settlement Process:</b> <br />
              i) The Merchant acknowledges and agrees that any Order Value which
              is collected by Amealio for, and on behalf of, the Merchant in
              accordance with these Terms, shall be passed on by Amealio to the
              Merchant subject to the deduction of the following amounts (as
              applicable) by Amealio from such Order amount:
            </p>
            <p>a.) Service Fee for Services subscribed by the Merchant;</p>
            <p>b.) Amount of Payment Processing Fee due from Merchant</p>
            <p>c.) Cancellation charges as levied by Amealio</p>
            <p>d.) Goods and Service Tax (GST)</p>
            <p>
              e.) Any other amount that are due to Amealio as per the agreed
              terms or on account of other services availed by Merchant from
              Amealio, to which specific consent of the Merchant is accorded.
            </p>
            <p>
              ii.) The Merchant acknowledge and agree that after the deduction
              of the aforementioned amounts, Amealio shall remit the Order Value
              due to the Merchant within a period of 3 bank working days, from
              the date the payment of the Order Value is received by Amealio.
            </p>
            <p>
              iii.) From time to time, Amealio may change the fees for the
              S-ervices, Cancelation charges including without limitation the
              Payment Mechanism Fee or include any additional charges/ fee,
              provided however, that Amealio communicates such change(s) via
              email or any other modes of communication, thirty (30)days prior
              to the date that such change(s) are to take effect.
            </p>
            <p>
              <b>8. Taxes:</b> <br />
              Notwithstanding anything to the contrary herein, the Merchant is,
              and will be responsible for all taxes, payments, fees, and any
              other liabilities associated with the computation, payment, and
              collection of taxes in connection with Customer Orders and the
              Merchant's use of the Platform and Amealio Services. Amealio may
              charge and collect applicable taxes from Customers on behalf of
              the Merchant in accordance with instructions provided by the
              Merchant and/or applicable law; and, in which case, Amealio will
              collect such tax solely on behalf of the Merchant and shall pay
              such amount collected to the Merchant. The Merchant shall be
              solely responsible for verifying amounts collected, filing the
              appropriate tax returns, and remitting the proper amount to the
              appropriate tax authorities. Taxes shall include all applicable
              taxes due in relation to the sale of food and beverages, including
              pick-up and delivery services (if applicable), by the Merchant. It
              is clarified that Amealilo will not be liable for payment of any
              Taxes that the Merchant is liable to pay in connection with the
              Restaurant Services which shall be provided by the Merchant to the
              Customers in accordance with these Terms and that the Merchant
              hereby undertakes to indemnify, defend and hold harmless, Amealio
              and each of its affiliates and (as applicable) all of their
              directors, officers employees, representatives and advisors
              against any tax liability that may arise against Amealio on
              account of the non-payment of Taxes by the Merchant under these
              Terms.
            </p>
            <p>
              <b>9. Confidentiality:</b> <br />
              i.) Other than for provision of Service(s) by Amealio, Amealio
              does not share any other information of the Merchant with third
              parties unless requisitioned by government authorities.
            </p>
            <p>
              ii.) Other than for the purpose of availing Service(s) from
              Amealio, the Merchant must not disclose any confidential
              information about Amealio, Including but not limited to these
              Terms, its business strategies, pricing, revenues, expenses,
              Customer Data, and Order information to third parties.
            </p>
            <p>
              <b>10. Warranty and Indemnity:</b> <br />
              i.) Merchant warrants that if the Merchant ceases to do business,
              closes operations for a material term or is otherwise unable to
              offer services to Customers it will inform Amealilo promptly.
              Where the Merchant neglects to do so, whether by omission or
              fault, Amealio will not be held responsible for any liabilities,
              whether financial or otherwise.
            </p>
            <p>
              ii.) Merchant warrants that it will not offer for sale any
              potentially hazardous food, beverages, tobacco product, or any
              other item prohibited by law.
            </p>
            <p>
              iii.) Amealio warranties that it will undertake its obligations
              with reasonable skill and care.
            </p>
            <p>
              iv.) Amealio does not guarantee or warrant that the Platform,
              Application, software, hardware or services will be free from
              defects or malfunctions. If errors occur, it will use its best
              endeavours to resolve these as quickly as possible.
            </p>
            <p>
              v.) Merchant warrants that it complies and will remain compliant
              with the Food Safety and Standards Act, 2006, Legal Metrology Act,
              2009 and the applicable rules and regulations made thereunder and
              any other requirements or applicable law in the relevant State or
              Territory and all other applicable legislation, regulations or
              standards.
            </p>
            <p>
              vi.) Merchant warrants to procure and keep valid during the
              subsistence of these Terms, all requisite licenses and/or
              registrations thereunder and provide copy of such licenses and/or
              registrations to Amealio prior to availing the Services.
            </p>
            <p>
              vii.) The Merchant agrees to indemnify and hold Amealio (and its
              directors, officers, agents, representatives and employees)
              harmless from and against any and all claims, suits, liabilities,
              judgments, losses and damages arising out of or in connection with
              any claim or suit or demand.
              <br />
              a.) By a Customer (or any party on whose behalf a Customer has
              been acting), for reasons not attributable to the Service.
            </p>
            <p>
              b.) By a Customer (or any party on whose behalf a Customer has
              been acting) or any third party in respect of, arising out of or
              relating to the Content, Information or material provided by the
              Merchant to Amealio, to be listed on the Platform.
            </p>
            <p>
              c.) In respect of, arising out of, or in connection with,
              Restaurant Services (ors any other services actually or
              purportedly offered in respect of or connected to the Restaurant
              Services) and delivery of such Restaurant Services when undertaken
              by the Merchant,
            </p>
            <p>
              d.) in respect of or connected to the collection or payment of
              applicable taxes in any way connected to these Terms or any matter
              or goods or services under or arising from it (e.g. Services).
            </p>
            <p>
              e.) in respect of quality of the Restaurant Services provided by
              the Merchant.
            </p>
            <p>
              f.) In respect of the warranties provided by the Merchant in
              Clause 10 (Warranty and Indemnity) here to,
            </p>
            <p>
              g.) Relating to harm caused by the Merchant supplying any unsafe
              Restaurant Services, any product failure, defect or hazard in any
              Restaurant Services supplied or sold by the Merchant or inadequate
              instructions or warnings provided to Customers pertaining to any
              hazards arising from or associated with the use of any Restaurant
              Services supplied or sold by the Merchant.
            </p>
            <p>
              h.) Brought either by Amealilo and/or third party on account of
              misuse, abuse, cheating, fraud, misrepresentation carried out by
              the Merchant;
            </p>
            <p>
              i.) In respect of, arising out of or in connection of any use of
              the Customer Data, not in accordance with the Terms and/or the
              applicable law.
            </p>
            <p>
              j.) For any misleading or incorrect or false, information or data
              provided by the Merchant;
            </p>
            <p>
              viii.) The Merchant acknowledges that it supplies certain rights
              to Amealio tenable Amealio to provide services to the Customer.
              Amealio will not be liable for any applicable taxes liability in
              respect of transactions by which the Merchant provides Restaurant
              Services to Customers and the Merchant hereby indemnifies Amealio
              against any applicable taxes it may become liable for arising out
              of such transactions.
            </p>
            <p>
              <b>11. Customer Data: </b> <br />
              The Merchant agrees that the Merchant will only use the Customer
              Data in fulfilling the applicant Customer Order and in complying
              with the Merchant's obligations, and the Merchant agrees that
              Customer Data will not be used to enhance any file or list of the
              Merchant or any third party. The Merchant represents, warrants,
              and covenants that it will not resell, broker or otherwise
              disclose any Customer Data to any third party, in whole or in
              part, for any purpose whatsoever. The Merchant agrees shall not
              use the Customer Data for sending any unsolicited marketing
              message, announcements and for feedback purposes, and shall be
              solely liable to ensure that any third party with whom Customer
              Data is shared complies with the restrictions set forth herein.
              The Merchant agrees that it will not copy or otherwise reproduce
              any Customer Data other than for the purpose of fulfilling the
              applicable Customer Order. The Merchant (and any other persons to
              whom the Merchant provides any Customer Data) will implement and
              comply with reasonable security measures in protecting, handling,
              and securing the Customer Data. If, any Customer Data is collected
              by the Merchant (or otherwise on its behalf), the Merchant shall
              ensure that it (and any applicable third parties) adopt, post, and
              process the Customer Data in conformity with an appropriate and
              customary privacy policy. For purposes of this Form, the
              restrictions set forth herein on the Merchant's use of Customer
              Data do not apply to: (a) data from any Customer who was a
              customer of Merchant prior to the Merchant using the Platform or
              the Amealio Services, but only with respect to data that was so
              previously provided to the Merchant by such an Customer; or (b)
              data supplied by a customer directly to the Merchant who becomes a
              customer of the Merchant and who explicitly opts in to receive
              communications from the Merchant for the purposes for which such
              Customer Data will be used by Merchant, and, provided in all
              cases, that the Merchant handles and uses such Customer Data in
              compliance with applicable Laws and the Merchant's posted privacy
              policy.
            </p>
            <p>
              <b> 12. Disclaimer:</b> <br />
              To the fullest extent permitted by law, Amealio and its
              affiliates, and each of the irrespective officers, directors,
              members, employees, and agents disclaim all warranties, express or
              implied, in connection with these terms, the Platform and the
              Amealio services and any use thereof) Including, without
              limitation, the implied warranties of merchantability, fitness for
              a particular purpose and non-Infringement. Amealio makes no
              warranties or representations about the accuracy or completeness
              of the content and data on the Platform or the Amealilo services'
              content or the content of any other websites linked to the
              website, and assumes no liability or responsibility for any (a)
              errors, mistakes, or inaccuracies of content and materials, (b)
              personal injury or property damage, of any nature whatsoever,
              resulting from the Merchant's access to and use of the Platform
              and the Amealio services, (c) any unauthorized access to or use of
              Amealio' servers and/or any and all personal information and/or
              financial information stored therein, (d) any interruption or
              cessation of transmission to or from the website or otherwise with
              respect to the Amealio services, (e) any bugs, viruses, trojan
              horses, or the like which may be transmitted to or through the
              website or the Amealio services by any third party, and/or (f) any
              errors or omissions in any content and materials or for any loss
              or damage of any kind incurred as a result of the use of any
              content posted, transmitted, or otherwise made available via the
              website or the Amealio Services.
            </p>
            <p>
              <b> 13. Limitation of Liability:</b> <br />
              For the purposes of this clause, "Liability' means liability in or
              for breach of contract, negligence, misrepresentation, tortious
              claim, restitution or any other cause of action whatsoever
              relating to or arising under or in connection with these Terms,
              including liability expressly provided for under this Form or
              arising by reason of the invalidity or unenforceability of any
              agreed terms. Amealio does not exclude or limit Liability for any
              Liability that cannot be excluded by law. Subject to the preceding
              sentence, Amealio shall not be under any Liability for loss of
              actual or anticipated profits, loss of goodwill, loss of business,
              loss of revenue or of the use of money, loss of contracts, loss of
              anticipated savings, loss of data and/or undertaking the
              restoration of data, fraudulent Orders, any special, indirect or
              consequential loss, and such liability is excluded whether it is
              foreseeable, known, foreseen or otherwise. For the avoidance of
              any doubt, this clause shall apply whether such damage or loss is
              direct, indirect, consequential or otherwise. Although Amealio
              will use its best endeavours to ensure that the unintentional
              operational errors do not occur, Amealio cannot provide any
              warranty or guarantee in this regard. Notwithstanding anything to
              the contrary herein set out, Amealio's aggregate liability under
              this Form shall not exceed the total value of the Order under
              which the claim arose.
            </p>
            <p>
              <b>14. Modification:</b> <br />
              Amealio may modify these Terms from time to time, and any such
              changes will (1) be reflected on the Website, and (ii) be
              effective immediately upon the changes being reflected on the
              Platform. The Merchant agrees to be bound to any such changes or
              modifications and understands and accepts the importance of
              regularly reviewing these Terms as updated on the Platform.
              Further, in the event Amealio upgrades, modifies or replaces the
              Services ("Service Modifications) offered to the Merchant Amealio
              will notify the Merchant prior to making the same and give the
              Merchant the opportunity to review and comment on the Service
              Modifications before continuing to use the Service or any
              alternative service offered by Amealio, The Service Modifications
              will also be reflected on the Platform. If the Merchant continues
              to use the Service or any alternate service offered by Amealio,
              following any notice of the Service Modifications, it shall
              constitute the Merchant's acceptance of such Service
              Modifications.
            </p>
            <p>
              Further, in the event Amealio upgrades, modifies or replaces the
              Services ("Service Modifications) offered to the Merchant Amealio
              will notify the Merchant prior to making the same and give the
              Merchant the opportunity to review and comment on the Service
              Modifications before continuing to use the Service or any
              alternative service offered by Ameallo, The Service Modifications
              will also be reflected on the Platform. If the Merchant continues
              to use the Service or any alternate service offered by Amealio,
              following any notice of the Service Modifications, it shall
              constitute the Merchant's acceptance of such Service
              Modifications.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Businessterms;
