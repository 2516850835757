import React from "react";

const Bannerfeatures = (props) => {
  const banner = props.data;
  const bannerImage = "../assets/images/features/" + banner.bannersImage;
  const bannerImage1 = "../assets/images/features/" + banner.bannerSubImage;
  console.log(banner);
  return (
    <section className="bannerconfigation">
      <div className="bannerfeature">
        <span className="explane-back"></span>
        <img src={bannerImage} alt="Banner" title="Banner" />
      </div>
      <div className="container">
        <div className="bannerexplanations">
          <img src={bannerImage1} alt="Sub Banners" title="Sub Banners"></img>
          <h1>{banner.bannersHeading}</h1>
          <p>{banner.bannerSubHeading}</p>
        </div>
      </div>
    </section>
  );
};
export default Bannerfeatures;
