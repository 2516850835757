import React from "react";
import { useLocation } from "react-router-dom";

const Subheading = (props) => {
  const pathname = useLocation();
  const pathvalue = pathname.pathname.substring(1);
  console.log(pathvalue, "pathvalue");
  const subheading = props.data;
  const subImage = "../assets/images/features/" + subheading.subheadingImage;
  const subVideoImg =
    "../assets/images/featuresimg/" + subheading.subheadingsubimg;

  // function close() {
  //   this.contentWindow.postMessage(
  //     '{"event":"command","func":"stopVideo","args":""}'
  //   );
  // }

  return (
    <section>
      <div className="container">
        <div className="row mobileapplication">
          <div className="col-md-6 mobileapp">
            <img src={subImage} alt="Features" title="Features" />
          </div>

          <div
            className={
              subheading.bulletin
                ? "bulletin col-md-6 mobileappdescribe"
                : "col-md-6 mobileappdescribe"
            }
          >
            <h1>{subheading.subheadingmain}</h1>

            <span>{subheading.subheadline1}</span>
            <span>{subheading.subheadline2}</span>
            <span>{subheading.subheadline3}</span>
            {subheading.subheadline4 ? (
              <span>{subheading.subheadline4}</span>
            ) : null}
          </div>
        </div>
        <div className="row mobileapplication">
          <div className="col-md-6 mobilevideoappdescribe">
            <h1>{subheading.subheadingsub}</h1>
            <p>{subheading.subheadingPara}</p>
            <button
              type="button"
              className="btn btn-dark videoapp-bttn"
              data-toggle="modal"
              data-target="#myModal"
            >
              {subheading.subheadingButtonName}
              <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </button>
          </div>
          <div className="col-md-6 subheading-amealiomobilevideo">
            <img
              src={subVideoImg}
              className="amealiomobilevideo"
              alt="Features"
              title="Features"
              width="100%"
              data-toggle="modal"
              data-target="#myModal"
            ></img>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="myModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog subheading-modal-dialog"
          role="document"
          data-dismiss="modal"
        >
          <div className="modal-content subheading-modal-content">
            <iframe
              width="100%"
              height="500px"
              src={subheading.youtubelink}
              title="YouTube video player"
              id="yt-player"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Subheading;
