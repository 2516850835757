import React from "react";
// import Header from '../templates/header';
import Footer from "../templates/footer";
const Businessreferral = () => {
  return (
    <div className="app">
      <section className="section-offer">
        <div className="container">
          <div className="offer-heading">
            <h2>
              <b>OFFERS AND REWARDS PROGRAMS</b>
            </h2>
          </div>
          <div className="offer-paragraph">
            <p>
              <b>Terms and Conditions: User Referral Program</b>
            </p>
            <p>
              Offer is valid for users on the App only, unless explicitly
              authorized by Merchants on our platform to be used offline.
            </p>
            <p>
              Initial referral amount received for first order cannot be used in
              conjunction with any other coupon discount Referral coupons are
              valid only on online payments through card or net-banking. Coupons
              are not valid on third party wallets
            </p>
            <p>Offer cannot be clubbed with any other scheme</p>
            <p>
              Envisionard Ads and Marketing Pvt. Ltd. (Amealio) reserves the
              right to cancel/change/modify/add/delete any of the terms and
              conditions of the offer at any time without <br /> notice. <br />
              Envisionard Ads and Marketing Pvt. Ltd. (Amealio) reserves the
              right to terminate the offer at any time without notice
              Envisionard Ads and Marketing Pvt. Ltd. (Amealio) reserves the
              right to deny or honoring the offer on the grounds of suspicion or
              abuse of the offer by any customer without providing customer any
              explanation thereof In no event shall Envisionard Ads and
              Marketing Pvt. Ltd. (Amealio) be liable for any abuse or misuse of
              the code due to the negligence of the customer
            </p>
            <p>
              The services shall be governed by the terms and conditions set out
              here <br /> In case of any query regarding the offer, please email
              us at referrals@amealio.com
            </p>
            <p>
              <b>Terms and Conditions: Non cashback coupons</b>
            </p>
            <p>Offer valid for a single restaurant only.</p>
            <p>
              <b>Offer cannot be clubbed with any other scheme</b>
            </p>
            <p>
              Envisionard Ads and Marketing Pvt. Ltd. (Amealio) reserves the
              right to cancel/ change/modify/add/delete any of the terms and
              conditions of the offer at any time <br /> without notice <br />{" "}
              <b>
                Envisionard Ads and Marketing Pvt. Ltd. (Amealio) reserves the
                right to terminate the offer at any time without notice
                Envisionard Ads and Marketing Pvt. Ltd.{" "}
              </b>{" "}
              <br /> (Amealio) reserves the right to deny honouring the offer on
              the grounds of suspicion or abuse of the offer by any customer
              without providing customer any explanation thereof No
              refunds/redemptions of coupons/offers offered through the website
              or the app for cash.
            </p>
            <p>
              In no event shall Envisionard Ads and Marketing Pvt. Ltd.
              (Amealio) be liable for any abuse or misuse of the code due to the
              negligence of the customer The services shall be governed by the
              terms and conditions set out in here (incorporated by reference)
              In case of any query regarding the offer, please email us at{" "}
              <br /> social@amealio.com
            </p>
            <p>
              <b>Terms and Conditions: Cashback coupons</b>
            </p>
            <p>
              Coupon valid on online payments only unless specified otherwise.{" "}
              <br />
              Offer valid for a single restaurant only. <br />
              Offer cannot be clubbed with any other scheme <br />
              Not valid on Cash on Pick up or Delivery and third-party wallet
              payments. <br />
            </p>
            <p>
              <b>
                Cashback will be received in form of Amealio money once order
                pick up or delivery is complete. The cashback will automatically
                be applied on your next order. Offer valid for a single
                restaurant only.
              </b>
            </p>
            <p>
              Offer cannot be clubbed with any other scheme <br />
              No refunds/redemptions of coupons/offers offered through the
              website or the app for cash. <br />
              Envisonard Ads and Marketing Pvt. Ltd. (Amealio) reserves the
              right to cancel/ change/modify/add/delete any of the terms and
              conditions of the offer at any time without <br /> notice <br />
              Envisionard Ads and Marketing Pvt. Ltd. (Amealio) reserves the
              right to terminate the offer at any time without notice
              Envisionard Ads and Marketing Pvt. Ltd. (Amealio) reserves the
              right to deny honoring the offer on the grounds of suspicion or
              abuse of the offer by any customer without providing customer any
              explanation thereof In no event shall Envisionard Ads and
              Marketing Pvt. Ltd. (Amealio) be liable for any abuse or misuse of
              the code due to the negligence of the customer
            </p>
            <p>
              <b>
                The services shall be governed by the terms and conditions set
                out in this site (incorporated by reference) In case of any
                query regarding the offer, please email us atsocial@amealio.com
              </b>
            </p>
            <p>
              <b>
                Terms and Conditions: Offline Offers by Merchants and Partners
                (Non cashback coupons){" "}
              </b>
            </p>
            <p>
              <b>Offer valid for a single restaurant only.</b> <br />
              Offer cannot be clubbed with any other scheme. Right to accept or
              deny the offer created by Merchant is with Merchant only, Amealio
              provides hosting facility to Merchant to show case the offer to be
              used at their facility during an offline process. Merchant
              reserves the right to deactivate any ongoing offers on the
              platform at any time. Envisionard Ads and Marketing Pvt. Ltd.
              (Amealio) or the Merchant Partner reserves the right to cancel/
              change/modify/add/delete any of the terms and conditions of the
              offer at any time without notice
            </p>
            <p>
              Envisionard Ads and Marketing Pvt. Ltd. (Amealio) and/or partner
              reserves the right to terminate the offer at any time without
              notice Envisionard Ads and Marketing Pvt. Ltd. (Amealio) and/ or
              partner reserves the right to deny honouring the offer on the
              grounds of suspicion or abuse of the offer by any customer without
              providing customer any explanation thereof No refunds/redemptions
              of coupons/offers offered through the website or the app for cash.
              In no event shall Envisionard Ads and Marketing Pvt. Ltd.
              (Amealio) be liable for any abuse or misuse of the code due to the
              negligence of the customer <br />
            </p>
            <p>
              The services shall be governed by the terms and conditions set out
              in here (incorporated by reference) In case of any query regarding
              the offer, please email us social@amealio.comif you have any
              denial by Merchant of any offers please report it
              togreviance@amealio.com and we would take necessary steps to
              ensure such incidents do not reoccur.
            </p>
            <p>
              <b>Terms and Conditions: Business Referral Program</b>
            </p>
            <p>
              <b>Offer is valid for users on the App only</b>
            </p>
            <p>
              Envisionard Ads and Marketing Pvt. Ltd. (Amealio) reserves the
              right to cancel/change/modify/add/delete any of the terms and
              conditions of the offer at any time without <br /> notice <br />
              Envisionard Ads and Marketing Pvt. Ltd. (Amealio) reserves the
              right to terminate the offer at any time without notice
              Envisionard Ads and Marketing Pvt. Ltd. (Amealio) reserves the
              right to deny or honoring the offer on the grounds of suspicion or
              abuse of the offer by any customer without providing customer any
              explanation thereof In no event shall Envisionard Ads and
              Marketing Pvt. Ltd. (Amealio) be liable for any abuse or misuse of
              the code due to the negligence of the customer The services shall
              be governed by the terms and conditions set out here <br /> In
              case of any query regarding the offer, please email us
              atbusinessreferrals@amealio.com
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Businessreferral;
