import React from "react";
import Media from 'react-media';
import AutoSlider from "../../../NewWebsite/autoplaysliders";
const Outlets = ()=> {
    return(
        <>
        <div className="outlets-and-reviews">
            <div className="container">
                <div className="pd-y4 pb-0 text-center">
                    <p className="heading-h5 pb-4 text-dark"> Trusted by <span className="amealiocolor">500+</span> leading hospitality brands</p>
                    <p className="paragraph-text">making the lives of restaurants, cafés, bars, pubs and hotels that much easier</p>
                </div>
            </div>
            <AutoSlider />
        </div>
        </>
    );
};
export default Outlets;