import React from "react";
import { NavLink } from "react-router-dom";

const Features = (props) => {
  const features = props.data;
  function convertToImg(data) {
    // console.log(data)
    let bannerimg = data.toLowerCase();
    // console.log(bannerimg)
    let bannerimg1 = bannerimg.split(" ").join("-");
    // console.log(bannerimg1)
    return bannerimg1;
  }

  return (
    <section className="feature-section">
      <div className="container">
        <h2 className="feature-header">
          <b>{features.featureHeading}</b>
        </h2>
        <ul className="features">
          {features.features.map((data, index) => {
            console.log("Features ==>", data);
            const featuresImage =
              "../assets/images/fastfood/" + convertToImg(data) + ".png";

            const link =
              `/${props.lang}/` + data.toLowerCase().replace(/ +/g, "");

            return (
              <li key={index}>
                <NavLink activeClassName="is-active" to={link} exact>
                  <img src={featuresImage} alt="img" width="230px"></img>
                  <span className="feature-text">{data}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default Features;
