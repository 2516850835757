import React from "react";
import Footer from "../templates/footer";
import { NavLink } from "react-router-dom";
const Microphone = () => {
  return (
    <div className="app">
      <section>
        <div className="section-microphone">
          <div className="container">
            <div className="microphone-heading">
              <h2>Microphone Access</h2>
            </div>
            <div className="microphone-paragraph">
              <ul>
                <li>
                  Users can access the AI-based virtual assistant 'Amealia' on
                  the Amealio Website, for a enhanced user experience.
                </li>
                <li>
                  When a user visits the Amealio website and clicks on the
                  microphone icon, a dialogue box displays, asking for
                  permission to use the device microphone, with options to allow
                  or deny access.{" "}
                </li>
                <li>
                  The user should provide microphone access in order to use this
                  feature. If a user refuses microphone access, he will not be
                  able to use the Platform's microphone feature, but he will be
                  able to use other services.{" "}
                </li>
                <li>
                  Access to the microphone is granted at the user's discretion.
                </li>
                <li>
                  Amealio reserves the right to record and store any related
                  information, including Your voice chats, to better understand
                  user preferences, trends, and patterns to enhance the user
                  experience in future.{" "}
                </li>
                <li>
                  The microphone access is governed by Amealio's terms and
                  conditions and privacy policy. It is suggested that you read
                  the Amealio Policies.
                </li>
                <li> The User may revoke microphone access at any moment.</li>
                <li>
                  If you turn off access, you’re asked to turn it on again the
                  next time the User tries to use the microphone feature.{" "}
                </li>
                <li>
                  Amealio reserves the right to revoke access to this feature
                  from Users at any time if it violates Amealio's terms and
                  conditions or privacy policy.{" "}
                </li>
                <li>
                  Amealio retains the right to change or amend these or other
                  associated policies at any time.
                </li>
              </ul>
            </div>
            <div className="microphone-buttoncontent">
              <NavLink activeClassName="is-active" to="/aboutamealia" exact>
                <button type="button" className="microphone-button">
                  Back
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Microphone;
