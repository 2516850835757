import { useNavigate } from "react-router-dom";
import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import cookies from "js-cookie";
import { LANGUAGES } from "../../AllEnum/LanguageEnum";

const Checkout = () => {
  const navigate = useNavigate();
  const currentLanguageCode = cookies.get("i18next") || "in";
  const currentLanguage = LANGUAGES.find((l) => l.code === currentLanguageCode);

  const [Check, Uncheck] = useState(true);

  return (
    <section className="checkout-section">
      <img
        src="../assets/images/backup/background.png"
        className="menu-img"
        alt="Background"
        title="Background"
      ></img>
      <div className="container-checkout">
        {Check ? (
          <div className="check">
            <i
              className="fa fa-times checkout-close"
              onClick={() => navigate(-1)}
              aria-hidden="true"
            ></i>
            <div className="toptitle">
              <h4>
                <b>Register Now</b>
              </h4>
            </div>
            <form action="#">
              <div className="user-details">
                <div className="input">
                  <div className="numSec">
                    <input type="text" placeholder="+91 Phone Number" />
                    <i
                      className="fa fa-chevron-right arrow "
                      aria-hidden="true"
                      onClick={() => Uncheck(false)}
                    ></i>
                  </div>
                  <div className="checkout-below">
                    <h5>
                      <b>Click Below To Download The App Now!</b>
                    </h5>
                    <div className="checkoutlogo">
                      <div className="bottomicon">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.envisionard.amealio"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src="../assets/images/footer/googleplay.png"
                            alt="get it on Google Play"
                            title="Google Play"
                          />
                        </a>
                        <a
                          href="https://apps.apple.com/in/app/amealio/id1516233942"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src="../assets/images/footer/apple-app-store-icon.png"
                            alt="get it on Apple Store"
                            title="Apple Store"
                            margin-left="12px"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="message-sent__box">
            <div className="message">
              <NavLink
                activeClassName="is-active"
                to={`/${currentLanguage.code.toUpperCase()}`}
                exact
              >
                <i
                  className="fa fa-times checkout-close message-sent__close"
                  aria-hidden="true"
                ></i>
              </NavLink>
              <div className="message-sent__title">
                <h4>
                  <i
                    className="fa fa-check-circle message-sent__circle"
                    aria-hidden="true"
                  ></i>
                  Message Sent
                </h4>
              </div>
              <div className="message-sent__subtitle">
                <p>
                  The Message has been successfully sent to the registered
                  mobile number 'XXX-XXX-9763'
                </p>
                <p>
                  Kindly Check on the given link in the SMS to enjoy the
                  features of Amealio App.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
export default Checkout;
