import React from "react";
import Slider from "react-slick";
import ReusableSliderCom from "./reusableSliderCom";

const OnTheGoSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 7000,
    // cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
  };

  const onTheGoDummyData = [
    {
      id: 1,
      title: "Curb Side",
      imgSrc: "curbside.png",
    },
    {
      id: 2,
      title: "Take Away",
      imgSrc: "TakeAway.png",
    },
  ];

  return (
    <div className="outter-features">
      <Slider {...settings}>
        {onTheGoDummyData &&
          onTheGoDummyData.map((goingOut) => {
            return (
              <React.Fragment key={goingOut.id}>
                <ReusableSliderCom data={goingOut} />
              </React.Fragment>
            );
          })}
      </Slider>
    </div>
  );
};

export default OnTheGoSlider;
