import React from "react";
import Slider from "react-slick";
const  Ratings = () => {
  var settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    speed: 2000,
    autoplaySpeed: 600,
    swipeToSlide: true,
    mobileFirst: true,
    initialSlide: 0,
 
  };
    return(
        <>
        <section className="ratings-section"> 
          <div className="heading-h1w text-center" style={{paddingTop:"8rem",paddingBottom:"2rem"}}>What people are saying...</div>
          <div className="row pd-y3">
            <div className="col-1 col-md-4"></div>
            <div className="col-5 col-md-2">
            <div className="heading-h1w text-center pd-y1 flexcenter">4.9<img className="star-img" src="../../assets/images/amealio/new/yellowstar.png" width="60px" /></div>
            <div className="flexcenter">        
              <a
              href="https://play.google.com/store/apps/details?id=com.envisionard.amealio"
              target="_blank"
               rel="noreferrer"
                >
              <img className="gplay-img" src="../../assets/images/amealio/new/gplay.png" width="220px" />
              </a>
              </div>
            </div>
            <div className="col-5 col-md-2">
            <div className="heading-h1w text-center pd-y1 flexcenter">4.7<img className="star-img" src="../../assets/images/amealio/new/yellowstar.png" width="60px" /></div>
            <div className="flexcenter">
            <a
            href="https://apps.apple.com/in/app/amealio/id1516233942"
            target="_blank"
            rel="noreferrer"
            >  
              <img className="gplay-img" src="../../assets/images/amealio/new/appstore.png" width="220px" />
              </a>
              </div>
            </div>
            <div className="col-1 col-md-4"></div>
          </div>
          
          <div className="pd-y5 container testinomial-w">
            <Slider {...settings}>
              <div>
              <p  className="text-center" style={{color:"white",fontSize:"18px"}}>"Amealio is my go-to app for all my food cravings and needs. I love the power it gives me to skip the line at food courts and never have to wait for food anymore!" <br /> <br />
- "Shilpa",28</p>
              </div>
              <div>
              <p  className="text-center" style={{color:"white",fontSize:"18px"}}>"With great offers, features and an easy to navigate interface, Amealio has become my go to application for everything food. Furthermore, I am able to discover new cuisines and places around me thanks to Amealio; it truly is a foodie’s best friend!" <br /> <br />
-Nidhi, 18</p>
              </div>
              <div>
              <p  className="text-center" style={{color:"white",fontSize:"18px"}}>" The Amealio App has distinctive features that will make your dining out experience enjoyable. They ensure the food is delivered to you according to your taste preferences so you can 
"Enjoy Your Moments" with your loved ones! <br /> <br />
-Natasha, 25</p>
              </div>
              <div>
              <p  className="text-center" style={{color:"white",fontSize:"18px"}}>"Amealia makes ordering and paying for food from my favorite restaurants such as easy experience. Amealia has further helped me experience the endless features offered by the app with ease." <br /> <br />
-Teresa, 18</p>
              </div>

            </Slider>


          </div>
        </section>
        {/* <section className="graphs-section">
        <div className="container"><img src="../../assets/images/amealio/new/graphs.png" width="100%" /></div>
        </section> */}
        </>
    );
};
export default Ratings;
