import React from "react";
import { NavLink } from "react-router-dom";

const Reward = (props) => {
  return (
    <div className="app">
      <section>
        <div className="referral">
          {/****************** DeskTop ***************/}
          <h2 className="f32-h4 text-center d-none d-sm-none d-md-block">
            <b>
              Enjoy Mouthfull Rewards with every Referral you Pass to you
              Friends <br />
              or your Favourite Place
            </b>
          </h2>
          {/****************** Mobile ***************/}
          <h2 className="f24-h5 text-center d-block d-sm-block d-md-none px-4">
            <b>
              Enjoy Mouthfull Rewards with every Referral you Pass to you
              Friends or your Favourite Place
            </b>
          </h2>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row referlogo">
            <div className="col-6 col-sm-6 col-md-2 mb-5 text-center referearn">
              <img src="../assets/images/enhanced/megaphone.png" alt="" />
              <h4 className="f16-p3">Refer N Earn</h4>
            </div>
            <div className="col-6 col-sm-6 col-md-2 mb-5 text-center cashbacks">
              <img src="../assets/images/enhanced/cashback.png" alt="" />
              <h4 className="f16-p3">Cash Backs</h4>
            </div>
            <div className="col-6 col-sm-6 col-md-2 mb-5 text-center offers">
              <img src="../assets/images/enhanced/discount.png" alt="" />
              <h4 className="f16-p3">Offers</h4>
            </div>
            <div className="col-6 col-sm-6 col-md-2 mb-5 text-center coupon">
              <img src="../assets/images/enhanced/coupon.png" alt="" />
              <h4 className="f16-p3">Coupons</h4>
            </div>
            <div className="col-6 col-sm-6 col-md-2 mb-5 text-center loyal">
              <img src="../assets/images/enhanced/card.png" alt="" />
              <h4 className="f16-p3">Loyalty Points</h4>
            </div>
          </div>
          <div className="py-5">
            <ul className="nav reward-refer">
              <li className="active">
                <a data-toggle="pill" href="#home" className="f18-p2">
                  <b>Refer a Friend</b>
                </a>
              </li>
              <li className="get">
                {" "}
                <a data-toggle="pill" href="#menu1" className="f18-p2">
                  <b>Refer a Business</b>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div id="home" className="tab-pane py-4 fade in active">
                <div className="referafriend">
                  <div className="row joy">
                    <div className="col-md-6 cnt1">
                      <h2 className="f24-h5">
                        Share The Joy Of Eating and Giving,together.
                        <br />
                        Here's how
                      </h2>
                      <p className="f14-p4">
                        Use your referral code, earn rewards in your wallet to
                        spend for your next <br />
                        meal while we feed someone in need. With every referral
                        we will help you <br />
                        give back to society. A little support from you can go a
                        long way.
                      </p>
                      <p className="f14-p4">
                        To know about our referral programs and our Social
                        Activities visit <br />
                        www.visionudbhav.org
                      </p>
                      <p className="f14-p4">
                        Spread the word and make someone happy, today!
                      </p>
                    </div>
                    <div className="col-md-6 cnt2">
                      <img
                        src="../assets/images/enhanced/5806789.png"
                        alt=""
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div id="menu1" className="tab-pane py-4 fade">
                <div className="referabusiness">
                  <div className="row business">
                    <div className="col-md-6 cnt3">
                      <img
                        src="../assets/images/enhanced/group69162.png"
                        alt=""
                        width="100%"
                      />
                    </div>
                    <div className="col-md-6 cnt4">
                      <h2 className="f24-h5">
                        <b>Refer a Business</b>
                      </h2>
                      <p className="f14-p4">
                        We need your help to bring your favourite restaurants to
                        <br /> Amealio. Become an ambassador and promote your
                        favourite
                        <br /> restaurants. They schedule a demo with one of our
                        experts
                        <br /> who will give them a customized tour tailored to
                        their needs.
                        <br />
                        You will receive cash rewards for each restaurant or
                        chain you
                        <br />
                        refer that joins Amealio through your qualified
                        referral!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="convenience-heading">
          {/****************** DeskTop ***************/}
          <h2 className="f32-h4 text-center text-white d-none d-sm-none d-md-block">
            <b>
              Enjoy a Wholesome Eating Experience <br />
              Download the App Now!
            </b>
          </h2>
          {/****************** Mobile ***************/}
          <h2 className="f24-h5 text-center text-white d-block d-sm-block d-md-none px-4">
            <b>Enjoy a Wholesome Eating Experience Download the App Now!</b>
          </h2>
          <p className="f16-p3 my-5 text-white">
            Amplify your food experience. Taste, comfort & satisfaction all at
            the same time.
            <br />
            Download it from Apple Store and Google Play store.{" "}
          </p>
          <NavLink to={`/${props.lang}/checkout`}>
            <button type="button" className="btn button-getapp">
              Download The App
            </button>
          </NavLink>
        </div>
      </section>
      {/* <section>
        <div className="aboutbanner7">
          <a href="#top">
            Back To Top
            <img src="../assets/images/backup/arrow.png" alt="image" />
          </a>
        </div>
      </section> */}
    </div>
  );
};
export default Reward;
