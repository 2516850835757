import React from "react";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import ReactPlayer from "react-player/youtube";

const Slidervideo = () => {
  var settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 10000,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "linear",
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  return (
    <section id="slider-video-pedding" className="slidervideo-section">
      <div className="container">
        <Slider {...settings}>
          <div className="slidervideo1">
            <img
              src="../assets/images/amealio/video01.jpg"
              alt=""
              width="60%"
              data-toggle="modal"
              data-target="#exampleModal"
            ></img>
            <p className="f14-p4 pt-3">
              {" "}
              Using Waitlist on those
              <br />
              special days.
            </p>
          </div>
          <div className="slidervideo">
            <img
              src="../assets/images/amealio/video09.jpg"
              alt=""
              width="60%"
              data-toggle="modal"
              data-target="#exampleModal1"
            ></img>
            <p className="f14-p4 pt-3">
              {" "}
              Accessibility feature makes it simple to
              <br />
              dine out with family.{" "}
            </p>
          </div>
          <div className="slidervideo2">
            <img
              src="../assets/images/amealio/video08.jpg"
              alt=""
              width="60%"
              data-toggle="modal"
              data-target="#exampleModal2"
            ></img>
            <p className="f14-p4 pt-3">
              Aishwarya Loves the Amealio offers
              <br />
              coming her way.
            </p>
          </div>
          <div className="slidervideo1">
            <img
              src="../assets/images/amealio/video05.jpg"
              alt=""
              width="60%"
              data-toggle="modal"
              data-target="#exampleModal3"
            ></img>
            <p className="f14-p4 pt-3">
              {" "}
              What it’s like to Skip the Line
              <br />
              at a busy restaurant.{" "}
            </p>
          </div>
          <div className="slidervideo">
            <img
              src="../assets/images/amealio/video07.jpg"
              alt=""
              width="60%"
              data-toggle="modal"
              data-target="#exampleModal4"
            ></img>
            <p className="f14-p4 pt-3">
              {" "}
              Happy customer describes Curbside
              <br />
              pickup.{" "}
            </p>
          </div>
          <div className="slidervideo2">
            <img
              src="../assets/images/amealio/video02.jpg"
              alt=""
              width="60%"
              data-toggle="modal"
              data-target="#exampleModal5"
            ></img>
            <p className="f14-p4 pt-3">Savlahari Ice cream Partner. </p>
          </div>
          <div className="slidervideo1">
            <img
              src="../assets/images/amealio/video03.jpg"
              alt=""
              width="60%"
              data-toggle="modal"
              data-target="#exampleModal6"
            ></img>
            <p className="f14-p4 pt-3">RocoMamas Testimonial.</p>
          </div>
          <div className="slidervideo">
            <img
              src="../assets/images/amealio/video04.jpg"
              alt=""
              width="60%"
              data-toggle="modal"
              data-target="#exampleModal7"
            ></img>
            <p className="f14-p4 pt-3">
              Pre-order and Reservation for those
              <br />
              who don’t like to wait.
            </p>
          </div>
          <div className="slidervideo2">
            <img
              src="../assets/images/amealio/video06.jpg"
              alt=""
              width="60%"
              data-toggle="modal"
              data-target="#exampleModal8"
            ></img>
            <p className="f14-p4 pt-3">
              Gecko kitchens owner promotes
              <br />
              Amealio.{" "}
            </p>
          </div>
        </Slider>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog subheading-modal-dialog" role="document">
          <div className="modal-content subheading-modal-content">
            <ReactPlayer
              url="https://www.youtube.com/embed/8U11idBcCyE"
              // playing={true}
              loop={true}
              controls={true}
              volume={1}
            />
            {/* <iframe
              width="100%"
              height="500px"
              src="https://www.youtube.com/embed/8U11idBcCyE"
              title="YouTube video player"
              id="ytplayer"
              frameBorder="0"
              allowFullScreen
            ></iframe> */}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog subheading-modal-dialog" role="document">
          <div className="modal-content subheading-modal-content">
            <ReactPlayer
              url="https://www.youtube.com/embed/KGJ6ShAaidA"
              // playing={true}
              loop={true}
              controls={true}
              volume={1}
            />
            {/* <iframe
              width="100%"
              height="500px"
              src="https://www.youtube.com/embed/KGJ6ShAaidA"
              title="YouTube video player"
              id="ytplayer"
              frameBorder="0"
              allowFullScreen
            ></iframe> */}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog subheading-modal-dialog" role="document">
          <div className="modal-content subheading-modal-content">
            <ReactPlayer
              url="https://www.youtube.com/embed/L91svi0ZTSY"
              // playing={true}
              loop={true}
              controls={true}
              volume={1}
            />
            {/* <iframe
              width="100%"
              height="500px"
              src="https://www.youtube.com/embed/L91svi0ZTSY"
              title="YouTube video player"
              id="ytplayer"
              frameBorder="0"
              allowFullScreen
            ></iframe> */}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal3"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog subheading-modal-dialog" role="document">
          <div className="modal-content subheading-modal-content">
            <ReactPlayer
              url="https://www.youtube.com/embed/5kRbpTbBARQ"
              // playing={true}
              loop={true}
              controls={true}
              volume={1}
            />
            {/* <iframe
              width="100%"
              height="500px"
              src="https://www.youtube.com/embed/5kRbpTbBARQ"
              title="YouTube video player"
              id="ytplayer"
              frameBorder="0"
              allowFullScreen
            ></iframe> */}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal4"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog subheading-modal-dialog" role="document">
          <div className="modal-content subheading-modal-content">
            <ReactPlayer
              url="https://www.youtube.com/embed/M8Etf1LaKi8"
              // playing={true}
              loop={true}
              controls={true}
              volume={1}
            />
            {/* <iframe
              width="100%"
              height="500px"
              src="https://www.youtube.com/embed/M8Etf1LaKi8"
              title="YouTube video player"
              id="ytplayer"
              frameBorder="0"
              allowFullScreen
            ></iframe> */}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal5"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog subheading-modal-dialog" role="document">
          <div className="modal-content subheading-modal-content">
            <ReactPlayer
              url="https://www.youtube.com/embed/a0ROV_z2Fzs"
              // playing={true}
              loop={true}
              controls={true}
              volume={1}
            />
            {/* <iframe
              width="100%"
              height="500px"
              src="https://www.youtube.com/embed/a0ROV_z2Fzs"
              title="YouTube video player"
              id="ytplayer"
              frameBorder="0"
              allowFullScreen
            ></iframe> */}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal6"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog subheading-modal-dialog" role="document">
          <div className="modal-content subheading-modal-content">
            <ReactPlayer
              url="https://www.youtube.com/embed/GuDrz090Kkw"
              // playing={true}
              loop={true}
              controls={true}
              volume={1}
            />
            {/* <iframe
              width="100%"
              height="500px"
              src="https://www.youtube.com/embed/GuDrz090Kkw"
              title="YouTube video player"
              id="ytplayer"
              frameBorder="0"
              allowFullScreen
            ></iframe> */}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal7"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog subheading-modal-dialog" role="document">
          <div className="modal-content subheading-modal-content">
            <ReactPlayer
              url="https://www.youtube.com/embed/pX2ovzzmHoY"
              // playing={true}
              loop={true}
              controls={true}
              volume={1}
            />
            {/* <iframe
              width="100%"
              height="500px"
              src="https://www.youtube.com/embed/pX2ovzzmHoY"
              title="YouTube video player"
              id="ytplayer"
              frameBorder="0"
              allowFullScreen
            ></iframe> */}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal8"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog subheading-modal-dialog" role="document">
          <div className="modal-content subheading-modal-content">
            <ReactPlayer
              url="https://www.youtube.com/embed/Qt1TwQuYk7M"
              // playing={true}
              loop={true}
              controls={true}
              volume={1}
            />
            {/* <iframe
              width="100%"
              height="500px"
              src="https://www.youtube.com/embed/Qt1TwQuYk7M"
              title="YouTube video player"
              id="ytplayer"
              frameBorder="0"
              allowFullScreen
            ></iframe> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slidervideo;
