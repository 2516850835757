const FeaturesData = {
    "type": "Features",
    "list": [{
            title: 'Waitlist',
            header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
            data: [{
                    header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
                    title: 'Announce Your Arrival',
                    image: 'arrival.png'
                },
                {
                    title: 'Manage Delays from Your Phone',
                    image: 'delay.png'
                },
                {
                    title: 'Easy Access',
                    image: 'encryption.png'
                },
                {
                    title: 'Order at Convenience',
                    image: 'online-order.png'
                },

                {
                    title: 'Get Notified For Every Activity',
                    image: 'notification-bell.png'
                },


            ],
            benifitheader: 'We Make Getting Food from High-Street Restaurants, Easy!',
            bannersImage: 'waitlistbanner.png',
            bannerSubImage: 'waitlist.png',
            bannersHeading: 'Waitlist Yourself at your Favorite Restaurant',
            benifitheader1: 'Put yourself on a waitlist from anywhere-- even if you are late or have arrived early. All of this without the waiting blues!',
            bulletin:'true',
            subheadingButtonName: 'Click here to see the video',
            subheadingImage: 'amealio_waitlist.png',
            subheadingmain: 'Your Waiting Days are Over with our Ordering Options',
            subheadline1: 'Skip The Line!',
            subheadline2: 'Curbside Delivery!',
            subheadline3: 'Take Away your Food Differently!',
            subheadingsub: 'Track It, Take It and Eat It! – It’s That Easy.',
            subheadingsubimg: 'waitlist.png',
            youtubelink: 'https://www.youtube.com/embed/w4m19PxlJnA',

        },
        {
            title: 'walkin',
            header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
            data: [{
                    header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
                    title: 'Be a VIP',
                    image: 'vip.png'
                },
                {
                    title: 'Feel like a Royalty',
                    image: 'crown.png'
                },
                {
                    title: 'Services on Preference',
                    image: 'feedback.png'
                },
                {
                    title: 'Contactless Seating',
                    image: 'contactlessseatting.png'
                },

                {
                    title: 'Inform them Before Leaving',
                    image: 'online-payment.png'
                },

            ],
            benifitheader: 'Make Walk-In(s) as per Time & Convenience',
            bannersImage: 'banner.png',
            bannerSubImage: 'walkin.png',
            bannersHeading: 'Walk In',
            subheadingButtonName: 'Click here to see the video',
            benifitheader1: 'Enjoy hassle free walk-in at the peak hours without wasting your time in queues',
            subheadingImage: 'amealiowalkin.png',
            subheadingmain: 'Contact Free Walk-Ins',
            subheadingPara: '',
            subheadline1: 'Feel like a royalty at the convenience of your home while you let them know that you are coming.',
            subheadline2: 'Simply Walk-in to the restaurant of your choice and enjoy contactless seating.',
            subheadingsub: 'Be a VIP and Get a Red-Carpet Welcome',
            subheadingsubimg: 'walkin.png',
            youtubelink: 'https://www.youtube.com/embed/5d9cZNZOWzU',
        },
        {
            title: 'reservation',
            header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
            data: [{
                    header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
                    title: 'Book Ahead',
                    image: 'booking.png'
                },
                {
                    title: 'Select Pax',
                    image: 'fish.png'
                },
                {
                    title: 'Seating Preferences',
                    image: 'seating.png'
                },
                {
                    title: 'Special Seating',
                    image: 'seat.png'
                },

                {
                    title: 'Track Request',
                    image: 'order.png'
                },


            ],
            benifitheader: 'Enjoy a Restaurant Visit by Booking Ahead',
            bannersImage: 'reservation-banner.png',
            bannerSubImage: 'reservation.png',
            bannersHeading: 'Surprise your Loved Ones and Make Reservations',
            benifitheader1: 'Make every moment count with your special ones. Book a table ahead and cherish the meals on time, as per your taste and convenience',
            subheadingImage: 'amealio_reservation.png',
            subheadingButtonName: 'Check out the video to see how',
            subheadingmain: 'Make Special Moments Count With Your Loved Ones',
            subheadline1: 'Be it a date or a birthday, plan ahead for special days with Amealio.',
            subheadline2: 'Need special accommodation like a high chair or handicap access? We’ve got you covered. Our partners will take requests for any occasion to make it special for you.',
            subheadline3: 'We brag over your Smiles! :)',
            subheadingsub: 'Whether it is pool side or by the patio, choose your favorite seating area here.',
            subheadingsubimg: 'reservation.png',
            youtubelink: 'https://www.youtube.com/embed/vqrMHbO9Z40',
        },
        {
            title: 'Take Away',
            header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
            data: [{
                    header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
                    title: 'Customize Your Food Order',
                    image: 'sound-mixer.png'
                },
                {
                    title: 'No More Calling',
                    image: 'no-calls-sign.png'
                },
                {
                    title: 'Customized Meals',
                    image: 'customization.png'
                },
                {
                    title: 'Walk N Hog',
                    image: 'walk.png'
                },

                {
                    title: 'Enjoy Discounts and Offers',
                    image: 'discount.png'
                },


            ],
            benifitheader: 'Just Pick it & Enjoy it Your Way!',
            benifitheader1:  'Track it, Take it and Eat It! – It’s as easy as 1.2.3. ',
            bannersImage: 'take-away-banner.png',
            bannerSubImage: 'take-away.png',
            bannersHeading: 'Take Away Your Food Differently',
            // bannerSubHeading: '',
            bulletin:'true',
            subheadingImage: 'amealio_takeaway.png',
            subheadingButtonName: 'Click here to see the video',
            subheadline1: 'Quick & easy meals for the enterprising you',
            subheadline2: 'Hassle-free meals for the hustlers',
            subheadline3: 'Better For the go-getter',
            subheadline4: ' Meals on the move',
            subheadingsub: 'Pick it, Click it, Take it!',
            subheadingsubimg: 'takeaway.png',
            youtubelink: 'https://www.youtube.com/embed/Igsej-eP3_c',
        },
        {
            title: 'Curbside',
            header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
            data: [{
                    header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
                    title: 'Hassle-Free Food Pickup',
                    image: 'take-away (1).png'
                },
                {
                    title: 'Contactless Delivery',
                    image: 'take-away.png'
                },
                {
                    title: 'Time Saver',
                    image: 'save-time.png'
                },
                {
                    title: 'No Parking Worries',
                    image: 'car-parking.png'
                },

                {
                    title: 'Remain Safe In Vehicle',
                    image: 'driver.png'
                },


            ],
            benifitheader: 'Drive Up to the Closest Pickup Point and Get Food Delivered to You, in Your Vehicle on the Go.',
            bannersImage: 'curbside-banner.png',
            bannerSubImage: 'curbside.png',
            bannersHeading: 'Highway or Hallway—We Bring your Order Wherever you’re Parked',
            benifitheader1: 'Drive your way to the closest pick-up point without finding a parking spot',
            bulletin:'true',
            subheadingImage: 'amealoi_curbside.png',
            subheadingButtonName: 'Watch the Video here',
            subheadingmain: 'Sign up to experience one of the easiest and quickest ways to pick-up food.',
            subheadline1: 'Order Online',
            subheadline2: 'Savor your favorites',
            subheadline3: 'Pick-up food on the go',
            subheadingsub: 'Introducing one of its kind to make your lives simpler and better.',
            subheadingsubimg: 'crubside.png',
            youtubelink: 'https://www.youtube.com/embed/Axh68R-sAWU',
        },
        {
            title: 'Dinein',
            header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
            data: [{
                    header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
                    title: 'Qr Scan Ordering',
                    image: 'mobile.png'
                },
                {
                    title: 'Track Order',
                    image: 'location.png'
                },
                {
                    title: 'Order Update',
                    image: 'browser.png'
                },
                {
                    title: 'Food Personalization',
                    image: 'customization.png'
                },

                {
                    title: 'Contactless Payment',
                    image: 'contactless.png'
                },


            ],
            benifitheader: 'Enjoy the Contactless Dine-in on Your Special Occasions at Your Favorite Restaurant.',
            bannersImage: 'jason-leung-poI7DelFiVA-unsplash.png',
            bannerSubImage: 'dine-in.png',
            bannersHeading: 'Have a Wholesome Dine-in Experience Made Just for You',
            benifitheader1: 'Eat at your favorite restaurant with hyper-personalized food just the way you like it',
            bulletin:'true',
            subheadingImage: 'amelio_dinein.png',
            subheadingButtonName: 'Click here to see the video',
            subheadingmain: 'Cherishing a Contactless Dine-in Experience via Amealio is as simple as 1.2.3.',
            subheadline1: 'Step 1: Scan the QR code Step',
            subheadline2: '2: Enter your table number Step',
            subheadline3: '3: Get your order delivered at your table',
            subheadingsub: 'See How We Are Transforming The Dine-In Industry.',
            subheadingsubimg: 'dinein.png',
            youtubelink: 'https://www.youtube.com/embed/z7lo9BT9hxo',
        },
        {
            title: 'skiptheline',
            header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
            data: [{
                    header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
                    title: 'Order In Advance',
                    image: 'order-food.png'
                },
                {
                    title: 'Pay Seamlessly',
                    image: 'payment-method.png'
                },
                {
                    title: 'Convenience Galore',
                    image: 'online-order.png'
                },
                {
                    title: 'Get Notified',
                    image: 'notifications.png'
                },

                {
                    title: 'Faster Than You Can Imagine',
                    image: 'fast-time.png'
                },


            ],
            benifitheader: 'Skip Line and Save Time',
            bannersImage: 'pexels-picography-4481.png',
            bannerSubImage: 'skip-the-line.png',
            bannersHeading: 'Be a VIP and Get a Red-Carpet Welcome',
            benifitheader1: 'Skip the line and get your food in style',
            subheadingButtonName: 'Check out the video to see how',
            subheadingImage: 'amealio_skiptheline.png',
            // subheadingmain: 'Grab your food in a click',
            subheadline1: 'Waiting in queues to order and pick up meals takes time and energy.',
            subheadline2: 'Order ahead, get notified, collect your order and get that',
            subheadline3: 'extra quality time with your loved ones.',
            subheadline4: 'Enjoy ordering meals at will.',
            subheadingsub: 'Pop-In & Pop-Out Out Of Your Favorite Restaurants In A Snap',
            subheadingsubimg: 'skiptheline.png',
            youtubelink: 'https://www.youtube.com/embed/cZq6pZyIY-o',
        },
        {
            title: 'Atmyseat',
            header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
            data: [{
                    header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
                    title: 'Order Food Anytime During The Movie',
                    image: 'order-food.png'
                },
                {
                    title: 'Avoid Queues',
                    image: 'queue (2).png'
                },
                {
                    title: 'Contactless Payment',
                    image: 'contactless.png'
                },
                {
                    title: 'Time Saver',
                    image: 'hourglass.png'
                },

                {
                    title: 'Needn’t Move An Inch From Your Seat',
                    image: 'cinema-seat.png'
                },


            ],
            benifitheader: 'Handpick from our Mouthful Selection of Refreshments',
            bannersImage: 'atmyseat.jpg',
            bannerSubImage: 'at-my-seat.png',
            bannersHeading: 'Ordering at a Movie Hall Made Easy',
            benifitheader1: 'Never let the action and the drama go amiss. We will roll out your order at your seat',
            subheadingImage: 'amealio_Qr.png',
            bulletin:'true',
            subheadingButtonName: 'watch the video here',
            subheadingmain: 'Carpe Diem your Move During the Movie',
            subheadline1: 'See it. Click it. Savor it.',
            subheadline2: 'Relish food without breaks',
            subheadline3: 'Eat all that you want & not what is offered at the hall',
            subheadingsub: 'Watch a Movie Break Free, and Cherish the Experience that’s Convenient and Wholesome',
            subheadingsubimg: 'live streamining.png',
            youtubelink: 'https://www.youtube.com/embed/5EBQZy8Ku1s',
        },
        {
            title: 'Roomservice',
            header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
            data: [{
                    header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
                    title: 'Save Time and Effort',
                    image: 'hourglass.png'
                },
                {
                    title: 'Voice Orders With Ease',
                    image: 'voice.png'
                },
                {
                    title: 'Get Notified',
                    image: 'notifications.png'
                },
                {
                    title: 'End To End Tracking',
                    image: 'location.png'
                },

                {
                    title: 'No More Calling Out',
                    image: 'no-calls-sign.png'
                },


            ],
            benifitheader: 'Make Every Moment Count During Your Stay',
            bannersImage: 'pexels-cottonbro-5371557.png',
            bannerSubImage: 'roomservice.png',
            bannersHeading: 'Friends, Family or a Work Trip We Make Your Hotel Stays Worth a Stay',
            benifitheader1: 'Make memories without getting disturbed It’s a DND with Amealio on every trip!',
            subheadingImage: 'amealio_Qr.png',
            bulletin:'true',
            subheadingmain: 'The Hotel Phone Directory Days are Over!',
            subheadline1: 'Room Service at your Disposal',
            subheadline2: ' At the Time of your Convenience',
            subheadline3: 'Announce your arrival/needs without making a call',
            // subheadingsub: 'Just sit back and relax!.',
            subheadingButtonName: 'Check out the video to see how',
            subheadingsubimg: 'rsvp.png',
            youtubelink: 'https://www.youtube.com/embed/fipFDwojjRk',
        },
        {
            title: 'Delivery',
            header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
            data: [{
                    header: 'Drive up to the closest pickup point and get food delivered to you, in your vehicle on the go.',
                    title: ' Get Food At Your Comfort',
                    image: 'work-from-home.png'
                },
                {
                    title: 'Avoid The Line',
                    image: 'queue (2).png'
                },
                {
                    title: 'Explore & Experience',
                    image: 'practice.png'
                },
                {
                    title: 'Contactless Delivery',
                    image: 'take-away.png'
                },

                {
                    title: 'Offers & Discounts',
                    image: 'discount.png'
                },


            ],
            benifitheader: 'Your Go-to Delivery Partner for Your Hunger Pangs',
            bannersImage: 'delivery2.png',
            bannerSubImage: 'delivery.png',
            bannersHeading: 'Delivery',
            benifitheader1: 'Think Fast Delivery. Think Amealio.',
            subheadingImage: 'amealiodelivery1.png',
            subheadingmain: 'Make your Everyday Brighter, Happier & Yummier',
            subheadline1: 'Make an informed choice',
            bulletin:'true',
            subheadline2: 'Know what you eat',
            subheadline3: 'Door dash delivery',
            subheadline4: ' Efficient & Effective',
            subheadingsub: 'Satisfy your Cravings & Savor the Food you Love Anywhere. Anytime.',
            subheadingButtonName: 'Check out the video to see how',
            subheadingsubimg: 'homedelivery.png',
            youtubelink: 'https://www.youtube.com/embed/z1VKOmaSdBY',
        },

    ]
}

export default FeaturesData;