import React from "react";

const Benefits = (props) => {
  const benefits = props.data;
  // console.log(benefits)
  return (
    <section>
      <div className="container">
        <div className="deliverycondition">
          <h1>{benefits.benifitheader}</h1>
          <p className="conditionheader">{benefits.benifitheader1}</p>
        </div>
        <div className="conditiondescribe">
          <h1>Benefits</h1>
          <ul>
            {benefits.data.map((benefits, index) => {
              const benefitsImage =
                "../assets/images/features/walkin/" + benefits.image;
              // console.log(benefits)
              return (
                <li key={index}>
                  <span className="benefit-outlet">
                    <img src={benefitsImage} alt="Benefits" title="Benefits" />
                  </span>
                  <p>{benefits.title}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};
export default Benefits;
