import React from "react";

const SliderElements = (props) => {
  return (
    <div>
      <div className="p-4 m-0">
        <img
          src={`../assets/images/businessNew/newImages/${props.imgSrc}.png`}
          alt={props.title}
          title={props.title}
          className="img-fluid mx-0 px-0 shadow"
      //   style={{ borderRadius: "30px" }}
        />
      </div>
    </div>
  );
};

export default SliderElements;