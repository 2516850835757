import React from "react";
// import Header from "../templates/header";
import Bussinessbanner from "./bussinessbanner";
import Footer from "../templates/footer";
import Customerfocused from "./customerfocused";
import Businessoutlet from "./businessoutlet";
import Partnerup from "./partnerup";
// import Restaurants from "./restaurants";
import Offers from "./offers";
import Multipledevice from "./multipledevice";
// import About from "./about";
import Service from "../home/service";
import Happycustomer from "../home/happycustomer";

const bussiness = (props) => {
  return (
    <div className="App">
      <Bussinessbanner lang={props.lang} />
      <Customerfocused lang={props.lang} />
      <Businessoutlet lang={props.lang} />
      <Multipledevice />
      <Partnerup />
      {/* <Restaurants /> */}
      <Service />
      <Offers />
      <Happycustomer />
      {/* <About /> */}
      <Footer />
    </div>
  );
};

export default bussiness;
