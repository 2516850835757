import React from "react";
// import Header from '../templates/header';
import Footer from "../templates/footer";
import Benefits from "./benefits";
import Bannerfeatures from "./bannerfeatures";
import Forbusiness from "./forbusiness";
import Subheading from "./subheading";
import FeaturesData from "./featuresData";
const Foodfeatures = (props) => {
  const categoriesData = FeaturesData.list.find(
    (data) => data.title === props.pagetype
  );

  return (
    <div>
      <Bannerfeatures data={categoriesData} />
      <Benefits data={categoriesData} />
      <Subheading data={categoriesData} />
      <Forbusiness lang={props.lang} />
      <Footer />
    </div>
  );
};

export default Foodfeatures;
