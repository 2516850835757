import React from "react";
import { NavLink } from "react-router-dom";

const Fastfoodbanner = (props) => {
  const banner = props.data;
  const bannerImage = "../assets/images/fastfood/" + banner.bannerImage;
  return (
    <div className="header-flex">
      <img src={bannerImage} alt="Banner" title="Banner" width="100%" />
      <div className="header-text">
        <div className="container">
          <div className="row">
            <div className="col-md-8 heading-bnr">
              <h1 className="header-h1">{banner.bannerHeading}</h1>
              <p className="header-para">{banner.bannerSubHeading}</p>
              <div className="header-button">
                <NavLink to={`/${props.lang}/demo`}>
                  <button className="btn header-btn">
                    {banner.bannerButtonName}
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fastfoodbanner;
