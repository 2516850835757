import React from "react";

const WhyChooseAmealio = (props) => {
  return (
    <div key={props.keys}>
      {/* <div className=""> */}
      <div className="partnerup-center">
        <div className="partnerup-round partner-new-slider-two m-0">
          <img
            src={`../assets/images/business/${props.data?.imgOne}`}
            alt={props.data?.imgTitle}
            title={props.data?.imgTitle}
            width="45%"
            className="partnerup-img m-0"
          ></img>
        </div>
        <h4 className="f18-p2 text-white">
          <b>{props.data?.title}</b>
        </h4>
        <p className="f12-p5 text-white">
          {props.data?.paragraph}
          <br />
          {props.data?.paragraphTwo}
        </p>
      </div>
      {/* </div> */}
    </div>
  );
};

export default WhyChooseAmealio;
