import React from "react";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import thinkfoodBanner from "./../../newAssets/images/HomePage/Banner/thinkfood.png";
// import thinkFoodSprial from "./../../newAssets/images/HomePage/sprial/ThinkFoodSprial.png";
import thinkFoodSprialTop from "./../../newAssets/images/HomePage/sprial/thinkfoodsprial1.png";
import WhyAmealioSlider from "./HomePageSlider/whyAmealioSlider";

const Whyamealio = () => {
  var settings = {
    // dots: true,
    // arrows: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipeToSlide: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  const whyAmealioMoment = [
    {
      id: 1,
      title:
        "Feed your cravings, fast and delicious. Choose from the best restaurants for any occasion from a wide range of choices and enjoy the benefits we have to offer.",
      imageName: "feed",
    },
    {
      id: 2,
      title:
        "Explore foods of your choice and enjoy the memorable experience, all with just a click on Amealio, Customize your ideal way of a food.",
      imageName: "explorfood",
    },
    {
      id: 3,
      title:
        "Concerned about hygiene and sanitation? Watch your favourite restaurant front,and food being cooked from wherever you are.",
      imageName: "feed",
    },
    {
      id: 4,
      title:
        "Get a VIP welcome for walk-in,waitlist,reservations with our contact-less entry and seating with QR code at your favourite Food Establishments.",
      imageName: "explorfood",
    },
    {
      id: 5,
      title:
        "Feed your cravings, fast and delicious. Choose from the best restaurants for any occasion from a wide range of choices and enjoy the benefits we have to offer.",
      imageName: "feed",
    },
    {
      id: 6,
      title:
        "Explore foods of your choice and enjoy the memorable experience, all with just a click on Amealio, Customize your ideal way of a food.",
      imageName: "explorfood",
    },
  ];

  return (
    <div className="app">
      <section
        className="think-food-think-amealio"
        style={{ backgroundImage: "url(" + thinkfoodBanner + ")" }}
      >
        <div className="top-image-right-sprial">
          <img
            src={thinkFoodSprialTop}
            alt="Happy Customers Sprial"
            title="Happy Customers Sprial"
            className="img-fluid"
          ></img>
        </div>
        <div className="mobileexperience-imghover">
          <img
            src="../assets/images/amealio/background.png"
            alt=""
            width="100%"
          ></img>
        </div>
        <div className="top-image-left-bg">
          <img
            src="../assets/images/amealio/svg/leafBottomRight.svg"
            alt="Burger"
            title="Burger food"
            className="img-fluid"
          ></img>
        </div>
        <div className="top-image-right-bg">
          <img
            src="../assets/images/amealio/svg/spoon.png"
            alt="Burger"
            title="Burger food"
            className="img-fluid"
          ></img>
        </div>
        <div className="container think-food-slider">
          <h1 className="think-food-h1-font">Enjoy Your Moments!</h1>
          <div className="my-5 py-5">
            <Slider {...settings}>
              {whyAmealioMoment &&
                whyAmealioMoment.map((data) => {
                  return (
                    <React.Fragment key={data.id}>
                      <WhyAmealioSlider
                        paragraph={data.title}
                        imageName={data.imageName}
                      />
                    </React.Fragment>
                  );
                })}
            </Slider>
          </div>
        </div>
        {/* <div className="bottom-image-left-bg">
          <img
            src="../assets/images/amealio/svg/leafBottomRight.svg"
            alt="Burger"
            title="Burger food"
            className="img-fluid"
          ></img>
        </div> */}
        <div className="bottom-image-right-bg">
          <img
            src="../assets/images/amealio/svg/leafBottomRight.svg"
            alt="Burger"
            title="Burger food"
            className="img-fluid"
          ></img>
        </div>
      </section>
    </div>
  );
};
export default Whyamealio;
