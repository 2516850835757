import React from "react";
import { useNavigate } from "react-router-dom";
// import experience from "./../../newAssets/images/HomePage/Banner/experience.svg";
import CountUp from "react-countup";

const Experience = (props) => {
  const navigate = useNavigate();

  const experienceData = [
    { id: 1, name: "PeopleSeated", value: 37000, title: "App Downloads" },
    { id: 2, name: "HoursSaved", value: 300, title: "User MoM Growth" },
    {
      id: 3,
      name: "LaunchedMajorCities",
      value: 2050000,
      title: "Value of Transactions",
    },
    {
      id: 4,
      name: "OrdersandRequests",
      value: 31325,
      title: "Happy Customers",
    },
    {
      id: 5,
      name: "RestaurantsOnboard",
      value: 500,
      title: "Restaurants Onboard",
    },
    {
      id: 6,
      name: "SocialCauses",
      value: 15132,
      title: "Total Number of Referrals",
    },
  ];

  return (
    <section
      className="join-experience-main"
      // style={{ backgroundImage: "url(" + experience + ")" }}
    >
      <div className="container text-center">
        {/* <div className="row">
          <div className="join-top-right-img">
            <img
              src="../assets/images/amealio/svg/cheeze.png"
              alt="Cheeze"
              title="Cheeze food"
              className="img-fluid"
            ></img>
          </div>
        </div> */}
        <div className="row">
          {/* <div className="join-medium-right-img">
            <img
              src="../assets/images/amealio/svg/salt.svg"
              alt="Burger"
              title="Burger food"
              className="img-fluid"
            ></img>
          </div> */}
          {/* <div className="join-left-img d-none d-md-block">
            <img
              src="../assets/images/amealio/svg/chili.png"
              alt="Burger"
              title="Burger food"
              className="img-fluid"
            ></img>
          </div> */}
          <div className="col-12 col-sm-12 col-md-5 m-auto">
            <h1 className="join-h1-font text-dark">The Amealio Experience</h1>
            <p className="join-p-paragraph px-2">
              Download the Amealio App to join our community for a wholesome
              dinning experience!
            </p>
          </div>
          <div className="col-12 col-sm-12 col-md-10 mt-5 m-auto">
            <div className="row">
              {experienceData.map((data, index) => {
                return (
                  <div
                    className="col-6 col-sm-6 col-md-4 d-flex justify-content-center"
                    key={index}
                  >
                    <div className="box-design-experience">
                      <div>
                        {data.id === 7 ? (
                          // || data.id === 5
                          <h1 className="join-h1-experience-Heading">
                            {data.value}
                          </h1>
                        ) : (
                          <CountUp
                            start={0}
                            end={data.value}
                            //   redraw={true}
                            enableScrollSpy={true}
                          >
                            {({ countUpRef }) => (
                              <div className="d-flex align-items-center justify-content-center">
                                <h1
                                  className="join-h1-experience-Heading"
                                  ref={countUpRef}
                                />
                                <h1 className="join-h1-experience-Heading">
                                  {data.id === 2 ? "%" : "+"}
                                </h1>
                              </div>
                            )}
                          </CountUp>
                        )}
                      </div>
                      <p className="join-p-experience-paragraph">
                        {data.title}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-12">
            <button
              type="button"
              className="btn join-us-button text-white"
              title="Restaurant Join us"
              onClick={() => navigate(`/${props.lang}/demo`)}
            >
              Restaurant Join us
            </button>
          </div>
          {/* <div className="join-bottom-left-img">
            <img
              src="../assets/images/amealio/svg/pizza.png"
              alt="Burger"
              title="Burger food"
              className="img-fluid"
            ></img>
          </div> */}
          <div className="bottom-image-position">
            <img
              src="../assets/images/amealio/svg/leafBottomRight.svg"
              alt="Burger"
              title="Burger food"
              className="img-fluid"
            ></img>
          </div>
        </div>
      </div>
    </section>
    //    <>
    //    <section className="experience-section">
    //        <div className="container">
    //            <h1 className="experience-head">Join Us for a Wholesome Dining Experience</h1>
    //            <div className="card experience-card">
    //                <div className="row experience-row">
    //                    <div className="col-md-4 col-xs-6 experience-col">
    //                        <h1 className="experience-h1">5000</h1>
    //                        <p>People Seated</p>
    //                    </div>
    //                    <div className="col-md-4 col-xs-6 experience-col">
    //                        <h1 className="experience-h1">10k+</h1>
    //                        <p>Hours Saved</p>
    //                    </div>
    //                    <div className="col-md-4 col-xs-6 experience-col">
    //                        <h1 className="experience-h1">2</h1>
    //                        <p>Launched in Major Cities</p>
    //                    </div>
    //                    <div className="col-md-4 col-xs-6 experience-col">
    //                        <h1 className="experience-h1">2500</h1>
    //                        <p>Orders and Requests Completed</p>
    //                    </div>
    //                    <div className="col-md-4 col-xs-6 experience-col">
    //                        <h1 className="experience-h1">100+</h1>
    //                        <p>Restaurants Onboard</p>
    //                    </div>
    //                    <div className="col-md-4 col-xs-6 experience-col">
    //                        <h1 className="experience-h1">$10k</h1>
    //                        <p>Collected Towards Social Causes</p>
    //                    </div>
    //                </div>
    //            </div>
    //     <NavLink to ="/demo"><center><button className="btn experience-btn">Get Onboarded</button></center></NavLink>
    //        </div>
    //    </section>
    //        </>
  );
};

export default Experience;
