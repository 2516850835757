import React, { useEffect, useState } from "react";
// import Header from "../templates/header";
import Footer from "../templates/footer";
import axios from "axios";

export default function Help() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [accordionData, setAccordionData] = useState(null);

  useEffect(() => {
    axios("https://merchantapi.amealio.com/help-and-faq?role=5")
      .then((response) => {
        setData(response.data);
        setAccordionData(response.data[0].records[0].QA);
      })

      .catch((error) => {
        console.error("Error fetching data: ", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function handleClick(selectedAccordion) {
    setAccordionData(selectedAccordion.records[0].QA);
    console.log(accordionData);
  }

  if (loading) return "loading...";
  if (error) return "Error";
  return (
    <>
      <div className="help-backlayer">
        <img
          src="../assets/images/help/backgroundred-icons.png"
          className="help-orgin"
          alt="Background icons"
          title="Background icons"
        ></img>
        <section>
          <div name="top"></div>
          <div className="container">
            <div className="helpresolve">
              <div className="row">
                <div className="col-md-6 resolveleft">
                  <h1>Curious To Know More About Us?</h1>
                  <div className="coustomdescribe">
                    <img
                      src="../assets/images/help/edit.png"
                      alt="Edit"
                      title="Edit"
                    ></img>
                    <h3>
                      <a href="#contact">Write To Us</a>
                    </h3>
                  </div>
                  <p>
                    We are more than glad to assist you with your queries and
                    curiosities.
                  </p>
                  {/* <div className="searchicon">
                    <h6>search</h6>
                    <i className="fa fa-search" href="#"></i>
                  </div>  */}
                </div>
                <div className="col-md-6 resolveright">
                  <img
                    src="../assets/images/help/customerservice.png"
                    alt="Customer Service"
                    title="Customer Service"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="frequentdiscribe">
              <h1>Frequently Asked Questions</h1>
              <p>
                Here's a list of few questions that people ask us frequently.
              </p>
            </div>
            <div className="conditionofcontent">
              <ul className="nav nav-pills topmenubar">
                {data?.map((cate, i) => {
                  return (
                    <li
                      key={i}
                      onClick={(_e) => handleClick(cate)}
                      className={i === 0 ? "active" : ""}
                    >
                      <a
                        className={i === 0 ? "active" : ""}
                        data-toggle="pill"
                        href="#service"
                      >
                        {cate.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
              {/* <div className="tab-scroll"></div> */}

              {accordionData?.map((QA, i) => {
                // const id = "/" +  data.toLowerCase().replace(/ +/g, "");
                return (
                  <div className="tab-content" key={i}>
                    <div id="service" className="tab-pane fade in active">
                      <div id="accordion">
                        <div className="card destination">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target={"#collapseOne" + i}
                            aria-expanded="true"
                            aria-controls={"collapseOne" + i}
                          >
                            <div
                              className="card-header varient"
                              id={"headingOne" + i}
                            >
                              <h5 className="mb-0">
                                <div className="btn-link">
                                  <p> {QA.question}</p>
                                  <i className="fa fa-chevron-down arrowdown"></i>
                                </div>
                              </h5>
                            </div>
                          </button>

                          <div
                            id={"collapseOne" + i}
                            className="collapse"
                            aria-labelledby={"headingOne" + i}
                            data-parent="#accordion"
                          >
                            <div className="card-body">{QA.answer}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/* <section>
          <div className="container">
            <div className="frequentdiscribe">
              <h1>Frequently asked questions</h1>
              <p>Here's a list of few questions that people ask us frequently.</p>
            </div>
            <div className="conditionofcontent">
              <ul className="nav nav-pills topmenubar">
                <li className="active"><a className="active" data-toggle="pill" href="#service" >Service</a></li>
                <li><a data-toggle="pill" href="#technical">Technical</a></li>
                <li><a data-toggle="pill" href="#payments">Payments</a></li>
                <li><a data-toggle="pill" href="#bookingcancellation">Booking & Cancellation</a></li>
                <li><a data-toggle="pill" href="#orders">Orders</a></li>
                <li><a data-toggle="pill" href="#others">Others</a></li>
              </ul>

              <div className="tab-content">
                <div id="service" className="tab-pane fade in active">
                  <div id="accordion">
                    <div className="card destination">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <div className="card-header varient" id="headingOne">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p> Are the placed seating request (Walk-in, Waitlist, Reservation) editable?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <div className="card-header varient" id="headingTwo">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>How can I change/cancel my seating request?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <div className="card-header varient" id="headingThree">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>What happens to my seating request, if I reach late at the restaurant?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#collapsefour" aria-expanded="true" aria-controls="collapsefour">
                        <div className="card-header varient" id="headingfour">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>How to request for reassign my waitlist?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapsefour" className="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                        <div className="card-header varient" id="headingfive">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>What are your hours of operations?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapsefive" className="collapse" aria-labelledby="headingfive" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="technical" className="tab-pane ">
                  <div id="accordion">
                    <div className="card destination">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <div className="card-header varient" id="headingOne">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>  Should I provide you the permission to GPS and notifications?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <div className="card-header varient" id="headingTwo">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p> Did not receive the OTP?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <div className="card-header varient" id="headingThree">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p> Did not receive referral coupon?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#collapsefour" aria-expanded="true" aria-controls="collapsefour">
                        <div className="card-header varient" id="headingOne">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>Deactivate my account or my account is deactivated</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapsefour" className="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                        <div className="card-header varient" id="headingTwo">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>How to view my profile details?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapsefive" className="collapse" aria-labelledby="headingfive" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="payments" className="tab-pane">
                  <div id="accordion">
                    <div className="card destination">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <div className="card-header varient" id="headingOne">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>What payment methods/options are available on Amealio?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <div className="card-header varient" id="headingTwo">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>Do I have to pay while placing a seating request/order?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <div className="card-header varient" id="headingThree">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>How will I know that the payment is done?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#collapsefour" aria-expanded="true" aria-controls="collapsefour">
                        <div className="card-header varient" id="headingfour">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>Will the amount for tips/donation be pre-added in the bill?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapsefour" className="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                        <div className="card-header varient" id="headingfive">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>Why did my payment fail?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapsefive" className="collapse" aria-labelledby="headingfive" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="bookingcancellation" className="tab-pane">
                  <div id="accordion">
                    <div className="card destination">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <div className="card-header varient" id="headingOne">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>How can I cancel a reservation?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <div className="card-header varient" id="headingTwo">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>Is there any cancellation fee?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <div className="card-header varient" id="headingThree">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>What happens if I don't show up for my reservation?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#collapsefour" aria-expanded="true" aria-controls="collapsefour">
                        <div className="card-header varient" id="headingfour">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>How many days in advance can I make a booking</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapsefour" className="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                        <div className="card-header varient" id="headingTwo">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>What is Threshold and how does it affect my seating request?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapsefive" className="collapse" aria-labelledby="headingfive" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="orders" className="tab-pane ">
                  <div id="accordion">
                    <div className="card destination">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <div className="card-header varient" id="headingOne">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>Can I edit my Order?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <div className="card-header varient" id="headingTwo">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>Can I cancel my Order?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <div className="card-header varient" id="headingThree">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>Who is accountable for quality/quantity of food?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#collapsefour" aria-expanded="true" aria-controls="collapsefour">
                        <div className="card-header varient" id="headingfour">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>Is there a minimum order value?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapsefour" className="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                        <div className="card-header varient" id="headingfive">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>Do you deliver food at home?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapsefive" className="collapse" aria-labelledby="headingfive" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                        <div className="card-header varient" id="headingsix">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>Is single order from many restaurants possible?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapsesix" className="collapse" aria-labelledby="headingsix" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="others" className="tab-pane">
                  <div id="accordion">
                    <div className="card destination">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <div className="card-header varient" id="headingOne">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>  What is default wait time?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <div className="card-header varient" id="headingTwo">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p>  Why handicap or highchair option is not available in all the places?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="card destination">
                      <button className="btn btn-link " data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <div className="card-header varient" id="headingThree">
                          <h5 className="mb-0">
                            <div className="btn-link">
                              <p> Why can't I find my locality?</p>
                              <i className="fa fa-chevron-down arrowdown"></i>
                            </div>
                          </h5>
                        </div>
                      </button>
                      <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section>
          <div className="container">
            <div className="contactdescribe">
              <h1>Still Looking for Something?</h1>
              <p>
                Amealio is here to help you resolve all your queries, if your
                query is not in one of our FAQ’s
              </p>
              <p>
                Or if in case you have some suggestions or insight for us, we
                will get back to you with the solution shortly
              </p>
            </div>
            <div>
              <div className="contactforms">
                <h1 id="contact">Contact Us</h1>
                <div className="contactdetails">
                  <div className="row">
                    <form
                      className="col-md-7 formfiller"
                      action="https://formspree.io/f/xrgjpldv"
                      method="POST"
                    >
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        id="dropdownMenuButton"
                        name="Selectcategory"
                      >
                        <option selected className="dropdown1-item">
                          Select Category
                        </option>

                        <option
                          value="Partner"
                          type="text"
                          id="drop"
                          className="dropdown-item"
                          name="Partner"
                        >
                          Partner
                        </option>
                        <option
                          value="Restaurant Partner"
                          type="text"
                          id="drop"
                          className="dropdown-item"
                          name="Restaurant Partner"
                        >
                          Restaurant Partner
                        </option>
                        <option
                          value="Delivery Partner"
                          type="text"
                          id="drop"
                          className="dropdown-item"
                          name="Delivery Partner"
                        >
                          Delivery Partner
                        </option>
                      </select>

                      <input
                        type="text"
                        id="fname"
                        name="Firstname"
                        placeholder="Full Name"
                      />
                      <input
                        type="email"
                        id="email"
                        name="Email"
                        placeholder="Email Address"
                      />
                      <input
                        type="phonenumber"
                        id="number"
                        name="Phonenumber"
                        placeholder="Phone Number"
                      />
                      <input
                        className="messagespot"
                        type="text"
                        id="message"
                        name="Message"
                        placeholder="Message Text"
                      />
                      <div className="textchangebtn">
                        <button
                          type="submit"
                          id="contactBtn"
                          className="primarybutton plane-contact1"
                        >
                          Submit
                        </button>
                        {/* <input className="primarybutton plane-contact" type="button" value="Send Message" />
                      <input className="primarybutton plane-contact" type="button" value="Submit" /> */}
                      </div>
                    </form>
                    <div className="col-md-5 maildetails">
                      <img
                        src="../assets/images/help/contactusmail.png"
                        alt="Contactus Mail"
                        title="Contactus Mail"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section>
          <div className="communitybanner3">
            <a href="#top">
              Back To Top
              <img src="../assets/images/backup/arrow.png" alt="image" />
            </a>
          </div>
        </section> */}
      </div>
      <Footer />
    </>
  );
}
