import React from "react";
const Madeperfect = () => {
  return (
    <section className="explore-section">
      <div className="container">
        <div className="row explore-secondblock">
          <div className="col-md-7 explore-content3">
            <img
              src="../assets/images/explore/group68587.png"
              alt=""
              width="100%"
            />
          </div>
          <div className="col-md-5 explore-content4">
            <h2 className="f24-h5">Easy and Convenient Ordering</h2>
            <p className="f16-p3">
              Explore varieties with Amealia and Personalize &<br />
              Customize your food just the way you like{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Madeperfect;
