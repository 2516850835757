import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import "flag-icons/css/flag-icons.min.css";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // supportedLngs: ["en", "fr", "ar"],
    // fallbackLng: "en",
    supportedLngs: ["in", "us"],
    fallbackLng: "in",
    detection: {
      order: ["cookie", "htmlTag", "subdomain", "localStorage", "path"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    // react: { useSuspense: false },
  });

const loadingMarkup = (
  // <div className="py-4 text-center">
  //   <h2>Loading...</h2>
  // </div>
  <div className="container-fluid h-100 w-100 d-flex align-items-center justify-content-center">
    <h2>Loading...</h2>
  </div>
);

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);

reportWebVitals();
