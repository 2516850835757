import React from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import FoodEstablishment from "./SliderComponent/foodEstablishment";

const customerfocused = (props) => {
  var settings = {
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  const imageSliderData = [
    {
      id: 1,
      title: "Submit your",
      title1: "Details",
      imgTitle: "Submit your Details",
      imgOne: "signerwhite.png",
      imgTwo: "group63075.png",
      show: true,
    },
    {
      id: 2,
      title: "Sign the MOU to",
      title1: "the sign up",
      imgTitle: "Sign the MOU to the sign up",
      imgOne: "contractwhite.png",
      imgTwo: "contract.png",
      show: true,
    },
    {
      id: 3,
      title: "Complete the Verification",
      title1: "for Approval",
      imgTitle: "Complete the Verification for Approval",
      imgOne: "immigrationwhite.png",
      imgTwo: "immigration.png",
      show: true,
    },
    {
      id: 4,
      title: "Get Trained to",
      title1: "Kickstart",
      imgTitle: "Get Trained to Kickstart",
      imgOne: "presentationwhite.png",
      imgTwo: "presentation.png",
      show: true,
    },
    {
      id: 5,
      title: "Elevate your Business",
      title1: "with Amealio",
      imgTitle: "Elevate your Business with Amealio",
      imgOne: "whyamealiowhite.png",
      imgTwo: "Why Amealio 2.png",
      show: false,
    },
  ];

  return (
    <section className="customer-section text-center">
      <div className="container">
        {/************** DeskTop ************/}
        <h2 className="f42-h3 text-white d-none d-sm-none d-md-block">
          <b>
            Have your food establishment listed for FREE & Reap the rewards of a
            customercentric platform
          </b>
        </h2>
        {/************** Mobile ************/}
        <h2 className="f24-h5 text-white d-block d-sm-block d-md-none">
          <b>
            Grow your business with a seamless onboarded experience, and be FREE
            of commitments and costs
          </b>
        </h2>
        <p className="f18-p2 pt-3 mb-5 pb-5 text-white">
          Grow your business with a seamless onboarded experience, and be free
          of commitments and costs
        </p>
        <div className="d-block d-sm-block d-md-none">
          <Slider {...settings}>
            {imageSliderData &&
              imageSliderData.map((data, index) => {
                return <FoodEstablishment keys={index} data={data} />;
              })}
          </Slider>
        </div>
        <div className="d-none d-sm-none d-md-block">
          <ul className="customer">
            <li className="customer-li">
              <div className="customer-varient">
                <img
                  src="../assets/images/business/group63075.png"
                  className="customer-image customer-white"
                  alt="Notes"
                  title="Notes"
                ></img>
                <img
                  src="../assets/images/business/signerwhite.png"
                  className="customer-image customer-orange"
                  alt="Sign"
                  title="Sign"
                ></img>
                <img
                  src="../assets/images/business/defaultstate.png"
                  className="customer-color customer-white"
                  alt="Default"
                  title="Default"
                ></img>
                <img
                  src="../assets/images/business/hovestate.png"
                  className="customer-color customer-orange"
                  alt="Hove"
                  title="Hove"
                ></img>
              </div>
              <span className="customer-span f18-p2 text-white mt-5">
                Submit your <br /> Details
              </span>
            </li>
            <div className="customer-round">
              <img
                src="../assets/images/business/arrow-right.png"
                alt="Right Arrow"
                title="Right Arrow"
                className="img-fluid shadow-sm customer-icon"
              ></img>
            </div>
            <li className="customer-li">
              <div className="customer-varient">
                <img
                  src="../assets/images/business/contract.png"
                  className="customer-image customer-white"
                  alt="Contract"
                  title="Contract"
                ></img>
                <img
                  src="../assets/images/business/contractwhite.png"
                  className="customer-image customer-orange"
                  alt="Contract White"
                  title="Contract White"
                ></img>
                <img
                  src="../assets/images/business/defaultstate.png"
                  className="customer-color customer-white"
                  alt="Default"
                  title="Default"
                ></img>
                <img
                  src="../assets/images/business/hovestate.png"
                  className="customer-color customer-orange"
                  alt="Hove"
                  title="Hove"
                ></img>
              </div>
              <span className="customer-span f18-p2 text-white mt-5">
                Sign the MOU to <br /> the sign up
              </span>
            </li>
            <div className="customer-round">
              <img
                src="../assets/images/business/arrow-right.png"
                alt="Right Arrow"
                title="Right Arrow"
                className="img-fluid shadow-sm customer-icon"
              ></img>
            </div>
            <li className="customer-li">
              <div className="customer-varient">
                <img
                  src="../assets/images/business/immigration.png"
                  className="customer-image customer-white"
                  alt="Immigration"
                  title="Immigration"
                ></img>
                <img
                  src="../assets/images/business/immigrationwhite.png"
                  className="customer-image customer-orange"
                  alt="Immigration White"
                  title="Immigration White"
                ></img>
                <img
                  src="../assets/images/business/defaultstate.png"
                  className="customer-color customer-white"
                  alt="Default"
                  title="Default"
                ></img>
                <img
                  src="../assets/images/business/hovestate.png"
                  className="customer-color customer-orange"
                  alt="Hove"
                  title="Hove"
                ></img>
              </div>

              <span className="customer-span f18-p2 text-white mt-5">
                Complete the <br /> Verification for <br /> Approval
              </span>
            </li>
            <div className="customer-round">
              <img
                src="../assets/images/business/arrow-right.png"
                alt="Right Arrow"
                title="Right Arrow"
                className="img-fluid shadow-sm customer-icon"
              ></img>
            </div>
            <li className="customer-li">
              <div className="customer-varient">
                <img
                  src="../assets/images/business/presentation.png"
                  className="customer-image customer-white"
                  alt="Presentation"
                  title="Presentation"
                ></img>
                <img
                  src="../assets/images/business/presentationwhite.png"
                  className="customer-image customer-orange"
                  alt="Presentation White"
                  title="Presentation White"
                ></img>
                <img
                  src="../assets/images/business/defaultstate.png"
                  className="customer-color customer-white"
                  alt="Default"
                  title="Default"
                ></img>
                <img
                  src="../assets/images/business/hovestate.png"
                  className="customer-color customer-orange"
                  alt="Default"
                  title="Default"
                ></img>
              </div>
              <span className="customer-span f18-p2 text-white mt-5">
                {" "}
                Get Trained to <br />
                Kickstart
              </span>
            </li>
            <div className="customer-round">
              <img
                src="../assets/images/business/arrow-right.png"
                alt="Right Arrow"
                title="Right Arrow"
                className="img-fluid shadow-sm customer-icon"
              ></img>
            </div>
            <li className="customer-li">
              <div className="customer-varient">
                <img
                  src="../assets/images/business/Why Amealio 2.png"
                  className="customer-image customer-white place-contact"
                  alt="Why Amealio"
                  title="Why Amealio"
                ></img>
                <img
                  src="../assets/images/business/whyamealiowhite.png"
                  className="customer-image customer-orange place-contact"
                  alt="Why Amealio White"
                  title="Why Amealio White"
                ></img>
                <img
                  src="../assets/images/business/defaultstate.png"
                  className="customer-color customer-white"
                  alt="Default"
                  title="Default"
                ></img>
                <img
                  src="../assets/images/business/hovestate.png"
                  className="customer-color customer-orange"
                  alt="Default"
                  title="Default"
                ></img>
              </div>

              <span className="customer-span f18-p2 text-white mt-5">
                Elevate your <br /> Business with <br /> Amealio{" "}
              </span>
            </li>
          </ul>
        </div>
        <div className="mt-4 mt-sm-4 mt-md-0">
          <NavLink to={`/${props.lang}/demo`}>
            {" "}
            <center>
              <button className="btn customer-btn shadow-sm f16-p3">
                Start Now
              </button>
            </center>
          </NavLink>
        </div>
      </div>
    </section>
  );
};

export default customerfocused;
