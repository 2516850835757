import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Newheader from "../NewWebsite/newheader";
import Slider from "react-slick";
import AutoSlider from "./autoplaysliders";
import CountUp from "react-countup";
import Footer from "../templates/footer";
import slider1  from "../../../src/images/new/slide1.png";
import MobileScroll from "./mobilescroll";
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, FadeOut, Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";
const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
const FadeUp = batch(Fade(), Move(), Sticky());
const DemoHome = () => {

 
  const settings = {
    centerMode: true,
    infinite: true,
  centerPadding: "10px",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <Newheader />  
      <div className="bg-black h-auto">
      {/* <ScrollPage> */}
        <div className="top-text-section pb-4">
          <div className="container-fluid">
            <div className="hot-deals">
              <span className="py-2 ">
                <NavLink to="#" className="button-hotdeals">
                  HOT DEALS{" "}
                  <i
                    className="fa fa-arrow-right"
                    aria-hidden="true"
                    style={{ cursor: "pointer" }}
                  ></i>
                </NavLink>
              </span>
            </div>
            <p className="wf20-700 text-center my-3">
            Share your meals and 
              <br /> memories
            </p>
            <div className="py-4">
              <p className="wf2-700 my-4 text-center">
                {" "}
                <span className="greencolor">Join 50K+,</span> Who Enjoy,
                Celebrate, & Socialize Everyday...
              </p>
            </div>

            <div className="text-center my-4" style={{padding:"8vh"}}>
              <span className="py-4">
                <NavLink to="#" className="button-download">
                  DOWNLOAD APP
                </NavLink>
              </span>
            </div>
          </div>
        </div>
        {/* </ScrollPage> */}
        {/* <ScrollPage> */}
        <div className="container" style={{paddingBottom:"36vh"}} >
          <Slider {...settings}>
            <div>
              <img   className="px-4"  src={slider1}  alt="slide1" style={{width:"100%",maxWidth:"100%"}}
              />
            </div>
            <div>
            <img
                className="px-4"
                src={slider1}
                alt="slide2"
              />
            </div>{" "}
            <div>
            <img
                className="px-4"
                src={require('./../../images/new/slide1.png')}
                alt="slide3"
              />
            </div>
          </Slider>
        </div>
        {/* </ScrollPage> */}
        {/* <ScrollPage> */}
        <div className="container-fluid mb-4" style={{ height: "10vh",paddingLeft:"2vw",letterSpacing:"2px" }}>
          <p className="wf5-700 pb-4">The best 500+ brands you love...</p>
        </div>
        {/* </ScrollPage>
        <ScrollPage> */}
        <AutoSlider />
        {/* </ScrollPage>
        <ScrollPage> */}
        <section className="bg-black">
          <div className="container-fluid bg-couple-eating-red" style={{ height: "80vh" }}>
            <div className="pd-text">
              <p className="wf5-700 mb-2">
                Designed for
                <br /> Every Mood & Occasions...
              </p>
              <p className="wf2-400 py-4 w-50">
                Whether you are going out alone or with a group you will always
                enjoy your moments. Socialize Find & book experiences for
                occasions & get togethers. Simplify everyday restaurant
                encounters with wide range of options like Pre-Order,
                Personalize Dine-In, ..
              </p>
              <div className="pd-y3">
                <div className="py-4 px-4">
                  <NavLink to="#" className="button-white pd-x4">
                    Explore
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* </ScrollPage>
        <ScrollPage> */}
        {/* <section className="mb-parallax">
        
          <div className="conatiner py-4">
            <div className="flexcenter">
              <img
                src="../assets/images/amealio/svg/phone1.png"
                alt="Enjoy Celebrate"
                title="Enjoy Celebrate"
                className="img-fluid"
              />
            </div>
            
            <div className="flexcenter">
              <p className="wf5-700 mb-4">Feel Special Everyday </p>
            </div>
            <div className="flexcenter container-fluid mt-2 mb-4">
              {" "}
              <p
                className="wf12-500 w-50 text-center"
                style={{ opacity: "0.5" }}
              >
                Never stand in line, Pre-Order and Peronslize your food each
                time to get it the way you want it mean you always have a
                delicious meal and a great experience. Simplify everyday
                restaurant encounters.with wide range of options like Waitlist,
                Reservation, Dine-In, Curbside, Take Away, and more.{" "}
              </p>
            </div>
          </div>
         
          
          <div className="conatiner py-4">
            <div className="flexcenter">
              <img
                src="../assets/images/amealio/svg/phone1.png"
                alt="Enjoy Celebrate"
                title="Enjoy Celebrate"
                className="img-fluid"
              />
            </div>
            <div className="flexcenter">
              <p className="wf5-700 mb-4">Celebrate Each Time</p>
            </div>
            <div className="flexcenter container-fluid mt-2 mb-4">
              {" "}
              <p
                className="wf12-500 w-50 text-center"
                style={{ opacity: "0.5" }}
              >
                Watch Videos, Pics and Explore experiences for everyday
                occasions and get-togethers. We curate them so you can enjoy
                your hangouts, birthdays, anniversaries, meetings, dates, local
                delicacies, restaurant specialties, and more.{" "}
              </p>
            </div>
          </div>
          
          
          <div className="conatiner py-4">
            <div className="flexcenter">
              <img
                src="../assets/images/amealio/svg/phone1.png"
                alt="Enjoy Celebrate"
                title="Enjoy Celebrate"
                className="img-fluid"
              />
            </div>
            <div className="flexcenter">
              <p className="wf5-700 mb-4">Getting-togethers Made Fun</p>
            </div>
            <div className="flexcenter container-fluid mt-2">
              {" "}
              <p
                className="wf12-500 w-50 text-center"
                style={{ opacity: "0.5" }}
              >
                Chat, Meet, Eat, Repeat. Perfect way of find experiences for any
                Hangout, ,get-togethers and gathering. Explore and order
                together with friends, family and others. AI-concierge makes it
                easy.{" "}
              </p>
            </div>
          </div>
          
        </section> */}
         <MobileScroll />
        {/* </ScrollPage>
        <ScrollPage> */}
        <AutoSlider  content={"experienceslide"}/>      
        <div className="bg-black">
          <div className="container-fluid text-center"
            style={{paddingTop:"5%",paddingBottom:"5%"}}
           >
            
                  <p className="wf7-700 mb-2">
                            Simplified Amazing
                            <br /> Experience...
                  </p>
                
          </div>
        </div>
       
       
        <div className="bg-black" style={{overflow:"hidden"}}>
            <div className="selifeimage p-0">
           {/* <img style={{maxWidth:"100%",width:"inherit" }} src="../../../" alt="" /> */}
            </div>
        </div>
         
        <div className="bg-black">
            <div className=" ratingsbg">
             <div className="row ">
              <div className="col-6 ratingssection">
                <div>
                  <img src="../assets/images/amealio/whitelogo1.png" alt="image" style={{maxWidth:"100%",width:"450px"}} />
                </div>
                <div className="row">
                  <div className="col-6">
                  <CountUp
                            start={0}
                            decimal="4.7"
                            end={4.7}
                            //   redraw={true}
                            enableScrollSpy={true}
                          >
                            {({ countUpRef }) => (
                              <>
                              <div className="d-flex align-items-center ">
                                <h1
                                  className="wf7-700"
                                  ref={countUpRef}
                                />
                                <h1 className="wf2-700 pl-4">
                                <i class="fa fa-star pl-4" aria-hidden="true"></i>
                                </h1>
                              </div>
                              <div className="wf5-700">
                                 <p>App Store</p>
                              </div>
                              </>
                            )}
                          </CountUp>

                  </div> <div className="col-6">
                  <CountUp
                            start={0}
                          decimal="4.9"
                          end={4.9}
                            //   redraw={true}
                            enableScrollSpy={true}
                          >
                            {({ countUpRef }) => (
                              <>
                              <div className="d-flex align-items-center justify-content-center">
                                <h1
                                  className="wf7-700"
                                  ref={countUpRef}
                                />
                                <h1 className="wf2-700 pl-4">
                                <i class="fa fa-star pl-4" aria-hidden="true"></i>
                                </h1>
                              </div>
                              <div className="wf5-700">
                                 <p>Play Store</p>
                              </div>
                              </>
                            )}
                          </CountUp>
                  </div>
                        
                          
                </div>
                <div className="wf2-400" style={{paddingTop:"5vh"}}>
                  <p>Ideal for dine out experiences. Curated concierge seals it. Chat,Meet, Eat, Repeat </p>
                </div>
              </div>
              <div className="col-6  flexcenter">
              <div>
                <div className="py-4 px-4">
                  <NavLink to="#" className="button-white px-4">
                  DOWNLOAD APP
                  </NavLink>
                </div>
              </div>
              </div>
             </div>
            </div>
        </div>
        
        <div className="bg-black">
          <div className="row" style={{height:"100vh"}}>
             <div className="col-8 mt-4">
                    <div className="container-fluid text-center py-4">
                          <p className="wf6-700 mt-4 text-start flexcenter pd-y3">
                          Make dine-out<br />
                          & get-togethers<br />
                          joyful experience <br />
                          each time..
                          </p>
                          <p className="wf2-700 my-4 text-start pd-y3 " style={{paddingLeft:"16vw"}}>
                    {" "}
                    <span className="greencolor " >“Enjoy, Celebrate, & Socialize”<br />
                                                @amealio</span>
                  </p>
                    </div> 
             </div>
             <div className="col-4 girl-eating-pizza" > </div>
          </div>
        </div>
       
       
      </div>
    
      <Footer backgroundColorData={"rgba(136, 132, 236, 0.24)"} />
      {/* <MobileScroll /> */}

    </>
  );
};

export default DemoHome;
