import React from "react";

const FoodPartnersSlider = (props) => {
  return (
    <div>
      <div className="mb-5 px-3 px-sm-3 px-md-0">
        <img
          src={`../assets/images/businessNew/newImages/${props.imgSrc}.png`}
          alt={props.title}
          title={props.title}
          className="img-fluid mx-auto shadow"
          style={{ borderRadius: "30px" }}
        />
      </div>
    </div>
  );
};

export default FoodPartnersSlider;
