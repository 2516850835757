import { NavLink } from "react-router-dom";

const Signupfeature = (props) => {
  return (
    <section className="signup-section">
      <div className="container">
        <h1 className="signup-header header-res">
          {" "}
          <b>
            Sign Up for FREE.
            <br />
            Be an Amealio Food Partner Today!
          </b>
        </h1>
        <ul className="signup">
          <li className="signup-li">
            <img
              src="../assets/images/fastfood/group63075.png"
              className="signup-image"
              alt="Sign Up For Free"
              title="Sign Up For Free"
            ></img>
            <span className="signup-span">
              Submit your <br /> Details
            </span>
          </li>
          <div className="signup-round">
            <i
              className="glyphicon glyphicon-arrow-right signup-icon"
              style={{ color: "red" }}
            ></i>
          </div>
          <li className="signup-li">
            <img
              src="../assets/images/fastfood/contract.png"
              className="signup-image"
              alt="Contract"
              title="Contract"
            ></img>
            <span className="signup-span">
              Sign Mou <br /> and Complete <br /> Sign Up
            </span>
          </li>
          <div className="signup-round">
            <i
              className="glyphicon glyphicon-arrow-right signup-icon"
              style={{ color: "red" }}
            ></i>
          </div>
          <li className="signup-li">
            <img
              src="../assets/images/fastfood/immigration.png"
              className="signup-image"
              alt="Immigration"
              title="Immigration"
            ></img>
            <span className="signup-span">
              Verification and <br /> Approval
            </span>
          </li>
          <div className="signup-round">
            <i
              className="glyphicon glyphicon-arrow-right signup-icon"
              style={{ color: "red" }}
            ></i>
          </div>
          <li className="signup-li">
            <img
              src="../assets/images/fastfood/presentation.png"
              className="signup-image"
              alt="Presentation"
              title="Presentation"
            ></img>
            <span className="signup-span">
              Training and <br /> Orientation Call
            </span>
          </li>
          <div className="signup-round">
            <i
              className="glyphicon glyphicon-arrow-right signup-icon"
              style={{ color: "red" }}
            ></i>
          </div>
          <li className="signup-li">
            <img
              src="../assets/images/fastfood/whyamealio2.png"
              className="signup-image1"
              alt="Why Amealio"
              title="Why Amealio"
            ></img>
            <span className="signup-span">
              Get on and <br /> Start your Business
            </span>
          </li>
        </ul>
        <NavLink to={`/${props.lang}/demo`}>
          <center>
            <button className="btn signup-btn">Start Your Journey</button>
          </center>
        </NavLink>
      </div>
    </section>
  );
};

export default Signupfeature;
