import React from "react";
import CountUp from "react-countup";
const Graphsection = () => {
    return(
        <>
        <section className="garph-section bg-main container-fluid">
         <div className="pd-graph">
            <div className="row my-4 flexcenter">
               <div className="col-5 col-xs-5 col-md-5 amealio-box text-light">
                  <div className="d-block text-center ">
                  <CountUp
                   start={0}
                   end={200}
                   enableScrollSpy={true}
                  >
                     {({ countUpRef }) => (
                       <div className="flexcenter" >
                                <div className="graphtext"  ref={countUpRef} /> 
                                 <div className="graphtext"> %</div> 
                              </div>
                            )}
                
                  </CountUp>
                  <div className="graphsubtext">Increased Satisfaction</div>     
                  </div>
                 
               </div>
               <div className="col-3 col-xs-4 col-md-3  yellow-box ">
               <div className="d-block text-center ">
               <CountUp
                   start={0}
                   end={49000}
                   enableScrollSpy={true}
                  >
                     {({ countUpRef }) => (
                        <div className="flexcenter" >
                                <div className="graphtext"  ref={countUpRef} /> 
                                 <div className="graphtext"> +</div> 
                              </div>
                            )}
                
                  </CountUp>
                  <div className="graphsubtext">App downloads</div>     
                  </div>
               </div>
            </div>
            <div className="row my-4 flexcenter">

               <div className="col-3 col-xs-4 col-md-3  yellow-box ">
               <div className="d-block text-center ">
               <CountUp
                   start={0}
                   end={3125}
                   enableScrollSpy={true}
                  >
                     {({ countUpRef }) => (
                       <div className="flexcenter" >
                                <div className="graphtext"  ref={countUpRef} /> 
                                 <div className="graphtext"> +</div> 
                              </div>
                            )}
                
                  </CountUp> 
                  <div className="graphsubtext">Happy moments</div>     
                  </div>
               </div>
               <div className="col-5 col-xs-5 col-md-5   amealio-box" style={{overflow:"hidden"}}>
               <img src="../../assets/images/amealio/new/bargraphs.png" width="100%" />
               </div>
            </div>
            {/* <div className="row pd-x5">
               <div className="col-4 m-4 yellow-box heading-h4">
               <div className="d-block text-center ">
                  <div className="graphtext"> 200%</div> 
                  <div className="graphsubtext">Increased Satisfaction</div>     
                  </div>
               </div>
               <div className="col-6 m-4 heading-h4 ">
               <img src="../../assets/images/amealio/new/bargraphs.png" width="100%" />
               </div>
            </div> */}
         </div>

        </section>
        </>
    )
};
export default Graphsection;