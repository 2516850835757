import React, { useState } from "react";
import Footer from "../templates/footer";
import InstagramFeed from "react-ig-feed";
import "react-ig-feed/dist/index.css";
// import { NavLink, Link } from "react-router-dom";

const Social = () => {
  const [countvalue, setcountvalue] = useState(3);

  function countNumber() {
    setcountvalue(countvalue + 3);
  }

  const goToVisionUdbhav = () => {
    window.open("https://visionudbhav.org", "_blank");
  };

  return (
    <>
      {" "}
      <div className="udhavsocial">
        <img
          src="../assets/images/backup/Background1.png"
          alt="Social-bg"
          title="Social-bg"
          className="backgroundpic"
        ></img>

        <section className="soclogo">
          <div className="container">
            <div className="row social-orgin">
              <div className="col-md-8">
                <div className="soc">
                  <h1 className="soc-head">
                    <b>
                      Make a Difference
                      <br /> Every Time you Eat
                    </b>
                  </h1>
                </div>
                <p className="soc-para">
                  <b>With Udbhav-Our Social Initiative</b>
                </p>
                <p className="soc-para">
                  Let's give back to the society. One bit-at-a-time!
                </p>
                <div className="button1-donate">
                  <button
                    className="btn button1"
                    type="button"
                    onClick={goToVisionUdbhav}
                    title="Vision Udbhav"
                  >
                    <small>
                      <b>Be a Volunteer</b>
                    </small>
                  </button>
                </div>
              </div>
              <div className="col-md-4">
                <img
                  src="../assets\images\social\udbhav-logo.png"
                  alt="Udbhav Logo"
                  title="Udbhav Logo"
                  className="udbhavlogo"
                  width="148px"
                ></img>
              </div>
            </div>
          </div>
        </section>

        <section className="slide1">
          <div className="container">
            <div className="carousel">
              <div
                id="myCarousel"
                className="carousel slide"
                data-ride="carousel"
              >
                <ol className="carousel-indicators">
                  <li
                    data-target="#myCarousel"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li data-target="#myCarousel" data-slide-to="1"></li>
                  <li data-target="#myCarousel" data-slide-to="2"></li>
                  <li data-target="#myCarousel" data-slide-to="3"></li>
                </ol>

                <div className="carousel-inner" role="listbox">
                  <div className="item active item1">
                    <img
                      src="../assets\images\social\udbhav.png"
                      className="imgcontent"
                      alt="Chania"
                      title="Chania"
                    ></img>
                    <span className="socialbackgroundeffect"></span>
                    <p className="slidecontent">
                      We intend to give back to the society by extending
                      support, warmth and care <br /> in this diverse social
                      landscape that you and I live in.
                    </p>
                  </div>

                  <div className="item item1">
                    <img
                      src="../assets\images\social\udbhav.png"
                      className="imgcontent"
                      alt="Chania-1"
                      title="Chania-1"
                    ></img>
                    <span className="socialbackgroundeffect"></span>
                    <p className="slidecontent">
                      We intend to give back to the society by extending
                      support, warmth and care <br /> in this diverse social
                      landscape that you and I live in.
                    </p>
                  </div>

                  <div className="item item1">
                    <img
                      src="../assets\images\social\udbhav.png"
                      className="imgcontent"
                      alt="Flower"
                      title="Flower"
                    ></img>
                    <span className="socialbackgroundeffect"></span>
                    <p className="slidecontent">
                      We intend to give back to the society by extending
                      support, warmth and care <br /> in this diverse social
                      landscape that you and I live in.
                    </p>
                  </div>

                  <div className="item item1">
                    <img
                      src="../assets\images\social\udbhav.png"
                      className="imgcontent"
                      alt="Flower"
                      title="Flower"
                    ></img>
                    <span className="socialbackgroundeffect"></span>
                    <p className="slidecontent">
                      We intend to give back to the society by extending
                      support, warmth and care <br /> in this diverse social
                      landscape that you and I live in.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <p className="joinus">
            If you want to be one of us then join us in our cause to make a
            difference
          </p>
        </div>
        <section>
          <div className="container social-head">
            <div className="Socialposts">
              <h4 className="Socialpost-header">
                <b>Social Posts</b>
              </h4>
              <div className="community-insta">
                <InstagramFeed
                  token="IGQVJWeGZA0bDVTVGxDZAUdYRlZAPMU9SMmxiQTVXYTh5eXdSNTBmV0ZAXcm9VZADBkN3phZAXVmRFBPYm5faEJ0NFVBbFZA5TGxXWDJiaFdRcFU2WW5LeHpTdENDY2RHMFQ1SGZALZADVsdWhIdklUczd3RGRRdAZDZD"
                  counter={countvalue}
                />
                {/* <div className="communitybanner1">
        <img src="../assets/images/backup/communitypng.png" alt="image" />
        </div> */}
              </div>
              {/* <div className="row socialpost">
                <div className="col-md-4 ">
        <InstagramFeed token="IGQVJYdldjTGVpbXh4a3dZATVN2Q3Y4bi1ncjFjWi1ZAZAG8wRXFFaW5FWUVtUHpoS3RMMXg5SEpTdWk5ODBJc2dDN3U5cTJCWXNrbnhFQmpUb1g4WEJRT2tmaU9wLS16Sk1RTjNUUV95LWdZAODE1MVpraAZDZD"  counter="3" />
                   <div className="socialpost-div"></div>
                </div>
                <div className="col-md-4">
                   <div className="socialpost-div"></div>
                </div>
                <div className="col-md-4">
                   <div className="socialpost-div"></div>
                </div>
                <div className="col-md-4">
                    <div className="socialpost-div"></div>
                </div>
                <div className="col-md-4">
                  <div className="socialpost-div"></div>
                </div>
                <div className="col-md-4">
                  <div className="socialpost-div"></div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="button1-knowmore">
            <center>
              {" "}
              <button
                onClick={() => countNumber()}
                className="btn button1-know"
              >
                <small>Load more</small>
              </button>
            </center>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Social;
