import React, { useState, useEffect } from "react";

const Subfeatures = (props) => {
  const [dispaysubcateory, setdispaysubcateory] = useState(false);
  const subfeatures = props.data;

  useEffect(() => {
    setdispaysubcateory(false);
  }, [props.data]);

  return (
    <section className="menu-section">
      {dispaysubcateory ? (
        <div className="container">
          <ul className="menu-ul">
            {subfeatures.subfeatures.map((data, index) => {
              const subfeaturesImage =
                "../assets/images/fastfood/subfeatures/" + data + ".png";
              return (
                <li key={index}>
                  <div className="menu-round">
                    <img
                      src={subfeaturesImage}
                      alt="Sub Features"
                      title="Sub Features"
                      className="menu-image"
                    ></img>
                  </div>
                  <span className="menu-span">{data}</span>
                </li>
              );
            })}
            .
          </ul>
        </div>
      ) : (
        <div></div>
      )}
      <button
        onClick={() => setdispaysubcateory(!dispaysubcateory)}
        className={
          dispaysubcateory ? "btn arrow-icon rotate" : "btn arrow-icon"
        }
      >
        <span className="left-bar"></span>
        <span className="right-bar"></span>
      </button>
    </section>
  );
};

export default Subfeatures;
