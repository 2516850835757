import React from "react";
import Slider from "react-slick";
import ReusableSliderOneCom from "./reusableSliderOneCom";

const HomePageSliderOne = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    // cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
  };

  const availableService = [
    {
      id: 1,
      title: "Dine In",
      imgSrc: "DineIn.png",
    },
    {
      id: 2,
      title: "Take Away",
      imgSrc: "TakeAway.png",
    },
    {
      id: 3,
      title: "Skip The Line",
      imgSrc: "Skiptheline.png",
    },
    {
      id: 4,
      title: "Curbside",
      imgSrc: "Curbside.png",
    },
    {
      id: 5,
      title: "Delivery",
      imgSrc: "Delivery.png",
    },
    {
      id: 6,
      title: "Catering",
      imgSrc: "Catering.png",
    },
    {
      id: 7,
      title: "At My Seat",
      imgSrc: "AtMyseat.png",
    },
    {
      id: 8,
      title: "Drive Thru",
      imgSrc: "DriveThru.png",
    },
    {
      id: 9,
      title: "Walk In",
      imgSrc: "Walkin.png",
    },
    {
      id: 10,
      title: "Waitlist",
      imgSrc: "Waitlist.png",
    },
    {
      id: 11,
      title: "Reservation",
      imgSrc: "Reservation.png",
    },
    {
      id: 12,
      title: "RSVP",
      imgSrc: "RSVP.png",
    },
  ];

  return (
    <div className="outter-features">
      <Slider {...settings}>
        {availableService &&
          availableService.map((goingOut) => {
            return (
              <React.Fragment key={goingOut.id}>
                <ReusableSliderOneCom data={goingOut} />
              </React.Fragment>
            );
          })}
      </Slider>
    </div>
  );
};

export default HomePageSliderOne;
