import React from "react";
import Frontpage from "../explore/frontpage";
import Exploreheader from "../explore/exploreheader";
import Madeperfect from "../explore/madeperfect";
import Payment from "../explore/payment";
import Exclusive from "../explore/exclusive";
import Footer from "../templates/footer";
import { NavLink } from "react-router-dom";
import cookies from "js-cookie";
import { LANGUAGES } from "../../AllEnum/LanguageEnum";

const Amealiarestaurant = (props) => {
  const currentLanguageCode = cookies.get("i18next") || "in";
  const currentLanguage = LANGUAGES.find((l) => l.code === currentLanguageCode);

  const [voiceClassVisible, setVoiceClass] = React.useState(false);
  return (
    <div className="app">
      <section className="aboutamealio-section amealio-headline">
        <div
          className={
            voiceClassVisible
              ? "aboutamealio-audio-active aboutamealio-audio"
              : "aboutamealio-audio"
          }
        >
          <div className="aboutamealio-p">
            <p>
              {" "}
              Experience one of it's kind food ordering experiences through
              voice at restaurant front.
            </p>
            <h6>Learn More</h6>
          </div>
          <div className="aboutamealio-center">
            <button
              className="btn aboutamealio-btn"
              onClick={() => {
                setVoiceClass(false);
                window.scrollTo(0, 1000);
              }}
              id="button1"
            >
              Allow
            </button>
            <button className="btn aboutamealio-btn" id="button">
              Deny
            </button>
            <NavLink
              activeClassName="is-active"
              to={`/${currentLanguage.code.toUpperCase()}`}
              exact
            >
              <img
                src="../assets/images/explore/amealialogo.png"
                alt=""
                width="100%"
              />
            </NavLink>
          </div>
        </div>
      </section>
      <section className="voice-section">
        <div className="container">
          <h1 className="voice-h1">
            A Multi-Modal Virtual Voice Assistance for All your Needs.
          </h1>
          <p className="voice-p">
            The Virtual assistant works on multiple modes including mobiles,
            tabs, smart speakers, T.V etc. It can help you in Ordering your
            food, Reserving your table, Placing you in a Queue virtually,
            Receives Take-away orders, Calls for Room Service and much more.
          </p>
          <img
            src="../assets/images/explore/amealia-screen-with-reflection@2x.png"
            alt="img"
            width="100%"
          ></img>
        </div>
      </section>
      <div className="container">
        <div className="aboutamealio-multi-modal">
          <NavLink activeClassName="is-active" to="/aboutamealia" exact>
            <img src="../assets/images/backup/arrow1.png" alt="img"></img>
          </NavLink>
          <NavLink activeClassName="is-active" to="/explore" exact>
            <img src="../assets/images/backup/menu.png" alt="img"></img>
          </NavLink>
        </div>
        <h1 className="aboutamealio-multi-modal-h1">
          A multi-modal Virtual Voice Assistance for All your Needs
        </h1>
        <p className="aboutamealio-multi-modal-p">
          {" "}
          The Virtual assistant works on multiple modes including mobiles, tabs,
          smart speakers, T.V etc. It can help you in Ordering your food,
          Reserving your table, Placing you in a Queue virtually, Receives
          Take-away orders, Calls for Room Service and much more.
        </p>
        <NavLink activeClassName="is-active" to="/explore" exact>
          <img
            src="../assets/images/explore/amealia-screen-with-reflection@2x.png"
            alt="img"
            width="100%"
            className="aboutamealio-multi-modal-img"
          ></img>
        </NavLink>
      </div>

      <div className="container">
        <NavLink to={`/${currentLanguage.code.toUpperCase()}`}>
          <img
            src="../assets/images/explore/amealialogo.png"
            className="amealia-logo"
            alt=""
          ></img>
        </NavLink>
      </div>
      <Frontpage />
      <Exploreheader />
      <Madeperfect />
      <Payment />
      <Exclusive />
      <Footer />
    </div>
  );
};

export default Amealiarestaurant;
