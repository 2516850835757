import React from "react";
import Header from "./header";
import Outlets from "./outlets";
import Foodtogether from "./foodtogether";
import SharingMeals from "./sharingMeals";
import SharingExperience from "./sharingExperience";
import Sharingmemories from "./sharingmemories";
import Ratings from "./ratings";
// import Footer from "./footer";
import Graphsection from "./graphsection";
import Footer from "../../../templates/footer";
const Mainpage = () => {
    return(
        <>
        <div className="bg-main" style={{height:"100%"}}>
         <Header />
         <Foodtogether />
         <Outlets />
         <SharingMeals />
         <SharingExperience />
         <Sharingmemories />
         <Ratings />
         <Graphsection />
         <Footer /> 
        </div>
    
        </>
    )
};
export default Mainpage;