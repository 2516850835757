import React from "react";
// import { NavLink } from "react-router-dom";

const Frontpage = () => {
  return (
    <section className="explore-section">
      <div className="expore-voice-section">
        <div className="container">
          <h1 className="f32-h4 text-center">
            A multi-modal Virtual Voice Assistance for All your Needs
          </h1>
          <p className="f16-p3 text-center">
            {" "}
            The Virtual assistant works on multiple modes including mobiles,
            tabs, smart speakers, T.V etc. It can help you in Ordering your
            food,
            <br />
            Reserving your table, Placing you in a Queue virtually, Receives
            Take-away orders, Calls for Room Service and much more.
          </p>
          <img
            src="../assets/images/explore/alexa-device-with-prompt.png"
            alt="Alexa Device Prompt"
            title="Alexa Device Prompt"
            width="100%"
            className="aboutamealio-multi-modal-img"
          ></img>
        </div>
      </div>
      {/* <div className='container'>
            <NavLink to="/"><img src="../assets/images/explore/amealialogo.png" className="amealia-logo" alt=""></img></NavLink>
         </div> */}
      <div className="container">
        <div className="explore-frntpage">
          {/* <img src="../assets/images/explore/Alexa Device with prompt.png" alt="" width="100%"/> */}
        </div>
      </div>
    </section>
  );
};
export default Frontpage;
