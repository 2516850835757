import React, { useEffect } from "react";
import {
  // BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import i18next from "i18next";
import axios from "axios";
import cookies from "js-cookie";

import FastFood from "./components/fastfood/fastfood";
import Foodfeatures from "./components/features/foodfeatures";
import Terms from "./components/static/terms";
import Businessterms from "./components/static/businessterms";
import WalletTerms from "./components/static/walletTerms";
import Policy from "./components/static/policy";
import Social from "./components/udbhav/social";
import Bussiness from "./components/ourbussiness/bussiness";
import Demo from "./components/freedemo/demo";
import About from "./components/about/about";
import Checkout from "./components/freedemo/checkout";
import Explore from "./components/explore/explore";
import Aboutamealia from "./components/aboutamealia/aboutamealia";
import Amealiarestaurant from "./components/aboutamealia/amealiarestaurant";
import Features from "./components/enhanced/features";
import Help from "./components/help/help";
import Community from "./components/community/community";
import Menu from "./components/menu/menu";
import Businessreferral from "./components/static/businessreferral";
import Offerreferral from "./components/static/offerreferral";
import Refundcancellation from "./components/static/refundcancellation";
import Usertouser from "./components/static/usertouser";
import Microphone from "./components/static/microphone";
import Usprivacy from "./components/static/usprivacy";
import Ususerterms from "./components/static/ususerterms";
import Usbusinessterms from "./components/static/usbusinessterms";
import PageNotFound from "./components/pageNotFound/pageNotFound";
import Home from "./components/home/home";
import Header from "./components/templates/header";
import DemoHome from "./components/NewWebsite/demoHome";
// import Homepagemain from "./components/websitev2/homepagev2/homepagemain";
// import Newhomepage from "./components/NewWebsite/homepage/newhomepage";
// import Masterfile from "./components/websitev4/mainpages/Homepage/masterfile";
import Mainpage from "./components/websitev4/mainpages/Homepage/mainpage";
import Aboutus from "./components/websitev4/mainpages/Aboutus";
import BecomeMerchant from "./components/websitev4/mainpages/becomeMerchant";
import { createBrowserHistory } from "history";
import { LANGUAGES } from "./AllEnum/LanguageEnum";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const currentLanguageCode = cookies.get("i18next") || "in";
  const currentLanguage = LANGUAGES.find((l) => l.code === currentLanguageCode);

  // useEffect(() => {
  //   axios
  //     .get("https://ipapi.co/json/")
  //     .then((response) => {
  //       let data = response.data;
  //       if (LANGUAGES.find((l) => l.code === data.country_code.toLowerCase())) {
  //         console.log("hii-1");
  //         onChangeRedirect(data);
  //       } else {
  //         console.log("hii-2");
  //         i18next.changeLanguage("in");
  //         navigate(`/${"in"}`);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        console.log("Responce", data.country_code);
        if (location.pathname === "/") {
          onChangeRedirect(data);
        } else if (
          location.pathname.includes(
            LANGUAGES.find((l) => l.code !== data.country_code.toLowerCase())
          )
        ) {
          navigate(`/${data.country_code}/*`);
        } else if (
          location.pathname.includes(
            LANGUAGES.find((l) => l.code === data.country_code.toLowerCase())
          )
        ) {
          onChangeRedirect(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onChangeRedirect = (data) => {
    let countryCode = data;
    if (LANGUAGES.find((l) => l.code === data.country_code.toLowerCase())) {
      i18next.changeLanguage(countryCode.country_code.toLowerCase());
      document.body.dir = currentLanguage.dir || "ltr";
      navigate(`/${countryCode.country_code}`);
      // navigate(`/${countryCode.country_code.toLowerCase()}`);
    } else {
      i18next.changeLanguage("in");
      // document.body.dir = currentLanguage.dir || "ltr";
      navigate(`/IN`);
    }
  };

  // useEffect(() => {
  //   document.body.dir = currentLanguage.dir || "ltr";
  //   i18next.changeLanguage(currentLanguage._code);
  // }, [currentLanguageCode]);

  React.useEffect(() => {
    //// runs on location, i.e. route, change//
    window.scrollTo(0, 0);
    // console.log("handle route change here", location);
  }, [location]);

  let url = `/${currentLanguage.code.toUpperCase()}`;

  return (
    <div>
      {
      location.pathname.includes("home") ? null :
      location.pathname === '/IN' ? null :
     location.pathname.includes("aboutamealio") ? null : 
     location.pathname.includes('becomemerchant') ? null : <Header type={location} />}
      <Routes history={createBrowserHistory}>
        <Route
          exact
          path={`/terms-conditions`}
          element={<Terms lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/terms-conditions`}
          element={<Terms lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/microphone`}
          element={<Microphone lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`/privacy-policy`}
          element={<Policy lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/privacy-policy`}
          element={<Policy lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`/cookie-policy`}
          element={<Policy lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/cookie-policy`}
          element={<Policy lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`/other-policy`}
          element={<Policy lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/other-policy`}
          element={<Policy lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/demo`}
          element={<Demo lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`/ususerterms`}
          element={<Ususerterms lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/ususerterms`}
          element={<Ususerterms lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`/usprivacy`}
          element={<Usprivacy lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/usprivacy`}
          element={<Usprivacy lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`/usbusinessterms`}
          element={<Usbusinessterms lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/usbusinessterms`}
          element={<Usbusinessterms lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`/social`}
          element={<Social lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/social`}
          element={<Social lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`/businessterms`}
          element={<Businessterms lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/businessterms`}
          element={<Businessterms lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`/walletterms`}
          element={<WalletTerms lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/walletterms`}
          element={<WalletTerms lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/bussiness`}
          element={<Bussiness lang={currentLanguageCode} />}
        />
        <Route exact  path={`${url}/oldpage`} element={<Home lang={currentLanguageCode} />} />
        {/* <Route
          exact
          path={`${url}`}
          element={<Home lang={currentLanguageCode} />}
        /> */}
        <Route
          exact
          path={`${url}/home`}
          element={<Mainpage lang={currentLanguageCode} />}
        /> 
        <Route
          exact
          path={`${url}/about`}
          element={<About lang={currentLanguageCode} />}
        />
        {/* <Route
          exact  
          path={`${url}/newhome`}
          element={<newHome lang={currentLanguageCode} />} 
        /> */}
        {/* <Route
          exact
          path={`${url}/demoHome`}
          element={<DemoHome lang={currentLanguageCode} />}
        /> */}
        <Route
          exact
           path={`${url}`}
          element={<Mainpage lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/aboutamealio`}
          element={<Aboutus lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/becomemerchant`}
          element={<BecomeMerchant lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/help`}
          element={<Help lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}community`}
          element={<Community lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/fastcasual`}
          element={
            <FastFood pagetype="Fast Casual" lang={currentLanguageCode} />
          }
        />
        <Route
          exact
          path={`${url}/fastfood`}
          element={<FastFood pagetype="Fast Food" lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/finedining`}
          element={
            <FastFood pagetype="Fine Dining" lang={currentLanguageCode} />
          }
        />
        <Route
          exact
          path={`${url}/driveindrivethru`}
          element={
            <FastFood
              pagetype="Drive-In/Driven-Thru"
              lang={currentLanguageCode}
            />
          }
        />
        <Route
          exact
          path={`${url}/casualdining`}
          element={
            <FastFood pagetype="Casual Dining" lang={currentLanguageCode} />
          }
        />
        <Route
          exact
          path={`${url}/hotelsandresorts`}
          element={
            <FastFood
              pagetype="Hotels and Resorts"
              lang={currentLanguageCode}
            />
          }
        />
        <Route
          exact
          path={`${url}/barsandclubs`}
          element={
            <FastFood pagetype="Bars and Clubs" lang={currentLanguageCode} />
          }
        />
        <Route
          exact
          path={`${url}/institutional`}
          element={
            <FastFood pagetype="Institutional" lang={currentLanguageCode} />
          }
        />
        <Route
          exact
          path={`${url}/fastfood`}
          element={<FastFood pagetype="Fast Food" lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/waitlist`}
          element={
            <Foodfeatures pagetype="Waitlist" lang={currentLanguageCode} />
          }
        />
        <Route
          exact
          path={`${url}/walkin`}
          element={
            <Foodfeatures pagetype="walkin" lang={currentLanguageCode} />
          }
        />
        <Route
          exact
          path={`${url}/reservation`}
          element={
            <Foodfeatures pagetype="reservation" lang={currentLanguageCode} />
          }
        />
        <Route
          exact
          path={`${url}/takeaway`}
          element={
            <Foodfeatures pagetype="Take Away" lang={currentLanguageCode} />
          }
        />
        <Route
          exact
          path={`${url}/curbside`}
          element={
            <Foodfeatures pagetype="Curbside" lang={currentLanguageCode} />
          }
        />
        <Route
          exact
          path={`${url}/dinein`}
          element={
            <Foodfeatures pagetype="Dinein" lang={currentLanguageCode} />
          }
        />
        <Route
          exact
          path={`${url}/skiptheline`}
          element={
            <Foodfeatures pagetype="skiptheline" lang={currentLanguageCode} />
          }
        />
        <Route
          exact
          path={`${url}/atmyseat`}
          element={
            <Foodfeatures pagetype="Atmyseat" lang={currentLanguageCode} />
          }
        />
        <Route
          exact
          path={`${url}/roomservice`}
          element={
            <Foodfeatures pagetype="Roomservice" lang={currentLanguageCode} />
          }
        />
        <Route
          exact
          path={`${url}/delivery`}
          element={
            <Foodfeatures pagetype="Delivery" lang={currentLanguageCode} />
          }
        />
        <Route
          exact
          path={`${url}/checkout`}
          element={<Checkout lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/explore`}
          element={<Explore lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/aboutAmealia`}
          element={<Aboutamealia lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/amealiarestaurant`}
          element={<Amealiarestaurant lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/features`}
          element={<Features lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/menu`}
          element={<Menu lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`/businessreferral`}
          element={<Businessreferral lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/businessreferral`}
          element={<Businessreferral lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`/offers-rewards-referral`}
          element={<Offerreferral lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/offers-rewards-referral`}
          element={<Offerreferral lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`/refunds-and-cancellations`}
          element={<Refundcancellation lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/refunds-and-cancellations`}
          element={<Refundcancellation lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`/programs`}
          element={<Usertouser lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/programs`}
          element={<Usertouser lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`*`}
          element={<PageNotFound lang={currentLanguageCode} />}
        />
        <Route
          exact
          path={`${url}/*`}
          element={<PageNotFound lang={currentLanguageCode} />}
        />
      </Routes>
    </div>
  );
}

export default App;
