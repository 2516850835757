import React from "react";
// import Header from "../templates/header";
// import { createSpeechlySpeechRecognition } from "@speechly/speech-recognition-polyfill";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { NavLink } from "react-router-dom";

const Aboutamealia = (props) => {
  const [voiceClassVisible, setVoiceClass] = React.useState(false);
  const [voiceClassVisible1, setVoiceClass1] = React.useState(false);
  // const [voiceClassVisible2, setVoiceClass2] = React.useState(false);
  const [voiceClassVisible4, setVoiceClass4] = React.useState(false);
  const [voiceClassVisible5, setVoiceClass5] = React.useState(false);
  // const voiceword = "Say Amealia, I would like to place takeaway order.";

  const commands = [
    {
      command: [
        "go to *",
        "open *",
        "amelia I would like to place * order",
        "I would like to place * order",
        "amelia I would like to place * away order",
        "I would like to place * away order",
        "amelia had one * burger to make art",
        "amelia and one * burger to make art",
        "a million and one Maharaja burger to my card",
        "amelia I would like to have a *",
        "I would like to have a *",
        "add 1 * burger",
        "add one * burger",
        "and one * burger",
        "please add one can of coca *",
        "please add one * of coca-cola",
        "no thanks show me the * summary",
        "yes please *",
        "amelia I would to try your wait list *",
        "amelia I would to try a wait list *",
        "I would to try your wait list *",
        "I would to try a wait list *",
        "one * and one kid",
        "one * access and one high Chair",
        "1 *",
        "1 asses",
        "one * access",
        "1 * access",
        "I want to try your wait list *",
        "yes I want to choose *",
        "no please * my request",
        "no please *",
        "no please continue my *",
        "* my request for waitlist",
        "* my request",
        "ok *",
      ],
      callback: (redirectPage) => speech(redirectPage),
    },
  ];

  function speech(redirectPage) {
    console.log(redirectPage);
    var msg = new SpeechSynthesisUtterance();
    if (redirectPage) {
      if (redirectPage === "takeaway") {
        msg.text =
          "Welcome back Miles Here is our menu. We have some excellent starters to begin and best soups and main course to serve this hour. What would you like";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "take") {
        msg.text =
          "Welcome back Miles Here is our menu. We have some excellent starters to begin and best soups and main course to serve this hour. What would you like";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "burger") {
        msg.text =
          "So here is a list of our burgers which one would you like to have? Aalu Tikki Burger is our Best Selling burger";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "Maharaja") {
        msg.text =
          "Got it. Would you like to add anything else or check out? We have recommended items like coca cola, french fries etc";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "Cola") {
        msg.text =
          "Got it. I have added 1 can of coca cola as well in the cart. Would you like to add anything else";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "can") {
        msg.text =
          "Got it. I have added 1 can of coca cola as well in the cart. Would you like to add anything else";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "order") {
        msg.text = "Would you like to proceed for payment?";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "address") {
        msg.text = "Would you like to proceed for payment?";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "proceed") {
        msg.text = "Your order total is Rs. 489";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "car") {
        msg.text =
          "Welcome to Red Cafe! Hi, I am Amealia! Your virtual assistant, I can help you with ordering food for take away or seating requests like Walk-In, Waitlist & making Reservation or get update on existing request.";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "service") {
        msg.text =
          "Please help us by answering few questions so that we can serve you better. what is your party size?";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "adult") {
        msg.text = "Do you require handicap and highchair accessibility?";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "handicap") {
        msg.text =
          "Choose your seating preference, preferences marked in grey are not available.";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "Indore") {
        msg.text = "Okay, Is there any special Occasion?";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "Indor") {
        msg.text = "Okay, Is there any special Occasion?";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "Indoor") {
        msg.text = "Okay, Is there any special Occasion?";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "continue") {
        msg.text =
          "Please check the details of your request to proceed further";
        window.speechSynthesis.speak(msg);
      } else if (redirectPage === "confirm") {
        msg.text =
          "Your waitlist request is successfully placed. Track your order from the link sent via SMS";
        window.speechSynthesis.speak(msg);
      }
      //   }else if (redirectPage == "") {
      //      msg.text = "sorry i can get you please repeat it again";
      //      window.speechSynthesis.speak(msg);
      //   }
    }
    return setRedirectUrl(redirectPage);
  }
  const { transcript, listening, browserSupportsSpeechRecognition } =
    useSpeechRecognition({ commands });

  const [redirectUrl, setRedirectUrl] = React.useState("");

  const pages = [
    "home",
    "take",
    "burger",
    "Maharaja",
    "can",
    "Cola",
    "order",
    "thanks",
    "proceed",
    "cart",
    "takeaway",
    "address",
    "add",
    "car",
    "service",
    "adult",
    "handicap",
    "request",
    "Indore",
    "Indor",
    "Indoor",
    "continue",
    "confirm",
    "thanks",
  ];
  const img = {
    home: "../assets/images/explore/home_2.png",
    take: "../assets/images/explore/takeaway.png",
    burger: "../assets/images/explore/menu.jpg",
    Maharaja: "../assets/images/explore/cart1.png",
    Cola: "../assets/images/explore/cart2.png",
    can: "../assets/images/explore/cart2.png",
    order: "../assets/images/explore/checkout.png",
    proceed: "../assets/images/explore/payment.png",
    cart: "../assets/images/explore/cart.png",
    takeaway: "../assets/images/explore/takeaway.png",
    address: "../assets/images/explore/checkout.png",
    add: "../assets/images/explore/5-hotel-room_view-cart_1_2.png",
    car: "../assets/images/explore/home_2.png",
    service: "../assets/images/explore/3-party-size_restaurant.png",
    adult: "../assets/images/explore/waitlist.png",
    handicap: "../assets/images/explore/5-seating-preference.png",
    Indore: "../assets/images/explore/continue.png",
    Indor: "../assets/images/explore/continue.png",
    Indoor: "../assets/images/explore/continue.png",
    continue: "../assets/images/explore/request.png",
    request: "../assets/images/explore/request.png",
    confirm: "../assets/images/explore/thanks.png",
    thanks: "../assets/images/explore/amelia.png",
  };
  const speechText = {
    home: "../assets/images/explore/home_2.png",
    take: "Try 'Amealia, I would like to have a burger'",
    burger: "Try 'Add 1 Maharaja Burger'",
    Maharaja: "Try 'Please add 1 can of coca cola'",
    Cola: "Try 'No Thanks, show me the order summary'",
    can: "Try 'No Thanks, show me the order summary'",
    order: "Try 'Yes, Please proceed'",
    // proceed: "../assets/images/explore/payment.png",
    cart: "../assets/images/explore/cart.png",
    takeaway: "Try 'Amealia, I would like to have a burger'",
    address: "Try 'Yes, Please proceed'",
    add: "../assets/images/explore/5-hotel-room_view-cart_1_2.png",
    car: " Try 'Amealia, I would to try your waitlist service.'",
    service: "Try '1 adult and 1 kid'",
    adult: "Try '1 Handicap'",
    handicap: "Try 'Yes, I want to choose indoor'",
    Indore: "Try 'No, Please continue.'",
    continue: "Try 'Confirm my request'",
    request: "Try 'Ok'",
    confirm: "Try 'Ok, Thanks'",
    thanks: " Try 'Amealia, I would to try your waitlist service.'",
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  let redirect = "";
  let redirect1 = "";
  if (redirectUrl) {
    if (pages.includes(redirectUrl)) {
      redirect = (
        <div>
          <img
            src={img[redirectUrl]}
            alt="img"
            width="75%"
            className="aboutamealio-headimg"
          />
          <p className="f18-p2 text-center">{speechText[redirectUrl]}</p>
        </div>
      );
    } else {
      redirect = (
        <p className="f18-p2 text-center">
          {" "}
          Could not find pages: {redirectUrl}
        </p>
      );
    }
  } else {
    redirect = (
      <div>
        <img
          src={img["home"]}
          alt="img"
          width="75%"
          className="aboutamealio-headimg"
        />
        <p className="f18-p2 text-center">
          Try "Amealia, I would like to place takeaway order"{" "}
        </p>
      </div>
    );
  }

  if (redirectUrl) {
    if (pages.includes(redirectUrl)) {
      redirect1 = (
        <div>
          <img
            src={img[redirectUrl]}
            alt="img"
            width="75%"
            className="aboutamealio-headimg"
          />
          <p className="f18-p2 text-center">{speechText[redirectUrl]}</p>
        </div>
      );
    } else {
      redirect1 = (
        <p className="f18-p2 text-center">
          {" "}
          Could not find pages: {redirectUrl}
        </p>
      );
    }
  } else {
    redirect1 = (
      <div>
        <img
          src={img["car"]}
          alt="img"
          width="75%"
          className="aboutamealio-headimg"
        />
        <p className="f18-p2 text-center">
          Try "Amealia, I would to try your waitlist service."
        </p>
      </div>
    );
  }

  return (
    <div className="app main-amealia-home">
      {/* <div className='container'>
            <NavLink to="/">
                <img src="../assets/images/explore/amealialogo.png" className="amealia-logo" alt=""></img>
                </NavLink>
            </div> */}
      {/* <div className="top-right-image">
        <img
          src="../assets/images/aboutamealio/cheeze.png"
          alt="Redefining"
          title="Redefining"
          className="img-fluid"
        />
      </div>
      <div className="bottom-left-image">
        <img
          src="../assets/images/aboutamealio/pizza.png"
          alt="Redefining"
          title="Redefining"
          className="img-fluid"
        />
      </div> */}

      <section className="aboutamealio-section amealio-headline">
        <div className="container aboutamealia-main">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-7 aboutamealia-heading-two service-takeaway-data">
              <h1 className="f50-h2 mb-0">Amealia -</h1>
              <h1 className="f50-h2 mt-0" style={{ color: "#EEB84E" }}>
                A Virtual Voice Assistant
              </h1>
              <h2 className="f18-h6">
                While the world awaits Amazon’s drone deliveries and Google’s
                driverless cars.
              </h2>
              <p className="f16-p3 mt-5">
                Brace yourself for a much-needed disruption in the food
                industry. Our virtual assistant will be at your service to help
                enhance the Amealio experience for you.- a platform on a mission
                to introduce voice capability to elevate your Food Experiences.
                This is Amealia, bringing the power of voice to the masses!
              </p>
              <p className="f16-p3 mt-5">We are defining the future ahead.</p>
              <div className="row mt-5 service-takeaway-data">
                <div className="col-12 col-sm-12 col-md-5 mb-4 mb-sm-4 mb-md-0">
                  <button
                    className="btn about-amealio1"
                    onClick={() => setVoiceClass(true)}
                    id="button"
                  >
                    &nbsp;Takeaway Service
                  </button>
                </div>
                <div className="col-12 col-sm-12 col-md-5">
                  <button
                    className="btn about-amealio1"
                    onClick={() => setVoiceClass5(true)}
                    id="button3"
                  >
                    &nbsp;Waitlist Service
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-5 aboutamealia-heading-three">
              <div className="center-right-image mx-auto mx-sm-auto mx-md-0 mt-md-5">
                <img
                  src="../assets/images/aboutamealio/voice-walkin-takeaway.png"
                  alt="voice"
                  title="voice"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Hotels voice */}
        {/* <div className={voiceClassVisible3 ? 'aboutamealio-audio-active aboutamealio-audio' : 'aboutamealio-audio'}   >
                    <p className="aboutamealio-p">Give access to your mic for a Seamless Experience</p>
                    <NavLink activeClassName="is-active" to="/microphone" exact><h6 className='aboutamealio-h6'>Learn More</h6></NavLink>
                    <div className="aboutamealio-center">
                        <button className="btn aboutamealio-btn" onClick={() => setVoiceClass4(true)} id="button1">Allow</button>
                        <button className="btn aboutamealio-btn" onClick={() => { setVoiceClass3(false); }} id="button">Deny</button>
                    </div>
                    <div className='aboutamealio-micaccess'>
                        <img src="../assets/images/explore/mic.png" alt="access microphone" />
                    </div>
                </div> */}

        {/* Restaurant voice */}
        <div
          className={
            voiceClassVisible
              ? "aboutamealio-audio-active aboutamealio-audio d-flex align-items-center justify-content-center"
              : "aboutamealio-audio d-flex align-items-center justify-content-center"
          }
        >
          <div>
            <p className="aboutamealio-p">
              Give access to your mic for a Seamless Experience.
            </p>
            <NavLink
              activeClassName="is-active"
              to={`${props.lang}/microphone`}
              exact
              style={{ textDecoration: "none" }}
            >
              <h6 className="aboutamealio-h6">Learn More</h6>
            </NavLink>
            <div className="aboutamealio-center">
              <button
                className="btn aboutamealio-btn"
                onClick={() => setVoiceClass1(true)}
                id="button1"
              >
                Allow
              </button>
              <button
                className="btn aboutamealio-btn"
                onClick={() => {
                  setVoiceClass(false);
                }}
                id="button"
              >
                Deny
              </button>
            </div>
            <div className="aboutamealio-micaccess">
              <img
                src="../assets/images/explore/mic.png"
                alt="access microphone"
              />
            </div>
          </div>
        </div>

        {/* car voice */}

        <div
          className={
            voiceClassVisible5
              ? "aboutamealio-audio-active aboutamealio-audio d-flex align-items-center justify-content-center"
              : "aboutamealio-audio d-flex align-items-center justify-content-center"
          }
        >
          <div>
            <p className="aboutamealio-p">
              Give access to your mic for a Seamless Experience
            </p>
            <NavLink
              activeClassName="is-active"
              to={`${props.lang}/microphone`}
              exact
            >
              <h6 className="aboutamealio-h6">Learn More</h6>
            </NavLink>
            <div className="aboutamealio-center">
              <button
                className="btn aboutamealio-btn"
                onClick={() => setVoiceClass4(true)}
                id="button1"
              >
                Allow
              </button>
              <button
                className="btn aboutamealio-btn"
                onClick={() => {
                  setVoiceClass5(false);
                }}
                id="button"
              >
                Deny
              </button>
            </div>
            <div className="aboutamealio-micaccess">
              <img
                src="../assets/images/explore/mic.png"
                alt="access microphone"
                title="access microphone"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        {/* Car voice */}
        <div
          className={
            voiceClassVisible4
              ? "aboutamealio-slide-active aboutamealio-slide"
              : "aboutamealio-slide"
          }
        >
          <div className="container-fluid">
            {/*{voiceClassVisible4 && voiceClassVisible5 ? (
              <>
                 <div className="top-right-image-fixed">
                  <img
                    src="../assets/images/aboutamealio/cheeze.png"
                    alt="Redefining"
                    title="Redefining"
                    className="img-fluid"
                  />
                </div>
                <div className="bottom-left-image-fixed">
                  <img
                    src="../assets/images/aboutamealio/pizza.png"
                    alt="Redefining"
                    title="Redefining"
                    className="img-fluid"
                  />
                </div> 
              </>
            ) : null}*/}
            <div className="container">
              <div className="aboutamealio-headflex">
                <button
                  className="btn button-active-focus"
                  onClick={() => {
                    setVoiceClass(false);
                    setVoiceClass1(false);
                    setVoiceClass4(false);
                    setVoiceClass5(false);
                  }}
                >
                  <img
                    className="img-fluid w-50"
                    src="../assets/images/aboutamealio/backArrow.png"
                    alt="Back"
                    title="Back"
                  ></img>
                </button>
                <NavLink
                  activeClassName="is-active"
                  to={`/${props.lang}/explore`}
                  exact
                  style={{ textDecoration: "none" }}
                >
                  <img
                    className="img-fluid w-50"
                    src="../assets/images/aboutamealio/closed.png"
                    alt="Closed"
                    title="Closed"
                  />
                </NavLink>
              </div>
              <h1 className="f42-h3 text-center">Amealia’s Waitlist Service</h1>
              <p className="f18-p2 text-center">
                The Virtual assistant will work on multiple modes including
                mobiles, tabs, smart speakers, T.V etc.
                <br />
                It will help you order your food, reserve table, place you in
                virtual queue, and much more.
              </p>
              <div className="aboutamealio-speechimg">{redirect1}</div>
              <div className="aboutamealio-mic">
                <p>{transcript}</p>
                <p>Microphone: {listening ? "on" : "off"}</p>
                <img
                  src="../assets/images/backup/mic.png"
                  alt="img"
                  onClick={SpeechRecognition.startListening}
                />
                <p onClick={SpeechRecognition.stopListening}>Tap</p>
              </div>
            </div>
          </div>
        </div>

        {/* Restaurant voice */}
        <div
          className={
            voiceClassVisible1
              ? "aboutamealio-slide-active aboutamealio-slide"
              : "aboutamealio-slide"
          }
        >
          <div className="container-fluid">
            {/* {voiceClassVisible1 ? (
              <>
                <div className="top-right-image-fixed">
                  <img
                    src="../assets/images/aboutamealio/cheeze.png"
                    alt="Redefining"
                    title="Redefining"
                    className="img-fluid"
                  />
                </div>
                <div className="bottom-left-image-fixed">
                  <img
                    src="../assets/images/aboutamealio/pizza.png"
                    alt="Redefining"
                    title="Redefining"
                    className="img-fluid"
                  />
                </div>
              </>
            ) : null} */}

            <div className="container">
              <div className="aboutamealio-headflex">
                <button
                  className="btn button-active-focus"
                  onClick={() => {
                    setVoiceClass(false);
                    setVoiceClass1(false);
                    setVoiceClass4(false);
                    setVoiceClass5(false);
                  }}
                >
                  <img
                    className="img-fluid w-50"
                    src="../assets/images/aboutamealio/backArrow.png"
                    alt="Back"
                    title="Back"
                  ></img>
                </button>
                <NavLink
                  activeClassName="is-active"
                  to={`/${props.lang}/explore`}
                  exact
                  style={{ textDecoration: "none" }}
                >
                  <img
                    className="img-fluid w-50"
                    src="../assets/images/aboutamealio/closed.png"
                    alt="Closed"
                    title="Closed"
                  />
                </NavLink>
              </div>
              <h1 className="f42-h3 text-center">Amealia’s Takeaway Service</h1>
              <p className="f18-p2 text-center">
                The Virtual assistant will work on multiple modes including
                mobiles, tabs, smart speakers, T.V etc.
                <br />
                It will help you place and receive your orders seamlessly.
              </p>
              <div className="aboutamealio-speechimg">{redirect}</div>
              <div className="aboutamealio-mic">
                <p>{transcript}</p>
                <p>Microphone: {listening ? "on" : "off"}</p>
                <img
                  src="../assets/images/backup/mic.png"
                  alt="img"
                  onClick={SpeechRecognition.startListening}
                />
                <p onClick={SpeechRecognition.stopListening}>Tap</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Aboutamealia;
