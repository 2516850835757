import React from "react";

const ReusableSliderOneCom = (props) => {
  return (
    <div>
      <div className="features-slider">
        <div className="features-amealio-head-divTwo">{props?.data?.title}</div>
        <div className="features-amealio-div-img">
          <img
            src={`../assets/images/amealio/3Dicon/${props?.data?.imgSrc}`}
            alt={props?.data?.title}
            title={props?.data?.title}
            className="img-fluid"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default ReusableSliderOneCom;
