import React from "react";
// import Header from '../templates/header';
import Footer from "../templates/footer";
import WalletTerms from "./walletTerms";
const Terms = () => {
  return (
    <div className="app">
      <section className="terms-section">
        <>
          <div className="container">
            <div className="terms-heading">
              <h2>
                <b>USER TERMS AND CONDITIONS</b>
              </h2>
            </div>
            <div className="terms-paragraph">
              <p>
                This document is an electronic record in terms of Information
                Technology Act, 2000 and rules there under as applicable and the
                amended provisions pertaining to electronic records in various
                statutes as amended by the Information Technology Act, 2000.
                This document is published in accordance with the provisions of
                Rule 3 (1) of the Information Technology (Intermediaries
                guidelines) Rules, 2021 that require publishing the rules and
                regulations, privacy policy and Terms of Use for access or usage
                of www.amealio.com website and Amealio application (Android and
                iOS) for mobile and handheld devices{" "}
                <strong className="text-dark">(“Amealio Platform”)</strong>. 
              </p>
              <p>
                Please read these Terms of Use (the “Terms”) carefully before
                you use the services. By accessing or using the Amealio
                Platform, you are agreeing to these Terms thereby concluding a
                legally binding contract with Envisionard Ads and Marketing Pvt.
                Ltd. and /or its affiliates (“Amealio”). The terms “Amealio”,
                “we” or “us” or “our” shall mean Envisionard Ads and Marketing
                Pvt. Ltd. If you do not agree to these or are incapable of being
                bound to the Terms and other Amealio policies (including but not
                limited to the Cancellation & Refund Policy, Privacy Policy) as
                posted on the Platform from time to time, which takes effect on
                the date on which you download, install or use the Services, and
                create a legally binding arrangement to abide by the same, you
                may not use the Services. Your use of the Platform is at your
                own risk; including the risk that you might be exposed to
                content that is objectionable, or otherwise inappropriate.
              </p>
              <p>
                <b>
                  {" "}
                  To use the Services, you must first accept the Terms. You can
                  accept the Terms by either:
                </b>{" "}
                <br />
                a. Clicking to accept or agree to the Terms, when provided by
                Amealio in the user interface for any specific Service; or 
                <br />
                b. By actually using the Services. In event of which, you
                acknowledge and agree that Amealio will regard your continued
                use of the Services as acceptance of the Terms.
              </p>
              <p>
                <b>Definitions:</b> <br />
                <b>1.Customer:</b> <br />
                “Customer” or “You” or “Your” or “User” refers to you, as a
                customer of the service. It shall mean any natural or legal
                person who has agreed to become a buyer or customer on the
                Platform by providing Registration Data while registering on the
                Platform as a registered user using any computer systems. The
                term shall mean any person or User accessing the Platform to
                access the services mentioned hereunder.
              </p>
              <p>
                <b>2.Restaurant(s):</b> <br />
                “Restaurant” means a commercial establishment(s) from where the
                Restaurant Services are made available to the Customer, moreover
                wherein the food and beverage items are prepared and/or
                delivered to the Customer.
              </p>
              <p>
                <b>3.Merchant(s):</b> <br />
                “Merchant” means the entity /individual being the legal
                owner/operator of the Restaurant and includes the merchant(s)
                listed on the Amealio Platform.
              </p>
              <p>
                <b>4.Platform</b> <br />
                Platform collectively refers to the Amealio Website
                (www.amealio.com) (the “Website”) and the Amealio application
                (the “App”) (Android and iOS) for mobile and other handheld
                devices, including any information provided, existing or in
                future provided through the website or the app.
              </p>
              <p>
                <b>Eligibility to use the services</b> <br />
                a. You hereby affirm and warrant that you are eighteen (18)
                years old or above and are fully able and competent to
                understand and agree to the terms, conditions, obligations,
                affirmations, representations, and warranties set forth in these
                Terms.
              </p>
              <p>
                b. Any person/s who is "incompetent to contract" within the
                scope of the Indian Contract Act, 1872 are not eligible to use
                the Platform.
              </p>
              <p>
                c. You hereby affirm that you are in compliance with all laws
                and regulations in the country in which you live when you access
                and use the Services offered by this Platform. You agree to use
                the Services only in a manner which is in compliance with these
                Terms and the applicable law of the respective country you are
                in, and in a manner that does not violate either our or any
                third party/s legal rights.
              </p>
              <p>
                d. If you are under 18 years of age and you wish to download,
                install, access or use the Platform, your parents or legal
                guardian must acknowledge and agree to the Terms of Use and
                Privacy Policy. If your parents or legal guardian fail to agree
                or acknowledge the Terms of Use and Amealio policies, you shall
                immediately discontinue its use. 
              </p>
              <p>
                e. Amealio reserves the right to terminate your Membership and /
                or deny access to the Platform if it is brought Amealio's notice
                that you are under the age of 18 years.
              </p>
              <p>
                <b>Amendments to the Terms:</b> <br />
                These Terms of Use are subject to modifications. We reserve the
                right to modify or change these Terms of Use and other Amealio
                policies at any time by posting modified documents on the
                Platform and notifying you of the same for your perusal. You
                shall be liable to update yourself of such changes, if any, by
                accessing the same. You shall, at all times, be responsible for
                regularly reviewing the Terms of Use and the other Amealio
                policies and note the changes made on the Platform. Your
                continued usage of the Services after any change is posted
                constitutes your acceptance of the amended Terms of Use and
                other Amealio policies. As long as you comply with these Terms
                of Use, Amealio grants you a personal, non-exclusive,
                non-transferable, limited privilege to access, enter, and use
                the Platform. By accepting these Terms of Use, you also accept
                and agree to be bound by the other terms and conditions and
                Amealio policies (including but not limited to Cancellation &
                Refund Policy, Privacy Policy) as may be posted on the Platform
                from time to time.
              </p>
              <p>
                <b>Account Registration:</b> <br />
                1. A User shall be required to furnish the following details
                before using the Service/s of Amealio. The user gets registered
                from the App by providing Name, Email Address, Mobile No. At the
                time of the registration, the Platform will send an OTP to the
                mobile no and email address provided by the user. The user shall
                copy the OTP and put the same in the App. After the verification
                is complete, the account will be created.
              </p>
              <p>
                2. Amealio holds an exclusive right to terminate the account or
                block the account of the User anytime. If the user uses the
                Platform for any other purpose then in such case the Amealio
                account shall be terminated or blocked without giving any prior
                notice to the user.
              </p>
              <p>
                3. A user can create only one account with the same email
                address and mobile number. A user can create an account by using
                third party verification. You can register yourself through your
                google account/Facebook account. However, in such a case, the
                User shall verify the third-party account from the App. After
                successful verification, the account shall be created with the
                name as mentioned in such third-party account. The user shall
                confirm the mobile no in such case before placing an order.
              </p>
              <p>
                4. If the user does not provide correct information, then in
                such case Amealio can delete the account of such user. In case
                any account is blocked or deleted by the Amealio, then the user
                shall not be eligible to create another account with the same
                number and email address.
              </p>
              <p>
                <b>
                  Terms and Conditions for Customers Using the Various Services
                  Offered By Amealio:
                </b>{" "}
                <br />
                <b>A) Online Seating and Ordering Services:</b> <br />
                1. Amealio facilitates transactions between Merchants dealing in
                prepared food and beverages and Customers. The Customer can
                choose from a variety of products and services listed and
                offered by various Merchants including but not limited to the
                restaurants and eateries on the Platform.
              </p>
              <p>
                2. The Customer can access the menu items or Products listed on
                the Merchant’s listing page and make request for various seating
                and ordering services against the Restaurant Partner through
                Amealio Platform, subject to the availability of the facility by
                a particular restaurant. While using the Amealio Seating and
                Ordering Services, you shall be required to provide certain
                details and you agree to provide correct details and warrant
                that these details are accurate and complete. By submitting a
                request for Seating and Ordering Services, you express your
                acceptance to Amealio’s terms and privacy policies and agree to
                receive booking confirmations by SMS and /or by any other means
                of communication after placing a request.
              </p>
              <p>
                <b> i.) Walk-in / Waitlist:</b> <br />
                a) The Customer can make a Walk-in /Wait List request for
                booking a table for specific number of persons, for specific
                needs (such as handicap and high chair requirements) at a
                Restaurant accepting Walk-in /Wait List request through the
                Amealio Platform. The Customer may also select seating area as
                per his preference as well as select special occasion. After the
                Customer makes a Walk-in /Wait List request, the Merchant after
                ascertaining the availability of a booking shall accept or
                reject the booking. Once the Merchant accepts the
                Walk-in/Waitlist request, the Customer will be notified through
                Push Notification and short message service (“SMS”).
              </p>
              <p>
                b) The Customer may edit/modify the Walk-in /Wait List request
                any time before the status changes to Seated, however, any
                request for modification of the confirmed bo0king will be
                subject to acceptance of the same by the Merchant.
              </p>
              <p>
                c) Order Ahead: The Customer has the option to place an Order
                through the Amealio Platform before reaching the Restaurant for
                which he has received a confirmation for the Walk-in /Wait List
                request.
              </p>
              <p>
                d) The Customer will provide Expected Time of Arrival (ETA) to
                the Merchant and shall be allowed to change the ETA three times
                and the Merchant must reconfirm the ETA five minutes before the
                expiry of ETA. Even after reconfirming the ETA for the third
                time, if the Customer does not show up, the Merchant may cancel
                the Walk-in /Wait List request.
              </p>
              <p>
                e) When the Customer arrives at the Restaurant, the Merchant
                should get the Customer seated at the earliest as per the
                selected seating preference and in conformity with the specific
                Walk-in /Wait List request. In the event, the Merchant fails to
                get the Customer upon his arrival as per the ETA, the Merchant
                shall be solely liable to the Customer for such delay and
                Amealio shall in no manner be held liable.
              </p>
              <p>
                f) In the event, the Merchant fails to honour the Walk-in /Wait
                List request or in case of any other complaint raised by the
                Customer in relation to the Booking, Amealio will make
                reasonable efforts to verify the facts of such complaint with
                the Restaurant and may at its sole discretion initiate the
                refund (if any) to the Customer and Amealio shall be entitled to
                deduct such Refund Amount from any amount payable to the
                Restaurant by Amealio.
              </p>
              <p>
                <b>ii.) Reservation:</b> <br />
                a) The Customer can make a request for “Reservation” for the
                same day or for a future date for specific number of persons,
                for specific needs (such as handicap and high chair
                requirements) at a Restaurant accepting Reservation request
                through the Amealio Platform. The Customer may also select
                seating area as per his preference as well as select special
                occasion. After the Customer makes a request for Reservation for
                the same day, the Merchant must ascertain the availability of a
                booking and accordingly accept and confirm the booking within 1
                minute of the receipt of request; else the Reservation request
                shall be auto-cancelled. If the Restaurant has activated
                “Auto-accept”, the Reservation request will get confirmed
                automatically. If the Customer makes a request for Reservation
                for a future day, the Merchant must ascertain the availability
                of a booking and accordingly accept and confirm the booking
                within 5 minutes of the receipt of request if received during
                business hours and if the request is received outside of
                business hours then the Merchant shall accept and confirm the
                booking within 5 minutes of the opening of the Restaurant; else
                the Reservation request shall be auto-cancelled. Once, the
                Restaurant accepts the request for Reservation, the Customer
                will be notified through Push Notification and short message
                service (“SMS”).
              </p>
              <p>
                b) The Customer may edit/modify the Reservation request any time
                with regards to the date/time reservation, seating preference,
                special occasion, special needs requirement before the status
                changes to Seated, however, any request for modification of the
                confirmed Reservation request will be subject to acceptance of
                the same by the Merchant. The Merchant will use its best
                endeavours to accept the request for modification of the
                Reservation request.
              </p>
              <p>
                c) Order Ahead: The Customer has the option to place an Order
                through the Amealio Platform before reaching the Restaurant for
                which he has received a confirmation for the Reservation
                request.
              </p>
              <p>
                d) The Customer may enable location tracking or provide Expected
                Time of Arrival (ETA) to the Merchant and shall be allowed to
                change the ETA three times and the Merchant must reconfirm the
                ETA five minutes before the expiry of ETA. Even after
                reconfirming the ETA for the third time, if the Customer does
                not show up until the expiry of _____minutes from the ETA, the
                Merchant may cancel the Reservation request.
              </p>
              <p>
                e) When the Customer arrives at the Restaurant, the Merchant
                should get the Customer seated at the earliest as per the
                selected seating preference and in conformity with the specific
                Reservation request. In the event, the Merchant fails to get the
                Customer seated upon his arrival as per the ETA, the Merchant
                shall be solely liable to the Customer for such delay and
                Amealio shall in no manner be held liable.
              </p>
              <p>
                f) In the event, the Merchant fails to honour the Reservation
                request or in case of any other complaint raised by the Customer
                in relation to the Reservation, Amealio will make reasonable
                efforts to verify the facts of such complaint with the Merchant
                and may at its sole discretion initiate the refund (if any) to
                the Customer and Amealio shall be entitled to deduct such Refund
                Amount from any amount payable to the Merchant by Amealio.
              </p>
              <p>
                <b>iii.) Takeaway:</b> <br />
                a) Takeaway would mean where a Merchant has agreed to provide an
                option to the Customers to collect the Order themselves from the
                Restaurant on which such an Order is placed. When the Customer
                opts for a Takeaway, he/she agrees to be solely liable to ensure
                compliance with the conditions governing the Takeaway at the
                time of placing the Order, and Amealio shall not be liable in
                any manner in this regard.
              </p>
              <p>
                b) Amealio accepts no liability associated with food preparation
                by the Restaurant accepting the Order, and all food preparation
                and hand over through Takeaway are the sole responsibility of
                the Restaurant accepting the Order. The Takeaway times for
                collection are provided by the Restaurant and are only
                estimates.
              </p>
              <p>
                <b>iv.) Curbside:</b> <br />
                a) Curbside would mean where a Merchant has agreed to provide
                Curbside Delivery to the Customers who have opted for Curbside
                Delivery from the Restaurant on which such an Order is placed.
                When the Customer opts for a Curbside delivery, he/she agrees to
                be solely liable to ensure compliance with the conditions
                governing the Curbside at the time of placing the Order, and
                Amealio shall not be liable in any manner in this regard.
              </p>
              <p>
                b) Amealio accepts no liability associated with food preparation
                by the Restaurant accepting the Order, and all food preparation
                and hand over through Curbside are the sole responsibility of
                the Restaurant accepting the Order. The Curbside delivery times
                for collection are provided by the Restaurant and are only
                estimates.
              </p>
              <p>
                c) When a Customer places an order for Curbside delivery, he/she
                is required to provide correct details of the Vehicle such as
                Vehicle Type, Colour, Model No. to the Merchant at the time of
                placing the request.
              </p>
              <p>
                d) The Customer may enable location tracking or enter Expected
                Time of Arrival (ETA) to the Merchant. The Customer shall be
                allowed to change the ETA three times and the Merchant must
                reconfirm the ETA five minutes before the expiry of ETA. Even
                after reconfirming the ETA for the third time, if the Customer
                does not show up, the Merchant may cancel the Order.
              </p>
              <p>
                e) In the event, the Merchant fails to deliver the Order to
                Customer by the Curbside Time, the Merchant shall be liable to
                the Customer for such delay.
              </p>
              <p>
                f) In the event the Customers raises any complaint on account of
                any delay beyond the Curbside Time or due to any fault
                attributable to the Merchant, Amealio shall be entitled to
                refund to the Customer an amount up to the Order Value at its
                sole discretion and Amealio shall be entitled to deduct such
                Refund Amount from any amount payable to the Merchant by
                Amealio.
              </p>
              <p>
                <b>v.) Skip the Line:</b> <br />
                a) Skip the Line would mean where a Restaurant Partner has
                agreed to provide an option to the Customers to place an order
                and pick up the Order themselves from the Pick Up Counter when
                it is ready, without standing in the queue. When the Customer
                opts for a Skip the Line, he/she agrees to be solely liable to
                ensure compliance with the conditions governing the Skip the
                Line at the time of placing the Order, and Amealio shall not be
                liable in any manner in this regard.
              </p>
              <p>
                b) Amealio accepts no liability associated with food preparation
                by the Restaurant Partner accepting the Order, and all food
                preparation and hand over through Takeaway are the sole
                responsibility of the Restaurant Partner accepting the Order.
                The Takeaway times for collection are provided by the Restaurant
                Partner and are only estimates.
              </p>
              <p>
                <b>General Terms and Conditions:</b> <br />
                i.) Amealio is not a manufacturer, seller or distributor of food
                and beverages or Products and merely places an order against the
                Restaurant(s)/Merchant (s) on behalf of the Customers pursuant
                to the unconditional and irrevocable authority granted by the
                Customers to Amealio, and facilitates the sale and purchase of
                food and beverages or Products between Customers and
                Restaurant(s)/Merchant(s), under the contract for sale and
                purchase of food and beverages or Products between the Customers
                and Restaurant Partners/Store(s).
              </p>
              <p>
                ii.) Amealio shall not be liable for any acts or omissions on
                part of the Restaurant /Merchant including deficiency in
                service, wrong delivery of order / order mismatch, quality,
                incorrect pricing, deficient quantity, time taken to prepare or
                deliver the order, etc.
              </p>
              <p>
                iii.) The Restaurant /Merchant shall be solely responsible for
                any warranty/guarantee of the food and beverages or Products
                sold to the Customer and in no event shall be the responsibility
                of Amealio.
              </p>
              <p>
                iv.) Please note that some of the food and beverages or Products
                may be suitable for certain ages only. You should check the dish
                you are ordering and read its description, if provided, prior to
                placing your order. Amealio shall not be liable in the event the
                food and beverages or the Product ordered by You does not meet
                your dietary or any other requirements and/or restrictions.
              </p>
              <p>
                v.) While placing an order you shall be required to provide
                certain details, including without limitation, contact number
                and delivery address. You agree to take particular care when
                providing these details and warrant that these details are
                accurate and complete at the time of placing an Order. By
                providing these details, you express your acceptance to
                Amealio's terms and privacy policies.
              </p>
              <p>
                vi.) You or any person instructed by you shall not resell food
                and beverages or Products purchased via the Amealio Platform.
              </p>
              <p>
                vii.) The total price for food ordered, including the Delivery
                Charges and other charges, will be displayed on the Amealio
                Platform when you place your order, which may be rounded up to
                the nearest amount. Customers shall make full payment towards
                such food or Products ordered via the Amealio Platform.
              </p>
              <p>
                viii.) The Customer agrees and acknowledges that Amealio shall
                not be responsible for the services or food items provided by
                the Merchants including but not limited to serving of food
                orders suiting your requirements and taste; availability or
                unavailability to certain items on the menu.
              </p>
              <p>
                <b>Cancellations and Refunds:</b> <br />
                Please refer to the Cancellation and Refund Policy for
                cancellation and refund terms in relation to usage of the
                Platform for availing Services.
              </p>
              <p>
                <b> Access to the Platform, Accuracy and security:</b> <br />
                i.) We endeavor to make the Platform available to during
                Merchant working hours. However, we do not represent that access
                to the Platform will be uninterrupted, timely, error free, free
                of viruses or other harmful components or that such defects will
                be corrected. 
              </p>
              <p>
                ii.) We do not warrant that the Platform will be compatible with
                all hardware and software which you may use. We shall not be
                liable for damage to, or viruses or other code that may affect,
                any equipment (including but not limited to your mobile device),
                software, data or other property as a result of your download,
                installation, access to or use of the Platform or your obtaining
                any material from, or as a result of using, the Platform. We
                shall also not be liable for the actions of third parties. 
              </p>
              <p>
                iii.) We do not represent or warranty that the information
                available on the Platform will be correct, accurate or otherwise
                reliable. 
              </p>
              <p>
                iv.) We reserve the right to suspend or withdraw access to the
                Platform to you personally, or to all users temporarily or
                permanently at any time without notice. We may any time at our
                sole discretion reinstate suspended users. A suspended User may
                not register or attempt to register with us or use the Platform
                in any manner whatsoever until such time that such user is
                reinstated by us. 
              </p>
              <p>
                <b>Content on the Platform:</b> <br />
                The term ‘Content’ hereby shall mean, any content, inclusive but
                not limited to the information, photographs, videos, reviews,
                feedback shared/ uploaded/ posted/ provided by You on the
                Amealio Platform.
              </p>
              <p>
                1. You shall not host, display, upload, download, modify,
                publish, transmit, update or share any information which:
                <br />
                a) belongs to another person and which you do not have any right
                to; 
              </p>
              <p>
                b) is grossly harmful, harassing, blasphemous, defamatory,
                obscene, pornographic, pedophilic, libelous, slanderous,
                criminally inciting or invasive of another's privacy, hateful,
                or racially, ethnically objectionable, disparaging, relating or
                encouraging money laundering or gambling, or otherwise unlawful
                in any manner whatsoever; or unlawfully threatening or
                unlawfully harassing to any community, religion, race, caste,
                gender including but not limited to "indecent representation of
                women" within the meaning of the Indecent Representation of
                Women (Prohibition) Act, 1986;
              </p>
              <p>c) is misleading or misrepresentative in anyway; </p>
              <p>
                d) is blatantly offensive to the online community guidelines
                and/or policies which is either deceptive or contains any kind
                of sensitive content such as sexually explicit/violent/dangerous
                content, or any content that is or promotes
                obscenity/harassment/bullying/violence, pedophilia, racism,
                bigotry, hatred, conspiracy within the meaning of Section 120 of
                the Indian Penal Code, 1860, suicide or physical harm of any
                kind against self, any group or individual; 
              </p>
              <p>e) harasses or advocates harassment of another person; </p>
              <p>
                f) involves the transmission of "junk mail", "chain letters", or
                unsolicited mass mailing or "spamming"; 
              </p>
              <p>
                g) promotes illegal activities or conduct that is abusive,
                threatening, obscene, defamatory or libelous; 
              </p>
              <p>
                h) infringes or violates any third party's rights [including,
                but not limited to, intellectual property rights, rights of
                privacy (including without limitation unauthorized disclosure of
                a person's name, email address, physical address or phone
                number) or rights of publicity]; 
              </p>
              <p>
                i) contains restricted or password-only access pages, or hidden
                pages or images (those not linked to or from another accessible
                page); 
              </p>
              <p>
                j) provides instructional information about illegal activities
                such as making or buying illegal or harmful weapons, violating
                someone's privacy, or providing or creating computer viruses; 
              </p>
              <p>
                k) contains video, photographs, or images of another person/s
                without prior permission from them or their guardians.
              </p>
              <p>
                l) tries to gain unauthorized access or exceeds the scope of
                authorized access to the Platform or to profiles, blogs,
                communities, account information, bulletins, friend request, or
                other areas of the Platform or solicits passwords or personal
                identifying information for commercial or unlawful purposes from
                other users; 
              </p>
              <p>
                m) engages in commercial activities and/or sales without our
                prior written consent such as contests, sweepstakes, barter,
                advertising and pyramid schemes, or the buying or selling of
                products related to the Platform. Throughout these Terms of
                Use, Amealio's prior written consent means a communication
                coming from Amealio's Legal Department, specifically in response
                to your request, and expressly addressing and allowing the
                activity or conduct for which you seek authorization; 
              </p>
              <p>
                n) solicits gambling or engages in any gambling activity which
                is or could be construed as being illegal; 
              </p>
              <p>
                o) interferes with another user's use and enjoyment of the
                Platform or any third party's user and enjoyment of similar
                services; 
              </p>
              <p>
                p) refers to any website or URL that, in our sole discretion,
                contains material that is inappropriate for the Platform or any
                other website, contains content that would be prohibited or
                violates the letter or spirit of these Terms of Use; harm minors
                in any way; 
              </p>
              <p>
                q) infringes any patent, trademark, copyright or other
                intellectual property rights or third party's trade secrets or
                rights of publicity or privacy or shall not be fraudulent or
                involve the sale of counterfeit or stolen products; 
              </p>
              <p>r) violates any law for the time being in force; </p>
              <p>
                s) deceives or misleads the addressee/users about the origin of
                such messages or communicates any information which is grossly
                offensive or menacing in nature; 
              </p>
              <p>t) impersonates another person; </p>
              <p>
                u) contains software viruses or any other computer code, files
                or programs designed to interrupt, destroy or limit the
                functionality of any computer resource; or contains any trojan
                horses, worms, time bombs, cancelbots, easter eggs or other
                computer programming routines that may damage, detrimentally
                interfere with, diminish value of, surreptitiously intercept or
                expropriate any system, data or personal information; 
              </p>
              <p>
                v) threatens the unity, integrity, defense, security or
                sovereignty of India, friendly relations with foreign states, or
                public order or causes incitement to the commission of any
                criminal offence or prevents investigation of any offence or is
                insulting any other nation; 
              </p>
              <p>w) is false, inaccurate or misleading; </p>
              <p>
                x) directly or indirectly, offers, attempts to offer, trades or
                attempts to trade in any item, the dealing of which is
                prohibited or restricted in any manner under the provisions of
                any applicable law, rule, regulation or guideline for the time
                being in force; or 
              </p>
              <p>
                y) creates liability for us or causes us to lose (in whole or in
                part) the services of our internet service provider or other
                suppliers. 
              </p>
              <p>
                2. We reserve the right, but have no obligation, to monitor the
                materials posted on the Platform. Amealio shall have the right
                to remove or edit any content that in its sole discretion
                violates, or is alleged to violate, any applicable law or either
                the spirit or letter of these Terms of Use. Notwithstanding this
                right, you remain solely responsible for the content of the
                materials you post on the platform and in your private messages.
              </p>
              <p>
                3. You hereby represent and warrant that you have all necessary
                rights in and to all Content which you provide and all
                information it contains and that such Content shall not infringe
                any proprietary or other rights of third parties or contain any
                libelous, tortious, or otherwise unlawful information. 
              </p>
              <p>
                4. In order to allow us to use the information supplied by you,
                without violating your rights or any laws, you agree to grant us
                a non-exclusive, worldwide, perpetual, irrevocable,
                royalty-free, sub-licensable (through multiple tiers) right to
                exercise the copyright, publicity, database rights or any other
                rights you have in your Information, in any media now known or
                not currently known, with respect to your Information. We will
                only use your information in accordance with these Terms of Use
                and Privacy Policy applicable to use of the Platform.
              </p>
              <p>5.Third Party Content</p>
              <p>
                a.) We cannot and will not assure that other users are or will
                be complying with the foregoing rules or any other provisions of
                these Terms of Use, and, as between you and us, you hereby
                assume all risk of harm or injury resulting from any such lack
                of compliance. 
              </p>
              <p>
                b.) You acknowledge that when you access a link that leaves the
                Platform, the site you will enter into is not controlled by us
                and different terms of use and privacy policy may apply. By
                assessing links to other sites, you acknowledge that we are not
                responsible for those sites. We reserve the right to disable
                links to and / or from third-party sites to the Platform,
                although we are under no obligation to do so.
              </p>
              <p>
                <b>Donations:</b> <br />
                1. The User may out of his free will and discretion pay certain
                amount (not exceeding Rs. 50/- (Rupees Fifty only) per Order /
                Booking) towards Donations while placing an Order / Booking
                through the Amealio Platform. Any amount received towards
                donation will be transferred to M/s Envisionard Udbhav
                Foundation (a Non-Profit organization) to help undertake various
                activities for social cause and thereby create a social impact
                in India.
              </p>
              <p>
                2. The donations once made cannot be cancelled and/or is
                non-refundable, even in case of cancellation of orders/bookings
                by the User.
              </p>
              <p>
                3. No certificate under section 80G of the Income Tax Act, 1961
                will be issued to the User.
              </p>
              <p>
                4. By proceeding to donate the amount, the User confirms that he
                has chosen to contribute the amount for social cause voluntarily
                and at his own risk and liability and expressly waives all
                claims, rights of action and/or remedies (under law or
                otherwise) that User may have against Amealio and/or Udbhav
                Foundation arising out of or in connection with the contribution
                made.
              </p>
              <p>
                5. The User confirms that he / she is a resident Indian and the
                amount contributed towards Donation is not from any foreign
                source as defined under Foreign Contribution Regulation Act,
                2010.
              </p>
              <p>
                <b>Disclaimers:</b>
              </p>
              <p>
                a.) The Platform may be under constant upgrades, and some
                functions and features may not be fully operational. 
              </p>
              <p>
                b.) Due to the vagaries that can occur in the electronic
                distribution of information and due to the limitations inherent
                in providing information obtained from multiple sources, there
                may be delays, omissions, or inaccuracies in the content
                provided on the platform or delay or errors in functionality of
                the Platform. As a result, we do not represent that the
                information posted is correct in every case. 
              </p>
              <p>
                c.) We expressly disclaim all liabilities that may arise as a
                consequence of any unauthorized use of credit/ debit cards. 
              </p>
              <p>
                d.) You acknowledge that third party services are available on
                the Platform. We may have formed partnerships or alliances with
                some of these third parties from time to time in order to
                facilitate the provision of certain services to you. However,
                you acknowledge and agree that at no time are we making any
                representation or warranty regarding any third party's services
                nor will we be liable to you or any third party for any
                consequences or claims arising from or in connection with such
                third party including, and not limited to, any liability or
                responsibility for, death, injury or impairment experienced by
                you or any third party. You hereby disclaim and waive any rights
                and claims you may have against us with respect to third party's
                / merchant’s services. 
              </p>
              <p>
                e.) Amealio disclaims any and all liability that may arise due
                to any violation of the Food Safety and Standards Act, 2006 and
                applicable rules and regulations made thereunder and such
                liability shall be attributable to the Merchant. 
              </p>
              <p>
                f.) While the materials provided on the Platform were prepared
                to provide accurate information regarding the subject discussed,
                the information contained in these materials is being made
                available with the understanding that we make no guarantees,
                representations or warranties whatsoever, whether expressed or
                implied, with respect to professional qualifications, expertise,
                quality of work or other information herein. Further, we do not,
                in any way, endorse any service offered or described herein. In
                no event shall we be liable to you or any third party for any
                decision made or action taken in reliance on such information. 
              </p>
              <p>
                g.) The information provided hereunder is provided "as is". We
                and/ or our employees make no warranty or representation
                regarding the timeliness, content, sequence, accuracy,
                effectiveness or completeness of any information or data
                furnished hereunder or that the information or data provided
                hereunder may be relied upon. Multiple responses may usually be
                made available from different sources and it is left to the
                judgement of users based on their specific circumstances to use,
                adapt, modify or alter suggestions or use them in conjunction
                with any other sources they may have, thereby absolving us as
                well as our consultants, business associates, affiliates,
                business partners and employees from any kind of professional
                liability. 
              </p>
              <p>
                h.) We shall not be liable to you or anyone else for any losses
                or injury arising out of or relating to the information provided
                on the Platform. In no event will we or our employees,
                affiliates, authors or agents be liable to you or any third
                party for any decision made or action taken by your reliance on
                the content contained herein. 
              </p>
              <p>
                i.) In no event will we be liable for any damages (including,
                without limitation, direct, indirect, incidental, special,
                consequential or exemplary damages, damages arising from
                personal injury/wrongful death, and damages resulting from lost
                profits, lost data or business interruption), resulting from any
                services provided by any third party or merchant accessed
                through the Platform, whether based on warranty, contract, tort,
                or any other legal theory and whether or not we are advised of
                the possibility of such damages.
              </p>
              <p>
                <b>Termination of User Account</b>
              </p>
              <p>
                i.) Amealio reserves the right to terminate / suspend the
                Customer's account in its sole discretion if the Customer has
                violated or appears to be in violations of the rules of the
                Platform and/or is/has been involved in activities that are in
                direct or indirect contravention of the Amealio terms and
                conditions and other policies or is/has engaged in activities
                which are fraudulent / unlawful in nature. 
              </p>
              <p>
                ii.) You shall not use the Platform or any content on the
                Platform for any purpose that is unlawful or prohibited by these
                Terms of Use or by the any laws of the country you are in, or to
                solicit the performance of any illegal activity or other
                activity that infringes the rights of Amealio and/or others. 
              </p>
              <p>
                iii.) We reserve the right to suspend or terminate
                your Amealio Account and your access to the Services (i) if any
                information provided during the registration process or
                thereafter proves to be inaccurate, not current or incomplete;
                (ii) if it is believed that your actions may cause legal
                liability for you, other users or us; and/or (iii) if you are
                found to be non- compliant with the Terms of Use. 
              </p>
              <p>
                iv.) You shall not make any negative, denigrating or defamatory
                statement(s) or comment(s) about us or the brand name or domain
                name used by us including the name 'Amealio', or otherwise
                engage in any conduct or action that might tarnish the image or
                reputation, of Amealio or Merchant on platform or otherwise
                tarnish or dilute any Amealio's trade or service marks, trade
                name and/or goodwill associated with such trade or service
                marks, as may be owned or used by us.
              </p>
              <p>
                <b>Intellectual property</b> <br />
                a.) We are either the owner of intellectual property rights or
                have the non-exclusive, worldwide, perpetual, irrevocable,
                royalty free, sub-licensable (through multiple tiers) right to
                exercise the intellectual property, in the Platform, and in the
                material published on it.
              </p>
              <p>
                b.) You may print off one copy, and may download extracts, of
                any page(s) from the Platform for your personal reference and
                you may draw the attention of others within your organization to
                material available on the Platform.
              </p>
              <p>
                c.) You must not modify the paper or digital copies of any
                materials you have printed off or downloaded in any way, and you
                must not use any illustrations, photographs, video or audio
                sequences or any graphics separately from any accompanying text.
              </p>
              <p>
                b.) You must not use any part of the materials on the Platform
                for commercial purposes without obtaining a license to do so
                from us or our licensors.
              </p>
              <p>
                e.) If you print off, copy or download any part of the Platform
                in breach of these Terms of Use, your right to use the Platform
                will cease immediately and you must, at our option, return or
                destroy any copies of the materials you have made.
              </p>
              <p>
                <b>Severability</b> <br />
                If any of these Terms of Use should be determined to be illegal,
                invalid or otherwise unenforceable by reason of the laws of any
                state or country in which these Terms of Use are intended to be
                effective, then to the extent and within the jurisdiction where
                that term is illegal, invalid or unenforceable, it shall be
                severed and deleted and the remaining Terms of Use shall
                survive, remain in full force and effect and continue to be
                binding and enforceable.
              </p>
              <p>
                <b>Governing law and dispute resolution</b> <br />
                These Terms of Use are governed by the laws of India. Any
                action, suit, or other legal proceeding, which is commenced to
                resolve any matter arising under or relating to this Platform,
                shall be subject to the jurisdiction of the courts at Mumbai,
                India.
              </p>
              <p>
                <b>Contact Us</b> <br />
                Please contact us at support@amealio.com for any questions or
                comments (including all inquiries unrelated to copyright
                infringement) regarding this Platform.
              </p>
              <p>
                <b>Grievance Officer</b> <br />
                In accordance with Information Technology Act 2000 and rules
                made there under, the name and contact details of the Grievance
                Officer are provided below:
              </p>
              <p>
                Mr. Raghavendra Mamidipalli <br />
                Envisionard Ads and Marketing Private Limited <br />
                T-Hub, IIT Campus, Gachibowli <br />
                Hyderabad, Telangana – 500032 India <br />
                Phone: +91-9490858089 <br />
              </p>
              <p>Email: grievances@amealio.com</p>
              <p>Time: Mon – Sat (9:00 – 18:00)</p>
            </div>
          </div>
        </>
      </section>
      <WalletTerms />
      <Footer />
    </div>
  );
};

export default Terms;
