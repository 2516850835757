import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import axios from "axios";
import HomePageSliderOne from "./HomePageSlider/homePageSliderOne";

const TopMainSlider = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 11000,
    pauseOnHover: true,
    // cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    fade: true,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          infinite: true,
          fade: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          infinite: true,
          fade: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          infinite: true,
          fade: false,
        },
      },
    ],
  };

  const [Check, Uncheck] = useState(true);

  const [mobileNumber, setMobileNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const changeMeassgeClick = () => {
    let numbers = /^[0-9]+$/;
    if (mobileNumber.length <= 9) {
      alert("Mobile number is not correct, Please check again");
    } else {
      if (mobileNumber.match(numbers)) {
        setLoading(true);
        let formData = {
          mobile_number: mobileNumber,
        };
        axios
          .post(`https://merchantapi.amealio.com/non-user-sms`, formData)
          .then((res) => {
            console.log(res);
            if (res.status === 201) {
              setLoading(false);
              Uncheck(false);
              setMobileNumber("");
            }
          })
          .catch((err) => {
            setLoading(false);
            alert(`<h4>${err}</h4>`);
          });
      } else {
        alert("Mobile number is not correct, Please check again");
      }
    }
  };

  const closedMessage = () => {
    Uncheck(true);
    setMobileNumber("");
  };

  /*********** Download Now Action ************/
  const onClickRedirectPage = () => {
    window.open("https://amealio.page.link/oHnQ88HYijeUaXiPA", "_blank");
  };

  const onClickStartYourJourney = () => {
    navigate(`/${props.lang}/demo`);
  };

  const onClickContactUs = () => {
    navigate(`/${props.lang}/help`);
  };

  return (
    <div className="top-main-slider">
      <Slider {...settings}>
        <div>
          <div className="container ">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 slider-home-page-one d-block d-sm-block d-md-none">
                <div className="features-right-section-image">
                  <img
                    src="../assets/images/amealio/svg/amealioTheAmazon.png"
                    alt="Enjoy Celebrate"
                    title="Enjoy Celebrate"
                    className="img-fluid"
                  />
                  <HomePageSliderOne />
                  <div className="download-button-css">
                    <button
                      className="btn button-design"
                      type="button"
                      title="Pre-Book Now"
                      onClick={onClickRedirectPage}
                    >
                      Pre-Book Now
                    </button>
                  </div>
                  <div
                    className="f18-h6 mb-1 text-center"
                    style={{ color: "#EEB84E" }}
                  >
                    {t("launch")}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-8 top-align-center slider-home-page-left-one">
                <h2 className="f50-h2 mb-4 mt-0 bg-white bg-sm-white bg-md-dark">
                  Market place for <b>Incredible</b>
                  <br />
                  <b>Food </b>Experiences.
                  {/* <b
                    className="d-none d-sm-none d-md-block"
                    style={{
                      color: t("language") === "us" ? "#212121" : "#212121",
                    }}
                  >
                    {t("welcome_to_reactOne")}
                    <br />
                    {t("welcome_to_reactFour")}
                  </b>
                  <b
                    className="d-block d-sm-block d-md-none"
                    style={{
                      color: t("language") === "us" ? "#EEB84E" : "#FFFFFF",
                    }}
                  >
                    {t("welcome_to_reactTwo")}
                    <br />
                    {t("welcome_to_reactThree")}
                    <br />
                    {t("welcome_to_reactFour")}
                  </b> */}
                </h2>
                {/* <h3 className="f24-h5 mb-4 px-4 px-sm-4 px-md-0 bg-white bg-sm-white bg-md-dark">
                  <b className="d-none d-sm-none d-md-block">
                    A platform that makes eating out an enjoyable and
                    <br />
                    happy experience.
                  </b>
                  <b
                    className="d-block d-sm-block d-md-none"
                    style={{ fontSize: "20px" }}
                  >
                    A platform that makes
                    <br />
                    eating out an enjoyable and
                    <br />
                    happy experience.
                  </b>
                  </h3> */}
                <p className="f16-p3 mb-4 pb-4 bg-white bg-sm-white bg-md-dark paragraph-width-home">
                  Forget spending hours on planning for special occasion or get
                  togethers. Instead, pick curated options, to pre-arrange and
                  personalize those occasions.
                </p>
                {Check ? (
                  <div className="check h-p-s-o-padding">
                    <div className="toptitle">
                      <h4 className="f18-p2" style={{ color: "#EEB84E" }}>
                        <b>Send Downlaod Link</b>
                      </h4>
                    </div>
                    <form action="#">
                      <div className="user-details">
                        <div className="input">
                          <div className="numSec register-width-style">
                            <p className="already-add-number">+91</p>
                            <input
                              type="text"
                              maxLength="10"
                              pattern="\d{10}"
                              title="Please enter exactly 10 digits"
                              placeholder="Phone Number"
                              onChange={(e) => onChange(e)}
                              className="px-3"
                              required
                            />
                            <i
                              className="fa fa-chevron-right arrow"
                              style={{ cursor: "pointer" }}
                              aria-hidden="true"
                              onClick={changeMeassgeClick}
                            ></i>
                          </div>
                          <div className="checkout-below">
                            {loading ? <h6>Please wait...</h6> : null}
                            <div className="checkoutlogo">
                              <div className="bottomicon-home w-100 w-sm-100 w-md-50 mb-4 mb-sm-4 mb-md-0">
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.envisionard.amealio"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src="../assets/images/amealio/svg/googleplay.png"
                                    alt="get it on Google Play"
                                    title="get it on Google Play"
                                    className="img-fluid icon-play-app-store"
                                  />
                                </a>
                                <a
                                  href="https://apps.apple.com/in/app/amealio/id1516233942"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src="../assets/images/amealio/svg/appleAppStoreIcon.png"
                                    alt="get it on Apple Store"
                                    title="get it on Apple Store"
                                    className="img-fluid icon-play-app-store"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="message-sent__box h-p-s-o-padding">
                    <div className="message p-0">
                      <i
                        className="fa fa-times checkout-close message-sent__close"
                        aria-hidden="true"
                        onClick={closedMessage}
                      ></i>
                      <div className="message-sent__title">
                        <h4>
                          <i
                            className="fa fa-check-circle message-sent__circle"
                            aria-hidden="true"
                          ></i>
                          Message Sent
                        </h4>
                      </div>
                      <div className="message-sent__subtitle">
                        <p>
                          The Message has been successfully sent to the
                          registered mobile number {mobileNumber}
                        </p>
                        <p>
                          Kindly Check on the given link in the SMS to enjoy the
                          features of Amealio App.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-12 col-sm-12 col-md-4 slider-home-page-one d-none d-sm-none d-md-block">
                <div className="features-right-section-image">
                  <div
                    className="f18-h6 mb-1 text-center"
                    style={{ color: "#EEB84E" }}
                  >
                    {t("launch")}
                  </div>
                  <img
                    src="../assets/images/amealio/svg/amealioTheAmazon.png"
                    alt="Enjoy Celebrate"
                    title="Enjoy Celebrate"
                    className="img-fluid"
                  />
                  <HomePageSliderOne />
                  <div className="download-button-css">
                    <button
                      className="btn button-design"
                      type="button"
                      title="Pre-Book Now"
                      onClick={onClickRedirectPage}
                    >
                      Pre-Book Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center mt-4 mt-sm-4 mt-md-0">
                <div className="position-of-mouse position-static">
                  <div id="scrolling-mouse"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 slider-home-page-one d-block d-sm-block d-md-none">
                <div className="features-right-section-image">
                  <img
                    src="../assets/images/amealio/svg/amealioTheAmazon.png"
                    alt="Enjoy Celebrate"
                    title="Enjoy Celebrate"
                    className="img-fluid"
                  />
                  <div className="slider-second-image-resize">
                    <img
                      src="../assets/images/amealio/svg/superApp.png"
                      alt="Enjoy Celebrate"
                      title="Enjoy Celebrate"
                      className="img-fluid"
                    />
                  </div>
                  <div
                    className="f18-h6 mb-1 text-center"
                    style={{ color: "#EEB84E" }}
                  >
                    {t("launch")}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-8 top-align-center slider-home-page-left-one">
                <h2 className="f50-h2 mb-4 mt-0">
                  Simplify the way you think
                  <br />
                  about Food <b>Everyday</b>
                </h2>
                <div className="home-second-slider">
                  <p className="f16-p3 mb-4">
                    Select, pre-set, and pre-order your daily conveniences from
                    local restaurants with options like Dine-In, Pre-Order,
                    Curbside, Skip-The-Line, Take Away and personalize to your
                    taste.
                  </p>
                </div>
                <div className="col-12 col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-sm-center justify-content-md-between">
                  <button
                    className="btn top-main-button-one top-main-button-one-red h-s-two-css extramargin-mobile"
                    onClick={onClickStartYourJourney}
                    type="button"
                    title="Start"
                  >
                    Start
                  </button>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 slider-home-page-one d-none d-sm-none d-md-block">
                <div className="features-right-section-image">
                  <div
                    className="f18-h6 mb-1 text-center"
                    style={{ color: "#EEB84E" }}
                  >
                    {t("launch")}
                  </div>
                  <img
                    src="../assets/images/amealio/svg/amealioTheAmazon.png"
                    alt="Enjoy Celebrate"
                    title="Enjoy Celebrate"
                    className="img-fluid"
                  />
                  <div className="slider-second-image-resize">
                    <img
                      src="../assets/images/amealio/svg/superApp.png"
                      alt="Enjoy Celebrate"
                      title="Enjoy Celebrate"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center mt-4 mt-sm-4 mt-md-0">
                <div className="position-of-mouse position-static">
                  <div id="scrolling-mouse"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 slider-home-page-one d-block d-sm-block d-md-none">
                <div className="features-right-section-image">
                  <img
                    src="../assets/images/amealio/svg/amealioTheAmazon.png"
                    alt="Enjoy Celebrate"
                    title="Enjoy Celebrate"
                    className="img-fluid"
                  />
                  <div className="slider-third-image-resize">
                    <img
                      src="../assets/images/amealio/svg/amealioVerseSlide2.png"
                      alt="Enjoy Celebrate"
                      title="Enjoy Celebrate"
                      className="img-fluid"
                    />
                  </div>
                  <div
                    className="f18-h6 mb-1 text-center"
                    style={{ color: "#EEB84E" }}
                  >
                    {t("launch")}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-8 top-align-center slider-home-page-left-one">
                <h2 className="f50-h2 mb-4 mt-0">
                  A New frontier of <b>Social</b>
                  <br />
                  Experiences over Food.
                </h2>
                <p className="f20-p mb-5 pb-4">
                  Arranging group hang out, or a business meeting over
                  <br />
                  food have never been easy. Connect with people, have
                  <br />
                  meaningful conversations over food and create
                  <br />
                  memories with amealio.
                </p>
                <div className="col-12 col-sm-12 col-md-7 d-block d-sm-block d-md-flex align-items-center justify-content-between px-0 enjoy-bottom-button-height">
                  <button
                    className="btn top-main-button-one top-main-button-one-black mr-5"
                    onClick={onClickContactUs}
                    type="button"
                    title="Connect"
                  >
                    Connect
                  </button>
                  <button
                    className="btn top-main-button-one top-main-button-one-red mt-4 mt-sm-4 mt-md-0"
                    onClick={onClickStartYourJourney}
                    type="button"
                    title="Start"
                  >
                    Start
                  </button>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 slider-home-page-one d-none d-sm-none d-md-block">
                <div className="features-right-section-image">
                  <div
                    className="f18-h6 mb-1 text-center"
                    style={{ color: "#EEB84E" }}
                  >
                    {t("launch")}
                  </div>
                  <img
                    src="../assets/images/amealio/svg/amealioTheAmazon.png"
                    alt="Enjoy Celebrate"
                    title="Enjoy Celebrate"
                    className="img-fluid"
                  />
                  <div className="slider-third-image-resize">
                    <img
                      src="../assets/images/amealio/svg/amealioVerseSlide2.png"
                      alt="Enjoy Celebrate"
                      title="Enjoy Celebrate"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center mt-4 mt-sm-4 mt-md-0">
                <div className="position-of-mouse position-static">
                  <div id="scrolling-mouse"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 slider-home-page-one d-block d-sm-block d-md-none">
                <div className="features-right-section-image">
                  <img
                    src="../assets/images/amealio/svg/amealioTheAmazon.png"
                    alt="Enjoy Celebrate"
                    title="Enjoy Celebrate"
                    className="img-fluid"
                  />
                  <div className="slider-third-image-resize">
                    <img
                      src="../assets/images/amealio/svg/enjoyCelebrate2.png"
                      alt="Enjoy Celebrate"
                      title="Enjoy Celebrate"
                      className="img-fluid"
                    />
                  </div>
                  <div
                    className="f18-h6 mb-1 text-center"
                    style={{ color: "#EEB84E" }}
                  >
                    {t("launch")}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-8 top-align-center slider-home-page-left-one">
                <h2 className="f50-h2 mb-4 mt-0">
                  <b>
                    Enjoy, Celebrate
                    <br />& Socialize with Food
                  </b>
                </h2>
                <p className="f20-p mb-5 pb-4">
                  We all know that going out with friends, family, on a date
                  <br />
                  or a business meeting always starts with a drink and ends
                  <br />
                  with dessert, as food is an integral part of our everyday
                  <br />
                  affairs including social outings.
                </p>
                <div className="col-12 col-sm-12 col-md-7 d-block d-sm-block d-md-flex align-items-center justify-content-between px-0 enjoy-bottom-button-height">
                  <button
                    className="btn top-main-button-one top-main-button-one-black mr-5"
                    onClick={onClickContactUs}
                    type="button"
                    title="Connect"
                  >
                    Connect
                  </button>
                  <button
                    className="btn top-main-button-one top-main-button-one-red mt-4 mt-sm-4 mt-md-0"
                    onClick={onClickStartYourJourney}
                    type="button"
                    title="Start"
                  >
                    Start
                  </button>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 slider-home-page-one d-none d-sm-none d-md-block">
                <div className="features-right-section-image">
                  <div
                    className="f18-h6 mb-1 text-center"
                    style={{ color: "#EEB84E" }}
                  >
                    {t("launch")}
                  </div>
                  <img
                    src="../assets/images/amealio/svg/amealioTheAmazon.png"
                    alt="Enjoy Celebrate"
                    title="Enjoy Celebrate"
                    className="img-fluid"
                  />
                  <div className="slider-third-image-resize">
                    <img
                      src="../assets/images/amealio/svg/enjoyCelebrate2.png"
                      alt="Enjoy Celebrate"
                      title="Enjoy Celebrate"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center mt-4 mt-sm-4 mt-md-0">
                <div className="position-of-mouse position-static">
                  <div id="scrolling-mouse"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default TopMainSlider;
