import React, { useState, useEffect } from "react";

import Fastfoodbanner from "./fastfoodbanner";
import Categoriesbelt from "./categoriesbelt";
import Commonfeature from "./commonfeature";
import Signupfeature from "./signupfeatures";
import Testimonial from "./testimonial";
import Features from "./features";
// import Header from "../templates/header";
import Subfeatures from "./subfeatures";
import Footer from "../templates/footer";
import fastFoodData from "../fastfood/fastfoodData";

const Fastfood = (props) => {
  const [loader, setloader] = useState(false);
  useEffect(() => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 2000);
  }, [props.pagetype]);

  const categoriesData = fastFoodData.list.find(
    (data) => data.title === props.pagetype
  );

  return (
    <div className="App">
      {loader ? (
        <div className="loader">
          <img src="../assets/images/loader.gif" alt="Loader" title="Loader" />
        </div>
      ) : null}
      <Fastfoodbanner data={categoriesData} lang={props.lang} />
      <Categoriesbelt data={categoriesData} />
      <hr></hr>
      <Features data={categoriesData} lang={props.lang} />
      <Subfeatures data={categoriesData} />
      <Testimonial data={categoriesData} />
      <Signupfeature lang={props.lang} />
      <Commonfeature lang={props.lang} />
      <Footer />
    </div>
  );
};

export default Fastfood;
