import React from "react";
import { NavLink } from "react-router-dom";
const businessoutlet = (props) => {
  return (
    <section>
      <div className="container our-business-outlest">
        <h2 className="f42-h3 text-center mb-5">
          <b>Our Business Outlets</b>
        </h2>
        <div className="row">
          {/* col-md-3 col-sm-6 col-xs-6 */}
          <div className="col-6 col-sm-6 col-md-3">
            <NavLink to={`/${props.lang}/fastfood`}>
              <div className="card businessoutlet-card">
                <div className="businessoutlet-center">
                  <button className="btn businessoutlet-btn">
                    <img
                      src="../assets/images/business/fastfood.png"
                      className="buss-area busshotel-black"
                      alt="Fast Food"
                      title="Fast Food"
                    ></img>
                    <img
                      src="../assets/images/business/fastfoodwhite.png"
                      className="buss-area busshotel-white"
                      alt="Fast Food White"
                      title="Fast Food White"
                    ></img>
                  </button>
                </div>
                <span className="businessoulet-span f16-p3">Fast Food</span>
              </div>
            </NavLink>
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            <NavLink to={`/${props.lang}/fastcasual`}>
              <div className="card businessoutlet-card">
                <div className="businessoutlet-center">
                  <button className="btn businessoutlet-btn">
                    <img
                      src="../assets/images/business/fastcasual.png"
                      className="buss-area busshotel-black buss-asses2"
                      alt="Fast Casual"
                      title="Fast Casual"
                    ></img>
                    <img
                      src="../assets/images/business/fastcasualwhite.png"
                      className="buss-area busshotel-white buss-asses2"
                      alt="Fast Casual White"
                      title="Fast Casual White"
                    ></img>
                  </button>
                </div>
                <span className="businessoulet-span f16-p3">Fast Casual</span>
              </div>
            </NavLink>
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            <NavLink to={`/${props.lang}/finedining`}>
              <div className="card businessoutlet-card">
                <div className="businessoutlet-center">
                  <button className="btn businessoutlet-btn">
                    <img
                      src="../assets/images/business/finedining.png"
                      className="buss-area busshotel-black buss-asses"
                      alt="Fine Dining"
                      title="Fine Dining"
                    ></img>
                    <img
                      src="../assets/images/business/finediningwhite.png"
                      className="buss-area busshotel-white buss-asses"
                      alt="Fine Dining White"
                      title="Fine Dining White"
                    ></img>
                  </button>
                </div>
                <span className="businessoulet-span f16-p3">Fine Dining</span>
              </div>
            </NavLink>
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            <NavLink to={`/${props.lang}/casualdining`}>
              <div className="card businessoutlet-card">
                <div className="businessoutlet-center">
                  <button className="btn businessoutlet-btn">
                    <img
                      src="../assets/images/business/casualdining.png"
                      className="buss-area busshotel-black buss-asses"
                      alt="Casual Dining"
                      title="Casual Dining"
                    ></img>
                    <img
                      src="../assets/images/business/casualdiningwhite.png"
                      className="buss-area busshotel-white buss-asses"
                      alt="Casual Dining White"
                      title="Casual Dining White"
                    ></img>
                  </button>
                </div>
                <span className="businessoulet-span f16-p3">Casual Dining</span>
              </div>
            </NavLink>
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            <NavLink to={`/${props.lang}/barsandclubs`}>
              <div className="card businessoutlet-card">
                <div className="businessoutlet-center">
                  <button className="btn businessoutlet-btn">
                    <img
                      src="../assets/images/business/Bars.png"
                      className="buss-area busshotel-black"
                      alt="Bars and Clubs"
                      title="Bars and Clubs"
                    ></img>
                    <img
                      src="../assets/images/business/barswhite.png"
                      className="buss-area busshotel-white"
                      alt="Bars and Clubs White"
                      title="Bars and Clubs White"
                    ></img>
                  </button>
                </div>
                <span className="businessoulet-span f16-p3">
                  Bars and Clubs
                </span>
              </div>
            </NavLink>
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            {/* <NavLink to={`/${props.lang}/institutional`}> */}
            <div className="card businessoutlet-card">
              <div className="businessoutlet-center">
                <button className="btn businessoutlet-btn">
                  <img
                    src="../assets/images/business/Institutional.png"
                    className="buss-area busshotel-black buss-asses1"
                    alt="Institutional"
                    title="Institutional"
                  ></img>
                  <img
                    src="../assets/images/business/Institutional-white.png"
                    className="buss-area busshotel-white buss-asses1"
                    alt="Institutional White"
                    title="Institutional White"
                  ></img>
                </button>
              </div>
              <span className="businessoulet-span f16-p3">Institutional</span>
            </div>
            {/* </NavLink> */}
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            <NavLink to={`/${props.lang}/hotelsandresorts`}>
              <div className="card businessoutlet-card">
                <div className="businessoutlet-center">
                  <button className="btn businessoutlet-btn">
                    <img
                      src="../assets/images/business/Hotels.png"
                      className="buss-area busshotel-black"
                      alt="Hotels"
                      title="Hotels"
                    ></img>
                    <img
                      src="../assets/images/business/hotelswhite.png"
                      className="buss-area busshotel-white"
                      alt="Hotels White"
                      title="Hotels White"
                    ></img>
                  </button>
                </div>
                <span className="businessoulet-span f16-p3">
                  Hotels and Resorts
                </span>
              </div>
            </NavLink>
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            {/* <NavLink to={`/${props.lang}/driveindrivethru`}> */}
            <div className="card businessoutlet-card">
              <div className="businessoutlet-center">
                <button className="btn businessoutlet-btn">
                  <img
                    src="../assets/images/business/drivethru.png"
                    className="buss-area busshotel-black"
                    alt="Drive Thru"
                    title="Drive Thru"
                  ></img>
                  <img
                    src="../assets/images/business/drivethruwhite.png"
                    className="buss-area busshotel-white"
                    alt="Drive Thru White"
                    title="Drive Thru White"
                  ></img>
                </button>
              </div>
              <span className="businessoulet-span f16-p3">Drive Thru</span>
            </div>
            {/* </NavLink> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default businessoutlet;
