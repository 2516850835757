import React from "react";
const Exploreheader = () => {
  return (
    <section className="explore-section">
      <div className="container">
        <div className="explore-menu">
          <div className="explore-fronttitle">
            <h1 className="f42-h3 text-center">
              <b>Explore, Experience and Eat with our Smart Features</b>
            </h1>
          </div>
          <div className="row explore-firstblock">
            <div className="col-md-6 explore-content1">
              <h2 className="f24-h5">Use Voice to Explore the Menu</h2>
              <p className="f16-p3">
                Discover explore and indulge your cravings with
                <br />
                your voice. Tell Amealia what you want and make
                <br />
                the perfect experience happen.
              </p>
            </div>
            <div className="col-md-6 explore-content2">
              <img
                src="../assets/images/explore/group68585.png"
                alt=""
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Exploreheader;
