const fastFoodData = {
  type: "FeatureCollection",
  list: [
    {
      title: "Fast Casual",
      data: [
        {
          title: "Fast Casual Eatery",
          image: "pizza",
        },
        {
          title: "Bistro",
          image: "bistro",
        },
        {
          title: "Café",
          image: "coffee-shop",
        },
        {
          title: "QSR",
          image: "bed-and-breakfast (1)",
        },

        {
          title: "Food Court",
          image: "food-court",
        },
      ],
      categoryBeltHeading: "Fast Casual Diners Categories Under Our Belt ",
      featureHeading: "Our Merchant-Centric Features",
      bannerImage: "fast-casual.png",
      bannerButtonName: "Register Now",
      bannerHeading: "Get Notified for Exciting Offers and Events",
      bannerSubHeading: "Sign up and elevate your eating experience forever!",
      features: ["Waitlist", "Reservation", "Take Away", "Dine In"],
      subfeatures: [
        "Delivery",
        "Curbside",
        "Offers Management",
        "Scan and Pay",
        "Catering and Banquet Services",
        "Manage Staff and Licences",
        "Event Management",
        "Live Streaming",
        "Menu Setup",
        "Voice Service Enabled",
      ],
      testimonialContent1:
        "Technology has always been difficult for me. But Amealio has made my business smart, popular and profitable easily.",
      testimonialContent2:
        "Amealio made managing orders and monitoring the processes organized and easy. Automated processes and analytics reports have helped me understand my progress.",
    },
    {
      title: "Fast Food",
      data: [
        {
          title: "Concept Kiosks",
          image: "kiosk",
        },
        {
          title: "Specialty Counters",
          image: "bar-counter",
        },
        {
          title: "Bakery and Confectionary",
          image: "bread",
        },
        {
          title: "Dessert Parlour",
          image: "dessert",
        },
        {
          title: "Sweet Mart",
          image: "sweets",
        },

        {
          title: "Ice-cream Parlour",
          image: "ice-cream",
        },

        {
          title: "Concept Fast Food Store",
          image: "fast-food",
        },
        {
          title: "Chaat Store",
          image: "chaat",
        },
        {
          title: "Juice Bar / Salad Bar",
          image: "beverage",
        },
        {
          title: "Tea House",
          image: "coffee-cup",
        },
        {
          title: "Home Chefs / Home Bakers",
          image: "house",
        },
        {
          title: "Food Truck",
          image: "food-truck",
        },
        {
          title: "Commute Vehhicle (Bus / Train /Air)",
          image: "car",
        },
      ],
      categoryBeltHeading: "Fast Food Categories Under our Belt",
      featureHeading: "Our Merchant-Centric Features",
      bannerImage: "fast-food&Others.png",
      // bannerHeading: 'Make your establishment the talk of the town.',
      // bannerSubHeading: 'Explore top-rated fast-food joints in your city. from big chains to your favoite street food corners',
      bannerButtonName: "Get Onboarded",
      bannerHeading: "Become our Food Partner, and Be the Talk of the Town",
      bannerSubHeading:
        "Reap the benefits of a famous establishment- by partnering with us. Let your food and our services do the talking.",
      features: ["Take Away", "Skip the Line", "At my Seat", "Delivery"],
      subfeatures: [
        "Voice Service Enabled",
        "Live Streaming",
        "Menu Setup",
        "Scan and Pay",
        "Catering and Banquet Services",
        "Manage Staff and Licences",
      ],
      testimonialContent1:
        "Apart from engaging new customers every day, Amealio has made my business running way smoother. Now my business is not just about selling food but also offering top convenience to my customers.",
      testimonialContent2:
        "Unique features like Skip the Line or curbside delivery have really made it easier for my customers. Leave it to Amealio when it comes to offer a hyper-personalized food experience to my customers. All this favors to a tremendous growth of my business.",
    },
    {
      title: "Bars and Clubs",
      categoryBeltHeading: "Bars & Clubs Under Our Belt ",
      featureHeading: "Our Merchant-Centric Features",
      data: [
        {
          title: "Small or Mid sized Bar",
          image: "small-glass",
        },
        {
          title: "Lounge Bar",
          image: "lounge",
        },
        {
          title: "Night Club",
          image: "entrance",
        },
        {
          title: "Public House",
          image: "home",
        },

        {
          title: "Micro-Brewery",
          image: "brewery",
        },
      ],
      bannerImage: "bars&clubs.png",
      bannerButtonName: "Order Now",
      bannerHeading: "Make Your Party Scenes Ice & Nice With Amealio",
      bannerSubHeading:
        "Sign up and experience our hyper personalized platform.",
      features: ["Waitlist", "Reservation", "Take Away", "Dine In"],
      subfeatures: [
        "Delivery",
        "Curbside",
        "Offers Management",
        "Scan and Pay",
        "Catering and Banquet Services",
        "Manage Staff and Licences",
        "Events Management",
        "Live Streaming",
        "Menu Setup",
        "Voice Service Enabled",
      ],
      testimonialContent1:
        "With Amealio, I feel our business has grown smarter and more connected. From onboarding to transforming my way of business, making it more convenient and unique, Amealio has been with us.",
      testimonialContent2:
        "I must say Amealio has truly transformed our business. Features like Curbside delivery has become quite the talk and made our customers feel happy and special. We have seen a 20% growth in revenue ever since Argealio.",
    },
    {
      title: "Drive-In/Driven-Thru",
      categoryBeltHeading: "Drive Restaurants Categories Under Our Belt ",
      featureHeading: "Our Merchant-Centric Features",
      data: [
        {
          title: "Drive In Restaurant",
          image: "drive-in (1)",
        },
        {
          title: "Drive Thru Restaurant",
          image: "drive-thru",
        },
      ],
      bannerImage: "drive-in.png",
      bannerHeading: "Fast Track your Order via Drive Thru",
      bannerButtonName: "Start Your Journey",
      bannerSubHeading:
        "Get. Set. Ameliorate. Your food orders on the Go. Literally!",
      features: ["Waitlist", "Reservation", "Dine In", "Curbside"],
      subfeatures: [
        "Manage Staff and Licences",
        "Events Management",
        "Menu Setup",
        "Scan and Pay",
        "Voice Service Enabled",
        "Live Streaming",
      ],
      testimonialContent1:
        "With Amealio my business is all ramped up to engage new customers. With Drive-in/ Drive-thru features, I get more ways to offer my services to my customers. Thanks to Amealio, I can run my business smarter and smoother.",
      testimonialContent2:
        "Features like Drive-in/Drive thru have created quite a buzz for my business among my customers. I am so glad that Amealio has all these unique features that have opened new dimensions to gain more customers for my business.",
    },
    {
      title: "Casual Dining",
      categoryBeltHeading: "Casual Dining Categories Under Our Belt",
      featureHeading: "Our Merchant-Centric Features",
      data: [
        {
          title: "Casual Dining",
          image: "restaurant",
        },
        {
          title: "Concept Café",
          image: "cafe",
        },
        {
          title: "Coffee House",
          image: "coffee",
        },
        {
          title: "Brasserie",
          image: "restaurant1",
        },
        {
          title: "Family style Restaurant",
          image: "dinner-table",
        },
        {
          title: "Buffet Restaurant",
          image: "buffet",
        },
      ],
      bannerImage: "casual-dining.png",
      bannerButtonName: "Get Onboarded",
      bannerHeading: "Turn Your Everyday Into a Saturday Night.",
      bannerSubHeading:
        "Sign-up to experience convenience and growth like never before.",
      features: ["Waitlist", "Reservation", "Take Away", "Dine In"],
      subfeatures: [
        "Delivery",
        "Curbside",
        "Offers Management",
        "Scan and Pay",
        "Catering and Banquet Services",
        "Manage Staff and Licences",
        "Event Management",
        "Live Streaming",
        "Menu Setup",
        "Voice Service Enabled",
      ],
      testimonialContent1:
        "Amealio is one of the best decisions I have taken for my cafe. The order management systems, reservations and waitlist has made our business easier and more successful.",
      testimonialContent2:
        "Efficient and Successful. Thats how my diner has grown ever since Amealio.",
    },
    {
      title: "Hotels and Resorts",
      categoryBeltHeading: "Hotels & Resorts Categories Under Our Belt",
      featureHeading: "Our Merchant-Centric Features",
      data: [
        {
          title: "3 Star Hotel",
          image: "three-star",
        },
        {
          title: "4 Star Hotel",
          image: "hotel",
        },
        {
          title: "5 Star Hotel",
          image: "hotel (1)",
        },
        {
          title: "Boutique Hotel",
          image: "boutique",
        },
        {
          title: "Stand-alone Hotel",
          image: "download (4)",
        },

        {
          title: "Bed and Breakfast",
          image: "bed-and-breakfast (1)",
        },

        {
          title: "Resort",
          image: "sun-bath",
        },
        {
          title: "Motel",
          image: "motel",
        },
        {
          title: "Club",
          image: "night-club",
        },
      ],
      bannerImage: "hotels&resorts.png",
      bannerButtonName: "Start Your Journey",
      bannerHeading: "Announce your Culture & Hospitality to the World",
      bannerSubHeading:
        "Join us and open the gates of new opportunities and popularity",
      features: ["Waitlist", "Reservation", "Room Service", "Dine In"],
      subfeatures: [
        "Attraction and Tour Package",
        "Menu Setup",
        "Offers Management",
        "Scan and Pay",
        "Spa and Other Service",
        "Amenities and Maintenance",
        "Event Management",
        "Live Streaming",
        "General Q&A and Information",
        "Voice Service Enabled",
      ],
      testimonialContent1:
        "Amealio has taken our customer engagement to a whole new level. The conveniences that come with it, make every one of our customers feel special.",
      testimonialContent2:
        "Customer growth has increased, business has grown smoother and successful. Thanks to Amealio, we have re-defined ourselves in the eyes of our customers.",
    },
    {
      title: "Fine Dining",
      categoryBeltHeading: "Fine Dine Categories Under Our Belt ",
      featureHeading: "Our Merchant-Centric Features",
      data: [
        {
          title: "Premium Casual Dining",
          image: "premium casual dining",
        },
        {
          title: "Fine Dine Restaurant",
          image: "fine dine restaurant",
        },
        {
          title: "Concept Restaurant",
          image: "concept restaurant",
        },
        {
          title: "Destination Restaurant",
          image: "destination restaurant",
        },

        {
          title: "Restaurant and bar",
          image: "restaurant and bar",
        },
      ],
      bannerImage: "fine--dining.png",
      bannerButtonName: "Enroll for FREE",
      bannerHeading: "Get your Establishment the Spotlight it Deserves",
      bannerSubHeading:
        "Register today to avail the benefits of an accomplished restaurant for a lifetime",
      features: ["Waitlist", "Reservation", "Take Away", "Dine In"],
      subfeatures: [
        "Delivery",
        "Curbside",
        "Offers Management",
        "Scan and Pay",
        "Catering and Banquet Services",
        "Manage Staff and Licences",
        "Event Management",
        "Live Streaming",
        "Menu setup",
        "Voice Service Enabled",
      ],
      testimonialContent1:
        "Our customers are elated and more than happy about the quick and smart services they can access. Amealio has helped retain and attract more customers just within 3 months.",
      testimonialContent2:
        "The smart waitlisting and reservations feature has made our entire booking process easier. Amealios auto-accept reservations, cancellation, booking and seating features has turned our diner over the top.",
    },
    {
      title: "Bars and Clubs",
      categoryBeltHeading: "Fast Food Categories Under Our Belt",
      featureHeading: "Our Merchant-Centric Features",
      data: [
        {
          title: "Small or Mid sized Bar",
          image: "small-glass",
        },
        {
          title: "Lounge Bar",
          image: "lounge",
        },
        {
          title: "Night Club",
          image: "entrance",
        },
        {
          title: "Public House",
          image: "home",
        },

        {
          title: "Micro-Brewery",
          image: "brewery",
        },
      ],
      bannerImage: "Bars & Clubs.png",
      bannerButtonName: "Order Now",
      bannerHeading:
        "Food can be half the fun when you grab it the amealio way!",
      bannerSubHeading:
        "Sign up and experience our hyper personalized platform.",
      features: ["Waitlist", "Reservation", "Take Away", "Dine In"],
      subfeatures: [
        "Delivery",
        "Curbside",
        "Offers Management",
        "Scan and Pay",
        "Catering and Banquet Services",
        "Manage Staff and Licences",
        "Events Management",
        "Live Streaming",
        "Menu Setup",
        "Voice Service Enabled",
      ],
      testimonialContent1:
        "With Amealio, I feel our business has grown smarter and more connected. From onboarding to transforming my way of business, making it more convenient and unique, Amealio has been with us.",
      testimonialContent2:
        "I must say Amealio has truly transformed our business. Features like Curbside delivery has become quite the talk and made our customers feel happy and special. We have seen a 20% growth in revenue ever since Amealio.",
    },
    {
      title: "Institutional",
      categoryBeltHeading: "Institutional Categories Under Our Belt ",
      featureHeading: "Our Merchant-Centric Features",
      data: [
        {
          title: "Gaming Zone",
          image: "computer-game",
        },
        {
          title: "Amusement Park",
          image: "amusement-park",
        },
        {
          title: "Cafetaria",
          image: "cafe (1)",
        },
        {
          title: "Cinema Hall",
          image: "cinema",
        },
      ],
      bannerImage: "institutional.png",
      bannerButtonName: "Book a Table now",
      bannerHeading: "Command your Way To Food Anytime, Anywhere!",
      bannerSubHeading: "Explore high-end features with voice and a click",
      features: ["Take Away", "Skip the Line", "Delivery", "Curbside"],
      subfeatures: [
        "Voice Service Enabled",
        "Live Streaming",
        "Menu Setup",
        "Scan and Pay",
        "Catering and Banquet Services",
        "Manage Staff and Licences",
      ],
      testimonialContent1:
        "Amealio has helped me widening my customer base and business presence. Doing business is now much easier and I only need to worry about how I should serve the customers, rest is taken care of by Amealio.",
      testimonialContent2:
        "I must say Amealio has truly transformed our business. Features like Skip the Line and Curbside delivers has become quite the talk and made our clotomers feel happy and special. We have seen a 20% growth in revenue ever since Ameallio.",
    },
  ],
};

export default fastFoodData;
