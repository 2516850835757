import React from "react";
import { NavLink } from "react-router-dom";
const Walkin = (props) => {
  return (
    <div className="app">
      <section>
        <div className="container">
          <div className="walkin-readmore">
            <div className="card walkin-contactfree">
              <img
                src="../assets/images/enhanced/mask-group43.png"
                alt=""
                width="100%"
              />
              <h4 className="f18-h6 heading-text">
                <b>Contact-free Walk-Ins anywhere</b>
              </h4>
              <p className="f14-p4">
                Feel like royalty at the convenience of your home
                <br />
                while you let them know that you are coming. <br />
                Simply Walk-in at the restaurant of your choice <br />
                and enjoy contactless seating!
              </p>
              <NavLink activeClassName="is-active" to="/walkin" exact>
                <div className="arw1 mt-5 pt-5 pb-5">
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  <button type="button">Read More</button>
                </div>
              </NavLink>
            </div>
            <div className="card walkin-waitlist">
              <img src="../assets/images/enhanced/mask-group44.png" alt="" />
              <h4 className="f18-h6 heading-text">
                <b>
                  Waitlisting made easy.
                  <br />
                  Get notified & time yourself.
                </b>
              </h4>
              <p className="f14-p4">
                Is the restaurant busy? Put yourself on a waitlist <br />
                from your home even if you are late or have <br />
                arrived early. All of this without the blues! <br />
                Manage your delays and get notified.
              </p>
              <NavLink activeClassName="is-active" to="/waitlist" exact>
                <div className="arw pb-5">
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  <button type="button">Read More</button>
                </div>
              </NavLink>
            </div>
            <div className="card walkin-reserve">
              <img src="../assets/images/enhanced/mask-group45.png" alt="" />
              <h4 className="f18-h6 heading-text">
                <b>
                  With advance Reservations, make <br />
                  every moment special
                </b>
              </h4>
              <p className="f14-p4">
                Be it a date or a birthday, plan ahead for special <br />
                days. Need special accommodation like a high <br />
                chair or handicap access? We've got you <br /> covered. Our
                partners will take requests for any <br />
                occasion to make it special for you.
              </p>
              <NavLink activeClassName="is-active" to="/reservation" exact>
                <div className="arw3 pb-5">
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  <button type="button">Read More</button>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="walkin-heading">
          <h3 className="f32-h4 heading-text text-white">
            <b>
              From Discover to Delivery - <br /> Our Features will Make your
              Dining Experiences Wholesome, Every Single Time
            </b>
          </h3>
          <p className="f16-p3 my-5 text-white">
            An ultimate visual guide with the power of technology helps you to
            relish your food your way.
          </p>
          <NavLink to={`/${props.lang}/checkout`}>
            <button type="button" className="btn btn-warning">
              {" "}
              Get the App{" "}
            </button>{" "}
          </NavLink>
        </div>
      </section>
    </div>
  );
};
export default Walkin;
