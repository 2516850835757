import React from "react";
const SharingExperience = ()=> {
    return(
        <>
        <section className="sharingExperiencesection" >
            <div className="row share-experience pd-y7">
                <div className="col-2 col-md-1"></div>
                <div className="col-xs-8 col-md-5 col-4 sharingmeals-text" style={{paddingTop:"6%"}}>
                <p className="heading-h1w"> Sharing <br />Experiences..</p>
                <p className="heading-h5">Curated experience for every get-together.</p>
                <div className="px-4 sharelist">
                <ul className="ulist" style={{color:"white"}}>
                    <li>Explore Curated Listings</li>
                    <li>Amazing options for Everyday Occasions!</li>
                    <li>Short Videos / Pics CTA</li>
                </ul>
                </div>
                </div>
                <div className="col-xs-10 col-md-5 col-5">
                <div className="container-fluid pd-y3" style={{height:"100%",paddingTop:"5%"}} >
                    <div className="flexcenter">
                        <span className="hashtags">#amealiocuration</span>
                    <img className="share-height" src="../../assets/images/amealio/new/experiences.png" width="100%" style={{width:"18em"}} />
                    </div> 
                  </div>
                </div>
            </div>
        </section>
        </>
    );
};
export default  SharingExperience;