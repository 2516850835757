import React from "react";

const Exclusive = () => {
  return (
    <div className="app">
      <section className="explore-section">
        <div className="explore-bottomtitle">
          <div className="container">
            <div className="explore-title">
              <h3 className="f42-h3">
                <b>Exclusively on</b>
              </h3>
            </div>
            <div className="explore-logo exp-logo">
              <img
                src="../assets/images/explore/amazongoogle.png"
                alt=""
                width="50%"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Exclusive;
