import React from "react";
// import Header from "../templates/header";
import Footer from "../templates/footer";
import { NavLink } from "react-router-dom";
import cookies from "js-cookie";
import { LANGUAGES } from "../../AllEnum/LanguageEnum";

const Usertouser = () => {
  const currentLanguageCode = cookies.get("i18next") || "in";
  const currentLanguage = LANGUAGES.find((l) => l.code === currentLanguageCode);

  return (
    <div className="app">
      <section className="usertouser-section">
        <div className="container">
          <div className="usertouser-heading">
            <h2>
              <b>USER TO USER REFERRAL PROGRAM</b>{" "}
            </h2>
          </div>
          <div className="usertouser-paragraph">
            <p>
              The Amealio User to User Referral Program <b>(the "Program")</b>{" "}
              offers Registered Users the opportunity to earn Credits by
              referring their friends, acquaintances to download, signup and use
              the Amealio Platform <b>(“Amealio")</b>. We reserve the right to
              terminate the Program at any time for any reason.
            </p>
            <p>
              These terms <b>("Terms")</b> apply to a User's participation in
              the Program. By participating in the Program, Users agree to use
              the Program as outlined herein, and consistent with any other
              terms we may apply to the Program. If you do not agree to these
              Terms in their entirety, then you cannot register and participate
              in the Program.
            </p>
            <p>
              <b>ELIGIBILITY</b> <br />
              Registered Users who refer others to the program are “Referrers”;
              those who are referred are “Referred Users”. Referrers must be at
              least the age of majority and must have an existing, valid Amealio
              App user account.
            </p>
            <p>
              <b>HOW DOES THE AMEALIO USER TO USER REFERRAL PROGRAM WORK?</b>{" "}
              <br />
              I. The first step is to sign up with the Amealio App with your
              Name, Mobile Number and Email Address in order to get your Unique
              Referral Code/ Link generated.
            </p>
            <p>
              II. The Unique Referral Code/Link can be shared to Friends,
              Acquaintances, and Family via WhatsApp and /or SMS (short message
              service) or any other means of electronic communications.
            </p>
            <p>
              III. The Registered User as a Referrers can earn Credits at the
              discretion of Amealio after all the following{" "}
              <b>“Qualifying Conditions”</b> are satisfied :
            </p>
            <div className="px-4">
              <p>
                1) A Referred User clicks on the Unique Referral link or uses
                the Unique Referral Code for signing up with the Amealio App and
                thereby creates a valid Amealio account that complies with our
                Terms of Service.
              </p>
              <p>
                2) The Referred User completes a <b>“Qualifying Activity”</b>. A
                Qualifying Activity may include any of the following :
              </p>
              <div className="px-4">
                <p>
                  i.) a Qualifying Food Ordering Activity : If the Referred User
                  places an order for food through the Platform and completes
                  the payment thereof.
                </p>
                <p>
                  ii.) a Qualifying Seating Activity: If the Referred User makes
                  a seating request at the Restaurant such as walk-in, waitlist,
                  or reservation through the Platform and completes the activity
                  and payment thereof after availing the services.
                </p>
                <p>
                  iii.) a Qualifying Booking for an Event: If the Referred User
                  makes a request for ticket booking or RSVP for an Event
                  through the Platform and completes the payment thereof after
                  availing the services.
                </p>
                <p>
                  iv.) a Qualifying Scan & Pay Activity: If the Referred User
                  makes a payment using Scan & Pay.
                </p>
              </div>
              <p>
                3.) The Referral qualifies when the Referred User does not
                delete the account or uninstalls the Amealio App within 30 days
                of the Qualifying Activity.
              </p>
            </div>
            <p>
              IV. Each Referrer who completes all Qualifying Conditions is
              considered a <b>"Qualified Referral"</b>.
              {/* Each Referrer who completes all Qualifying Conditions is
              considered a "Qualified Referral". Some qualifying activities are
              waived at our discretion. Currently our referral credits are based
              on below in "How Credits Work". */}
            </p>
            <p>
              V. For each Qualified Referral, the Referrer shall receive such
              amount as per the discretion of Amealio as a reward{" "}
              <b>(“Credit”)</b> in the Amealio Wallet when the Referred User
              clicks on the Unique Referral link or uses the Unique Referral
              Code for signing up with the Amealio App and thereby creates a
              valid Amealio account that complies with our Terms of Service,
              subject to verification by us. The Referral Credit is valid for 60
              days from the date of issue.
              {/* For each Qualified Referral, the Referrer shall receive an
              amount of Rs. 50/- (Rupees Fifty only) as a reward (“Credit”) in
              the Amealio Wallet when the Referred User clicks on the Unique
              Referral link or uses the Unique Referral Code for signing up with
              the Amealio App and thereby creates a valid Amealio account that
              complies with our Terms of Service, subject to verification by us. */}
            </p>
            <p>
              {/* VI. The Referred User who signs up with the Amealio App and
              thereby creates a valid Amealio account that complies with our
              Terms Service shall receive an amount of Rs. 50/- (Rupees Fifty
              only) as Referral Credit in the Amealio Wallet, subject to
              verification by us. */}
              VI. The Referred User who signs up with the Amealio App and
              thereby creates a valid Amealio account that complies with our
              Terms Service shall receive such amount as per the discretion of
              Amealio as Referral Credit in the Amealio Wallet, subject to
              verification by us. The Referral Credit is valid for 60 days from
              the date of issue.
            </p>
            <p>
              <b>RESTRICTIONS:</b> <br />
              Referrers should only be made to your personal connections and
              acquaintances. You may notify your friends and acquaintances about
              this Program through your personal social media accounts, but
              posting public notices or your unique link on jobs boards, blogs,
              websites, search engines, or other platforms accessible to the
              general public are not allowed. Referrers are prohibited from
              “spamming” anyone with referral invitations. This includes mass
              emailing, texting or messaging people you do not know or using
              automated systems or bots through any channel to distribute your
              referral link. Referrers are prohibited from paying to advertise
              their referral links.
            </p>
            <p>
              <b>TERMINATION AND CHANGES:</b> <br />
              Amealio may suspend or terminate the Program or a User’s ability
              to participate in it at any time for any reason. We reserve the
              right to suspend accounts, if we notice any activity that we
              believe is abusive, fraudulent, or in violation of the Amealio’s
              Terms of Service. We reserve the right to review and investigate
              all referral activities and to suspend accounts or modify
              referrals in our sole discretion as deemed fair and appropriate.
            </p>
            <p>
              <b>UPDATES TO THE TERMS:</b> <br />
              We can update these terms at any time without prior notice. If we
              modify these terms, we will post the modification on the
              www.amealio.com website and mobile applications, which are
              effective upon posting. Continued participation in the Program
              after any modification shall constitute consent to such
              modification.
              <br />
              If you have questions about the Program, please contact us at{" "}
              <NavLink to={`/${currentLanguage.code.toUpperCase()}/help`}>
                www.amealio.com/help
              </NavLink>{" "}
              or writing an email to{" "}
              <span style={{ fontSize: 12, fontWeight: 400, color: "#428bca" }}>
                amealio-referral@envisionard.com
              </span>
            </p>
            {/* <p>
              <b>HOW CREDITS WORK</b> <br />
              1. Referrers are allowed up to maximum 25 unique Credits (25
              unique Qualified Referrals or Rs. 1250/- (Rupees One Thousand two
              hundred and Fifty only) as Referral Credits. The amount of Rs.
              50/- (Rupees Fifty only) per Qualified Referral shall be credited
              to the Amealio Wallet.
            </p>
            <p>
              2. The Referred User upon signing up with the Amealio App and
              creating a valid Amealio account shall receive an amount of
              Rs.50/- (Rupees Fifty only) in the Amealio Wallet.
            </p> */}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Usertouser;
