import React from "react";

const Testimonial = (props) => {
  const testimonial = props.data;
  return (
    <section className="testimonial-section">
      <div className="container">
        <h1 className="testimonial-header">
          <b>Here's What They Have to Say</b>
        </h1>
        <ul className="testimonial">
          <li>
            <div className="card testimonial-card">
              <center>
                <img
                  src="../assets/images/fastfood/diptithakur.png"
                  className="testimonial-image"
                  alt="Testimonials-1"
                  title="Testimonials-1"
                ></img>
              </center>
              <h6 className="testimonial-h6">
                <b>Dipti Thakur</b>
              </h6>
              <p className="testimonial-p">FoodCourt Owner </p>
              <img
                src="../assets/images/fastfood/quote-left.png"
                alt="Testimonials-2"
                title="Testimonials-2"
                className="testimonial-img"
              ></img>
              <p className="testimonial-para">
                {" "}
                {testimonial.testimonialContent1}{" "}
              </p>
              <div className="testimonial-img1">
                <img
                  src="../assets/images/fastfood/quote-right.png"
                  alt="Testimonials-3"
                  title="Testimonials-3"
                  width="15px"
                ></img>
              </div>
            </div>
          </li>
          <li>
            <div className="card testimonial-card">
              <center>
                <img
                  src="../assets/images/fastfood/diptithakur.png"
                  className="testimonial-image"
                  alt="Testimonials-4"
                  title="Testimonials-4"
                ></img>
              </center>
              <h6 className="testimonial-h6">
                <b>Dipti Thakur</b>
              </h6>
              <p className="testimonial-p">FoodCourt Owner </p>
              <img
                src="../assets/images/fastfood/quote-left.png"
                alt="Testimonials-5"
                title="Testimonials-5"
                className="testimonial-img"
              ></img>
              <p className="testimonial-para">
                {testimonial.testimonialContent2}
              </p>
              <div className="testimonial-img1">
                <img
                  src="../assets/images/fastfood/quote-right.png"
                  alt="Testimonials-6"
                  title="Testimonials-6"
                  width="15px"
                ></img>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Testimonial;
