import React from "react";
import { NavLink } from "react-router-dom";
import cookies from "js-cookie";
import { LANGUAGES } from "../../AllEnum/LanguageEnum";
import { useNavigate } from "react-router-dom";

const Menu = () => {
  const navigate = useNavigate();

  const currentLanguageCode = cookies.get("i18next") || "in";
  const currentLanguage = LANGUAGES.find((l) => l.code === currentLanguageCode);

  return (
    <section className="main-menu">
      <img
        src="../assets/images/backup/background.png"
        className="menu-img"
        alt="Background"
        title="Background"
      ></img>
      <div className="container">
        <div className="menucolor">
          <div className="menulogo">
            <div className="menuimg">
              <div className="logoimg">
                <NavLink to={`/${currentLanguage.code.toUpperCase()}`}>
                  <img
                    src="../assets/images/backup/amealiologo.png"
                    alt="logo icon"
                  />
                </NavLink>

                <img
                  onClick={() => navigate(-1)}
                  src="../assets/images/backup/close-icon.png"
                  alt="logo icon"
                />
              </div>
            </div>
          </div>
          <div className="menucontent">
            <ul className="home">
              <li>
                <NavLink to={`/${currentLanguage.code.toUpperCase()}`}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/features">Features</NavLink>
              </li>
              <li>
                <NavLink
                  to={`/${currentLanguage.code.toUpperCase()}/bussiness`}
                >
                  For Business
                </NavLink>
              </li>
              <li>
                <NavLink to="/about">About Amealio</NavLink>
              </li>
            </ul>
            <ul>
              <li>
                <NavLink to="/aboutAmealia">About Amealia</NavLink>
              </li>
              <li>
                <NavLink to="/social">Udbhav</NavLink>
              </li>
              <li>
                <NavLink to="/community">Community</NavLink>
              </li>
              <li>
                <NavLink to={`/${currentLanguage.code.toUpperCase()}/help`}>
                  Help
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Menu;
