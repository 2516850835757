import React, { useState } from "react";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import Slidervideo from "./slidervideo";
// import happyCustomers from "./../../newAssets/images/HomePage/Banner/happyCustomers.png";

const Happycustomer = (props) => {
  const [activeSlide, setActiveSlide] = useState(0);
  // const [activeSlide2, setActiveSlide2] = useState(0);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 10000,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "linear",
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          centerMode: false,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          centerMode: false,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          centerMode: false,
          arrows: false,
          dots: true,
        },
      },
    ],
    beforeChange: (current, next) => setActiveSlide(next),
    // afterChange: (current) => setActiveSlide2(current),
  };

  const sliderDataIN = [
    {
      name: "Shilpa",
      lang: "IN",
      age: 28,
      paragraph:
        "Amealio is my go-to app for all my food cravings and needs. I love the power it gives me to skip the line at food courts and never have to wait for food anymore!",
    },
    {
      name: "Nidhi",
      lang: "IN",
      age: 25,
      paragraph:
        "With great offers, features and an easy to navigate interface, Amealio has become my go to application for everything food. Furthermore, I am able to discover new cuisines and places around me thanks to Amealio; it truly is a foodie’s best friend!",
    },
    {
      name: "Natasha",
      lang: "IN",
      age: 18,
      paragraph:
        "Amealio offers me everything that I am looking for in a food app. I love how it is so customer focused and friendly. Ordering my favorite munchies was never easier!",
    },
    {
      name: "Teresa",
      lang: "IN",
      age: 18,
      paragraph:
        "Amealia makes ordering and paying for food from my favorite restaurants such as easy experience. Amealia has further helped me experience the endless features offered by the app with ease.",
    },
  ];

  const sliderDataUS = [
    {
      name: "Linda",
      lang: "US",
      age: 23,
      paragraph:
        "Amealio is my go-to app for all my food cravings and needs. I love the power it gives me to skip the line at food courts and never have to wait for food anymore!",
    },
    {
      name: "Richard",
      lang: "US",
      age: 25,
      paragraph:
        "With great offers, features and an easy to navigate interface, Amealio has become my go to application for everything food. Furthermore, I am able to discover new cuisines and places around me thanks to Amealio; it truly is a foodie’s best friend!",
    },
    {
      name: "Jennifer",
      lang: "US",
      age: 28,
      paragraph:
        "Amealia makes ordering and paying for food from my favorite restaurants such as easy experience. Amealia has further helped me experience the endless features offered by the app with ease.",
    },
    {
      name: "James",
      lang: "US",
      age: 22,
      paragraph:
        "Amealio offers me everything that I am looking for in a food app. I love how it is so customer focused and friendly. Ordering my favorite munchies was never easier!",
    },
  ];

  let sliderData = props.lang !== "IN" ? sliderDataUS : sliderDataIN;

  return (
    <>
      <section
        className="happycustomer-section"
        // style={{ backgroundImage: "url(" + happyCustomersSprial + ")" }}
      >
        <div className="container-fluid">
          <div className="col-12">
            <div className="top-image-right-sprial">
              <img
                src="../assets/images/amealio/svg/happyCustomers.png"
                alt="Happy Customers Sprial"
                title="Happy Customers Sprial"
                className="img-fluid"
              ></img>
            </div>
            {/* <div className="top-image-left-bg">
              <img
                src="../assets/images/amealio/svg/happyleft.png"
                alt="Happy Customers"
                title="Happy Customers"
                className="img-fluid"
              ></img>
            </div> */}
            {/* <div className="top-image-right-bg">
              <img
                src="../assets/images/amealio/svg/happyRight.png"
                alt="Happy Customers"
                title="Happy Customers"
                className="img-fluid"
              ></img>
            </div> */}
          </div>
        </div>
        <div className="container-fluid happycustomer-hours-slider">
          <h1 className="f42-h3 text-center">Happy Customers with Love!</h1>
          <Slider {...settings}>
            {sliderData &&
              sliderData.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <SliderHappyHoursReuse
                      activeSlide={activeSlide}
                      indexValue={index}
                      paragraph={data.paragraph}
                      name={data.name}
                      age={data.age}
                    />
                  </React.Fragment>
                );
              })}
          </Slider>
          {/* <div className="top-image-medium-bg">
            <img
              src="../assets/images/amealio/svg/tomato.png"
              alt="Happy Customers"
              title="Happy Customers"
              className="img-fluid"
            ></img>
          </div> */}
        </div>
      </section>
      {props.lang !== "IN" ? null : <Slidervideo />}
    </>
  );
};

export default Happycustomer;

export const SliderHappyHoursReuse = (props) => {
  return (
    <div>
      <div
        className={`shadow ${
          props.activeSlide === props.indexValue
            ? "happycustomer-box5"
            : "happycustomer-box3"
        }`}
      >
        {props.activeSlide === props.indexValue ? (
          <img
            src="../assets/images/amealio/svg/burrger.png"
            alt="Burrger"
            title="Burrger"
            className="happycustomer-top-img bg-white"
          ></img>
        ) : (
          <></>
        )}
        <div className="happycustomer-align">
          <p
            className={`${
              props.activeSlide === props.indexValue
                ? "happycustomer-align-paraBig paragraph-color-white"
                : "happycustomer-align-para"
            }`}
          >
            {props.paragraph}
          </p>
          <p
            className={`${
              props.activeSlide === props.indexValue
                ? "happycustomer-align-paraBig paragraph-color-white"
                : "happycustomer-align-para paragraph-color-purple"
            }`}
          >
            - {props.name}, {props.age}
          </p>
        </div>
        {props.activeSlide === props.indexValue ? (
          <img
            src="../assets/images/amealio/svg/shake.png"
            alt="Shake"
            title="Shake"
            className="happycustomer-bottom-img bg-white"
          ></img>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
