import React from "react";
import Slider from "react-slick";
import ReusableSliderCom from "./reusableSliderCom";

const GoingOutSlide = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 7000,
    // cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
  };

  const goingOutDummyData = [
    {
      id: 1,
      title: "Take Away",
      imgSrc: "TakeAway.png",
    },
    {
      id: 2,
      title: "Dine In",
      imgSrc: "Dinein.png",
    },
    {
      id: 3,
      title: "Skip The Line",
      imgSrc: "SkiptheLine.png",
    },
    {
      id: 4,
      title: "Walk In",
      imgSrc: "walkin.png",
    },
    {
      id: 5,
      title: "Waitlist",
      imgSrc: "Waitlist.png",
    },
    {
      id: 6,
      title: "Reservation",
      imgSrc: "Reserve.png",
    },
    {
      id: 7,
      title: "Events",
      imgSrc: "Events.png",
    },
  ];

  return (
    <div className="outter-features">
      <Slider {...settings}>
        {goingOutDummyData &&
          goingOutDummyData.map((goingOut) => {
            return (
              <React.Fragment key={goingOut.id}>
                <ReusableSliderCom data={goingOut} />
              </React.Fragment>
            );
          })}
      </Slider>
    </div>
  );
};

export default GoingOutSlide;
