import React from "react";
import Footer from "../templates/footer";
import { useNavigate } from "react-router-dom";

const PageNotFound = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container-fluid error_404_section">
        <div className="row">
          <div className="col-12 error_404_section_top">
            <h1>4</h1>
            <div className="img-rotation">
              <img
                src="../assets/images/Animation/dinein-pasta.png"
                alt="404 Error"
                title="404 Error"
                className="img-fluid"
              />
            </div>
            <h1>4</h1>
          </div>
          <div className="col-12 error_404_section_top_two">
            <h1 className="f32-h4 mb-0">Look like you're lost</h1>
            <p className="f18-p2">the page you are looking for not avaible!</p>
          </div>
          <div className="col-12 error_404_section_top_two">
            <button
              type="button"
              class="btn btn-primary custom-button-data"
              onClick={() => navigate(`/${props.lang.toUpperCase()}`)}
            >
              Go Back To Home Page
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PageNotFound;
