import React from "react";
import { useNavigate } from "react-router-dom";

const Demo = () => {
  const navigate = useNavigate();

  return (
    <section className="demo-section">
      <img
        src="../assets/images/backup/background.png"
        alt="Background"
        title="Background"
        className="menu-img"
      ></img>
      <div className="container1">
        <div className="freedemo">
          <div className="title">
            <b> Get a free demo!</b>
            <i
              onClick={() => navigate(-1)}
              className="fa fa-times-circle cls"
              aria-hidden="true"
            ></i>
          </div>
          <form
            className="formspree"
            action="https://formspree.io/f/xwkylqzz"
            method="POST"
          >
            <div className="user details">
              <div className="inputbox">
                <span className="details">
                  <b> Name</b>
                  <div style={{ color: "red" }}>&#42;</div>{" "}
                </span>
                <input type="text" id="name" name="Name" />
              </div>
              <div className="flex s">
                <div className="inputbox">
                  <span className="details">
                    <b>Phone Number</b>
                    <div style={{ color: "red" }}>&#42;</div>{" "}
                  </span>
                  <input type="text" id="phonenumber" name="Phonenumber" />
                </div>
                <div className="inputbox">
                  <span className="details">
                    <b>Email</b>
                    <div style={{ color: "red" }}>&#42;</div>
                  </span>
                  <input type="text" id="email" name="Email" />
                </div>
              </div>

              <div className="inputbox">
                <span className="details">
                  <b>City</b>
                  <div style={{ color: "red" }}>&#42;</div>
                </span>
                <input type="text" id="city" name="City" />
              </div>
              <div className="inputbox">
                <span className="details">
                  <b>Company Name</b>
                  <div style={{ color: "red" }}>&#42;</div>
                </span>
                <input type="text" id="restaurantname" name="Restaurantname" />
              </div>
              <div className="inputbox">
                <span className="details">
                  <b>Company Details</b>
                  <div style={{ color: "red" }}>&#42;</div>
                </span>
                <input
                  type="text"
                  id="restaurantdetails"
                  name="Restaurantdetails"
                />
              </div>
              <div className="inputbox">
                <span className="details">
                  <b>Write Message</b>
                  <div style={{ color: "red" }}>&#42;</div>
                </span>
                <input
                  type="text"
                  id="restaurantmessage"
                  name="Restaurantmessage"
                />
              </div>
              <div className="inputbox">
                <span className="details">
                  <b>Referral Code</b>
                  <div style={{ color: "lightgray" }}>(Optional)</div>
                </span>
                <input type="text" id="referralcode" name="Referralcode" />
              </div>
              <div className="button-submit">
                <button type="submit" id="sendMessage" className="btn-submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Demo;
