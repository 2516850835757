import React from "react";
import { useNavigate } from "react-router-dom";
import amealioVerse from "./../../newAssets/images/HomePage/Banner/amealioVerse.png";
import amealioVerseSprial from "./../../newAssets/images/HomePage/sprial/IntroducingAmealioSprial.png";
// import amealioVerseSlider from "./../../newAssets/images/HomePage/Banner/amealioVerseSliderBg.png";
import Slider from "react-slick";

const IntroduceAmealioVerse = (props) => {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    // cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    fade: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          infinite: true,
          fade: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          infinite: true,
          fade: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          infinite: true,
          fade: false,
        },
      },
    ],
  };

  return (
    <section
      className="introduce-amealio-verse"
      style={{ backgroundImage: "url(" + amealioVerse + ")" }}
    >
      <div className="container-fluid">
        <div className="col-12">
          <div className="top-image-sprial">
            <img
              src={amealioVerseSprial}
              alt="Amealio Verse"
              title="Amealio Verse"
              className="img-fluid"
            ></img>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6">
            <h1 className="introduce-h1-font">
              {/* Introducing */}
              Amealioverse
            </h1>
            <h3 className="introduce-h3-font">
              Social Engagement Platform (coming soon){" "}
            </h3>
            <p className="introduce-p-paragraph">
              Create your profile, invite friends and meet new people and
              hangout. Build meaningful conversations and connections have
              seamless digital to real experiences over food.
            </p>
            <p className="introduce-p-paragraph">
              Eating is not just an act of the moment, it is often the
              experience of togetherness, of the company that we keep.
            </p>
            <div className="col-12 learn-more-center">
              <button
                type="button"
                className="btn learn-more-button"
                title="Learn More"
                onClick={() => navigate(`/${props.lang}/about`)}
              >
                Learn More
              </button>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 introduce-right">
            <div className="amealioVerse-slider-img">
              <div className="amealioVerse-slider-div-align">
                <Slider {...settings}>
                  <div>
                    <div className="introduce-verse-slider">
                      <div className="introduce-amealio-head-div">
                        <div className="introduce-amealio-head-divOne">
                          <h2 className="introduce-verse-head">
                            Create Your Profile
                          </h2>
                        </div>
                        <div className="introduce-amealio-div-imgone">
                          <img
                            src="../assets/images/amealio/svg/amealioVerseSlide1.png"
                            alt="Create Your Profile"
                            title="Create Your Profile"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="introduce-verse-slider">
                      <div className="introduce-amealio-head-div">
                        <div className="introduce-amealio-head-divOne">
                          <h2 className="introduce-verse-head">Hang Out</h2>
                        </div>
                        <div className="introduce-amealio-div-imgone">
                          <img
                            src="../assets/images/amealio/svg/amealioVerseSlide2.png"
                            alt="Hang Out"
                            title="Hang Out"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="introduce-verse-slider">
                      <div className="introduce-amealio-head-div">
                        <div className="introduce-amealio-head-divOne">
                          <h2 className="introduce-verse-head">
                            Order in Group & Pre-Arrange
                            <br />
                            your Experiences
                          </h2>
                        </div>
                        <div className="introduce-amealio-div-imgone">
                          <img
                            src="../assets/images/amealio/svg/amealioVerseSlide3.png"
                            alt="Order in Group"
                            title="Order in Group"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
              <img
                src="../assets/images/amealio/svg/amealioVerseSliderBg.png"
                alt="Amealio Verse"
                title="Amealio Verse"
                className="img-fluid pt-3"
              />
            </div>
          </div>
          <div
            className="col-12 text-center position-relative"
            style={{ zIndex: 1 }}
          >
            <h1 className="launching-fall-f">LAUNCHING FALL 22</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroduceAmealioVerse;
