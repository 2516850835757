const LANGUAGES = [
  {
    code: "us",
    name: "United States",
    country_code: "us",
    country_code_iso3: "USA",
  },
  {
    code: "in",
    name: "India",
    country_code: "in",
    country_code_iso3: "IND",
  },
  // { code: "fr", name: "Français", country_code: "fr" },
  // { code: "en", name: "English", country_code: "gb" },
  // { code: "ar", name: "العربية", country_code: "sa", dir: "rtl" },
];

export { LANGUAGES };
