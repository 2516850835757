import React from "react";
import Cater from "./cater";
// import Header from "../templates/header";
import Walkin from "./walkin";
import Away from "./away";
import Reward from "./reward";
import Footer from "../templates/footer";

const Features = (props) => {
  return (
    <>
      <Cater lang={props.lang.toUpperCase()} />
      <Walkin lang={props.lang.toUpperCase()} />
      <Away />
      <Reward lang={props.lang.toUpperCase()} />
      <Footer />
    </>
  );
};
export default Features;
