import React from "react";
const multipledevice = (props) => {
  return (
    <section>
      <div className="container-fluid">
        <div className="row">
          <div className="multipledevice-h1">
            <h1 className="f42-h3 text-center">
              <b>Easy Access on Multiple Devices.</b>
            </h1>
          </div>
          <div className="multipledevice">
            <img
              src="../assets/images/business/amelio-desk-view_1.png"
              alt="Easy Access"
              className="img-fluid"
            ></img>
          </div>
        </div>
      </div>
    </section>
  );
};
export default multipledevice;
