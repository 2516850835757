import React from "react";
// import Header from '../templates/header';
import Footer from "../templates/footer";

const Businessreferral = () => {
  return (
    <div className="app">
      <section className="demo-referral">
        <div className="container">
          <div className="business-referral">
            <h2>
              <b>BUSINESS TO USER REFERRAL PROGRAM</b>
            </h2>
          </div>
          <div className="business-program">
            <p>
              The Amealio Business to User Referral Program (the "Program")
              offers Registered Merchants the opportunity to earn Credits by
              referring their Customers, friends, acquaintances to download,
              signup and use the Amealio Platform (“Amealio"). We reserve the
              right to terminate the Program at any time for any reason.
            </p>
            <p>
              These terms ("Terms") apply to a Merchant's participation in the
              Program. By participating in the Program, Merchants agree to use
              the Program as outlined herein, and consistent with any other
              terms we may apply to the Program. If you do not agree to these
              Terms in their entirety, then you cannot register and participate
              in the Program.
            </p>
            <p>
              <b>ELIGIBILITY</b> <br />
              Registered Merchants who refer others to the program are
              “Referrers”; those who are referred are “Referred Users”.
              Referrers must have an existing, valid Amealio Merchant account
              and must be listed on the Amealio Platform.
            </p>
            <p>
              <b>
                HOW DOES THE AMEALIO BUSINESS TO USER REFERRAL PROGRAM WORK?
              </b>{" "}
              <br />
              I. The first step is to sign up with the Amealio App and get
              registered as a Merchant after submitting all the required details
              in order to get your Unique Referral Code/ QR Code generated.
            </p>
            <p>
              II. The Unique Referral Code/QR Code can be shared to Customers,
              Friends, Acquaintances, and Family via WhatsApp and /or SMS (short
              message service) or any other means of electronic communications.
            </p>
            <p>
              III. The Merchant shall also be provided with a Referral QR code
              display stands for the purpose of keeping it at the conspicuous
              places of the Restaurant so that the Customers visiting the
              Restaurant can scan the QR Code and download the Amealio App.
            </p>
            <p>
              IV. The Registered Merchant as a Referrer can earn Credits after
              all the following “Qualifying Conditions” are satisfied :
            </p>
            <p>
              1.) A Referred User clicks on the Unique Referral link or uses the
              Unique Referral Code or scans the QR Code for signing up with the
              Amealio App and thereby creates a valid Amealio account that
              complies with our Terms of Use.
            </p>
            <p>
              2.) The Referred User completes a “Qualifying Activity”. A
              Qualifying Activity may include any of the following :
            </p>
            <p>
              i.) a Qualifying Food Ordering Activity : If the Referred User
              places an order for food through the Platform and completes the
              payment thereof.
            </p>
            <p>
              ii.) a Qualifying Seating Activity: If the Referred User makes a
              seating request at the Restaurant such as walk-in, waitlist, or
              reservation through the Platform and completes the activity and
              payment thereof after availing the services.
            </p>
            <p>
              iii.) a Qualifying Booking for an Event: If the Referred User
              makes a request for ticket booking or RSVP for an Event through
              the Platform and completes the payment thereof after availing the
              services.
            </p>
            <p>
              iv.) a Qualifying Scan & Pay Activity: If the Referred User makes
              a payment using Scan & Pay.
            </p>
            <p>
              3.) The Referrer qualifies when the Referred User does not delete
              the account or uninstalls the Amealio App within 30 days of the
              Qualifying Activity.
            </p>
            <p>
              V. Each Referrer who completes all Qualifying Conditions is
              considered a "Qualified Referral".
            </p>
            <p>
              VI. For each Qualified Referral, the Referrer shall receive an
              amount of Rs. 30/- (Rupees Thirty only) as a reward (“Credit”),
              after the expiry of 30 days of the Qualifying Event, subject to
              verification by us.
            </p>
            <p>
              <b>RESTRICTIONS:</b> <br />
              Referrers should only be made to your Customers, personal
              connections and acquaintances. You may notify your Customers,
              friends and acquaintances about this Program through your personal
              social media accounts, but posting public notices or your unique
              link on jobs boards, blogs, websites, search engines, or other
              platforms accessible to the general public are not allowed.
              Referrers are prohibited from “spamming” anyone with referral
              invitations. This includes mass emailing, texting or messaging
              people you do not know or using automated systems or bots through
              any channel to distribute your referral link. Referrers are
              prohibited from paying to advertise their referral links.
            </p>
            <p>
              <b>HOW CREDITS WORK</b> <br />
              1. Referrers are allowed up to ____ unique Credits (____ unique
              Qualified Referrals).
            </p>
            <p>
              2. Credits are subject to verification and will generally be
              awarded after the expiry of 30 days of the Qualifying Event.
              Amealio may withhold a Credit if it reasonably believes additional
              verification is required. Amealio may also withhold or invalidate
              any Qualified Referral it deems fraudulent, suspect, or in
              violation of these Terms.
            </p>
            <p>
              3. The amount of Rs. 30/- (Rupees Thirty only) per Qualified
              Referral shall be credited to the Bank account provided by the
              Merchant and an amount of Rs. 5/- (Rupees Five only) shall be
              transferred to Udbhav Foundation for Charitable Purpose after the
              expiry of 30 days of the Qualifying Event.
            </p>
            <p>
              4. The total amount to be credited to the Referrer towards Credits
              for Qualified Referral shall always be subject to deduction of TDS
              and other applicable taxes at the prevailing rates.
            </p>
            <p>
              5. If the Referrer’s/Merchant Account registered with Amealio App
              is suspended for any reason, the Referrer shall not be entitled to
              receive any Credits for Qualified Referral.
            </p>
            <p>
              <b>TERMINATION AND CHANGES</b> <br />
              Amealio may change terms and conditions or suspend or terminate
              the Program or a Merchant’s ability to participate in it at any
              time for any reason.
            </p>
            <p>
              We reserve the right to suspend accounts, if we notice any
              activity that we believe is abusive, fraudulent, or in violation
              of the Amealio’s Terms of Service. We reserve the right to review
              and investigate all referral activities and to suspend accounts or
              modify referrals in our sole discretion as deemed fair and
              appropriate.
            </p>
            <p>
              <b>UPDATES TO THE TERMS</b> <br />
              We can update these terms at any time without prior notice. If we
              modify these terms, we will post the modification on the
              www.amealio.com/referral website and mobile applications, which
              are effective upon posting. Continued participation in the Program
              after any modification shall constitute consent to such
              modification.
            </p>
            <p>
              If you have questions about the Program, please contact us at
              www.amealio.com/help/ or writing an email to
              amealio-referral@envisionard.com
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Businessreferral;
