import React from "react";
import Frontpage from "./frontpage";
import Exploreheader from "./exploreheader";
import Madeperfect from "./madeperfect";
import Payment from "./payment";
import Exclusive from "./exclusive";
import Footer from "../templates/footer";
const Explore = () => {
  return (
    <>
      <Frontpage />
      <Exploreheader />
      <Madeperfect />
      <Payment />
      <Exclusive />
      <Footer />
    </>
  );
};
export default Explore;
