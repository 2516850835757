import React from "react";
import { NavLink } from "react-router-dom";

const Forbusiness = (props) => {
  return (
    <section>
      <div className="container">
        <div className="hotel">
          <div className="row">
            <div className="col-lg-6 hotelbannerdiscribe">
              <div className="hotelbanner">
                <img
                  src="../assets/images/features/dining-resturant.png"
                  alt="Dining Restaurant"
                  title="Dining Restaurant"
                />
                <div className="hoteldescribe">
                  <h1>For Business</h1>
                  <NavLink
                    activeClassName="is-active"
                    to={`/${props.lang}/demo`}
                    exact
                  >
                    <button className="private">Get On boarded</button>
                  </NavLink>
                  <NavLink
                    activeClassName="is-active"
                    to={`/${props.lang}/bussiness`}
                    exact
                  >
                    <h6>Learn more</h6>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-6 hotelbannerdiscribe">
              <div className="hotelbanner">
                <img
                  src="../assets/images/features/dining-hall.png"
                  alt="Dining Hell"
                  title="Dining Hell"
                />
                <div className="hoteldescribe">
                  <h1>For Users</h1>
                  <NavLink
                    activeClassName="is-active"
                    to={`/${props.lang}/checkout`}
                    exact
                  >
                    <button className="private">Download Now</button>
                  </NavLink>
                  <NavLink
                    activeClassName="is-active"
                    to={`/${props.lang}/home`}
                    exact
                  >
                    <h6>Learn more</h6>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Forbusiness;
