import React from "react";
// import Header from '../templates/header';
import Footer from "../templates/footer";

const Ususerterms = () => {
  return (
    <div className="app">
      <section>
        <div className="container">
          <div className="us_userterms">
            <h2>
              <b>USER TERMS AND CONDITIONS</b>
            </h2>
          </div>
          <div className="user_terms">
            <p>
              This following sets out the terms and conditions through for Your
              use of our website, mobile browser site and Applications for
              Mobile, Tablets and Other Smart Devices, owned by Envisionard
              Corporation ( “the Company”).{" "}
            </p>

            <p>
              Please refer the Dispute Resolution Section of this agreement for
              the particulars on how disputes with Amealio are to be addressed.
              It provides for an arbitration clause between You and Amealio to
              exclusively settle all or any dispute or controversy arising under
              or in connection with this Agreement, through binding and final
              arbitration filed in individual capacity, including claims which
              have initiated prior to enforcement of this agreement.
            </p>

            <p>
              <b>1. Definition Clause:</b>
              <br />
              <b>a.) Merchant -</b>The term “Merchant” shall mean the merchants,
              vendors, restaurants, eateries Listed on the Platform. This is not
              an exhaustive list. The term can mean any establishment that would
              provide for prepared food and beverages either for dine in,
              delivery, drive through, etc.{" "}
            </p>

            <p>
              <b>b.) Buyer </b>- “Customer”, “you”, “your” or “User” shall mean
              any natural or legal person who has agreed to become a buyer or
              customer on the Platform either by providing Data while
              registering on the Platform as a registered user using or
              accessing the platform to access the services mentioned hereunder.
            </p>

            <p>
              <b>c.) Platform </b>- Platform collectively refers to the Amealio
              Website (www.amealio.com) (the “ Website”) and the Amealio
              application (the “ App”) (Android and iOS) for mobile and other
              handheld devices, including any information provided, existing or
              in future provided through the website or the app.
            </p>

            <p>
              <b>
                The terms “Amealio”, “we”, “us” or “our” shall mean Envisionard
                Corporation
              </b>
            </p>

            <p>
              <b>d.) Platform Services </b> - This refers to all the services
              offered by Amealio website and the app, mentioned under the
              “Services Offered” Section. Amealio bears no responsibility for
              any other services offered either by the merchant or the delivery
              partners in their individual capacities at any stage of the
              transaction.
            </p>

            <p>
              <b>2. Eligibility to use the services:</b>
              <br />
              a.) You hereby affirm and warrant that you are eighteen (18) years
              old [Nineteen (19) years of age for Alabama and Nebraska,
              Twenty-one (21) years of age for Mississippi] or above and are
              fully able and competent to understand and agree to the terms,
              conditions, obligations, affirmations, representations, and
              warranties set forth in these Terms.
            </p>

            <p>
              b.) If you are a minor in the jurisdiction you reside in and you
              wish to download, install, access or use the Platform, you may
              only access the platform with the consent and under the
              supervision of your Parent/ legal guardian where your Parent/
              legal guardian acknowledge and agree to the Terms and Conditions
              and Privacy Policy.
            </p>

            <p>
              c.) You hereby affirm that you are in compliance with all federal,
              state and local laws and other regulations in the jurisdiction you
              reside when you access the services of the platform.
            </p>

            <p>
              d.) In case of acting on behalf of an organization, You affirm
              that you have the authority to register or access the platform and
              its services on behalf of your organization.
            </p>

            <p>
              <b>About Amealio:</b>
              <br />
              This Platform is operated by Envisionard Corporation having its
              office address at One Sansome St Suite 3500, San Francisco, CA
              94104{" "}
            </p>

            <p>
              Amealio is merely a facilitator between You and the Merchant/s. It
              facilitates communication of services provided by the Merchant to
              the customer and once a service has been opted by the customer, it
              notifies the Merchant of the same, so that necessary arrangements
              are made for fulfilling the order made.
            </p>

            <p>
              Please read these Terms and conditions carefully before you use
              the services. By accessing or using the Amealio Platform, you are
              agreeing to these Terms thereby concluding a legally binding
              contract with Envisionard Corporation. By entering in this
              agreement, You expressly agree to be bound by all terms mentioned
              herein and acknowledge that You have read the agreement carefully
              and have understood the consequences of entering in this
              agreement.{" "}
            </p>

            <p>
              If you do not agree to the Terms or are in any way incapable of
              being bound to the Terms of Use and other Amealio policies
              (including but not limited to the Cancellation & Refund Policy,
              Privacy Policy) as posted on the Platform from time to time, you
              may not use the services on the Platform, and we request you to
              uninstall the App.
            </p>

            <p>
              <b>
                To use the Services, you must first accept the Terms. You can
                accept the Terms by either:
              </b>
              <br />
              a.) Clicking to accept or agree to the Terms, when provided by
              Amealio in the user interface for any specific Service; or
            </p>

            <p>
              b.) By actually using the Services. In event of which, you
              acknowledge and agree that Amealio will regard your continued use
              of the Services as acceptance of the Terms. Your use of the
              Platform is at your own risk, including the risk that you might be
              exposed to content that is objectionable, or otherwise
              inappropriate.These Terms and Conditions are intended to govern
              and make you aware of your legal rights and responsibilities with
              respect to your access and use of our Platform.
            </p>

            <p>
              <b>Modifications:</b>
              <br />
              With exception to the autonomy of Arbitration Clause, we expressly
              reserve the right to modify, alter, change, add or delete, in
              whole or in part to these Terms and/or other Amealio policies at
              any time and for any reason that it in its sole discretion,
              considers appropriate. Changes to the Terms and the Privacy Policy
              shall take immediate effect and will apply to all pre-existing
              accounts and those accounts registering after the date of the
              changes without limitation.{" "}
            </p>

            <p>
              Such Modifications shall be effective upon posting of an updated
              version of this Agreement through the Services. You should
              regularly review this Agreement, as your continued use of the
              Services after any such changes constitutes your agreement to such
              changes.
            </p>

            <p>
              <b>Account and Password:</b>
              <br />
              a.) Registration is open to all-natural persons above the legal
              age in the jurisdiction you reside in.
            </p>

            <p>
              b.) You may access the Platform by registering to create an
              account (" Amealio Account") and become a member; or you can also
              register to join by logging into your account with certain
              third-party social networking sites (" SNS") (including, but not
              limited to, Facebook); a " Third Party Account", via our Platform,
              as described below.{" "}
            </p>

            <p>
              c.) As a registered user of the Site and the Mobile App, you
              explicitly agree to:
            </p>

            <p>
              d.) Provide true, accurate, current (this refers to recent,
              on-going and valid information as on the date of registration),
              and complete information about yourself as prompted by the Site
              registration form (“Registration Data”), and{" "}
            </p>

            <p>
              e.) Maintain and promptly update the Registration Data to keep it
              true, accurate, current, and complete.
            </p>

            <p>
              f.) If you choose to use the Platform, it shall be your
              responsibility to keep your Account and the account related
              details such as the User Identification Code, Password (“Login
              Credentials”) and any other piece of information that we may
              provide, as part of our security procedures, confidential, not
              disclose the same to any person or entity other than us and
              restricting access to your computer or mobile device, and you
              agree to take responsibility for all activities that occur under
              your account or password.. We shall reserve the right to disable
              any Account or login credentials if you have failed to comply with
              any of the provisions of these Terms of Service.
            </p>

            <p>
              <b>g.) You also agree to: </b>
              <br />
              i.) Immediately notify Amealio of any unauthorized use of your
              password or user name or any other breach of security and
            </p>

            <p>
              ii.) Indemnify of any loss or damage arising from unauthorized use
              of your password or user name.
            </p>

            <p>
              iii.) Amealio reserves the right to terminate Your Registration or
              deny access to the platform at any time for reasons it may in its
              discretion deem fit.
            </p>

            <p>
              iv.) You may only have one Amealio Account. You are not permitted
              to create multiple accounts. If found, you are having multiple
              accounts, Amealio reserves right to suspend such multiple account
              without being liable for any compensation.{" "}
            </p>

            <p>
              <b>Termination of User Account:</b>
              <br />
              We reserve a right to terminate/ suspend Your account, any time,
              without prior notice, if the User being a minor in the
              jurisdiction where he resides, accesses the platform without the
              permission and supervision of a parent/ legal guardian; or Amealio
              reserves the right to terminate / suspend the User's account in
              its sole discretion if the User has violated or appears to be in
              violation of these Terms or any other Policies of the Platform
              and/or is/has been involved in activities that are in direct or
              indirect contravention of the Amealio Platform terms and
              conditions and other policies or is/has engaged in activities
              which are fraudulent / unlawful in nature; or You shall not
              maliciously make any false statements which are negative,
              denigrating or defamatory statement(s) or comment(s) about us or
              the brand name or domain name used by us including the name
              'Amealio', or otherwise engage in any conduct or action that might
              tarnish the image or reputation, of Amealio or any Merchant on
              platform or otherwise tarnish or dilute any Amealio's trade or
              service marks, trade name and/or goodwill associated with such
              trade or service marks, as may be owned or used by us.
            </p>

            <p>
              <b>Terms of Service:</b>
              <br />
              By using the Platform, you represent and warrant that:{" "}
            </p>

            <p>
              a.) All registration information you submit is truthful, lawful
              and accurate and that you agree to maintain the accuracy of such
              information.
            </p>

            <p>
              b.) You will not use the Services if you are not legally able or
              competent to agree to the terms of this Agreement;
            </p>

            <p>c.) You will only use the Services for lawful purposes. </p>

            <p>
              d.) Your use of the Platform shall be solely for your personal use
              and you shall not authorize others to use your account. In case
              you authorize your account access to a third party You shall be
              solely responsible for any actions taken through your account.{" "}
            </p>

            <p>
              e.) You shall be solely responsible for the content published or
              displayed through your account.
            </p>

            <p>
              f.) You will not submit, post, upload, contribute, distribute, or
              otherwise make available or transmit any content that:
            </p>

            <p>
              i.) Is defamatory, abusive, harassing, insulting, threatening, or
              that could be deemed to be stalking or constitute an invasion of a
              right of privacy of another person;
            </p>

            <p>ii.) Is bigoted, hateful, or racially or otherwise offensive;</p>

            <p>
              iii.) Is violent, vulgar, obscene, pornographic or otherwise
              sexually explicit;
            </p>

            <p>
              iv.) Is illegal or encourages or advocates illegal activity or the
              discussion of illegal activities with the intent to commit them.
            </p>

            <p>
              g.) You will not post, submit, upload, distribute, or otherwise
              transmit or make available any software or other computer files
              that contain a virus or other harmful component, or otherwise
              impair or damage the Platform or any connected network, or
              otherwise interfere with any person or entity's use or enjoyment
              of the Platform.{" "}
            </p>

            <p>
              h.) You will not use the Services to advertise, solicit or
              transmit commercial advertisements or engage in any form of
              antisocial, disrupting, or destructive acts, including "flaming,"
              "spamming," "flooding," "trolling."{" "}
            </p>

            <p>
              I.) You will not copy, or distribute the Services or other content
              without written permission from Amealio;
            </p>

            <p>
              j.) You shall not access the Platform without authority or use the
              Platform in any manner which damages, interferes or disrupts any
              part of the Platform or the Platform software; or any equipment or
              any network on which the Platform is stored or any equipment of
              any third party.{" "}
            </p>

            <p>
              k.) You shall provide us with any State/ Government issued
              identification proof for authentication whenever it is reasonably
              requested.
            </p>

            <p>
              l.) When the delivery arrives, you will provide our driver with
              your State/ Government issued ID which is a proof that you are the
              requisite age as applicable, and if such state issued ID was not
              issued by the state in which such delivery is made, you will
              provide such other type(s) of identification as may be requested.
            </p>

            <p>
              m.) We reserve the right to refuse your delivery request for any
              reason;{" "}
            </p>

            <p>
              n.) A __restocking fee, which is non-refundable will be
              automatically charged to you if your delivery has been declined
              for any reason including the inability to produce a valid ID for
              authentication wherever required.{" "}
            </p>

            <p>
              o.) Amealio makes no representation as to any Laws of any
              jurisdiction regarding the sale, service, transportation or
              delivery of Products to a customer/consumer, including you.{" "}
            </p>

            <p>
              p.) Amealio shall not be liable for any loss or damage arising
              from your failure to comply with the terms set forth in this
              Agreement or to comply with applicable Law. Disclaimers
            </p>

            <p>
              <b>
                Please read and understand the following disclaimers carefully:{" "}
              </b>
              <b />
              a.) Amealio and Envisionard Corporation; its affiliates and
              partners; and their respective officers, directors, employees,
              agents, members, licensors, representatives shall not be held
              liable for your use of the Services or any injury alleged to have
              been caused by the consequence of the above. We expressly disclaim
              all liabilities to the maximum extent of law, relating to or
              arising out of Your use of the Platform.
            </p>

            <p>
              b.) You expressly understand and agree that to the fullest extent
              of law, your use of the Platform and services is entirely at your
              own risk. We do not warrant the Quality, Accuracy, Merchantability
              with respect to services .{" "}
            </p>

            <p>
              c.) The information provided hereunder is provided "as is". We and
              / or our employees make no warranty or representation regarding
              the timeliness, sequence, accuracy, effectiveness or completeness
              of information or data furnished hereunder.
            </p>

            <p>
              d.) We are absolved of any liability arising out of due to the
              volatility of connection or Internet that can occur in the
              electronic distribution of information, which may result in
              delays, omissions, or inaccuracies in the content provided on the
              platform or delay or errors in functionality of the platform.
              Subsequently, we do not represent that the information posted is
              correct in every case.
            </p>

            <p>
              e.) The platform may be under constant upgrades, and some
              functions and features may not be fully operational.
            </p>

            <p>
              f.) We do not warrant uninterrupted or error free or virus -free
              Use the Platform.
            </p>

            <p>
              g.) We do not guarantee the compatibility of the Platform or
              Services shall be accessible or compatible with any application or
              devices.
            </p>

            <p>
              h.) In no event will we be liable for any damages (including,
              without limitation, direct, indirect, incidental, special,
              consequential or exemplary damages, damages arising from personal
              injury/wrongful death, and damages resulting from lost profits,
              lost data or business interruption), resulting from any services
              provided by any third party or merchant accessed through the
              platform, whether based on warranty, contract, tort, or any other
              legal theory and whether or not we are advised of the possibility
              of such damages.
            </p>

            <p>
              Some Jurisdictions do not allow exclusion of liability from
              damages, in which case the exclusion of liability may not be
              applicable to You.
            </p>

            <p>
              <b>Limitation of liability:</b>
              <b />
              In no event will Amealio be liable for any damages, including
              without limitation direct or indirect, special, incidental, or
              consequential damages, losses or expenses arising in connection
              with this site or any linked site or use thereof or inability to
              use by any party, or in connection with any failure of
              performance, error, omission, interruption, defect, delay in
              operation or transmission, computer virus or line or system
              failure, even if amealio, or representatives thereof, are advised
              of the possibility of such damages, losses or expenses.
            </p>

            <p>
              <b>Availability:</b>
              <b />
              This site is not intended for distribution to, or use by, any
              person or entity in any jurisdiction or country where such
              distribution or use would be contrary to local law or regulation.
              By offering this site and information, or any products or services
              via this site, no distribution or solicitation is made by amealio
              to any person to use this site, or such information, products or
              services in jurisdictions where the provision of this site and
              such information, products and services is prohibited by law.{" "}
            </p>

            <p>
              <b>Injunctive Relief:</b>
              <b />
              To prevent actual or threatened infringement, misappropriation, or
              violation of a party's copyrights, trademarks, trade secrets,
              patents, or other confidential or proprietary information or
              intellectual property rights, a party may seek injunctive or other
              equitable relief in a court of competent jurisdiction.{" "}
            </p>

            <p>
              <b>Dispute Resolution:</b>This section provides for an arbitration
              clause between you and Amealio stating that all or any dispute or
              controversy arising under or in connection with this agreement,
              shall be exclusively settled through binding and final arbitration
              it requires you to arbitrate disputes on an individual basis and
              restricts the ways in which you can seek redressal from Amealio.
            </p>

            <p>
              <b>Please read this section carefully. </b>
            </p>

            <p>
              <b>a.) You agree and acknowledge that - </b>
              <b />
              (1) You waive the right to participate or seek relief as a
              plaintiff or class member in any class or representative action or
              proceeding against
            </p>

            <p>
              (2) You waiving your right to seek relief in a court of law and to
              have a trial by jury.
            </p>

            <p>
              This Section shall survive the termination or cancellation of this
              Agreement.
            </p>

            <p>
              <b>b.) Arbitration Clause </b>
              <b />
              Any controversy or claim arising out of or relating to this
              agreement inclusive of any claims or disputes arising before the
              existence of this agreement, or the breach thereof, shall be
              settled by arbitration administered by the American Arbitration
              Association under its Commercial Arbitration Rules.{" "}
            </p>

            <p>
              c.) An arbitrator will decide the claim, where no judge or jury
              will be a part to adjudicate the proceedings.   We both
              acknowledge and agreed that we waived the right to trial by jury
              to the fullest extent permitted by law, and we both agree to waive
              the right to such a trial.
            </p>

            <p>
              <b>d.) Arbitration Rule</b>
              <b />
              i.) The arbitration will be administered by the American
              Arbitration Association ("AAA"). If AAA is not available to
              arbitrate, the parties will select an alternative arbitration
              provider. Except as modified by this “Dispute Resolution”
              provision, the AAA will administer the arbitration in accordance
              with either (A) the Commercial Arbitration Rules then in effect,
              or (B) the Consumer Arbitration Rules then in effect if the matter
              involves a “consumer” agreement as defined by Consumer Arbitration
              Rule R-1 (together, the "Applicable AAA Rules"). The Applicable
              AAA Rules are available at https://www.adr.org/Rules or by calling
              the AAA at 1-800-778-7879.{" "}
            </p>

            <p>
              ii.) The number of arbitrator shall be one. The place of
              arbitration shall be ________. [State] law shall apply.
            </p>

            <p>
              iii.) The arbitration will be conducted in the English language.
            </p>

            <p>
              iv.) Judgment on the award rendered by the arbitrator may be
              entered in any court having jurisdiction thereof.
            </p>

            <p>
              v.) Subject to the Applicable AAA Rules, the arbitrator will have
              the discretion to direct a reasonable exchange of information by
              the parties, consistent with the expedited nature of the
              arbitration.  {" "}
            </p>

            <p>
              vi.) Unless otherwise prohibited by law, all arbitration
              proceedings will be confidential and closed to the public and any
              parties other than you and Amealio, and all records relating
              thereto will be permanently sealed, except as necessary to obtain
              court confirmation of the arbitration award. 
            </p>

            <p>
              <b>e.) Arbitration Process:</b>
              <b />
              Any dispute, controversy or claim arising out of or relating in
              any way to the agreement including without limitation any dispute
              concerning the construction, validity, interpretation,
              enforceability or breach, shall be exclusively resolved by binding
              arbitration upon a Party’s submission of the dispute to
              arbitration.
            </p>

            <p>
              i.) In the event of a dispute, controversy or claim arising out of
              or relating in any way to the agreement, the complaining Party
              shall notify the other Party in writing, their intent to arbitrate
              thereof.
            </p>

            <p>
              ii.) Within thirty (30) days of such notice, the representatives
              of both Parties shall meet at an agreed location to attempt to
              resolve the dispute in good faith as a prerequisite to filing for
              Arbitration.
            </p>

            <p>
              iii.) Should the dispute not be resolved within thirty (30) days
              after such notice, the complaining Party shall seek remedies
              exclusively through arbitration. The demand for arbitration shall
              be made within a reasonable time after the claim, dispute or other
              matter in question has arisen, and in no event shall it be made
              after two years from when the aggrieved party knew or should have
              known of the controversy, claim, dispute or breach.{" "}
            </p>

            <p>
              iv.) The arbitration will be conducted by the American Arbitration
              Association (“AAA”) under its rules as modified by this Agreement,
              including the AAA’s Supplementary Procedures for Consumer-Related
              Disputes. The AAA’s rules are available at www.adr.org{" "}
            </p>

            <p>
              v.) The number of arbitrators shall be one. All issues are for the
              arbitrator to decide, including the scope of this arbitration
              clause, but the arbitrator is bound by the terms of this
              Agreement. Payment of all filing, administration and arbitrator
              fees will be governed by the AAA’s rules.
            </p>

            <p>
              vi.) You may choose to have the arbitration conducted by
              telephone, based on written submissions, or in person in the
              county where you live or at another mutually agreed location. The
              arbitration will be conducted in the English language. Judgment on
              the award rendered by the arbitrator may be entered in any court
              having jurisdiction thereof. The arbitrator, and not any federal,
              state, or local court, shall have exclusive authority to resolve
              any dispute relating to the interpretation, applicability,
              unconscionability, arbitrability, enforceability, or formation of
              this arbitration agreement, including any claim that all or any
              part of this arbitration agreement is void or voidable.{" "}
            </p>

            <p>
              <b>f.) Arbitrator's Decision:</b>
            </p>

            <p>
              i.) The Arbitrator shall include the fundamental findings and
              conclusions on which the arbitrator based the award in the ruling.
            </p>

            <p>
              ii.) The arbitrator shall make the decision within the set time
              frame and in accordance with the AAA rules.
            </p>

            <p>
              iii.) The arbitrator may award monetary damages as well as any
              non-monetary relief available to an individual under applicable
              law, the rules of the arbitral forum, and this Agreement.
            </p>

            <p>
              iv.) Judgment on the award rendered by the arbitrator may be
              entered in any court having jurisdiction thereof.{" "}
            </p>

            <p>
              v.) The arbitrator, and not any federal, state, or local court,
              shall have exclusive authority to resolve any dispute relating to
              the interpretation, applicability, unconscionability,
              arbitrability, enforceability, or formation of this arbitration
              agreement, including any claim that all or any part of this
              arbitration agreement is void or voidable.
            </p>

            <p>
              <b>g.) Cost and Fees:</b>
              <b />
              The cost of the arbitration proceeding and any proceeding in court
              to confirm or to vacate any arbitration award, as applicable
              (including, without limitation, reasonable attorneys’ fees and
              costs), shall be borne by the unsuccessful party, as determined by
              the arbitrators, and shall be awarded as part of the arbitrator’s
              award. It is specifically understood and agreed that any party may
              enforce any award rendered pursuant to the arbitration provisions
              of this Section by bringing suit in any court of competent
              jurisdiction. The parties agree that the arbitrator shall have
              authority to grant injunctive or other forms of equitable relief
              to any party.{" "}
            </p>

            <p>
              The successful party in the arbitration will only be entitled to
              attorneys' fees if the applicable substantive law governing the
              claims in the arbitration allows it. Amealio shall be entitled to
              attorneys' fees and expenses if the arbitrator finds that the
              substance of your claim or the relief sought in your Demand for
              Arbitration was frivolous or undertaken for an improper purpose
              (as measured by the standards set out in Federal Rule of Civil
              Procedure 11(b)).
            </p>

            <p>
              <b>h.) Right to Opt-Out of Arbitration:</b>
              <b />
              You have a right to opt out of the Arbitration within thirty (30)
              days of accepting this agreement through a written intent to opt
              out of arbitration notice, to be sent to the Amealio
              _______[address].
            </p>

            <p>
              <b>i.) Modifications:</b>Amealio retains the right to modify this
              "Dispute Resolution" section at any time, but such modifications
              will not apply to any specific claim(s) for which you have
              previously submitted notice to Amealio. If Amealio modifies this
              "Dispute Resolution" clause after the date you first accepted this
              Agreement, you agree that your continued use of the Platform or
              Services following such modification will be considered acceptance
              of such modifications.{" "}
            </p>

            <p>
              If you do not agree with the modifications you have a right to opt
              out of the same through a notice with an intent to opt out of the
              Dispute Resolution mechanism within 30 days from the day such
              changes have been effective.{" "}
            </p>

            <p>
              <b>j.) Class action waiver:</b>
              <b />
              You and Amealio agree that, to the MAXIMUM EXTENT ALLOWED BY LAW,
              EXCEPT AS provided hereunder:
            </p>

            <p>
              a.) That each claim is personal to you and amealio, and shall only
              be conducted as an individual arbitration (or an individual court
              proceeding and not as a class action or other form of
              representative action;
            </p>

            <p>
              b.) Expressly waive any right to file or participate in a class
              action or seek relief on a class or representative basis; and{" "}
            </p>

            <p>
              c.) The arbitrator (or court, with respect to claims excluded from
              the arbitration agreement, pursuant to subpart may only conduct an
              individual arbitration (or court action, with respect to claims
              excluded from the arbitration agreement, may not consolidate more
              than one individual’s claims, and may not preside over any form of
              representative or class proceeding relating to such claims. Unless
              you submit a proper opt out notice (as described in subsection you
              and bnea agree that each may bring claims against the other only
              in your or its individual capacity, and not as a plaintiff or
              class member in any purported class or representative proceeding.
            </p>

            <p>
              <b>Access to the Platform, Accuracy and security:</b>
              <b />
              i.) We endeavor to make the Platform available to during Merchant
              working hours. However, we do not represent that access to the
              Platform will be uninterrupted, timely, error free, free of
              viruses or other harmful components or that such defects will be
              corrected.
            </p>

            <p>
              ii.) We do not warrant that the Platform will be compatible with
              all hardware and software which you may use. We shall not be
              liable for damage to, or viruses or other code that may affect,
              any equipment (including but not limited to your mobile device),
              software, data or other property as a result of your download,
              installation, access to or use of the Platform or your obtaining
              any material from, or as a result of using, the Platform. We shall
              also not be liable for the actions of third parties.
            </p>

            <p>
              iii.) We do not represent or warranty that the information
              available on the Platform will be correct, accurate or otherwise
              reliable.{" "}
            </p>

            <p>
              iv.) We reserve the right to suspend or withdraw access to the
              Platform to you personally, or to all users temporarily or
              permanently at any time without notice. We may any time at our
              sole discretion reinstate suspended users. A suspended User may
              not register or attempt to register with us or use the Platform in
              any manner whatsoever until such time that such user is reinstated
              by us.
            </p>

            <p>
              iv.) We reserve the right to suspend or withdraw access to the
              Platform to you personally, or to all users temporarily or
              permanently at any time without notice. We may any time at our
              sole discretion reinstate suspended users. A suspended User may
              not register or attempt to register with us or use the Platform in
              any manner whatsoever until such time that such user is reinstated
              by us.
            </p>

            <p>
              vi.) Not all products and services are available in all geographic
              areas.
            </p>

            <p>
              <b>Indemnity Clause:</b>
              <b />
              YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD HARMLESS Amealio and
              Envisionard Corporation; its affiliates and partners; and their
              respective officers, directors, employees, agents, members,
              licensors, representatives, and third party providers to the Site
              and the Mobile App from and against all losses, expenses, damages
              and costs, including reasonable attorneys’ fees, resulting from
              any violation of these Terms for the consequence of any of the
              actions of the Users of the Platform or for any delay or failure
              in performance arising from causes beyond Amealio’s reasonable
              control, including, without limitation, delays and other problems
              inherent in the use of the internet and electronic communications{" "}
            </p>

            <p>
              Amealio reserves the right to assume, at its sole expense, the
              exclusive defense and control of any matter subject to
              indemnification by you, in which event you will fully cooperate
              with Amealio in asserting any available defenses.{" "}
            </p>

            <p>
              Notwithstanding its reasonable efforts in that behalf, Amealio
              cannot take responsibility or control the information provided by
              other Users which is made available on the Platform. You may find
              another User's information to be offensive, harmful, inconsistent,
              inaccurate, or deceptive. Please use caution and practice safe
              trading when using the Platform.
            </p>

            <p>
              <b>Severability:</b>
              <b />
              If any of the provisions, or portions thereof, of this Agreement
              are determined to be illegal, invalid or unenforceable under any
              applicable statute or rule of law in which these Terms of Use are
              intended to be effective, then, that provision notwithstanding,
              this Agreement shall remain in full force and effect and such
              provision or portion thereof shall be deemed omitted.
            </p>

            <p>
              <b>Non-assignment:</b>
              <b />
              This Agreement and the rights granted and obligations undertaken
              hereunder may not be transferred, assigned or delegated in any
              manner by you, the user, but may be so transferred, assigned or
              delegated by Amealio.{" "}
            </p>

            <p>
              <b>Waiver:</b>
              <b />
              Any waiver of any provision of this Agreement, or a delay by any
              party in the enforcement of any right hereunder, shall neither be
              construed as a continuing waiver nor create an expectation of
              non-enforcement of that or any other provision or right.{" "}
            </p>

            <p>
              <b>Governing law:</b>
              <b />
              This Agreement is made under and shall be governed by and
              construed in accordance with the laws of land. Both Amealio and
              you (the “Parties”) hereby agree that any action, suit, or other
              legal proceeding, which is commenced to resolve any matter arising
              under or relating to this Platform and Use of this site shall be
              governed by all applicable Federal laws of the United States of
              America and the laws of the State of New York.{" "}
            </p>

            <p>
              <b>Intellectual Property Rights:</b>
            </p>

            <p>
              a.) Copyright in the pages and in the screens displaying the
              pages, and in the information and material herein is owned by
              Amealio. unless otherwise indicated.
            </p>

            <p>
              <b>b.) Trademarks </b>
              <b />
              Amealio is a trademark and service marks of Envisionard
              Corporation and are used and registered throughout the world.
            </p>

            <p>
              c.) We are either the owner of intellectual property rights or
              have the non-exclusive, worldwide or regional, perpetual,
              irrevocable, royalty free, sub-licensable (through multiple tiers)
              right to exercise the intellectual property, in the Platform, and
              in the material published on it.
            </p>

            <p>
              d.) You may print off one copy, and may download extracts, of any
              page(s) from the Platform for your personal reference and you may
              draw the attention of others within your organization to material
              available on the Platform.{" "}
            </p>

            <p>
              e.) You must not modify the paper or digital copies of any
              materials you have printed off or downloaded in any way, and you
              must not use any illustrations, photographs, video or audio
              sequences or any graphics separately from any accompanying text.{" "}
            </p>

            <p>
              f.) You must not use any part of the materials on the Platform for
              commercial purposes without obtaining a license to do so from us
              or our licensors.
            </p>

            <p>
              g.) If you print off, copy or download any part of the Platform in
              breach of these Terms of Use, your right to use the Platform will
              cease immediately and you must, at our option, return or destroy
              any copies of the materials you have made.{" "}
            </p>

            <p>
              <b>
                NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT
                RELATING TO THE ACTIVITIES OF SERVICE PROVIDERS{" "}
              </b>
            </p>
            <p>
              <b>DESIGNATED AGENT </b>
            </p>

            <p>
              Pursuant to Section 512(c)(2) of the Copyright Revision Act, as
              amended by the Digital Millennium Copyright Act ("Act"), written
              notice OF ANY CLAIMED COPYRIGHT INFRINGEMENT RELATING TO THE
              ACTIVITIES OF SERVICE PROVIDERS UNDER SECTION 512 OF THE ACT must
              be submitted to the Designated Agent named below. ANY NOTICE SENT
              TO THE DESIGNATED AGENT MUST MEET THE REQUIREMENTS OF SECTION
              512(C)(3) OF COPYRIGHT REVISION ACT, AS AMENDED BY THE DIGITAL
              MILLENNIUM COPYRIGHT ACT.
            </p>

            <p>Service Provider(s): </p>

            <p>Designated to Receive Notification of Claimed Infringement: </p>

            <p>Name: Designation: </p>

            <p>Address: </p>

            <p>Email:</p>

            <p>
              <b>Third party links:</b>
              <b />
              This site may contain links to web sites controlled or offered by
              third parties (non-affiliates of amealio). Amealio hereby
              disclaims liability for, any information, materials, products or
              services posted or offered at any of the third party sites linked
              to this web site. By creating a link to a third party web site,
              amealio does not endorse or recommend any products or services
              offered or information contained at that web site, nor is Amealio
              liable for any failure of products or services offered or
              advertised at those sites. Such third party may have a privacy
              policy different from that of amealio and the third party website
              may provide less security than the amealio site.{" "}
            </p>

            <p>
              <b>No warranty:</b>
              <b />
              The information and materials contained in this site, including
              text, graphics, links or other items are provided "as is", "as
              available". Amealio does not warrant the accuracy, adequacy or
              completeness of this information and materials and expressly
              disclaims liability for errors or omissions in this information
              and materials. No warranty of any kind, implied, expressed or
              statutory including but not limited to the warranties of
              non-infringement of third party rights, title, merchantability,
              fitness for a particular purpose and freedom from computer virus,
              is given in conjunction with the information and materials.
            </p>

            <p>
              <b>Additional Terms:</b>
              <b />
              Certain sections or pages on this site may contain separate terms
              and conditions, which are in addition to these terms and
              conditions. In the event of a conflict, the additional terms and
              conditions will govern for those sections or pages.{" "}
            </p>

            <p>
              <b>Payments:</b>
              <b />
              1. Any payments made by you towards Amealio for completed and
              delivered orders, inclusive of payment for placing and receiving
              orders, are final and non-refundable. Amealio will charge you on
              the payment method specified at the time of purchase for services
              or orders.{" "}
            </p>

            <p>
              2. Where required by law, charges will include relevant taxes,
              other relevant fees, cost of returning or denying the order/
              delivery and/or cancellation fees may be charged.
            </p>

            <p>
              3. Furthermore, you understand and accept that Charges applicable
              in certain geographical regions may significantly fluctuate.
            </p>

            <p>
              4. The prices on the mentioned on the Menu are not final and are
              subject to fluctuations. Prices for items on the menu or other
              services displayed on the Platform may differ from prices offered
              or published by Merchants for the same menu or other items, as
              well as prices available on third-party websites, and may not be
              the lowest prices at which the menu or other items are sold.{" "}
            </p>

            <p>
              5. Delivery Costs, Service Fees, Small Order Fees, and Surge Fees
              are types of fees that we may change as we think necessary or
              suitable to our operations. Amealio reserves a right to change the
              prices mentioned under point 5 of this section at any time on its
              sole discretion.{" "}
            </p>

            <p>
              6. All or some of our users may also be eligible for special
              offers or other promotions at Amealio's discretion, which may
              include different features and pricing. Perks and promotional
              offers will not affect your responsibility to pay the amounts
              charged if they are not distributed to you and are not made
              available to you.{" "}
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Ususerterms;
