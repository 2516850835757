import React from "react";
// import ReactDOM from "react-dom";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
// VisibilityContext

// NOTE: embrace power of CSS flexbox!
// import "./arrowsOnBottomOrTop.css";
// import "./hideScrollbar.css";
// import "./firstItemMargin.css";

const elemPrefix = "test";
const getId = (index) => `${elemPrefix}${index}`;

const getItems = () =>
  Array(20)
    .fill(0)
    .map((_, ind) => ({ id: getId(ind) }));

const Categoriesbelt = (props) => {
  const categories = props.data;
  const [items] = React.useState(getItems);

  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag =
    ({ scrollContainer }) =>
    (ev) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const [selected, setSelected] = React.useState("");
  const handleItemClick = (itemId) => () => {
    if (dragging) {
      return false;
    }
    setSelected(selected !== itemId ? itemId : "");
  };

  return (
    <section className="categories-belt-section">
      <div className=" ">
        <h2 className="categories-belt-header">
          <b>{categories.categoryBeltHeading}</b>
        </h2>

        <div className="categories">
          {/* <Swiper
               spaceBetween={20}
               slidesPerView={11}                
               onSlideChange={() => console.log("slide change")}
               onSwiper={swiper => console.log(swiper)}
            >

               {categories.data.map(categories => {
                  let imgData = "../assets/images/fastfood/" + categories.image + ".png";
                  return (
                     <SwiperSlide key={categories.title} className="categories-belt-li">
                        <img src={imgData} alt="img"></img>
                        <label className="categories-label">{categories.title}</label>
                     </SwiperSlide>
                  )
               })
               }
            </Swiper> */}

          <div
            className={
              categories.data.length < 8 ? "scrollcat centerdata" : "scrollcat"
            }
            onMouseLeave={dragStop}
          >
            <ScrollMenu
              onWheel={onWheel}
              onMouseDown={() => dragStart}
              onMouseUp={() => dragStop}
              onMouseMove={handleDrag}
            >
              {categories.data.map((categories) => {
                let imgData =
                  "../assets/images/fastfood/" + categories.image + ".png";
                return (
                  <li key={categories.title} className="categories-belt-li">
                    <img src={imgData} alt="img"></img>
                    <label className="categories-label">
                      {categories.title}
                    </label>
                  </li>
                );
              })}
            </ScrollMenu>
          </div>

          <ul className="categories-belt">
            {categories.data.map((categories) => {
              let imgData =
                "../assets/images/fastfood/" + categories.image + ".png";
              return (
                <li key={categories.title} className="categories-belt-li">
                  <img src={imgData} alt="img"></img>
                  <label className="categories-label">{categories.title}</label>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

function useDrag() {
  const [clicked, setClicked] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);
  const position = React.useRef(0);

  const dragStart = React.useCallback((ev) => {
    position.current = ev.clientX;
    setClicked(true);
  }, []);

  const dragStop = React.useCallback(
    () =>
      // NOTE: need some delay so item under cursor won't be clicked
      window.requestAnimationFrame(() => {
        setDragging(false);
        setClicked(false);
      }),
    []
  );

  const dragMove = (ev, cb) => {
    const newDiff = position.current - ev.clientX;

    const movedEnough = Math.abs(newDiff) > 5;

    if (clicked && movedEnough) {
      setDragging(true);
    }

    if (dragging && movedEnough) {
      position.current = ev.clientX;
      cb(newDiff);
    }
  };

  return {
    dragStart,
    dragStop,
    dragMove,
    dragging,
    position,
    setDragging,
  };
}

function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

export default Categoriesbelt;
