import React from "react";
// import Header from '../templates/header';
import Footer from "../templates/footer";

const Usbusinessterms = () => {
  return (
    <div className="app">
      <section>
        <div className="container">
          <div className="us_businessterms">
            <h2>
              <b>BUSINESS TERMS AND CONDITIONS</b>
            </h2>
          </div>
          <div className="us_business_content">
            <p>
              Hey! We are glad to have you here, and we can't wait to serve you,
              so that you can serve your guests better, but before you use our
              Platform, please read these Terms and Conditions carefully.
            </p>

            <p>
              This following sets out the terms and conditions on which you may
              use the content on our website, mobile browser site, Applications
              and other digital publishing services owned by Envisionard
              Corporation hereby “the Company”.{" "}
            </p>

            <p>
              <b>About Us:</b>
              <br />
              This Platform is operated by Envisionard Corporation, a Company
              incorporated under the Companies Act, 2013 and having its
              registered address at, One Sansome St Suite 3500, San Francisco,
              California,
              <br />
              USA 94104.{" "}
            </p>

            <p>
              Please note that these Terms of Service constitute a legally
              binding agreement between Merchants registered on the Amealio for
              Business Platform (“Merchant” or “You” or “Your”) and Envisionard
              Corporation (“Amealio”,“we”, “us” or “our”), regarding use of
              Amealio’s Platform for the provision of Restaurant Services by the
              Merchant to the Customers. This Agreement shall commence on the
              Effective Date and continue until terminated by either Party in
              accordance with these terms and Conditions. Certain features of
              our Services may be subject to additional terms and conditions,
              which are incorporated herein by reference.{" "}
            </p>

            <p>
              Please note that these Terms are intended to govern and make you
              aware of your legal rights and responsibilities with respect to
              your subscription to our services and use of our Platform.
            </p>

            <p>
              Amealio enables transactions on its Platform inter alia between
              participating Restaurants/Merchants/Vendors and Buyers/Customers,
              dealing in (a) prepared food and beverages, (b) consumer goods,
              and (c) other products and services ("Platform Services")
              operating an online/mobile market platform named as Amealio. The
              Buyers/Customers ("Customer/s") through enhanced experience can
              choose and place orders ("Orders") from a variety of products and
              services listed and offered for sale by various merchants
              including but not limited to the restaurants, eateries and grocery
              stores ("Merchant/Restaurant Partner/Vendor/Outlet/s"), on the
              Platform, pursuant to which Amealio enables enhanced experiences
              and offerings.{" "}
            </p>

            <p>
              To use our Services, you must first accept the Terms. You can
              accept the Terms by accessing and/or using the Amealio Platform or
              Services, or by clicking on “I Agree” icon, you agree to all the
              terms mentioned in this Agreement. If you do not agree with these,
              please do not access and/or use our Platform or Services. Your use
              of the Platform is at your own risk, including the risk that you
              might be exposed to content that is objectionable, or otherwise
              inappropriate.
            </p>

            <p>
              The services provided on our platform are not available to minors
              i.e., persons under the age of 18 (eighteen) years, Nineteen (19)
              years of age in Alabama and Nebraska, Twenty-one (21) years of age
              in Mississippi or to any Users suspended or removed by us from
              accessing the Platform for any reason whatsoever. You warrant and
              represent that you are "competent to contract" and are eligible to
              use the Platform You are of legal age to form a binding contract
              and are not a person barred from receiving using or availing our
              Services under the applicable laws. If you are minor and still
              wish to visit, install, access or use the Platform, your parents
              or legal guardian must acknowledge and agree to these Terms and
              Conditions. If they failed to do so or refuse to agree or
              acknowledge these terms & conditions and Amealio policies, you
              shall immediately discontinue its use and delete your account.
            </p>

            <p>
              Section 16 of these terms and conditions shall govern any claims
              or dispute arising that the parties may have against each other,
              including without limitation a binding arbitration provision,
              including claims which have initiated prior to enforcement of this
              agreement.{" "}
            </p>

            <p>
              <b>1.) Definitions:</b>
              <br />
              i.) “Platform” - “Platform” means the Amealio platform provided by
              the company which include online ordering, dine in, skip the
              table, curbside, table reservations, etc.which may be amended by
              the company from time to time, including: (i) the Website and (ii)
              the App. The Amealio Website and the App are collectively referred
              to as Platform the including, but not limited to, the conveyance
              of any information on or through the website, whether existing or
              in future, that links to the Terms.{" "}
            </p>

            <p>
              ii.) “Merchant” means the party that includes any individual
              and/or entity and/or corporation and/or any other legal person
              that carries out business of selling food and beverages on the
              Amealio platform{" "}
            </p>

            <p>
              iii.) “User” means an employee or agent of Merchant who accesses
              and uses the Platform on these terms and conditions.{" "}
            </p>

            <p>
              iv.) “Menu” means your Merchant menu submitted to Amealio, as
              updated by you from time to time.{" "}
            </p>

            <p>
              v.) “Order” means a meal order for your food and/or beverages
              submitted by Customers through Amealio via the Platform.
            </p>

            <p>
              vi.) “Data” means data collected by or through the Platform,
              including, without limitation the Personal Identifiable
              Information (PII) of Customers.{" "}
            </p>

            <p>
              vii.) “Effective Date” means the date You accepted this terms and
              conditions.{" "}
            </p>

            <p>
              viii.) “Merchant Content” means any content submitted by you to
              Amealio, including, without limitation, the Menu.{" "}
            </p>

            <p>
              ix.) “Customer/Buyer” means a party that places an Order or
              Marketplace Order on our platform.{" "}
            </p>

            <p>
              x.) “Amealio Content" means content that Amealio creates and make
              available in connection with the Services including, but not
              limited to, visual interfaces, interactive features, graphics,
              design, compilation, computer code, products, software, aggregate
              ratings, reports and other usage-related data in connection with
              activities associated with your account and all other elements and
              components of the Services excluding Your Content and Third-Party
              Content.{" "}
            </p>

            <p>
              xi.)"Third Party Content" means content that comes from parties
              other than Amealio or its Customers and is available on the
              Services.
            </p>

            <p>
              xii.) "Content" will include (but is not limited to) reviews,
              images, photos, audio, video, location data, nearby places, and
              all other forms of information or data. "Customer Content" means
              content that the customer who uploads, shares or transmits to,
              through or in connection with the Services, such as likes,
              ratings, reviews, images, photos, messages, profile information,
              and any other materials that you publicly display or displayed in
              your account profile. "Amealio Content" means content that Amealio
              creates and make available in connection with the Services
              including, but not limited to, visual interfaces, interactive
              features, graphics, design, compilation, computer code, products,
              software, aggregate ratings, reports and other usage-related data
              in connection with activities associated with your account and all
              other elements and components of the Services excluding Your
              Content and Third Party Content. "Third Party Content" means
              content that comes from parties other than Amelio or its Customers
              and is available on the Services.{" "}
            </p>

            <p>
              xiii.) “Restaurant” means a commercial establishment(s) from where
              the Restaurant Services are made available to the Customer,
              moreover wherein the food and beverage items are prepared and/or
              delivered to the Customer.{" "}
            </p>

            <p>
              <b>2.) Registration:</b>
              <br />
              i.) The Merchant hereby can join the Platform by signing up into
              the account with some third-party social networking sites (" SNS")
              (including but not limited to, Facebook); or by registering for an
              account on our platform and become our Merchant Partner.{" "}
            </p>

            <p>
              ii.) Membership is absolutely non-transferable and is restricted
              for the purpose. You can link your Amealio Account with Third
              Party Accounts as part of the functionality by either providing
              your Third-Party Account login information to us through the
              Platform or allowing us access to your Third-Party Account, as
              permitted by the applicable terms and conditions that govern your
              use of each Third-Party Account.{" "}
            </p>

            <p>
              iii.) The Merchant hereby affirms and understand that he can only
              create and use one account on the Platforms for your personal use.
            </p>

            <p>
              iv.) While registering on our Amealio Business platform, merchant
              will be asked to provide some basic business-related information.
              The Merchant hereby agree and acknowledge that the information you
              submit on the Platform is complete, accurate, and up-to-date as of
              the date of your registration. You must notify us in writing of
              any changes to such information at least one week before the
              change takes effect. You understand and agree that Amealio has not
              independently validated the information you have provided to us.
              Amealio assumes no liability or responsibility for the
              correctness, inaccuracy, obsolescence, or completeness of any
              information given.{" "}
            </p>

            <p>
              v.) If you choose to use the Platform or appoint a user at its
              discretion for the purpose of handling their Business Account on
              our platform, it shall be your responsibility (a) to keep your
              Account and the account related details such as the User
              Identification Code, Password (“Login Credentials”) and any other
              piece of information that we may provide, as part of our security
              procedures, confidential, not disclose the same to any person or
              entity other than us and restricting access to your computer or
              mobile device, (b) update us all the necessary information of that
              you have and your authorized user has to ensure that the data is
              current, accurate and complete, and (c) for all activities that
              occur under your account or password.
            </p>

            <p>
              vi.) We shall reserve the right to disable any Account or login
              credentials if you have failed to comply with any of the
              provisions of these Terms.
            </p>

            <p>
              vii.) We have the right to revoke or suspend your access to our
              services if we learn that you have shared your personal access.
              You are responsible for all service usage made by you or anyone
              else using your ID, as well as preventing illegal usage of your
              ID.{" "}
            </p>

            <p>
              viii.) If you suspect there has been a security breach, such as
              the exposure, theft, or unauthorised use of your ID or payment
              information, please notify us right away at__________________. We
              recommend that you use a user password that is not obvious (such
              as your name) and that you update it on a frequent basis.
            </p>

            <p>
              <b>3.) Term, Termination, And Suspension:</b>
              <br />
              i.) Term - This Agreement shall commence on the Effective Date and
              continue until terminated by either Party in accordance with these
              terms and Conditions.
            </p>

            <p>
              ii.) Termination and Suspension. Either Party may terminate this
              Agreement: <br />
              a.) For a material breach by the other Party where such breach is
              not cured within five (5) days of written notice of such breach;
              and{" "}
            </p>

            <p>
              b.) Time, for any or no reason, upon ten(10) days prior notice to
              the other Party (without penalty or liability). The Company may
              suspend access to the Amealio Platform or remove some or all
              Merchant’s Content from our platform in the Company’s sole
              discretion, if the Company’s reasonably believes that Merchant,
              Merchant’s patrons, or any third part is engaged in:{" "}
            </p>

            <p>
              c.) Any activity that may harm the company, its systems, or any
              third-party systems; or{" "}
            </p>

            <p>
              d.) Fraudulent or illegal activity or any other activity that
              could result in legal liability to the Company or any third party.
              Any such suspension may continue until the activity causing the
              suspension has been cured and the company has received
              satisfactory assurances that it will not recur.{" "}
            </p>

            <p>
              iii.) Effect of Termination. Upon termination of this Agreement:{" "}
              <br />
              a.) The Company shall charge Merchant’s credit card, bank account,
              or other approved facility for any outstanding Fees and Taxes
              pursuant to section____________ and any other amounts due under
              this Agreement and Refer our Refund Policy for further details;
              and{" "}
            </p>

            <p>
              b.) All rights and licenses granted hereunder will immediately
              cease, and Merchant will immediately cease all access to and use
              of the Platform.
            </p>

            <p>
              c.) The company shall remove your Business Listing from your
              website, and you shall assist us in doing so.
            </p>

            <p>
              d.) The Company may terminate Your future access to the Platform
              or suspend or terminate Your Account and Amealio Services if it
              believes, in its sole and absolute discretion that You have
              infringed, breached, violated, abused, or unethically manipulated
              or exploited any term of these Terms of Use or anyway otherwise
              acted unethically.{" "}
            </p>

            <p>
              e.) You hereby agree and acknowledge, upon termination, Amealio
              shall have the right to retain all information pertaining to the
              transactions initiated by You on the Platform.
            </p>

            <p>
              f.) You hereby further agree and acknowledge that on or before
              termination, You shall ensure that all the monies due to be paid
              to Amealio are paid in a timely manner.
            </p>

            <p>
              g.) You hereby further agree and acknowledge that in case of
              non-payment of dues within the prescribed timelines, Amealio may:
              (a) adjust the amount due from the amount payable by Amealio to
              You; and (b) at its sole discretion take appropriate legal action
              against You to recover the same.
            </p>

            <p>
              <b>4.) Merchant’s Obligations: </b>
              <br />
              i.) The Merchant shall accept the request for Orders/Bookings
              received through the Platform within a time span of 5 (Five)
              minutes of receipt of request from the Customer, failing which the
              request for Orders/Bookings shall be automatically cancelled. In
              case of request for Orders/Bookings received from the Customer
              through the Platform for a future date, the Merchant shall accept
              the order at the earliest possible but not later than 24 hours of
              the receipt of request from the Customer. After the expiry of 24
              hours, the request for Orders/Bookings shall stand automatically
              cancelled.
            </p>

            <p>
              ii.) While servicing Orders/Bookings received from Customer
              through the Platform and/or providing Restaurant Services to
              Customers, the Merchant will not discriminate in any manner.{" "}
            </p>

            <p>
              iii.) Merchant shall ensure that all mandatory information
              pertaining to taxes, levies, and charges applicable on the
              Order(s) / Booking (s) are clearly visible to the Customers on
              their invoice as per the applicable laws.{" "}
            </p>

            <p>
              iv.) Merchant will ensure that the Information provided to Amealio
              is current and accurate, including but not limited to the
              Merchant’s name, address, contact number, email, manager/contact
              person details, delivery times, opening hours, menus, price lists,
              allergy and nutrition information, taxes, service addresses, and
              other relevant information.
            </p>

            <p>
              v.) Merchant shall ensure that it is the sole owner of, or
              otherwise controls all the content / material, including but not
              limited to the Merchant’s name, Restaurant /business establishment
              name, logo, menu items, images of the food and beverages/ menu
              items, etc. transmitted or submitted by the Merchant to Amealio
              either through the Platform or otherwise (“Content”), or has been
              granted the right to use such Content from the rights holder of
              such Content and that it does not violate or infringe the rights
              of any third party or applicable laws;
            </p>

            <p>
              vi.) Merchant shall process and execute the Order(s)/Booking(s)
              promptly.
            </p>

            <p>
              vii.) Merchant shall not cancel any order/booking after acceptance
              of the same through the Platform. In the event the Merchant
              cancels any order, Amealio reserves the right to levy or recover a
              charge of an amount up to the Order Value of the order/booking
              that is cancelled.{" "}
            </p>

            <p>
              viii.) It is the responsibility of the Merchant to inform Amealio
              about any change or modification made to the Order/Booking by the
              Customer, directly with the Merchant.{" "}
            </p>

            <p>
              ix.) The Merchant warrants that the food and beverages provided to
              Customers under the Restaurant Services are: (a) of high quality
              and fit for human consumption; (b) compliant with and the rules,
              regulations, licenses, standards and guidelines issued thereunder;
              and (c) compliant with all other relevant Indian legislation,
              including all applicable food industry regulations.
            </p>

            <p>
              x.) The Merchant shall strictly adhere to the safety standards
              including sanitation and temperature standards. Merchant shall
              ensure that all bags or containers that are delivered to the
              customer be sealed by the restaurant before they are handed off to
              the delivery person. In addition to that, food holding areas must
              meet sanitation standards, and food must be maintained at a
              temperature necessary to prevent any spoilage.{" "}
            </p>

            <p>
              xi.) The Merchant shall be solely responsible for the accuracy and
              veracity of the Allergy and Nutrition information provided by the
              Merchant and displayed by the Amealio on its Platform. The
              Merchant shall make all reasonable efforts for strict adherance of
              the same and ensure that the food items are in consonance with the
              Allergy and Nutrition information as provided by them. In the
              event, any mishap takes place or any claim arises from the
              User/Buyer due to misrepresentation of the Allergy and Nutrition
              information, the Merchant shall be solely liable for the same and
              shall at no point of time hold Amealio liable for the same. The
              Merchant further agrees to indemnify and keep indemnified the
              Amealio for any claims, losses, costs, damages, expenses and
              liabilities of any nature whatsoever that may be sustained by
              Amealio due to any such misrepresented or misleading Allergy and
              Nutrition information.
            </p>

            <p>
              xii.) You are properly registered to do business and have obtained
              all necessary licences (including, but not limited to, a liquor
              license, if applicable) to provide the products and services
              listed on your menu listing and/or provided to Users in accordance
              with all federal, state and local laws and other applicable laws
              and regulations in all jurisdictions where they conduct business;
            </p>

            <p>
              xiii.) The Merchant shall maintain and procure all authorized
              user/s to maintain the confidentiality of the username
              (“Username”) and password (“Password”) required to access the
              System.
            </p>

            <p>
              xiv.) In the event, the merchant appoints one or more authorized
              user for handling the business account on our platform. It shall
              be the responsibility of the merchant to keep and maintain an
              accurate list of its authorized user/s, as we may review and
              request you to send us the list of the authorized user/s from time
              to time, as the case maybe, in order to keep and support our
              services and to ensure compliance with these terms.{" "}
            </p>

            <p>
              xv.) The Merchant is solely responsible for all the changes made
              by its authorized user/s, regardless whether or not such action
              was authorized by the Merchant or not. Amealio shall not be liable
              for any such action taken by the authorized user/s after the
              Merchant has attempted to removal of such authorized user/s from
              our platform.{" "}
            </p>

            <p>
              xvi.) The Merchant is solely responsible for any use of, action
              taken and transactions that occur under the Username and the
              Password.{" "}
            </p>

            <p>
              xvii.) The merchant will be responsible to prepare, handle, store,
              label and package (including but not limited to foodware,
              plasticware, and other disposable restaurant supplies) all Items
              in accordance with the relevant applicable laws and regulations,
              including without limitation all laws, rules and regulations
              governing time or temperature controls, sanitation required for
              food safety (“Food Safety Standards”). The merchant will further
              disclose the common allergens in any of the Merchant Menu items
              listed on our Platform
            </p>

            <p>
              xviii.) The merchant will handle and label ‘Alcohol Items’, if
              applicable in accordance with all the applicable laws, rules and
              regulation as per the ‘Alcohol Safety Standards’.
            </p>

            <p>
              xix.) Merchant consents to receive messages from Amealio or the
              Company by email, text message, phone calls, and push
              notifications to the cellular telephone number/email address
              Merchant provided to Amealio. Merchant agrees to receive
              communications generated by automatic telephone dialling systems
              and/or that transmit prerecorded messages delivered by or on
              behalf of Amealio and its connected entities. In Merchant's
              Account Settings, the merchant can opt out of such messages.
            </p>

            <p>
              xx.) The merchant is entitled to use our Platform solely for the
              purpose as set forth in this Agreement. The merchant shall not,
              and shall not authorize except to the extent permitted by
              applicable law, decompile, disassemble, reverse engineer, or
              otherwise attempt to derive the source code or underlying
              technology, methodologies, or algorithms of any of our platform or
              services with respect to the Dashboard, App, or website, (b)
              sublicense, lease, rent, sell, give, or otherwise transfer or
              provide access to any unaffiliated third party. All rights not
              expressly given to a third party, including Authorized Users, are
              reserved by Amealio under this Agreement.{" "}
            </p>

            <p>
              <b>
                5.) Merchant Menu, Price List and Allergy/Nutrition information:
              </b>
              <br />
              i.) Amealio will display on the Platform the menu and price list
              for all of its Merchants. The Merchant agrees that Amealio
              reserves the right to modify and delete certain items from the
              Merchant’s menu list at its sole discretion, listed on the
              Platform, to ensure compliance with Health and Safety Code,
              Business and Professions Code, requirements or applicable law in
              the relevant State or Territory and all other applicable
              legislation, regulations or standards. Amealio shall endeavour to
              update price lists within 24 hours of being notified of changes
              (in writing) by a Merchant.
            </p>

            <p>
              ii.) The Merchant will, at all times, maintain parity in the
              pricing for all products offered for sale to the Customers via the
              Platform as against that made available for sale otherwise,
              including but not limited to any online aggregator and at its
              Restaurant locations. For the purpose of clarity, such parity is
              required to be maintained by the Merchant in food and beverage
              items sold via the Platform and its quality, quantity and other
              charges that may be levied by the Merchant.{" "}
            </p>

            <p>
              iii.) The Merchant shall not charge an amount over and above the
              maximum retail price ("MRP") for all such food and beverage items
              which have MRP mentioned on them.
            </p>

            <p>
              iv.) Amealio may perform a variety of marketing activities to
              promote the Merchant and the Merchant's menu; provided, however,
              that all such marketing activities will be determined in Amealio’s
              sole and absolute discretion and the Platform may be modified or
              updated, without notice and from time to time, to reflect any such
              changes.{" "}
            </p>

            <p>
              v.) Allergy and Nutrition Information: If the Merchant has agreed
              to provide the Allergy, Personalization and/or Nutrition Info,
              reasonable efforts should be made to describe the ingredients and
              allergy information on each of the menu items in detail and items
              are properly tagged with the properties to provide information
              related to it. Personalization items are served as per the
              requests stipulated by the Customer, Merchant shall make
              reasonable items to substitute or make choice available as per
              choice of Customer. The Merchant shall be solely responsible for
              the accuracy and conformity to facts of the Allergy,
              Personalization and Nutrition information provided by the Merchant
              and displayed by the Amealio on its Platform.
            </p>

            <p>
              The Merchant shall ensure compliance with Patient Protection and
              Affordable Care Act of 2010 (Affordable Care Act), if such
              restaurant and similar retail food establishment are
              <br />
              a.) Part of a chain with 20 or more locations, and{" "}
            </p>

            <p>b.) Doing business under the same name, and </p>

            <p>c.) Offering for sale substantially the same menu items </p>

            <p>
              are required to disclose calorie information on menus and menu
              boards for standard menu items; post a succinct statement
              concerning suggested daily caloric intake on menus and menu
              boards; and post on menus and menu boards a statement that written
              nutrition information is available upon request of the customer.
              In addition, this law requires to display calories for combination
              meals, which come with more than one food item, and also if the
              alcoholic beverage is a standard menu item that is listed on a
              menu.
            </p>

            <p>
              6.) Additional Terms And Conditions For The Merchants Providing
              Online Ordering And Seating Services Through Amealio Platform:
              <br />
              A.) Walk-in /Waitlist: <br />
              a.) The Customer can make a Walk-in /Wait List request for booking
              a table for specific number of persons, for specific needs (such
              as handicap and high chair requirements) at a Restaurant accepting
              Walk-in /Wait List request through the Amealio Platform. The
              Customer may also select seating area as per his preference as
              well as select special occasion. After the Customer makes a
              Walk-in /Wait List request, the Merchant must ascertain the
              availability of a booking and accordingly accept and confirm the
              booking within 5 minutes of the receipt of request; else the
              Walk-in /Wait List request shall be auto-cancelled. If the
              Merchant has activated “Auto-accept”, the Walk-in request will get
              confirmed automatically. Once, the Merchant accepts the Walk-in
              request, the Customer will be notified through Push Notification
              and short message service (“SMS”).{" "}
            </p>

            <p>
              b.) The Customer may edit/modify the Walk-in /Wait List request
              any time before the status changes to Seated, however, any request
              for modification of the confirmed bo0king will be subject to
              acceptance of the same by the Merchant. The Merchant will use its
              best endeavours to accept the request for modification of the
              Walk-in /Wait List request.
            </p>

            <p>
              c.) Order Ahead: The Customer has the option to place an Order
              through the Amealio Platform before reaching the Restaurant for
              which he has received a confirmation for the Walk-in /Wait List
              request.
            </p>

            <p>
              d.) The Customer will provide Expected Time of Arrival (ETA) to
              the Merchant and shall be allowed to change the ETA three times
              and the Merchant must reconfirm the ETA five minutes before the
              expiry of ETA. Even after reconfirming the ETA for the third time,
              if the Customer does not show up, the Merchant may cancel the
              Walk-in /Wait List request.
            </p>

            <p>
              e.) When the Customer arrives at the Restaurant, the Merchant
              should get the Customer seated at the earliest as per the selected
              seating preference and in conformity with the specific Walk-in
              /Wait List request. In the event, the Merchant fails to get the
              Customer upon his arrival as per the ETA, the Merchant shall be
              solely liable to the Customer for such delay and Amealio shall in
              no manner be held liable.
            </p>

            <p>
              f.) In the event, the Merchant fails to honor the Walk-in /Wait
              List request or in case of any other complaint raised by the
              Customer in relation to the Booking, Amealio will make reasonable
              efforts to verify the facts of such complaint with the Restaurant
              and may at its sole discretion initiate the refund (if any) to the
              Customer and Amealio shall be entitled to deduct such Refund
              Amount from any amount payable to the Restaurant by Amealio.{" "}
            </p>
            <p>
              <b>B.) Reservation:</b>
              <br />
              a.) The Customer can make a request for “Reservation” for the same
              day or for a future date for specific number of persons, for
              specific needs (such as handicap and high chair requirements) at a
              Restaurant accepting Reservation request through the Amealio
              Platform. The Customer may also select seating area as per his
              preference as well as select special occasion. After the Customer
              makes a request for Reservation for the same day, the Merchant
              must ascertain the availability of a booking and accordingly
              accept and confirm the booking within 5 minutes of the receipt of
              request; else the Reservation request shall be auto-cancelled. If
              the Restaurant has activated “Auto-accept”, the Reservation
              request will get confirmed automatically. If the Customer makes a
              request for Reservation for a future day, the Merchant must
              ascertain the availability of a booking and accordingly accept and
              confirm the booking within 24 hours of the receipt of request;
              else the Reservation request shall be auto-cancelled. Once, the
              Restaurant accepts the request for Reservation, the Customer will
              be notified through Push Notification and short message service
              (“SMS”).
            </p>

            <p>
              b.) The Customer may edit/modify the Reservation request any time
              with regards to the date/time reservation, seating preference,
              special occasion, special needs requirement before the status
              changes to Seated, however, any request for modification of the
              confirmed Reservation request will be subject to acceptance of the
              same by the Merchant. The Merchant will use its best endeavors to
              accept the request for modification of the Reservation request.
            </p>

            <p>
              c.) Order Ahead: The Customer has the option to place an Order
              through the Amealio Platform before reaching the Restaurant for
              which he has received a confirmation for the Reservation request.
            </p>

            <p>
              d.) The Customer may enable location tracking or provide Expected
              Time of Arrival (ETA) to the Merchant and shall be allowed to
              change the ETA three times and the Merchant must reconfirm the ETA
              five minutes before the expiry of ETA. Even after reconfirming the
              ETA for the third time, if the Customer does not show up until the
              expiry of _____minutes from the ETA, the Merchant may cancel the
              Reservation request.{" "}
            </p>

            <p>
              e.) When the Customer arrives at the Restaurant, the Merchant
              should get the Customer seated at the earliest as per the selected
              seating preference and in conformity with the specific Reservation
              request. In the event, the Merchant fails to get the Customer
              seated upon his arrival as per the ETA, the Merchant shall be
              solely liable to the Customer for such delay and Amealio shall in
              no manner be held liable.
            </p>

            <p>
              f.) In the event, the Merchant fails to honor the Reservation
              request or in case of any other complaint raised by the Customer
              in relation to the Reservation, Amealio will make reasonable
              efforts to verify the facts of such complaint with the Merchant
              and may at its sole discretion initiate the refund (if any) to the
              Customer and Amealio shall be entitled to deduct such Refund
              Amount from any amount payable to the Merchant by Amealio.{" "}
            </p>

            <p>
              <b>C.) Takeaway: </b>
              <br />
              The Merchant agrees to the following terms and conditions if the
              Merchant has accepted to provide an option to the Customers who
              have placed Order through the Amealio Platform and opted to
              collect the Order themselves from the Restaurants ("Takeaway"):{" "}
            </p>

            <p>
              a.) After the Order is accepted by the Merchant and the
              preparation time is communicated to the Customer on the Platform
              (“Takeaway Time”), the Merchant shall ensure that the Order is
              ready by the Takeaway Time.{" "}
            </p>

            <p>
              b.) In the event, the Merchant fails to deliver the Order to
              Customer by the Takeaway Time, the Merchant shall be liable to the
              Customer for such delay.
            </p>

            <p>
              c.) In the event the Customers raises any complaint on account of
              any delay beyond the Takeaway Time or due to any fault
              attributable to the Merchant, Amealio shall be entitled to refund
              to the Customer an amount up to the Order Value at its sole
              discretion and Amealio shall be entitled to deduct such Refund
              Amount from any amount payable to the Merchant by Amealio.
            </p>

            <p>
              <b>D.) Curbside:</b>
              <br />
              The Merchant agrees to the following terms and conditions if the
              Merchant has accepted to provide the option of Curbside Delivery
              to the Customers who have placed Order through the Amealio
              Platform and opted for Curbside Delivery from the select
              Restaurants ("Curbside"):
            </p>

            <p>
              a.) Merchant shall ensure that the Order is prepared and ready by
              the Curbside Delivery Time communicated to the Customer on the
              Platform ("Curbside Time").
            </p>

            <p>
              b.) The Merchant shall provide Curbside Delivery to the Vehicle of
              the Customer (details of the Vehicle such as Vehicle Type, Colour,
              Model No.) which are provided by the Customer placing the Order on
              the Platform).{" "}
            </p>

            <p>
              c.) The Customer may enable location tracking or enter Expected
              Time of Arrival (ETA) to the Merchant. The Customer shall be
              allowed to change the ETA three times and the Merchant must
              reconfirm the ETA five minutes before the expiry of ETA. Even
              after reconfirming the ETA for the third time, if the Customer
              does not show up, the Merchant may cancel the Order.
            </p>

            <p>
              d.) In the event, the Merchant fails to deliver the Order to
              Customer by the Curbside Time, the Merchant shall be liable to the
              Customer for such delay.
            </p>

            <p>
              e.) In the event the Customers raises any complaint on account of
              any delay beyond the Curbside Time or due to any fault
              attributable to the Merchant, Amealio shall be entitled to refund
              to the Customer an amount up to the Order Value at its sole
              discretion and Amealio shall be entitled to deduct such Refund
              Amount from any amount payable to the Merchant by Amealio.
            </p>

            <p>
              <b>E.) Skip the Line:</b>
              <br />
              The Merchant agrees to the following terms and conditions if the
              Merchant has accepted to provide an option to the Customers to
              place the Order through the Amealio Platform and collect Order
              themselves from the Restaurants when the Order is ready without
              standing in the queue ("Skip The Line"):
            </p>

            <p>
              a.) Merchant shall ensure the Order is ready by the preparation
              time communicated to the Customer on the Platform ("Skip the Line
              Time").
            </p>

            <p>
              b.) In the event, the Merchant fails to handover the Order to
              Customer by the Skip the Line Time, the Merchant shall be liable
              to the Customer for such delay.
            </p>

            <p>
              c.) In the event, the Merchant fails to deliver the Order to
              Customer by the Skip the Line Time, the Merchant shall be liable
              to the Customer for such complaint or due to any fault
              attributable to the Merchant, Amealio shall be entitled to refund
              to the Customer an amount up to the Order Value at its sole
              discretion and Amealio shall be entitled to deduct such Refund
              Amount from any amount payable to the Merchant by Amealio.
            </p>

            <p>
              <b>F.) Drive in/Drive Through:</b>
              <br />
              The Merchant agrees to the following terms and conditions if the
              Merchant has accepted to provide another takeaway option to the
              Customers to place the Order through the Amealio Platform and
              collect Order themselves from the Restaurants when the Order is
              ready without leaving their cars ("Drive-in/Drive Thru"):
            </p>

            <p>
              a.) Merchant shall ensure the Order is ready by the preparation
              time communicated to the Customer on the Platform ("Drive-in/Drive
              Thru Time").
            </p>

            <p>
              b.) The Merchant may enable ‘Auto-Accept' the order immediately,
              and/or may provide for the future date and time reservation for
              accepting such order.
            </p>

            <p>
              c.) The Merchant may enable customization to all or specific
              order/s to be availed by the customer through Drive-in/Drive Thru
              service. The merchant is entitled to enable the item availability
              schedule for its menu item/s, whether or not such item is
              available for Drive-in/Drive/ Drive Thru service during the
              customer’s specified time.
            </p>

            <p>
              d.) The Merchant shall provide the Drive-Thru Pickup Counter/Drive
              in Pickup Location to the Customer (details of the Counter like
              name or number of the counter and its detailed address).
            </p>

            <p>
              e.) In the event, the Merchant fails to handover the Order to
              Customer by the Drive-in/Drive Thru Time, the Merchant shall be
              liable to the Customer for such delay.
            </p>

            <p>
              f.) In the event, the Merchant fails to deliver the Order to
              Customer by the Drive-in/Drive-Thru Time, the Merchant shall be
              liable to the Customer for such complaint or due to any fault
              attributable to the Merchant, Amealio shall be entitled to refund
              to the Customer an amount up to the Order Value at its sole
              discretion and Amealio shall be entitled to deduct such Refund
              Amount from any amount payable to the Merchant by Amealio.
            </p>

            <p>
              <b>G. Banquet and Catering:</b>
              <br />
              The Merchant agrees to the following terms and conditions if the
              Merchant has accepted to provide the option through Amealio
              Platform of Catering/Banquet services allowing customers to
              reserve a particular event or celebration or offering Merchant
              services to special events located at Merchant’s Business Premises
              such as birthdays, dinner parties, or family gatherings ("
              Banquet/Catering "):
            </p>

            <p>
              a.) The Customer is entitled for “Banquet/Catering” services for
              the same day or for a future date for specific number of persons,
              for specific needs (such as Food Presentations, Decorations as per
              the occasion requirements) at a Restaurant accepting the Event
              request through our Platform. The Customer may also select seating
              area as per his preference as well as select special occasion.
              When the customer makes such request for the same day, the
              Merchant must ascertain the availability of a booking and
              accordingly accept and confirm the booking within 5 minutes of the
              receipt of request; else such request shall be deemed
              auto-cancelled.{" "}
            </p>

            <p>
              b.) If the Merchant has enabled “Auto-accept”, the
              Banquet/Catering request will get confirmed automatically
              depending upon the availability of such services during the
              customer specified date and time. If the Customer has opted for a
              future date while making such request, the Merchant must ascertain
              the availability of a booking and accordingly accept and confirm
              the booking within 24 hours of the receipt of request; else the
              Reservation request shall be deemed auto-cancelled. Once, the
              Merchant accepts the Banquet/Catering service request, the
              Customer will be notified through Push Notification and short
              message service (“SMS”).{" "}
            </p>

            <p>
              c.) The Merchant is entitled to create a banquet area on our
              platform by filling out the details such as floor number, area
              name, shape, capacity, seating service including the seating
              location such as garden, indoor, outdoor, AC/Non-AC, etc., seating
              services.{" "}
            </p>

            <p>
              d.) The Customer may edit/modify the request any time with regards
              to the date/time reservation, seating preference, special
              occasion, special needs requirement before the status changes to
              Seated, however, any request for modification of the confirmed
              Banquet/Catering request will be subject to acceptance of the same
              by the Merchant. The Merchant will use its best endeavors to
              accept the request for modification of such request.{" "}
            </p>

            <p>
              e.) The Customer may enable location tracking or provide Expected
              Time of Arrival (ETA) to the Merchant and shall be allowed to
              change the ETA three times and the Merchant must reconfirm the ETA
              five minutes before the expiry of ETA. Even after reconfirming the
              ETA for the third time, if the Customer does not show up until the
              expiry of _____minutes from the ETA, the Merchant may cancel the
              Banquet/Catering request.{" "}
            </p>

            <p>
              f.) When the Customer arrives at the Restaurant, the Merchant
              should make all the necessary arrangements as requested by the
              Customer and get the Customer seated at the earliest as per the
              selected seating preference and in conformity with the specific
              Banquet/Seating request. In the event, the Merchant fails to get
              the Customer seated upon his arrival as per the ETA, the Merchant
              shall be solely liable to the Customer for such delay and Amealio
              shall in no manner be held liable.
            </p>

            <p>
              g.) In the event, the Merchant fails to honour the
              Banquet/Catering request or in case of any other complaint raised
              by the Customer in relation to the Banquet/Catering Services,
              Amealio will make reasonable efforts to verify the facts of such
              complaint with the Merchant and may at its sole discretion
              initiate the refund (if any) to the Customer and Amealio shall be
              entitled to deduct such Refund Amount from any amount payable to
              the Merchant by Amealio.{" "}
            </p>

            <p>
              <b>7. Customer Reviews Content:</b>
              <br />
              i.) There will be times, when customer/s will write reviews and
              give ratings for the food, packaging, or Restaurant related
              services on our Platform, such reviews and ratings are customer’s
              opinions and they do not reflect opinions of Amealio’s. .
              Furthermore, none of the content made accessible through the
              Services is approved or certified by the content's producers or
              licensors. Amealio is not responsible for any of Your Content or
              third-party content.{" "}
            </p>

            <p>
              ii.) Amealio is a neutral platform that only facilitates dialogue
              between customers/reviewers, as well as restaurant
              owners/representatives, who have access to the restaurant's
              business page. Advertisements on the Amealio Platform are
              published regardless of the feedback obtained by such advertisers.
              However, if someone publishes a review that the restaurant does
              not believe is accurate, the restaurant representative's best
              choice is to contact the reviewer or make a public response to
              clear up any misconceptions with decency.
            </p>

            <p>
              iii.) If the restaurant feels that a particular Customer's review
              is in violation of any of Amealio's policies, the restaurant may
              write to us to alert us. If the review is in violation of the
              Terms, or content standards and rules, or otherwise detrimental to
              our services, Amealio may delete it at its sole discretion.{" "}
            </p>

            <p>
              iv.) Amealio reserves the right, at any time and without notice,
              to delete, prohibit, or deactivate access to any Content that we,
              in our sole discretion, believe to be objectionable, in violation
              of the Terms, or otherwise damaging to the Services or our
              Customers or is illegal or against the law in any manner for any
              reason or no reason.
            </p>

            <p>
              v.) We are not required to return any of the Merchant’s Content to
              you under any circumstances, subject to the requirements of
              applicable law. Furthermore, the Merchant retains the right, at
              its sole discretion, to remove any photos or pictures that are
              part of Customer Content from the Restaurant's listing page.
            </p>

            <p>
              <b>8. License: </b>
              <br />
              i.) Merchant grants to Amealio an unrestricted, non-exclusive,
              royalty-free licence in respect of all Content (defined
              hereinabove) and Information provided to Amealio by the Merchant
              for the purposes of inclusion on the Platform. This includes, but
              is not limited to, (a) use of the Merchant’s name in the context
              of Google ad words to support advertising and promotional
              campaigns to promote online ordering on internet which may be
              undertaken by Amealio (b) preparation of derivative works of, or
              incorporate into other works, (d) menus, photographs (either
              uploaded by the Merchant or on Merchant’s website), trademarks,
              logos, business information and Merchant’s goods description
              (either uploaded by the Merchant or on Merchant’s website), all or
              any portion of the marketing materials which will be made by
              Amealio for the purposes of its business. Please refer our Photo
              and Video guidelines for more details.{" "}
            </p>

            <p>
              ii.) Any Content, information or material that the Merchant
              transmits or submits to Amealio either through the Platform or
              otherwise shall be considered and may be treated by Amealio as
              non-confidential, subject to Amealio’s obligations under relevant
              data protection legislation.{" "}
            </p>

            <p>
              iii.) The Merchant also grants to Amealio a royalty-free,
              perpetual, irrevocable, non-exclusive license to use, copy,
              modify, adapt, translate, publish and distribute world-wide any
              Content, information or material for the purposes of providing
              Services under these Terms or to or for the purposes of
              advertising and promotion of the Platform. The Merchant agrees
              that all Content, information or material provided to Amealio that
              is published on the Platform, may be relied upon and viewed by
              Customers to enable them to make informed decisions.
            </p>

            <p>
              iv.) Any breach of the license provisions as mentioned in this
              Section by you may result in the immediate termination/suspension
              of your Account, as well as potential responsibility for copyright
              and other IP Rights infringement, depending on the circumstances.
            </p>

            <p>
              <b>9. Terms Related To The Sale Of Alcohol Items:</b>
              <br />
              Merchant may choose to offer alcoholic beverages for sale on our
              Amealio Platform in your menu listings, subject to applicable laws
              and regulations and the following terms and conditions:
            </p>

            <p>
              Representations and Warranties <br />
              If Merchant is eligible to offer the sale and fulfillment of
              alcoholic beverages through specified Sales Channels in designated
              U.S. states, Merchant represents and warrants that
            </p>

            <p>
              a.) Merchant shall comply with all laws and regulations related to
              the sale of Alcohol Items applicable to Merchant, including
              without limitation, all laws and regulations related to the
              promotion, packaging, sale, taxation and delivery of Alcohol
              Items; and{" "}
            </p>

            <p>
              b.) Merchant has and will continue to have all the necessary
              licenses, permits, or similar authorizations that may be required
              for it to sell Alcoholic Items during the Term of the Agreement,
              including without limitation, Required Licenses for the specific
              categories of Alcohol Items that Merchant makes available via the
              App(s) and/or Required Licenses to sell and deliver Alcohol Items
              in a given state or local jurisdiction.
            </p>

            <p>
              c.) When the customer places an order, Merchant agrees and
              acknowledges that it is Merchant's responsibility to verify
              identity and the age of the Customer and confirm that the Customer
              is not intoxicated when delivering Alcohol Items to the Customer
              or when the Customer picks up the alcohol from Merchant on its
              business premises. It will be the responsibility of the Merchant
              to ensure that the customer is of legal drinking age. The merchant
              must take every effort to ensure that alcoholic beverages are not
              delivered to or served to anyone who is under the qualified age
              and comply with all other relevant respective state laws.{" "}
            </p>

            <p>
              d.) You further acknowledge and agree that Amealio will not be
              liable for delivery of alcoholic beverages by the merchant’s
              noncompliant delivery of Alcohol Items.
            </p>

            <p>
              e.) The merchant must ensure compliance with the 1984 National
              Minimum Drinking Age Act, [23 U.S.C. § 158]. When a customer
              places an order of alcoholic beverages from the menu listings, the
              Merchant must ensure that the customer is of legal drinking age in
              order to access our Platform for placing an order for alcoholic
              beverages.
            </p>

            <p>
              f.) Amealio as well as the Merchant are responsible for taking any
              necessary steps to inform the other party of the applicable laws,
              codes, regulations, licenses, and any other similar requirements
              related to the sale and delivery of Alcohol Items.{" "}
            </p>

            <p>
              <b>10. Amendments:</b>
              <br />
              These Terms and Conditions are subject to modifications at any
              point in time hereon. We reserve the right to modify or change
              these Terms of Use and other Amealio policies at any time by
              posting changes on the Platform, and you shall be liable to update
              yourself of such changes if any, by accessing the changes on the
              Platform from time to time. You shall, at all times, be
              responsible for regularly reviewing the Terms of Use and the other
              Amealio policies and note the changes made on the Platform. Your
              continued usage of the services after any change is posted
              constitutes your acceptance of the amended Terms of Use and other
              Amealio policies and you are bound by such changed/amended Terms.
            </p>

            <p>
              <b>11. Indemnity & Limitation of Liability:</b>
              <br />
              i.) In consideration for you being given access to our Platform,
              you hereby release, waive, discharge and covenant not to sue the
              Amealio, subsidiaries, DBAs, affiliates, successors, contractors,
              agents, representatives and/or employees from any and all
              liability, claims, demands, actions and causes of action
              whatsoever arising out of or relating to any damage to your
              property or loss, damage or injury that you personally sustain,
              including death, whether caused by the negligence of the Amealio
              or its representatives or not while participating in Amealio
              programs, using Site content and/or attending Amealio Events in
              person, regardless of location. You hereby assume all risks to
              your property and your person and in no way will the Amealio be
              liable to you for damages or injuries you sustain.{" "}
            </p>

            <p>
              ii.) Amealio in no manner is liable to the member if any outlet of
              Merchant temporarily or permanently shuts down its operations.
              Amealio will only take care of claims/liabilities arising out of
              offers/membership plans advertised by our platforms solely.{" "}
            </p>

            <p>
              Merchant shall at all times indemnify and hold harmless the
              Company and its officers, employees, agents and contractors from
              and against any loss (including legal costs and expenses), damage,
              settlement, claim or liability incurred or suffered as a result
              of:{" "}
            </p>

            <p>
              a.) A breach by the Merchant of any obligations under the
              Agreement;
            </p>

            <p>
              b.) Any willful, unlawful or negligent act or omission of the
              Merchant or its Related Parties;
            </p>

            <p>
              c.) Any breach or inaccuracy in any of the Merchant’s
              representations, undertakings or warranties under the Agreement;{" "}
            </p>

            <p>
              d.) The Merchant or the Related Parties’ negligence or willful
              misconduct; or
            </p>

            <p>
              any action, complaint, liability or claim, including claims for
              personal injury, sickness, death or property damage, brought by
              (i) any party in connection with his/her use of any offer, coupon,
              Voucher, table booking and/or queuing service and/or spot payment
              service and/or any other service made available on the platform
              through the Merchant use of the Merchant Services or arising from
              his/her consumption of any service or goods; or (ii) any party
              which relates to all or any part of the Information, including but
              not limited to leakage of information from the Database or any
              infringement of intellectual property rights, defamation or
              misappropriation of trade secrets caused by any act of the
              Merchant or by his authorized user or any of the Related Parties.
            </p>

            <p>
              <b>12. Amealio’s Obligations:</b>
              <br />
              i.) Amealio will (a) list the Menu /Food items along-with the
              price list on the Platform; (b) display photos of Food items and
              Business establishment and (c) display allergy and/or nutrition
              information if the Merchant has agreed to provide the same.{" "}
            </p>

            <p>
              ii.) All necessary information provided by the Merchant will
              displayed by Amealio on the Platform, on best effort basis.
              However, Amealio is not obliged to display any information until
              the Merchant provides all required information and such
              information is in compliance with Amealio’s policies and
              guidelines.
            </p>

            <p>
              iii.) Amealio shall have the right to collect the Payment on
              behalf of the Merchant in respect of the Orders/Events/Bookings
              received through the Platform from the Customers and remit the
              same to the bank account of the Merchant in accordance with the
              Payment Terms after deducting therefrom (i) Service Fee (ii) Other
              Charges and (iii) Other applicable taxes thereon at the prevailing
              rates.
            </p>

            <p>
              iv.) Amealio will transmit the Orders placed by the Customer with
              the Merchant on the Merchant dashboard on the Application and such
              Order transmission will be on a real time basis.
            </p>

            <p>
              v.) Amealio reserves the right to automatically cancel the request
              for Orders/Bookings received through the Platform, if the Merchant
              does not accept the request within a time span of 5 (Five) minutes
              of receipt of request from the Customer. In case of request for
              Orders/Bookings received from the Customer through the Platform
              for a future date, Amealio reserves the right to automatically
              cancel the request for Orders/Bookings if the Merchant does not
              accept the request within 24 hours of the receipt of request for
              Orders /Bookings from the Customers through the Platform.
            </p>

            <p>
              vi.) Amealio will redress the Customers and the Merchant’s
              complaints in respect of the functioning of the Platform.
            </p>

            <p>
              vii.) For the avoidance of doubt it is hereby expressly clarified
              that Amealio is only responsible for providing (i) a Platform to
              the Merchant to list, offer and sell the Restaurant Services to
              the Customers and/or (ii) Logistics Services (if applicable) and
              that Amealio will not be responsible or liable for (i) the quality
              of the Restaurant Services listed and advertised on the Platform;
              and/or (ii) the processing of the Orders which have been placed by
              the Customers with the Merchant on the Platform; and/or (iii) any
              delay in preparation or delivery of the Order by Merchant.
            </p>

            <p>
              viii.) If the Merchant is found to be non-compliant with the Food
              Safety and Standards and/or Alcohol Standards, if applicable and
              the rules, regulations, licenses, standards and guidelines issued
              thereunder from time to time, Amealio may suspend the Merchant’s
              account.{" "}
            </p>

            <p>
              ix.) In the event, the Merchant opts for Logistics Services; (a)
              Merchant agrees to pay the delivery charges as may be levied by
              Amealio. (b) The Merchant acknowledges and agrees that delivery
              will be facilitated by Amealio through a Logistics Personnel.
              Merchant understands and agrees that Amealio shall not be
              responsible if the logistics personnel indulge in theft of the
              Order or indulges in any illegal activity or misconduct
              against/with the Customer(s) and / or the staff of Merchant or
              indulges in any vandalism activity against the Merchant. (c) The
              Merchant shall not hold Amealio liable for any claims, raised by
              the Customer where the delay in handing over the Order to the
              Logistics Personnel is solely attributable to the Merchant and its
              staff. (d) Amealio may at its sole discretion charge Delivery
              Charges from Customer for delivery of the Order.{" "}
            </p>

            <p>
              <b>a. Payment Settlement Process:</b>
              <br />
              i.) The Merchant acknowledges and agrees that any Order Value
              which is collected by Amealio for, and on behalf of, the Merchant
              in accordance with these Terms, shall be passed on by Amealio to
              the Merchant subject to the deduction of the following amounts (as
              applicable) by Amealio from such Order amount:
              <br />
              a.) Service Fee for Services subscribed by the Merchant;{" "}
            </p>

            <p>
              b.) Commission Rate, Marketing fees, Activation Fees, subscription
              fees, and any other fees which Amealio may notify you with at
              least 7 days prior written notice;
            </p>

            <p>c.) Amount of Payment Mechanism Fee due from Merchant; </p>

            <p>d.) Cancellation charges as levied by Amealio; </p>

            <p>
              e.) Any other amount that are due to Amealio as per the agreed
              terms or on account of other services availed by Merchant from
              Amealio, to which specific consent of the Merchant is accorded.
            </p>

            <p>
              ii.) The Merchant acknowledges and agrees that we may charge the
              Customer fees (including but limited to) Delivery charges in
              Amealio’s sole Discretion.
            </p>

            <p>
              iii.) The Merchant acknowledge and agree that after the deduction
              of the aforementioned amounts, Amealio shall remit the Order Value
              due to the Merchant within a period of 3 bank working days, from
              the date the payment of the Order Value is received by Amealio.
            </p>

            <p>
              iv.) From time to time, Amealio may change the fees for the
              Services, Cancellation charges including without limitation the
              Payment Mechanism Fee or include any additional charges/ fee,
              provided however, that Amealio communicates such change(s) via
              email or any other modes of communication, thirty (30)days prior
              to the date that such change(s) are to take effect.
            </p>

            <p>
              <b>b. Taxes: </b>
              <br />
              i.) Notwithstanding anything to the contrary herein, the Merchant
              is, and will be, responsible for all taxes, payments, fees, and
              any other liabilities associated with the computation, payment,
              and collection of taxes in connection with Customer Orders and the
              Merchant’s use of the Platform and Amealio Services. Amealio may
              charge and collect applicable taxes from Customers on behalf of
              the Merchant in accordance with instructions provided by the
              Merchant and/or applicable law; and, in which case, Amealio will
              collect such tax solely on behalf of the Merchant and shall pay
              such amount collected to the Merchant. The Merchant shall be
              solely responsible for verifying amounts collected, filing the
              appropriate tax returns, and remitting the proper amount to the
              appropriate tax authorities. Taxes shall include all applicable
              taxes due in relation to the sale of food and beverages, including
              pick-up and delivery services (if applicable), by the Merchant. It
              is clarified that Amealio will not be liable for payment of any
              Taxes that the Merchant is liable to pay in connection with the
              Restaurant Services which shall be provided by the Merchant to the
              Customers in accordance with these Terms and that the Merchant
              hereby undertakes to indemnify, defend and hold harmless, Amealio
              and each of its affiliates and (as applicable) all of their
              directors, officers employees, representatives and advisors
              against any tax liability that may arise against Amealio on
              account of the non-payment of Taxes by the Merchant under these
              Terms. Merchant will be required to deposit relevant taxes,
              including tax deducted at source (TDS) on the Service Fee payable
              to Amealio.{" "}
            </p>

            <p>
              ii.) Amealio may be obliged to collect sales, use, or similar
              taxes from the Customer and submit such taxes directly to the tax
              authorities in various US States. Amealio will notify Merchant in
              such States that any collected taxes will be sent to the tax
              authorities, and Amealio will be released of any duty to pay such
              collected taxes to Merchant.
            </p>

            <p>
              <b>c. Refund and Cancellation:</b>
              <br />
              1.) By Merchant:
              <br />
              i.) The Merchant shall not be entitled to cancel the Request after
              acceptance and confirmation of the same. However, in the event the
              Merchant is either unable to honor the request of the Customer or
              cancels the Order, due to reasons including without limitation,
              technical errors, non-availability of food items ordered by the
              Customer, non – availability of the service, or any other reasons
              attributable to the Merchant, the Customer is eligible for the
              refund of the Order Value or any part thereof and the said amount
              will be reversed in the Amealio Wallet.
            </p>

            <p>
              ii.) In case of cancellation for any of the reasons attributable
              to the Merchant, Amealio shall not charge any liquidated damages
              from the Customer.
            </p>

            <p>
              <b>2). By Amealio:</b>
              <br />
              i.) There may be circumstances due to which Amealio is either
              unable to accept the order, due to any reasons including without
              limitation, technical errors, non-availability of the service,
              inaccurate particulars such as contact number, address, etc.
              provided by the Customer, unresponsive Customer, etc. In such
              circumstances, Amealio reserves the right, at its sole discretion,
              to refuse or cancel any order/booking for any reason whatsoever,
              without assigning any reason to the Customer and the Customer
              accepts and undertakes to not raise any dispute on the same.
            </p>

            <p>
              ii.) If the order is cancelled after payment has been made and the
              Customer is eligible for the refund of the Order Value or any part
              thereof in accordance with the policies of Amealio, the said
              amount will be reversed in the Customer’s Amealio Wallet. In the
              event the Customer has provided incorrect particulars, e.g.,
              contact number, delivery address, etc., or that the Customer was
              unresponsive, not reachable or unavailable for fulfillment of the
              services offered to them, the Customer will not be eligible for
              any refunds.
            </p>

            <p>
              iii.) Amealio's decision of cancellation of order shall be final
              and Amealio shall not be liable for any such cancellation
              whatsoever.
            </p>

            <p>
              <b>d. Dispute Resolution:</b>
              <br />
              Amealio and Merchant believe that making good-faith informal
              efforts to address conflicts may frequently lead to a quick,
              low-cost, and mutually beneficial resolution. As a result, before
              either you or Amealio files a demand for arbitration against the
              other, we undertake to meet and confer informally through
              telephone or videoconference in a good-faith attempt to resolve
              any dispute covered by this mutual Arbitration Agreement.
              Participating in an informal dispute resolution conference is a
              condition that must be met before arbitration may begin. While the
              parties engage in the informal dispute settlement procedure
              required by this paragraph, the statute of limitations and any
              filing fee deadlines will be tolled.
            </p>

            <p>
              This section provides for an arbitration clause between the
              Merchant and Amealio stating that all or any dispute or
              controversy arising under or in connection with this agreement,
              shall be exclusively settled through binding and final arbitration
              it requires you to arbitrate disputes on an individual basis and
              restricts the ways in which you can seek redressal from Amealio.
            </p>

            <p>Please read this section carefully. </p>

            <p>
              1. You agree and acknowledge that:
              <br />
              I.) You waive the right to participate or seek relief as a
              plaintiff or class member in any class or representative action or
              proceeding against.{" "}
            </p>

            <p>
              ii.) You waiving your right to seek relief in a court of law and
              to have a trial by jury.{" "}
            </p>

            <p>
              iii.) This Section shall survive the termination or cancellation
              of this Agreement.{" "}
            </p>

            <p>
              <b>2. Arbitration Clause:</b>
              <br />
              i.) Any controversy or claim arising out of or relating to this
              agreement inclusive of any claims or disputes arising before the
              existence of this agreement, or the breach thereof, shall be
              settled by arbitration administered by the American Arbitration
              Association under its Commercial Arbitration Rules.{" "}
            </p>

            <p>
              ii.) An arbitrator will decide the claim, where no judge or jury
              will be a part to adjudicate the proceedings.   We both
              acknowledge and agreed that we waived the right to trial by jury
              to the fullest extent permitted by law, and we both agree to waive
              the right to such a trial.
            </p>

            <p>
              <b>3. Arbitration Rules:</b>
              <br />
              i.) The arbitration will be administered by the American
              Arbitration Association ("AAA"). If AAA is not available to
              arbitrate, the parties will select an alternative arbitration
              provider. Except as modified by this “Dispute Resolution”
              provision, the AAA will administer the arbitration in accordance
              with either (A) the Commercial Arbitration Rules then in effect,
              or (B) the Consumer Arbitration Rules then in effect if the matter
              involves a “consumer” agreement as defined by Consumer Arbitration
              Rule R-1 (together, the "Applicable AAA Rules"). The Applicable
              AAA Rules are available at https://www.adr.org/Rules or by calling
              the AAA at 1-800-778-7879.{" "}
            </p>

            <p>
              ii.) The number of arbitrator shall be one. The place of
              arbitration shall be ________. [State] law shall apply.{" "}
            </p>

            <p>
              iii.) The arbitration will be conducted in the English language.{" "}
            </p>

            <p>
              iv.) Judgment on the award rendered by the arbitrator may be
              entered in any court having jurisdiction thereof. {" "}
            </p>

            <p>
              v.) Subject to the Applicable AAA Rules, the arbitrator will have
              the discretion to direct a reasonable exchange of information by
              the parties, consistent with the expedited nature of the
              arbitration. {" "}
            </p>

            <p>
              vi.) Unless otherwise prohibited by law, all arbitration
              proceedings will be confidential and closed to the public and any
              parties other than you and Amealio, and all records relating
              thereto will be permanently sealed, except as necessary to obtain
              court confirmation of the arbitration award. {" "}
            </p>

            <p>
              <b>4. Arbitration Process:</b>i.) Any dispute, controversy or
              claim arising out of or relating in any way to the agreement
              including without limitation any dispute concerning the
              construction, validity, interpretation, enforceability or breach,
              shall be exclusively resolved by binding arbitration upon a
              Party’s submission of the dispute to arbitration.{" "}
            </p>

            <p>
              ii.) In the event of a dispute, controversy or claim arising out
              of or relating in any way to the agreement, the complaining Party
              shall notify the other Party in writing, their intent to arbitrate
              thereof.{" "}
            </p>

            <p>
              iii.) Within thirty (30) days of such notice, the representatives
              of both Parties shall meet at an agreed location to attempt to
              resolve the dispute in good faith as a prerequisite to filing for
              Arbitration.{" "}
            </p>

            <p>
              iv.) Should the dispute not be resolved within thirty (30) days
              after such notice, the complaining Party shall seek remedies
              exclusively through arbitration. The demand for arbitration shall
              be made within a reasonable time after the claim, dispute or other
              matter in question has arisen, and in no event shall it be made
              after two years from when the aggrieved party knew or should have
              known of the controversy, claim, dispute or breach.
            </p>

            <p>
              v.) The arbitration will be conducted by the American Arbitration
              Association (“AAA”) under its rules as modified by this Agreement,
              including the AAA’s Supplementary Procedures for Consumer-Related
              Disputes. The AAA’s rules are available at www.adr.org.{" "}
            </p>

            <p>
              vi.) The number of arbitrators shall be one. All issues are for
              the arbitrator to decide, including the scope of this arbitration
              clause, but the arbitrator is bound by the terms of this
              Agreement. Payment of all filing, administration and arbitrator
              fees will be governed by the AAA’s rules.{" "}
            </p>

            <p>
              You may choose to have the arbitration conducted by telephone,
              based on written submissions, or in person in the county where you
              live or at another mutually agreed location. The arbitration will
              be conducted in the English language. Judgment on the award
              rendered by the arbitrator may be entered in any court having
              jurisdiction thereof. The arbitrator, and not any federal, state,
              or local court, shall have exclusive authority to resolve any
              dispute relating to the interpretation, applicability,
              unconscionability, arbitrability, enforceability, or formation of
              this arbitration agreement, including any claim that all or any
              part of this arbitration agreement is void or voidable.{" "}
            </p>

            <p>
              <b>5. Arbitrator's Decision:</b>
              <br />
              i.) The Arbitrator shall include the fundamental findings and
              conclusions on which the arbitrator based the award in the ruling.{" "}
            </p>

            <p>
              ii.) The arbitrator shall make the decision within the set time
              frame and in accordance with the AAA rules.{" "}
            </p>

            <p>
              iii.) The arbitrator may award monetary damages as well as any
              non-monetary relief available to an individual under applicable
              law, the rules of the arbitral forum, and this Agreement.{" "}
            </p>

            <p>
              iv.) Judgment on the award rendered by the arbitrator may be
              entered in any court having jurisdiction thereof.{" "}
            </p>

            <p>
              v.) The arbitrator, and not any federal, state, or local court,
              shall have exclusive authority to resolve any dispute relating to
              the interpretation, applicability, unconscionability,
              arbitrability, enforceability, or formation of this arbitration
              agreement, including any claim that all or any part of this
              arbitration agreement is void or voidable.{" "}
            </p>

            <p>
              <b>6. Cost and Fees:</b>
              <br />
              The cost of the arbitration proceeding and any proceeding in court
              to confirm or to vacate any arbitration award, as applicable
              (including, without limitation, reasonable attorneys’ fees and
              costs), shall be borne by the unsuccessful party, as determined by
              the arbitrators, and shall be awarded as part of the arbitrator’s
              award. It is specifically understood and agreed that any party may
              enforce any award rendered pursuant to the arbitration provisions
              of this Section by bringing suit in any court of competent
              jurisdiction. The parties agree that the arbitrator shall have
              authority to grant injunctive or other forms of equitable relief
              to any party.{" "}
            </p>

            <p>
              The successful party in the arbitration will only be entitled to
              attorneys' fees if the applicable substantive law governing the
              claims in the arbitration allows it. Amealio shall be entitled to
              attorneys' fees and expenses if the arbitrator finds that the
              substance of your claim or the relief sought in your Demand for
              Arbitration was frivolous or undertaken for an improper purpose
              (as measured by the standards set out in Federal Rule of Civil
              Procedure 11(b)).{" "}
            </p>

            <p>
              <b>7. Right to Opt-Out:</b>
              <br />
              You have a right to opt out of the Arbitration within thirty (30)
              days of accepting this agreement through a written intent to opt
              out of arbitration notice, to be sent to the Amealio
              _______[address]
            </p>

            <p>
              <b>13. Privacy Policy:</b>
              <br />
              By using the Services, you agree to be bound by this Agreement and
              acknowledge and agree to the collection, use, and disclosure of
              your personal information in accordance with Amealio’s Privacy
              Policy, which is incorporated in this Agreement by reference. You
              also agree to abide by any additional Amealio policies for Users
              that are published on our platform.{" "}
            </p>

            <p>
              You represent that you have read understood and agreed to our
              Privacy Policy.{" "}
            </p>

            <p>
              Please note that we may disclose information about you to third
              parties or government authorities if we believe that such a
              disclosure is reasonably necessary to <br />
              i.) Take action regarding suspected illegal activities;{" "}
            </p>

            <p>ii.) Enforce or apply our Terms and Privacy Policy; </p>

            <p>
              iii.) Comply with legal process or other government inquiry, such
              as a search warrant, subpoena, statute, judicial proceeding, or
              other legal process/notice served on us; or{" "}
            </p>

            <p>
              iv.) Protect our rights, reputation, and property, or that of the
              Customers, affiliates, or the general public.
            </p>

            <p>
              Use of and access to the Platform, is offered to the Merchant upon
              the condition of acceptance of (i) these Terms of Use, (ii) any
              agreement signed by the Merchant with Amealio (iii) the Privacy
              Policy available at____________(website), and (iv) any
              modifications made by Amealio at its sole discretion and posted on
              the Platform from time to time.
            </p>

            <p>
              <b>14. Confidentiality: </b>
              <br />
              Other than for provision of Service(s) by Amealio, Amealio does
              not share any other information of the Merchant with third parties
              unless requisitioned by government authorities.{" "}
            </p>

            <p>
              Other than for the purpose of availing Service(s) from Amealio,
              the Merchant must not disclose any confidential information about
              Amealio, including but not limited to these Terms, its business
              strategies, pricing, revenues, expenses, Customer Data, and Order
              information to third parties.{" "}
            </p>

            <p>
              <b>15. Warranty and Indemnity: </b>
              <br />
              i.) Both the parties Amealio and Merchant hereby warrant and
              represent that (a) both has the full power, eligibility and
              authority to agree with the terms and conditions set forth in this
              agreement and perform their obligations hereunder in the decided
              manner, (b) such party’s acceptance and performance of its
              obligations, does not and will not violate any other agreement to
              which such party is a party, and (c) both the parties are duly
              organized, validly existing and in good standing under the laws of
              its origin.
            </p>

            <p>
              ii.) Merchant warrants that if the Merchant ceases to do business,
              closes operations for a material term or is otherwise unable to
              offer services to Customers it will inform Amealio promptly. Where
              the Merchant neglects to do so, whether by omission or fault,
              Amealio will not be held responsible for any liabilities, whether
              financial or otherwise.{" "}
            </p>

            <p>
              iii.) Merchant warrants that it will not offer for sale any
              potentially hazardous food, beverages, tobacco product, or any
              other item prohibited by law.{" "}
            </p>

            <p>
              iv.) Amealio warrants that it will undertake its obligations with
              reasonable skill and care.{" "}
            </p>

            <p>
              v.) Amealio does not guarantee or warrant that the Platform,
              Application, software, hardware or services will be free from
              defects or malfunctions. If errors occur, it will use its best
              endeavours to resolve these as quickly as possible.
            </p>

            <p>
              vi.) Merchant warrants that it complies and will remain compliant
              with the Health and Safety Code, Business and Profession Code,
              Alcohol Standards and the applicable local, city, state and
              federal, national and international laws, rules, regulations or
              standards, or any other requirements made thereunder{" "}
            </p>

            <p>
              vii.) Merchant warrants to procure and keep valid during the
              subsistence of these Terms, all requisite licenses and/or
              registrations thereunder and provide copy of such licenses and/or
              registrations to Amealio prior to availing the Services.
            </p>

            <p>
              viii.) The Merchant agrees to indemnify and hold Amealio (and its
              directors, officers, agents, representatives and employees)
              harmless from and against any and all claims, suits, liabilities,
              judgments, losses and damages arising out of or in connection with
              any claim or suit or demand: <br />
              a.) By a Customer (or any party on whose behalf a Customer has
              been acting), for reasons not attributable to the Service;{" "}
            </p>

            <p>
              b.) By a Customer (or any party on whose behalf a Customer has
              been acting) or any third party in respect of, arising out of or
              relating to the Content, Information or material provided by the
              Merchant to Amealio, to be listed on the Platform.{" "}
            </p>

            <p>
              c.) In respect of, arising out of, or in connection with,
              Restaurant Services (or any other services actually or purportedly
              offered in respect of or connected to the Restaurant Services) and
              delivery of such Restaurant Services when undertaken by the
              Merchant;{" "}
            </p>

            <p>
              d.) In respect of or connected to the collection or payment of
              applicable taxes in any way connected to these Terms or any matter
              or goods or services under or arising from it (e.g. Services){" "}
            </p>

            <p>
              e.) In respect of quality of the Restaurant Services provided by
              the Merchant;
            </p>

            <p>
              f.) In respect of the warranties provided by the Merchant in
              Clause 10 (Warranty and Indemnity) hereto;{" "}
            </p>

            <p>
              g.) Relating to harm caused by the Merchant supplying any unsafe
              Restaurant Services, any product failure, defect or hazard in any
              Restaurant Services supplied or sold by the Merchant or inadequate
              instructions or warnings provided to Customers pertaining to any
              hazards arising from or associated with the use of any Restaurant
              Services supplied or sold by the Merchant;{" "}
            </p>

            <p>
              h.) Brought either by Amealio and/or third party on account of
              misuse, abuse, cheating, fraud, misrepresentation carried out by
              the Merchant;{" "}
            </p>

            <p>
              i.) In respect of, arising out of or in connection of any use of
              the Customer Data, not in accordance with the Terms and/or the
              applicable law;{" "}
            </p>

            <p>
              j.) For any misleading or incorrect or false, information or data
              provided by the Merchant;{" "}
            </p>

            <p>
              k.) The Merchant acknowledges that it supplies certain rights to
              Amealio to enable Amealio to provide services to the Customer.
              Amealio will not be liable for any applicable taxes liability in
              respect of transactions by which the Merchant provides Restaurant
              Services to Customers and the Merchant hereby indemnifies Amealio
              against any applicable taxes it may become liable for arising out
              of such transactions.
            </p>

            <p>
              <b>16. Customer Data: </b>
              <br />
              The Merchant agrees that the Merchant will only use the Customer
              Data in fulfilling the applicable Customer Order and in complying
              with the Merchant's obligations, and the Merchant agrees that
              Customer Data will not be used to enhance any file or list of the
              Merchant or any third party. The Merchant represents, warrants,
              and covenants that it will not resell, broker or otherwise
              disclose any Customer Data to any third party, in whole or in
              part, for any purpose whatsoever. The Merchant agrees it shall not
              use the Customer Data for sending any unsolicited marketing
              message, announcements and for feedback purposes, and shall be
              solely liable to ensure that any third party with whom Customer
              Data is shared complies with the restrictions set forth herein.
              The Merchant agrees that it will not copy or otherwise reproduce
              any Customer Data other than for the purpose of fulfilling the
              applicable Customer Order. The Merchant (and any other persons to
              whom the Merchant provides any Customer Data) will implement and
              comply with reasonable security measures in protecting, handling,
              and securing the Customer Data. If, any Customer Data is collected
              by the Merchant (or otherwise on its behalf), the Merchant shall
              ensure that it (and any applicable third parties) adopt, post, and
              process the Customer Data in conformity with an appropriate and
              customary privacy policy. For purposes of this Form, the
              restrictions set forth herein on the Merchant 's use of Customer
              Data do not apply to: (a) data from any Customer who was a
              customer of Merchant prior to the Merchant using the Platform or
              the Amealio Services, but only with respect to data that was so
              previously provided to the Merchant by such Customer; or (b) data
              supplied by a customer directly to the Merchant who becomes a
              customer of the Merchant and who explicitly opts in to receive
              communications from the Merchant for the purposes for which such
              Customer Data will be used by Merchant; and, provided in all
              cases, that the Merchant handles and uses such Customer Data in
              compliance with applicable Laws and the Merchant 's posted privacy
              policy.{" "}
            </p>

            <p>
              <b>17. Disclaimer: </b>
              <br />
              To the fullest extent permitted by law, Amealio and its
              affiliates, and each of the irrespective officers, directors,
              members, employees, and agents disclaim all warranties, express or
              implied, in connection with these terms, the Platform and the
              Amealio services and any use thereof, including, without
              limitation, the implied warranties of merchantability, fitness for
              a particular purpose and non-infringement. Amealio makes no
              warranties or representations about the accuracy or completeness
              of the content and data on the Platform or the Amealio services'
              content or the content of any other websites linked to the
              website, and assumes no liability or responsibility for any (a)
              errors, mistakes, or inaccuracies of content and materials, (b)
              personal injury or property damage, of any nature whatsoever,
              resulting from the Merchant's access to and use of the Platform
              and the Amealio services, (c) any unauthorized access to or use of
              Amealio' servers and/or any and all personal information and/or
              financial information stored therein, (d) any interruption or
              cessation of transmission to or from the website or otherwise with
              respect to the Amealio services, (e) any bugs, viruses, trojan
              horses, or the like which may be transmitted to or through the
              website or the Amealio services by any third party, and/or (f) any
              errors or omissions in any content and materials or for any loss
              or damage of any kind incurred as a result of the use of any
              content posted, transmitted, or otherwise made available (g)
              features and functions available on any of our platform meet the
              Merchant’s requirements via the website or the Amealio Services.
            </p>

            <p>
              Amealio is a platform that merely acts in the capacity of a
              facilitator and provides a platform for communication between the
              buyer / consumer and the merchants and it does not at any point of
              time during any transaction between Buyer/ Customer and Merchant
              on the Platform come into or take possession of any of the
              products or services offered by Merchant.
            </p>

            <p>
              <b>18. General Terms and Conditions:</b>
            </p>

            <p>
              i.) Statutory Compliances by Merchant: Amealio bears no
              responsibility for the compliance with statutory rules,
              regulations, and licenses by the Restaurant/Salon Partners. We
              shall not be liable in any manner if the customer is unable to
              avail the offer(s) with a Merchant due to Merchant’s violation of
              any statutory rule, regulation, and license.{" "}
            </p>

            <p>
              ii.) Assignment: We may assign its right obligations under these
              terms and conditions to any of its affiliates or any third party
              at any time.{" "}
            </p>

            <p>
              iii.) Violation: You agree that any violation by You of these
              Terms of Use will constitute an unlawful and unfair business
              practice, and will cause irreparable harm to the Company, for
              which monetary damages would be inadequate, and You consent to our
              platform obtaining any injunctive or equitable relief that they
              deem necessary or appropriate in such circumstances. These
              remedies are in addition to any other remedies that the company
              may have at law or in equity. If the Company takes any legal
              action against You as a result of Your violation of these Terms
              and Conditions, We will be entitled to recover it from You, and
              You agree to pay all reasonable attorneys’ fees and costs of such
              action, in addition to any other relief that may be granted.{" "}
            </p>

            <p>
              iv.) Additional Terms: We may also require You to follow
              additional rules, guidelines or other conditions in order to
              participate in certain promotions or activities available through
              the Platform. These additional terms shall form a part of these
              Terms of Use, and You agree to comply with them when You
              participate in those promotions, or otherwise engage in activities
              governed by such additional terms.{" "}
            </p>

            <p>
              v.) IP Infringement: If You believe the Platform violates Your
              intellectual property, You must promptly notify Amealio in writing
              at______________ These notifications should only be submitted by
              the owner of the intellectual property or an agent duly authorized
              to act on his/her behalf. However, any false claim by You may
              result in the termination of Your access to the Platform. You are
              required to provide the following details in Your notice:{" "}
            </p>

            <p>
              a.) The intellectual property that You believe is being infringed;
            </p>

            <p>
              b.) The item that You think is infringing and include sufficient
              information about where the material is located on the Platform;{" "}
            </p>

            <p>
              c.) A statement that You believe in good faith that the item You
              have identified as infringing is not authorized by the
              intellectual property owner, its agent, or the law to be used in
              connection with the Platform;{" "}
            </p>

            <p>
              d.) Your contact details, such as Your address, telephone number,
              and/or email;{" "}
            </p>

            <p>
              e.) A statement that the information You provided in Your notice
              is accurate, and that You are the intellectual property owner or
              an agent authorized to act on behalf of the intellectual property
              owner whose intellectual property is being infringed; and{" "}
            </p>

            <p>f.) Your physical or electronic signature. </p>

            <p>
              vi).Force Majeure: Any delay in or failure to perform any
              obligations by either party under these Terms of Use shall not
              constitute default hereunder if and to the extent caused by force
              majeure, which is defined to be occurrences beyond the reasonable
              control of such party committing default, including and limited to
              acts of the government authorities, acts of God, fire, flood,
              explosion, riots, war, labour strikes, sabotage, rebellion,
              insurrection, epidemic, pandemics or similar outbreak (“Force
              Majeure”). Provided, however, You shall give prompt written notice
              within a period of 7 (seven) days from the date of the force
              majeure occurrence to Amealio. You shall use all reasonable
              efforts to avoid or remove such cause of non-performance and shall
              continue performance hereunder whenever such causes of force
              majeure are removed. In the event the Force Majeure event
              continues for a period of 7 (seven) days from the date on which
              Amealio receives the notice from You as above, we shall have the
              right to terminate these Terms of Use.{" "}
            </p>

            <p>
              vii.) Severability: If any provision of this Agreement is held to
              be unenforceable or invalid, such provision will be changed and
              interpreted to accomplish the objectives of such provision to the
              greatest extent possible under applicable law and the remaining
              provisions will continue in full force and effect.{" "}
            </p>

            <p>
              viii.) Entire Agreement: This Agreement, Terms of Service and our
              Privacy Policy constitute the entire agreement between you and
              Company with respect to the Application and supersede all prior or
              contemporaneous understandings and agreements, whether written or
              oral, with respect to the Application.{" "}
            </p>

            <p>
              Survival- The rights and responsibilities set out in these Terms
              that, by their nature or stated terms, should or do survive or
              continue beyond the termination or expiration of these Terms,
              shall do so.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Usbusinessterms;
