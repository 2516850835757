import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import cookies from "js-cookie";
import { LANGUAGES } from "../../AllEnum/LanguageEnum";
import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const { t } = useTranslation();

  const [selects, setSelects] = useState("");
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  const currentLanguageCode = cookies.get("i18next") || "in";
  const currentLanguage = LANGUAGES.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    setSelects(currentLanguage.code.toUpperCase());
  }, [currentLanguageCode, currentLanguage]);

  return (
    <>
      {/* <section>
        <div
          className="container-fluid"
          style={{ backgroundColor: props.backgroundColorData }}
        >
          <div className="row">
            <div className="col-12 d-flex justify-content-end my-4">
              <button
                className="btn common-backtotop-button"
                onClick={scrollToTop}
              >
                Back to Top&nbsp;&nbsp;&nbsp;
                <i className="fa fa-arrow-circle-up" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </section> */}
      <section className="footer-amealio-wrapper">
        <div className="container-fluid mb-4">
          {/* <div className="col-12">
            <div className="top-image-sprial">
              <img
                src="../assets/images/footer/footerSpiral.png"
                alt="Footer Sprial"
                title="Footer Sprial"
                className="img-fluid"
              ></img>
            </div>
          </div> */}
          <div className="row" >
          <div className="col-12 col-xs-12 col-md-3 pd-y5">
          <div className="py-4 flexcenter">
                    <img
                    alt="logo"
                    title="Amealio"
                    className="img-fluid "
                    width="200px"
                    src="../assets/images/amealio/amealio1.png"
                  />
                    </div>
                    <div  className="py-4 flexcenter"  style={{fontSize:"18px"}}>
                    Write to us <span className="amealiocolor mx-3">info@amealio.com</span>
                    </div>
                    <div className="flexcenter" >
                    <a  href="https://www.instagram.com/amealioapp/" target="_blank" rel="noreferrer">
                       <img  className="social-icons-footer" src="../assets/images/amealio/social-insta.png" alt="image" style={{maxWidth:"100%"}} /> 
                      </a>
                      <a href="https://www.facebook.com/Amealio" target="_blank" rel="noreferrer">
                      <img  className="social-icons-footer" src="../assets/images/amealio/social-fb.png" alt="image" style={{maxWidth:"100%",width:"36px"}} /> 
                      </a>
                      <a   href="https://twitter.com/Amealio4" target="_blank" rel="noreferrer">
                      <img className="social-icons-footer"  src="../assets/images/amealio/social-twitter.png" alt="image" style={{maxWidth:"100%"}} /> 
                      </a>
                      <a  href="#" target="_blank" rel="noreferrer">
                      <img className="social-icons-footer"  src="../assets/images/amealio/social-yt.png" alt="image" style={{maxWidth:"100%"}} /> 
                      </a>
                      <a  href="https://www.linkedin.com/company/amealio-in/" target="_blank" rel="noreferrer">
                      <img className="social-icons-footer"  src="../assets/images/amealio/social-in.png" alt="image" style={{maxWidth:"100%"}} /> 
                      </a>
                      {/* <a  href="https://www.instagram.com/amealioapp/" target="_blank" rel="noreferrer">
                       <img  className="social-icons-footer" src="../assets/images/amealio/social-wtsup.png" alt="image" style={{maxWidth:"100%"}} /> 
                      </a> */}

                    </div>
                    {/* <div className="socialmedia-icons container flexcenter">
                    <img  className="icons-media" src="../assets/images/amealio/watsappicon.png" alt="image" style={{maxWidth:"100%"}} /> 
                    <a  href="https://www.instagram.com/amealioapp/" target="_blank" rel="noreferrer">
                       <img  className="icons-media" src="../assets/images/amealio/social-wtsup.png" alt="image" style={{maxWidth:"100%"}} /> 
                      </a>
                      <a href="https://www.facebook.com/Amealio" target="_blank" rel="noreferrer">
                      <img  className="icons-media" src="../assets/images/amealio/social-fb.png" alt="image" style={{maxWidth:"100%",width:"102px"}} /> 
                      </a>
                      <a   href="https://twitter.com/Amealio4" target="_blank" rel="noreferrer">
                      <img className="icons-media"  src="../assets/images/amealio/social-twitter.png" alt="image" style={{maxWidth:"100%"}} /> 
                      </a>
                      <a  href="#" target="_blank" rel="noreferrer">
                      <img className="icons-media"  src="../assets/images/amealio/social-yt.png" alt="image" style={{maxWidth:"100%"}} /> 
                      </a>
                      <a  href="https://www.linkedin.com/company/amealio-in/" target="_blank" rel="noreferrer">
                      <img className="icons-media"  src="../assets/images/amealio/linkedin.png" alt="image" style={{maxWidth:"100%"}} /> 
                      </a>
                      <a  href="https://www.instagram.com/amealioapp/" target="_blank" rel="noreferrer">
                       <img  className="icons-media" src="../assets/images/amealio/social-wtsup.png" alt="image" style={{maxWidth:"100%"}} /> 
                      </a>
                </div> */}
                 <div className="row">
                    <div className="col-12 py-3 flexcenter">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.envisionard.amealio"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/footer/googleplay.png"
                          alt="Amealio App"
                          title="Amealio App"
                          className="img-fluid"
                          width="200px"

                        ></img>
                      </a>
                    </div>
                    <div className="col-12 py-3 flexcenter">
                      <a
                        href="https://apps.apple.com/in/app/amealio/id1516233942"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/footer/apple-app-store-icon.png"
                          alt="Amealio Ios App"
                          title="Amealio Ios App"
                          className="img-fluid"
                          width="200px"
                        ></img>
                      </a>
                    </div>
                  </div>
            </div>
            <div className="col-6 col-xs-6 col-md-2 px-3 pd-y5">
              <div className="footer-links-head">
                <h2>Quick Links</h2>
                <ul>
                  <NavLink
                    activeClassName="is-active"
                    to={`/${currentLanguage.code.toUpperCase()}`}
                    exact
                  >
                    <li>Home</li>
                  </NavLink>
                  <NavLink
                    activeClassName="is-active"
                    to={`/${currentLanguage.code.toUpperCase()}/features`}
                    exact
                  >
                    <li>Features</li>
                  </NavLink>
                  <NavLink
                    activeClassName="is-active"
                    to={`/${currentLanguage.code.toUpperCase()}/bussiness`}
                    exact
                  >
                    <li>For Business</li>
                  </NavLink>
                  <NavLink
                    activeClassName="is-active"
                    to={`/${currentLanguage.code.toUpperCase()}/aboutamealio`}
                    exact
                  >
                    <li>About Amealio</li>
                  </NavLink>
                  {/* <NavLink
                    activeClassName="is-active"
                    to={`/${currentLanguage.code.toUpperCase()}/aboutAmealia`}
                    exact
                  >
                    <li>About Amealia</li>
                  </NavLink> */}
                  <NavLink
                    activeClassName="is-active"
                    to={`/${currentLanguage.code.toUpperCase()}/help`}
                    exact
                  >
                    <li>Help and FAQs</li>
                  </NavLink>
                  <NavLink
                    activeClassName="is-active"
                    to={`/${currentLanguage.code.toUpperCase()}/community`}
                    exact
                  >
                    <li>Community</li>
                  </NavLink>
                </ul>
              </div>
            </div>
            <div className="col-6 col-xs-6 col-md-2 pd-y5">
              <div className="footer-links-head">
                <h2>Know Us More</h2>
                <ul>
                  <li>
                    {" "}
                    <HashLink
                      className="text-dark"
                      to={`/${currentLanguage.code.toUpperCase()}/home#location`}
                    >
                      Top Cities
                    </HashLink>
                  </li>
                  <li>Blogs</li>
                  <NavLink
                    activeClassName="is-active"
                    to={`/${currentLanguage.code.toUpperCase()}/offers-rewards-referral`}
                    exact
                  >
                    <li>Offers and Rewards</li>
                  </NavLink>
                  <NavLink
                    activeClassName="is-active"
                    to={`/${currentLanguage.code.toUpperCase()}/walletterms`}
                    exact
                  >
                    <li>Wallet Terms</li>
                  </NavLink>
                  {/* <NavLink activeClassName="is-active" to="/walletterms" exact><li>Amealio wallet terms</li></NavLink>                      */}
                  {/* <li>Glassdoor</li> */}
                </ul>
              </div>
            </div>
            <div className="col-6 col-xsm-12 col-md-2 px-3 pd-y5">
              <div className="footer-links-head">
                <h2>Business</h2>
                <ul>
                  <li>
                    <HashLink
                      className="text-dark"
                      to={`/${currentLanguage.code.toUpperCase()}/help#contact`}
                    >
                      Contact Us
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      className="text-dark"
                      to={`/${currentLanguage.code.toUpperCase()}/demo`}
                    >
                      Become a Partner
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      className="text-dark"
                      to={`/${currentLanguage.code.toUpperCase()}/demo`}
                    >
                      Become a Restaurant Partner
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      className="text-dark"
                      to={`/${currentLanguage.code.toUpperCase()}/demo`}
                    >
                      Become a Delivery Partner
                    </HashLink>
                  </li>
                </ul>
                {/* <h2>Social Services</h2> */}
                <ul>
                  <NavLink
                    activeClassName="is-active"
                    to={`/${currentLanguage.code.toUpperCase()}/social`}
                    exact
                  >
                    <li>Udbhav Foundation</li>
                  </NavLink>
                </ul>
              </div>
            </div>
            <div className="col-6 col-xs-12 col-md-3 px-3 pd-y5">
              <div className="footer-links-head">
                <h2>Legal</h2>
                <ul>
                  {selects === "no" ? (
                    <>
                      <NavLink
                        activeClassName="is-active"
                        to={`/${currentLanguage.code.toUpperCase()}/usbusinessterms`}
                        exact
                      >
                        <li>Business Terms & Conditions (US)</li>
                      </NavLink>
                      <NavLink
                        activeClassName="is-active"
                        to={`/${currentLanguage.code.toUpperCase()}/ususerterms`}
                        exact
                      >
                        <li>Users Terms & Conditions (US)</li>
                      </NavLink>
                      <NavLink
                        activeClassName="is-active"
                        to={`/${currentLanguage.code.toUpperCase()}/usprivacy`}
                        exact
                      >
                        <li>Privacy Policy (US)</li>
                      </NavLink>
                    </>
                  ) : (
                    <>
                      <NavLink
                        activeClassName="is-active"
                        to={`/${currentLanguage.code.toUpperCase()}/businessterms`}
                        exact
                      >
                        <li>Business Terms & Conditions</li>
                      </NavLink>
                      <NavLink
                        activeClassName="is-active"
                        to={`/${currentLanguage.code.toUpperCase()}/terms-conditions`}
                        exact
                      >
                        <li>Users Terms & Conditions</li>
                      </NavLink>
                      <NavLink
                        activeClassName="is-active"
                        to={`/${currentLanguage.code.toUpperCase()}/privacy-policy`}
                        exact
                      >
                        <li>Privacy Policy</li>
                      </NavLink>
                    </>
                  )}
                  <NavLink
                    activeClassName="is-active"
                    to={`/${currentLanguage.code.toUpperCase()}/refunds-and-cancellations`}
                    exact
                  >
                    <li>Refunds & Cancellation</li>
                  </NavLink>
                  <NavLink
                    activeClassName="is-active"
                    to={`/${currentLanguage.code.toUpperCase()}/businessreferral`}
                    exact
                  >
                    <li>Business to User Referral Program</li>
                  </NavLink>
                  <NavLink
                    activeClassName="is-active"
                    to={`/${currentLanguage.code.toUpperCase()}/programs`}
                    exact
                  >
                    <li>User to User Referral Program</li>
                  </NavLink>
                </ul>
              </div>
            </div>

          </div>
        </div>
        <hr  className="mt-4"/>
        <div className="container">
        <div className="below-footer">
            <div  className="footer-text" >
            Hiranandani Fortune City, Panvel, Navi Mumbai, Maharashtra 410221, India
            </div>
            <div className="footer-text amealiocolor">
            Copyright 2022 Envisionard Corporation
            </div>
          </div>

        </div>
      </section>
      {/* <section className="footer-amealio-wrapperTwo px-2 px-sm-2 px-md-4 py-4 py-sm-4 py-md-4">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6">
              <div className="d-flex align-items-center justify-content-center">
                <img
                  src="../assets/images/footer/logobrand.png"
                  alt="Amealio logo"
                  title="Amealio logo"
                  className="img-fluid"
                ></img>
                <p className="footer-amealio-wrapper-paragraph m-0">
                  {t("address")}
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 d-flex align-items-center text-center text-sm-center text-md-left ">
              <p className="footer-amealio-wrapper-paragraph m-0 mt-3 mt-sm-3 mt-md-0">
                Copyright 2022: Envisionard Ads and Marketing Pvt. Ltd.
                Copyright 2022: Envisionard Corporation
              </p>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Footer;
