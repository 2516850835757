import React from "react";
import { NavLink } from "react-router-dom";

const Cater = (props) => {
  return (
    <div className="app">
      <section>
        <div className="container">
          <div className="row cater-des1">
            <div className="col-12 col-sm-12 col-md-6">
              {/* cater-blk1 */}
              <h1 className="f50-h2">
                <b>
                  Eat what you Like.
                  <br />
                  Not what they Show!
                </b>
              </h1>
              <p className="f16-p3 pt-4">
                Customize Your Food As per Your Liking Using Our Voice &<br />
                Artificial Intelligence To Make Your Food As Good As The Last
                Time.
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-6 cater-blk2 text-center my-5 my-sm-5 my-md-0">
              <img
                src="../assets/images/enhanced/customized-cup-cak.png"
                alt="Eat What You Like"
                title="Eat What You Like"
                className="img-fluid w-50"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-sm-6 col-md-3">
              <div className="cater-search">
                <img
                  src="../assets/images/enhanced/search.png"
                  alt="enhanced discovery"
                  className="img-fluid"
                />
                <h4>
                  Enhanced
                  <br />
                  Discovery
                </h4>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 p-0 p-sm-0 px-0 px-sm-0 px-md-3">
              <div className="cater-search">
                <img
                  src="../assets/images/enhanced/qrscan.png"
                  alt="scan"
                  className="img-fluid"
                />
                <h4>
                  Scan & Pay Touchless
                  <br />
                  Hassle-Free Payments.
                </h4>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3 mt-4 mt-sm-4 mt-md-0">
              <div className="cater-search ">
                <img
                  src="../assets/images/enhanced/tag.png"
                  alt="exciting offers and events"
                  className="img-fluid"
                />
                <h4>
                  Exciting Offers
                  <br />
                  and Events
                </h4>
              </div>
            </div>
          </div>
          <div className="cater-enhanced"></div>
        </div>
      </section>
      <section>
        <div className="cater-heading">
          <h3 className="f32-h4 text-white">
            Explore, Eat and Engage with Endless Options
          </h3>
          <p className="f16-p3 text-white">
            Developed keeping food lovers in mind, the Amealio mobile
            application is the answer to everything related to food. Amealio
            <br />
            aims to build a better-connected world for users and businesses
            alike and become the first name that pops in mind when
            <br />
            one thinks food.
          </p>
          <NavLink to={`/${props.lang}/checkout`}>
            <button type="button" className="btn btn-warning">
              {" "}
              Get the App{" "}
            </button>{" "}
          </NavLink>
        </div>
      </section>
    </div>
  );
};
export default Cater;
