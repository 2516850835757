import React from "react";
import Header from "./Homepage/header";
//import Footer from "./Homepage/footer";
import Footer from "../../templates/footer";
import Media from 'react-media';
import AutoSlider from "../../NewWebsite/autoplaysliders";
const BecomeMerchant = () => {
    return(
        <div style={{ overflowX: "hidden" }}>
        <Header />
        <section className="become-merchant bg-main" style={{overflowX:"hidden"}}>
            <div className="container-fluid food-together">
                <div className="heading-h2 amealiocolor" style={{paddingTop:"2em"}}>
                Move faster, engage smarter, and<br /> connect deeply with customers
                </div>
                <div className="heading-h4 pd-y2" style={{color:"black"}}>
                {/* Join 500K+  Who Enjoy,  <Media query="(max-width: 767px)"><br /></Media> Celebrate & Socialize Everyday! */}
                Trusted by 500+ restaurants
                </div>
                <div>
                <div className="loginBtnsPadding">
                    <div className="col-6 col-xs-12 col-md-6 register-btn">
                        <a href="#joinus">
                        <span className="download-btn pd-x8 mx-4">Register</span>
                        </a>  
                    </div>
                    <div className="col-6 col-xs-12 col-md-6 login-btn">
                        <a href="http://merchant.amealio.com" target="_blank">
                        <span className="hallow-btn  pd-x8 mx-4">Login</span>
                        </a>
                    
                    </div> 
                </div>
                </div>
            </div>
            <div className="container-fluid" style={{height:"100%",paddingTop:"5em"}}>
            <Media query="(max-width: 767px)">
        <img src="../../assets/images/amealio/new/merchnatMockup.png" width="100%" />
        </Media>
        <Media query="(min-width: 768px)">
            <div className="flexcenter">
            <img src="../../assets/images/amealio/new/merchnatMockup.png" width="70%" />
            </div>
        </Media>
        </div>
        <div className="bg-amealio">
            <div className="container pd-y8 friendOfAmealio">
                <p className="heading-h4w">Friends of<Media query="(max-width: 767px)"><br /></Media> Amealio !</p>
                <p className="paragraph-text text-light flexcenter w-50web" style={{margin:"auto"}}>
                “Become a part of the credible community of restaurants and Amealio, to provide the best service to customers, and help restaurants do better business”
                </p>
            </div>
        </div>
        <div className="outlets-and-reviews">
            <div className="container">
                <div className="pd-y4 pb-0 text-center">
                    <p className="heading-h5 pb-4 text-dark"> Trusted by <span className="amealiocolor">500+</span> leading hospitality brands</p>
                    <p className="paragraph-text">making the lives of restaurants, cafés, bars, pubs and hotels that much easier</p>
                </div>
            </div>
            <AutoSlider />
        </div>
        <div className="ratings-section flexcenter">
            <div className="row joinus-width joinus-pd">
                <div className="col-6 col-xs-12 col-md-7 flexcenter py-4">
                    <div className="text-start">
                        <p className="heading-h1 text-light"> Join us</p>
                        <p className="paragraph-text text-light">
                        Not friends with Amealio yet?<br />
                        Submit your details and our team will get you<Media query="(min-width: 768px)"><br /></Media> where the party is at.
                        </p>
                    </div>
                    </div>
                    <div  id="joinus" className="col-5 col-xs-12 col-md-4 py-4">
                        <div className="join-form bg-white">
                            <div className="pd-y3">
                            <form
                            action="https://formspree.io/f/xrgjpldv"
                            method="POST"
                            >
                            <input type="text" class="form-control my-4 " id="name" name="name" placeholder="Name of Restaurant *" />
                            <input type="text" class="form-control my-4" id="pointofcontact" name="pointofcontact" placeholder="Name of Point of Contact (Manager / Owner) *" />
                            <input type="phonenumber" class="form-control my-4" id="contact" name="contact" placeholder="Contact *" />
                            <input type="email" class="form-control my-4" id="emailaddress" name="emailaddress" placeholder="Email address *" />
                            <div className="py-4 flexcenter">
                                <button type="submit" className="border-0">
                                <span className="download-btn pd-x8"> Join now !</span>
                                </button>
                               
                            </div>
                            </form>
                            </div>
                       
                        </div>

                    </div>
               
            </div>
        </div>
        </section>
        <Footer />
        </div> 
    )
};
export default BecomeMerchant;