import React from "react";

const offers = (props) => {
  return (
    <section className="offers-main">
      <div className="container">
        <h1 className="f42-h3 text-center">
          <b>What we Offer</b>
        </h1>
        <div className="row">
          <div className="col-sm-3 offer-col mt-5 pt-3">
            <label className="f18-h6">
              <b>Data Security</b>
            </label>
            <div className="offer-box">
              <img
                src="../assets/images/business/datasecurity.png"
                alt=""
                className="offers-img"
              ></img>
            </div>
            <p className="f14-p4">
              Protecting your confidential data is our highest priority. APIs
              and integrations ensure that your data is flowing between your
              validated systems.
            </p>
          </div>
          <div className="col-sm-3 offer-col mt-5 pt-3">
            <label className="f18-h6">
              <b>Customer Support</b>
            </label>
            <div className="offer-box">
              <img
                src="../assets/images/business/customersupport.png"
                alt=""
                className="offers-img"
              ></img>
            </div>
            <p className="f14-p4">
              We handle your on-premise dining requirements from search to seat
              to repeat, whether before, during, or after service.
            </p>
          </div>
          <div className="col-sm-3 offer-col mt-5 pt-3">
            <label className="f18-h6">
              <b>Report & Analytics</b>
            </label>
            <div className="offer-box">
              <img
                src="../assets/images/business/Reports.png"
                alt=""
                className="offers-img"
              ></img>
            </div>
            <p className="f14-p4">
              Customer insights and customized reports provide a holistic view
              into performance.
            </p>
          </div>
          <div className="col-md-3 offer-col mt-5 pt-3">
            <label className="f18-h6">
              <b>Online Payments</b>
            </label>
            <div className="offer-box">
              <img
                src="../assets/images/business/onlinepayments.png"
                alt=""
                className="offers-img"
              ></img>
            </div>
            <p className="f14-p4">
              Razorpay facilitates online payments from consumers and serves as
              a convenient gateway between their bank account and us. Using your
              dashboard, you may keep track of every payment made by the user.
            </p>
          </div>
          <div className="col-md-3 offer-col mt-5 pt-3">
            <label className="f18-h6">
              <b>Easy Customization</b>
            </label>
            <div className="offer-box">
              <img
                src="../assets/images/business/easycustomization.png"
                alt=""
                className="offers-img"
              ></img>
            </div>
            <p className="f14-p4">
              Allow guests to choose where they eat (for example, patio or
              chef's counter) by providing custom and available table types
              throughout the booking sequence.
            </p>
          </div>
          <div className="col-md-3 offer-col mt-5 pt-3">
            <label className="f18-h6">
              <b>Simple UI</b>
            </label>
            <div className="offer-box">
              <img
                src="../assets/images/business/simpleui.png"
                alt=""
                className="offers-img"
              ></img>
            </div>
            <p className="f14-p4">
              We provide numerous helpful and interactive pages and elements
              that allow users to easily explore, preview, learn, store, and
              order their favourite dishes.
            </p>
          </div>
          <div className="col-md-3 offer-col mt-5 pt-3">
            <label className="f18-h6">
              <b>Alerts/Notification</b>
            </label>
            <div className="offer-box">
              <img
                src="../assets/images/business/AlertsNotification.png"
                alt=""
                className="offers-img"
              ></img>
            </div>
            <p className="f14-p4">
              Customers receive real-time queue notifications and reminders,
              allowing them to do other things while waiting to be seated or for
              their order.
            </p>
          </div>
          <div className="col-md-3 offer-col mt-5 pt-3">
            <label className="f18-h6">
              <b>No Integration</b>
            </label>
            <div className="offer-box">
              <img
                src="../assets/images/business/nointegration.png"
                alt=""
                className="offers-img"
              ></img>
            </div>
            <p className="f14-p4">
              No integration required with your existing system. The Amealio
              application functions independently, allowing you to manage your
              clients, cut wait times, process orders, and grow your business.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default offers;
