import React from "react";
const Sharingmemories = ()=> {
    return(
        <>
        <section className="sharingMemoriesection" >
            <div className="row share-memories pd-y7">
                <div className="col-2 col-md-1"></div>
                <div className="col-xs-8 col-md-5 col-4 sharingmeals-text" style={{paddingTop:"6%"}}>
                <p className="heading-h1w"> Sharing <br />Memories...</p>
                <p className="heading-h5">Sharing & exploring our food journeyes together</p>
                <div className="px-4 sharelist">
                <ul className="ulist" style={{color:"white"}}>
                    <li>Group Chat/Book/Order</li>
                    <li>Meet New People</li>
                    <li>Bloggers Foodies (influencers)</li>
                    <li>UGC ( User Generated Content with CTA)</li>
                </ul>
                </div>
                </div>
                <div className="col-xs-10 col-md-5 col-5">
                <div className="container-fluid pd-y3" style={{height:"100%",paddingTop:"5%"}} >
                    <div className="flexcenter">
                        <span className="hashtags fade-down-animi">#amealiosocial</span>
                    <img className="share-height" src="../../assets/images/amealio/new/memories.png" width="100%" style={{width:"18em"}} />
                    </div> 
                  </div>
                </div>
            </div>
        </section>
        </>
    );
};
export default  Sharingmemories;