import React, { useState } from "react";
import amealioFeaturesBanner from "./../../newAssets/images/HomePage/Banner/features.png";
import featuresBottomRound from "./../../newAssets/images/HomePage/Banner/featuresBottomRound.png";
import amealioFeaturesSprial from "./../../newAssets/images/HomePage/sprial/AmealioFeaturesSprial.png";
import GoingOutSlide from "./AmealioFeaturesAllSlider/GoingOutSlide";
import OnTheGoSlider from "./AmealioFeaturesAllSlider/OnTheGoSlider";
import OrderInSlider from "./AmealioFeaturesAllSlider/OrderInSlider";

const AmealioFeatures = () => {
  const [selected, setSelected] = useState(1);

  return (
    <section
      className="amealio-features-main"
      style={{ backgroundImage: "url(" + amealioFeaturesBanner + ")" }}
    >
      <div className="container-fluid">
        <div className="col-12">
          <div className="top-image-sprial">
            <img
              src={amealioFeaturesSprial}
              alt="Amealio Verse"
              title="Amealio Verse"
              className="img-fluid"
            ></img>
          </div>
          {/* <div className="top-image-center-leaf d-none d-md-block">
            <img
              src="../assets/images/amealio/svg/leafTopRight.svg"
              alt="Amealio Verse"
              title="Amealio Verse"
              className="img-fluid"
            ></img>
          </div> */}
        </div>
        <div className="container p-0 p-sm-0">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 features-left-section">
              <h1 className="features-h1-font">Amealio Experiences</h1>
              <p className="features-p-paragraph">
                Pick from the experiences that are
                <br />
                bound to pique your love for food.
              </p>
              <div className="row features-button-slider">
                <div className="col-4">
                  <button
                    type="button"
                    title="Everyday Anywhere"
                    className={`${
                      selected === 1
                        ? "shadow futures-slider-btn futures-slider-btn-active"
                        : "futures-slider-btn"
                    }`}
                    onClick={() => setSelected(1)}
                  >
                    <div className="inside-box">Everyday Anywhere</div>
                  </button>
                </div>
                <div className="col-4">
                  <button
                    type="button"
                    title="Curated & Special"
                    className={`${
                      selected === 2
                        ? "shadow futures-slider-btn futures-slider-btn-active"
                        : "futures-slider-btn"
                    }`}
                    onClick={() => setSelected(2)}
                  >
                    <div className="inside-box">Curated & Special</div>
                  </button>
                </div>
                <div className="col-4">
                  <button
                    type="button"
                    title="Social Experience"
                    className={`${
                      selected === 3
                        ? "shadow futures-slider-btn futures-slider-btn-active"
                        : "futures-slider-btn"
                    }`}
                    onClick={() => setSelected(3)}
                  >
                    <div className="inside-box">Social Experience</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 features-right-section">
              <div className="features-right-section-image">
                <img
                  src="../assets/images/amealio/svg/mobileScreen.png"
                  alt="Features Food"
                  title="Features Food"
                  className="img-fluid"
                ></img>
                {selected === 1 ? (
                  <GoingOutSlide />
                ) : selected === 2 ? (
                  <OnTheGoSlider />
                ) : (
                  <OrderInSlider />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-12 d-none d-md-block"> */}
        <div
          className="features-image-bottom d-none d-sm-none d-md-block"
          // style={{ backgroundImage: "url(" + featuresBottomRound + ")" }}
        >
          <img
            src={featuresBottomRound}
            className="img-fluid features-image-bottom-one"
            alt="Features Food"
            title="Features Food"
          />
          <img
            src={`./assets/images/amealio/svg/${
              selected === 1
                ? `burrger.png`
                : selected === 2
                ? `donuts.png`
                : selected === 3
                ? `noodles.png`
                : `burrger.png`
            }`}
            alt="Features Food"
            title="Features Food"
            className="img-fluid features-image-bottom-two"
          ></img>
        </div>
        {/* </div> */}
      </div>
    </section>
  );
};

export default AmealioFeatures;
