import React, { useState } from "react";
import Header from "./Homepage/header";
// import Footer from "./Homepage/footer";
import Footer from "../../templates/footer";
const Aboutus = () => {
  const [forRes, setforRes] = useState(false);
  return (
    <React.Fragment>
      <div style={{ overflowX: "hidden" }}>
        <Header />
        <section className="about-Amealio bg-main">
          <div className="container">
            <p
              className="heading-h1 amealiocolor"
              style={{ paddingTop: "15vh" }}
            >
              {" "}
              About Amealio !
            </p>
          </div>
          <div className="container">
            <p className="heading-h5b py-4">What is Amealio ?</p>
            <p className="paragraph-text py-4">
              Imagine if #food on social media was made into a social platform
              itself, completely dedicated to food and experiences, enabling
              users to search, explore, engage, and experience food through
              shortform image and video content. From checking out
              recommendations from local foodies, to making table reservations,
              to preordering your meal on your way, or to collect your order on
              the curbside while sitting in your car, making it easier to plan
              your weekend outings, or everyday meals, and yes, sharing back
              your experience on Amealio for fellow food enthusiasts to know
              what their next plan should be! <br /> <p />
              <p className="paragraph-text py-4">
                Amealio’s content is contributed by Restaurants, foodies, and
                the unsung heroes of this eco system—bloggers —in the form of
                videos and visual blogs, making it interesting to share their
                food journey, and discover that of others’, all personalised and
                filtered as per one’s tastes and preferences by powerful
                artificial intelligence and machine learning technologies.{" "}
                <br />
              </p>
              <p className="paragraph-text py-4">
                By 2023 Amealio evolves into a social platform, creating tight
                online circles for friends, familes, and colleagues, to explore
                and share on this social platform for all things food, and
                experience it together.
                <br />
              </p>
              <p className="paragraph-text py-4">
                This is what Amealio is all about: A passionate journey of
                Sharing Meals, Experiences, and Memories
              </p>
            </p>
            <div className="pt-4">
              <p className="heading-h5b py-4">Who are we ?</p>
              <p className="paragraph-text py-4">
                Amealio team is a unique amalgamation of former startup founders
                and experienced corporate veterans
                <br />
                Food and Beverages Background: First hand experience in
                running/owning multiple restaurants across India, and USA.
                <br />
                Deeply passionate about Food and Customer Satisfaction
              </p>
            </div>
          </div>
          <div className="container  whyAmealio">
            <p
              className="heading-h1 amealiocolor"
              style={{ paddingTop: "15vh" }}
            >
              {" "}
              Why Amealio ?
            </p>
            <div className="pd-y6">
              <span
                onClick={() => {
                  setforRes(false);
                }}
                className={`${
                  forRes ? "hallow-btn" : "download-btn"
                } download-pd mx-4`}
              >
                For Diners
              </span>{" "}
              <span
                onClick={() => {
                  setforRes(true);
                }}
                className={`${
                  forRes ? "download-btn" : "hallow-btn"
                } download-pd   mx-4`}
              >
                For Restaurants
              </span>
            </div>
            <div className="container">
              <div className="row pd-y5">
                <div className="col-4 col-xs-12 col-md-4 paragraph-text d-flex py-4">
                  <img
                    alt="Like"
                    title="Like"
                    className="img-fluid"
                    width="70px"
                    height="70px"
                    src="../../assets/images/amealio/heart.png"
                  />
                  {forRes ? (
                    <>
                      <p className="px-4 w-75">
                        {" "}
                        "Why does nobody list me on top based on my food and
                        service quality. It's all about who pays the most for
                        visibility, or offers the most discounts"
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="px-4 w-75">
                        {" "}
                        " I want to have the best authentic asian cuisine
                        tonight but all I see on these apps are sponsored ads"
                      </p>
                    </>
                  )}
                </div>
                <div className="col-4 col-xs-12 col-md-4 paragraph-text d-flex py-4">
                  <img
                    alt="Like"
                    title="Like"
                    className="img-fluid"
                    width="70px"
                    height="70px"
                    src="../../assets/images/amealio/heart.png"
                  />
                  {forRes ? (
                    <>
                      <p className="px-4 w-75">
                        {" "}
                        "I can't afford these heavy commissions of 20-30% on
                        every order to these apps "
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="px-4 w-75">
                        "I wanna go someplace new! I'm bored of the same old
                        spots"
                      </p>
                    </>
                  )}
                </div>
                <div className="col-4 col-xs-12 col-md-4 paragraph-text d-flex py-4">
                  <img
                    alt="Like"
                    title="Like"
                    className="img-fluid"
                    width="70px"
                    height="70px"
                    src="../../assets/images/amealio/heart.png"
                  />
                  {forRes ? (
                    <>
                      <p className="px-4 w-75">
                        {" "}
                        "We are more than just our everyday menu. How do I
                        communicate our events”
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="px-4 w-75">
                        "I want to attend a good Karaoke night? But where? I'm
                        clueless!"
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-4 col-xs-12 col-md-4 paragraph-text d-flex py-4">
                  <img
                    alt="Like"
                    title="Like"
                    className="img-fluid"
                    width="70px"
                    height="70px"
                    src="../../assets/images/amealio/heart.png"
                  />
                  {forRes ? (
                    <>
                      <p className="px-4 w-75">
                        "How do I make statistical decisions for my restaurant?”
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="px-4 w-75">
                        "Need to plan my birthday party! What a nightmare"
                      </p>
                    </>
                  )}
                </div>
                <div className="col-4 col-xs-12 col-md-4 paragraph-text d-flex py-4">
                  <img
                    alt="Like"
                    title="Like"
                    className="img-fluid"
                    width="70px"
                    height="70px"
                    src="../../assets/images/amealio/heart.png"
                  />

                  {forRes ? (
                    <>
                      <p className="px-4 w-75">
                        “How do I stay in touch with my customers, learn their
                        specific tastes, preferences, and use it to delight
                        them, making them my regulars."
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="px-4 w-75">
                        "Need it less salty, maybe no tomatoes? How cumbersome!"
                      </p>
                    </>
                  )}
                </div>
                <div className="col-4 col-xs-12 col-md-4 paragraph-text d-flex py-4">
                  <img
                    alt="Like"
                    title="Like"
                    className="img-fluid"
                    width="70px"
                    height="70px"
                    src="../../assets/images/amealio/heart.png"
                  />

                  {forRes ? (
                    <>
                      <p className="px-4 w-75">
                        "We are more than just our everyday menu. How do I
                        communicate our events, experiences, service quality,
                        curations, special preparations, and everything else"
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="px-4 w-75">
                        "Why eat alone when you can eat with someone new? Let's
                        Socialize"
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row whyAmealio">
            <p
              className="heading-h1 amealiocolor text-center"
              style={{ paddingTop: "15vh", paddingBottom: "4rem" }}
            >
              {" "}
              #gotoamealio
            </p>
          </div>
          <div className="listen-stories">
            <div
              className="heading-h1 text-center"
              style={{ color: "whitesmoke", paddingTop: "5rem" }}
            >
              Listen to their stories...
            </div>
            <div
              className="paragraph-text text-center container pd-y4"
              style={{ color: "whitesmoke" }}
            >
              Padma expresses how Amealio made her date even more memorable. She
              used Amealio’s Waitlist feature to get a table at a highly coveted
              restaurant and was able to surprise her boyfriend on their special
              date night.
            </div>
            <div className="container flexcenter">

              <img
               onClick={()=>window.open('https://youtu.be/8U11idBcCyE', '_blank')}
                alt="stories"
                className="img-fluid pb-4"
                src="../../assets/images/amealio/new/listenstories.png"
              />
            </div>
            
          </div>
        </section>
        <Footer />
      </div>
    </React.Fragment>
  );
};
export default Aboutus;
