import React from "react";

const FoodEstablishment = (props) => {
  return (
    <div key={props.keys}>
      <div className="col business-food-establishment-rewards">
        <div
          className="business-food-establishment-rewards-two"
          style={{
            backgroundImage: `url(/assets/images/business/defaultstate.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "auto",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={`../assets/images/business/${props.data?.imgTwo}`}
            alt={`${props.data?.title + " " + props.data?.title1}`}
            title={`${props.data?.title + " " + props.data?.title1}`}
          />
        </div>
      </div>
      <div className="f18-p2 text-white mt-5">
        {props.data?.title}
        <br />
        {props.data?.title1}
      </div>
    </div>
  );
};

export default FoodEstablishment;
