import React  from "react";
import Slider from "react-slick";
import serviceSprial from "./../../newAssets/images/HomePage/sprial/FoodPartnersSprial.png";
import FoodPartnersSlider from "../home/HomePageSlider/foodPartnersSlider";
import SliderElements from "./slidercomponents/sliderElements";
import { useEffect } from "react";

const AutoSlider = (props) => {

  var settings = {
  //  dots: true,
    arrows: false,
    // className: "center",
    // centerMode: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 6,
    speed: 1000,
    autoplaySpeed: 4000,
    rows: 1,
    slidesPerRow: 1,
    swipeToSlide: true,
    mobileFirst: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageSliderData = [
    {
      id: 1,
      imgTitle: "Rocomama's",
      imgSrc: "Rocomamas",
    },
    {
      id: 2,
      imgTitle: "Elephant & Co",
      imgSrc: "Elephant-&-Co",
    },
    {
      id: 3,
      imgTitle: "Biggies Burger",
      imgSrc: "Biggies-Burger",
    },
    {
      id: 4,
      imgTitle: "Drunken Monkey",
      imgSrc: "Drunken-Monkey",
    },
    {
      id: 5,
      imgTitle: "Barista",
      imgSrc: "Barista",
    },
    {
      id: 6,
      imgTitle: "Crazy Noodles",
      imgSrc: "Crazy-Noodles",
    },
    {
      id: 7,
      imgTitle: "Cream Craver",
      imgSrc: "Cream-Craver",
    },

    {
      id: 8,
      imgTitle: "Hennys Gourmet",
      imgSrc: "Hennys-Gourmet",
    },
    {
      id: 9,
      imgTitle: "High spirits cafe",
      imgSrc: "High-spirits-cafe",
    },
    {
      id: 10,
      imgTitle: "Huber And Holly",
      imgSrc: "Huber-And-Holly",
    },
    {
      id: 11,
      imgTitle: "The 3 Muskeeters",
      imgSrc: "The-3-Muskeeters",
    },
    {
      id: 12,
      imgTitle: "The Chocolate Room",
      imgSrc: "The-Chocolate-Room",
    },
    {
      id: 13,
      imgTitle: "Yummy Q",
      imgSrc: "Yummy-Q",
    },
    {
      id: 14,
      imgTitle: "Monks And Blue",
      imgSrc: "Monks-and-blue",
    },
    {
      id: 15,
      imgTitle: "Irani Cafe",
      imgSrc: "Irani-Cafe",
    },
    {
      id: 16,
      imgTitle: "Jimmy's",
      imgSrc: "Jimmys",
    },
    {
      id: 17,
      imgTitle: "Kaka Halwai Sweets",
      imgSrc: "Kaka-Halwai-Sweets",
    },
    {
      id: 18,
      imgTitle: "Akhtars Samosa",
      imgSrc: "Akhtars-Samosa",
    },
    {
      id: 19,
      imgTitle: "Shiv Sagar Veg Restaurant",
      imgSrc: "Shiv-Sagar-Veg-Restaurant",
    },
    {
      id: 20,
      imgTitle: "Savlahari",
      imgSrc: "Savlahari",
    },
  ];
  const imageSliderData2 = [
    {
      id: 1,
      imgTitle: "image",
      imgSrc: "s1",
    },
    {
      id: 2,
      imgTitle: "image",
      imgSrc: "s2",
    },
    {
      id: 3,
      imgTitle: "image",
      imgSrc: "s3",
    },
    {
      id: 4,
     imgTitle: "image",
      imgSrc: "s4",
    },
    {
      id: 5,
      imgTitle: "image",
      imgSrc: "s5",
    },
    {
      id: 6,
      imgTitle: "image",
      imgSrc: "s1",
    },
    {
      id: 7,
     imgTitle: "image",
      imgSrc: "s2",
    },
    {
      id: 8,
      imgTitle: "image",
      imgSrc: "s3",
    },
    
  ];

  return (
    <>
      <section
        className="py-4 m-0 "
      >
        <div>
          <div className="slider-scroll-bottom h-auto m-0 p-0">
            <Slider {...settings}>
              {imageSliderData &&
                imageSliderData.map((data) => {
                  return (
                    <React.Fragment key={data.id}>
                      <SliderElements
                        title={data.imgTitle}
                        imgSrc={data.imgSrc}
                      />
                    </React.Fragment>
                  );
                })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default AutoSlider;
