import React from "react";
import { NavLink } from "react-router-dom";
const Away = () => {
  return (
    <section>
      <div className="container">
        <div className="walkin-readmore">
          <div className="card walkin-contactfree">
            <img
              src="../assets/images/enhanced/mask-group52.png"
              alt="Be a VIP"
              title="Be a VIP"
              width="100%"
            />
            <h4 className="f18-h6 heading-text">
              <b>Be a VIP and skip the Line!</b>
            </h4>
            <p className="f14-p4 mt-3">
              We understand the value of your time. Waiting <br />
              in lines to order your food and pick it up is time <br />
              and energy consuming. Do not fret! Just order <br />
              ahead, get notified & collect your food. <br />
              The days of standing in lines are over!
            </p>
            <NavLink activeClassName="is-active" to="/skiptheline" exact>
              <div className="arw1 mt-5 pt-5 pb-5">
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                <button type="button">Read More</button>
              </div>
            </NavLink>
          </div>
          <div className="card walkin-waitlist">
            <img
              src="../assets/images/enhanced/mask-group51.png"
              alt="Enjoy Curbside Delivery"
              title="Enjoy Curbside Delivery"
            />
            <h4 className="f18-h6 heading-text">
              <b>Enjoy Curbside Delivery on the go!</b>
            </h4>
            <p className="f14-p4">
              Now get food delivered straight to your vehicle <br />
              without having to find a parking spot. No going <br />
              to the restaurant and waiting endlessly. Enjoy a hot <br />
              packed meal delivered to your bike/car. <br />
              We Make getting food from high-street <br />
              restaurants, easy!
            </p>
            <NavLink activeClassName="is-active" to="/curbside" exact>
              <div className="arw mt-4 pb-5">
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                <button type="button">Read More</button>
              </div>
            </NavLink>
          </div>
          <div className="card walkin-reserve">
            <img
              src="../assets/images/enhanced/mask-group50.png"
              alt="Takeaway food"
              title="Takeaway food"
            />
            <h4 className="f18-h6 heading-text">
              <b>Takeaway your food differently</b>
            </h4>
            <p className="f14-p4">
              Get your food the way you like it! Our ETA <br />
              (Expected Time of Arrival) tracking feature <br />
              gets you hot, fresh and delicious food packed <br />
              and ready for you to take away! <br /> Track it. Take it. Eat it -
              It's that easy!
            </p>
            <NavLink activeClassName="is-active" to="/takeaway" exact>
              <div className="arw3 pt-5 pb-5">
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                <button type="button">Read More</button>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Away;
