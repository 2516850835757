import React from "react";
import ReactPlayer from "react-player/youtube";
import WhoAreWeBanner from "./../../newAssets/images/HomePage/Banner/whoarewe.png";

const WhoArewe = () => {
  return (
    <section
      className="who-are-we-main"
      style={{ backgroundImage: "url(" + WhoAreWeBanner + ")" }}
    >
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-12">
            <h1 className="who-head text-white mt-0">Why Amealio?</h1>
            <p className="who-paragraph text-white w-100 m-0">
              While going out and eating has been highly fragmented, we designed
              a novel approach to democratize the complete engagement in the
              hands of users and give them a personalized and seamless
              experience.
            </p>
          </div>
          <div className="col-12 col-sm-12 col-md-7 m-auto my-5">
            <div className="d-none d-sm-none d-md-block img-thumbnail rounded-0 w-100 h-auto">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=i04FZJ-gBmA"
                playing={true}
                loop={true}
                controls={true}
                volume={0}
              />
            </div>
            <div className="d-block d-sm-block d-md-none img-thumbnail rounded-0 w-100 h-auto">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=i04FZJ-gBmA"
                playing={true}
                loop={true}
                controls={true}
                volume={0}
                width="100%"
                height="auto"
              />
            </div>

            {/* <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  //   width="560"
                  //   height="315"
                  src="https://www.youtube.com/embed/QS5R1vksONA?rel=0&amp;autoplay=1&mute=1" //?autoplay=1&loop=1&playlist=i04FZJ-gBmA&
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe> 
                <iframe
                  src="https://www.youtube.com/embed/i04FZJ-gBmA?rel=0&amp;autoplay=1&loop=1&playlist=i04FZJ-gBmA&mute=1"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe> 
              </div> */}
          </div>
          <div className="col-12 col-sm-12 col-md-12">
            <p className="who-paragraph text-white w-100 m-0">
              Today’s customers expect to be empowered and engaged in the food
              convenience 2.0. They want their experience to be convenient: when
              they want, what they want, how they want, and where they want it.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoArewe;
