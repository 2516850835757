import React from "react";
import thinkFoodSprial from "./../../../newAssets/images/HomePage/sprial/ThinkFoodSprial.png";

const WhyAmealioSlider = (props) => {
  return (
    <div className="mb-0 mb-sm-0 mb-md-5 mt-5 pt-5 pt-sm-5 pt-md-0">
      <div className="whyamealio-li">
        <div className="card whyamealio-card">
          <div className="whyamealio-align">
            <div className="whyamealio-round">
              <div className="whyamealio-round2">
                <img
                  src={`./assets/images/amealio/svg/${props.imageName}.png`}
                  alt=""
                  className="img-fluid"
                ></img>
              </div>
            </div>
          </div>
          <div className="whyamealio-box">
            <p className={`whyamealio-p ${props.keys === 2 ? "mb-5" : null}`}>
              {props.paragraph}
            </p>
            <div className="bottom-thinkfood-sprial">
              <img
                src={thinkFoodSprial}
                alt="Amealio Verse"
                title="Amealio Verse"
                className="img-fluid"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyAmealioSlider;
