import React from "react";
const SharingMeals = ()=> {
    return(
        <>
        <section className="sharingMealssection" >
            <div className="row share-meals pd-y7">
                <div className="col-2 col-md-1"></div>
                <div className="col-xs-8 col-md-5 col-4 sharingmeals-text" style={{paddingTop:"6%"}}>
                <p className="heading-h1w"> Sharing <br />Meals.</p>
                <p className="heading-h5">Everyday convenience</p>
                <div className="px-4 sharelist">
                <ul className="ulist" style={{color:"white"}}>
                    <li>Order Ahead</li>
                    <li>Dine-In Curb Side</li>
                    <li>Delivery Pre-Order</li>
                    <li>Waitlist</li>
                    <li>Reservation Personalization</li>
                </ul>
                </div>

                </div>
                <div className="col-xs-10 col-md-5 col-5">
                <div className="container-fluid pd-y3" style={{height:"100%",paddingTop:"5%"}} >
                    <div className="flexcenter">
                        <span className="hashtags">#amealioeveryday</span>
                    <img className="share-height" src="../../assets/images/amealio/new/melas.png" width="100%" style={{width:"18em"}} />
                    </div> 
                  </div>
                </div>
            </div>
        </section>
        </>
    );
};
export default  SharingMeals;