import React from "react";
import { NavLink } from "react-router-dom";

const bussinessbanner = (props) => {
  return (
    <section className="business-header">
      <div className="container">
        <div className="business-flex">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 business-banner">
              <h1 className="f50-h2">Explore. Engage. Evolve.</h1>
              <h3 className="f32-h4 mt-4">Your Business Like Never Before.</h3>
              <p className="f18-p2 mt-4">
                Let our exciting features take charge of your
                <br />
                growth - professionally and personally!
              </p>
              <div className="col-12 col-sm-12 col-md-7 d-block d-sm-block d-md-flex align-items-center justify-content-between mt-4 pt-5">
                <NavLink to={`/${props.lang}/help`}>
                  <button className="btn top-main-button-one top-main-button-one-black mr-5 mb-4 mb-sm-4 mb-md-0">
                    Contact Us
                  </button>
                </NavLink>
                <NavLink to={`/${props.lang}/demo`}>
                  <button className="btn top-main-button-one top-main-button-one-red">
                    Start Your Journey
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 business-mobviewcol">
              <div className="business-top-image-bg">
                <img
                  src="../assets/images/business/explorEnlarge.png"
                  alt="Explore"
                  title="Explore"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <div className="position-of-mouse position-static">
                <div id="scrolling-mouse"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default bussinessbanner;
