import React from "react";
import Slider from "react-slick";
import ReusableSliderCom from "./reusableSliderCom";

const OrderInSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 7000,
    // cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
  };

  const orderInDummyData = [
    {
      id: 1,
      title: "Delivery",
      imgSrc: "delivery.png",
    },
    {
      id: 2,
      title: "Buffet",
      imgSrc: "buffet.png",
    },
  ];

  return (
    <div className="outter-features">
      <Slider {...settings}>
        {orderInDummyData &&
          orderInDummyData.map((goingOut) => {
            return (
              <React.Fragment key={goingOut.id}>
                <ReusableSliderCom data={goingOut} />
              </React.Fragment>
            );
          })}
      </Slider>
    </div>
  );
};

export default OrderInSlider;
