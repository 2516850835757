import React, { useState, useEffect } from "react";
import Whyamealio from "./whyamealio";
import Experience from "./experience";
import Happycustomer from "./happycustomer";
import Service from "./service";
import Footer from "../templates/footer";
import WhoArewe from "./whoArewe";
import IntroduceAmealioVerse from "./introduceAmealioVerse";
import AmealioFeatures from "./amealioFeatures";
import TopMainSlider from "./TopMainSlider";

// import TableAnimation from "./tableanimation";
// import CarAnimation from "./Car-Animation";
// import TakeAwayAnimation from "./Take-Away-Animation";
// import Mobileexperience from "./mobileexperience";
// import Explorefeatures from "./explorefeatures";
// import Connect from "./connect";
// import Slidervideo from "./slidervideo";
// import Foundation from "./foundation";
// import Location from "./location";
// import Discover from "./discover";

const Home = (props) => {
  const [lang, setLang] = useState("");
  // useEffect(() => {
  //   setLang(props?.lang !== "" ? props?.lang : "us");
  // }, []);

  useEffect(() => {
    setLang(props?.lang !== "" ? props?.lang : "US");
  }, [props.lang]);

  // const [voiceClassVisible, setVoiceClass] = React.useState(false);
  // const [screen, setScreen] = useState(1);
  // const [animation, setAnimation] = useState(true);
  // const [set, setstate] = useState(false);

  // let onceAnimationEnd = (time, animation) => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       setScreen(animation);
  //       resolve();
  //     }, time);
  //   });
  // };

  // let move_box_one = async () => {
  //   await onceAnimationEnd(10000, 1);
  // };
  // let move_box_two = async () => {
  //   await onceAnimationEnd(11500, 2);
  // };

  // let move_box_three = async () => {
  //   await onceAnimationEnd(12000, 3);
  // };

  // let move_boxes = async () => {
  //   await move_box_two();
  //   await move_box_three();
  //   await move_box_one();
  // };

  // useEffect(() => {
  //   animationTIme();
  //   handleClick();
  //   setScreen(1);
  // }, []);

  // useEffect(() => {
  //   return () => {
  //     setAnimation(false);
  //     setstate(true);
  //     setScreen(1);
  //   };
  // }, []);

  // function animationTIme() {
  //   if (animation) {
  //     move_boxes().then(() => animationTIme());
  //   }
  // }

  // function handleClick(time, val) {
  //   setScreen(val === undefined ? 1 : val);
  //   onceAnimationEnd(
  //     time === undefined ? 10000 : time,
  //     val === undefined ? 1 : val
  //   );
  // }

  return (
    <div className="App">
      <TopMainSlider lang={lang.toUpperCase()} />
      <WhoArewe />
      <Whyamealio />
      <AmealioFeatures />
      <Experience lang={lang.toUpperCase()} />
      <IntroduceAmealioVerse lang={lang.toUpperCase()} />
      <Happycustomer lang={lang.toUpperCase()} />
      <Service displaySprialImg={true} />
      <Footer backgroundColorData={"rgba(136, 132, 236, 0.24)"} />
      {/* <TableAnimation />
      {screen == 1 ? (
        <CarAnimation />
      ) : screen == 2 ? (
        <TableAnimation />
      ) : (
        <TakeAwayAnimation />
      )}      
      </div> 
      <Mobileexperience /> 
      <Explorefeatures /> 
      <Discover /> 
      <Connect /> 
      <Foundation /> 
      <Location id="location" /> 
      <Slidervideo /> */}
    </div>
  );
};
export default Home;
