import React from 'react';
// import { mobilesimg } from "../../../../images/new/mobiles.png";
import Media from 'react-media';
import { NavLink } from 'react-router-dom';

const Foodtogether = () => {
  const userAgent = window.navigator.userAgent;
  const handleNavigateDownload = () => {
    if (userAgent.indexOf('Win') !== -1) {
      window.open(
        'https://play.google.com/store/apps/details?id=com.envisionard.amealio',
        '_blank'
      );
    } else if (userAgent.indexOf('Mac') !== -1) {
      window.open(
        'https://apps.apple.com/in/app/amealio/id1516233942',
        '_blank'
      );
    }
  };
  return (
    <>
      <div className="bg-main">
        <div className="container food-together">
          <div
            className="heading-h1 amealiocolor"
            style={{ paddingTop: '2.5em' }}
          >
            #FOODTOGETHER
          </div>
          <div className="heading-h4 pd-y2" style={{ color: 'black' }}>
            Join 50K+ Who Enjoy , Celebrate & Socialize Everyday!
          </div>
          <div className="pd-y4">
            <Media query="(max-width: 767px)">
              <button
                className="download-btn border-0"
                onClick={() =>
                  window.open(
                    'https://amealio.page.link/oHnQ88HYijeUaXiPA',
                    '_blank'
                  )
                }
              >
                DOWNLOAD APP
              </button>
            </Media>
            <Media query="(min-width: 768px)">
              <button
                className="download-btn border-0"
                onClick={() => handleNavigateDownload()}
              >
                DOWNLOAD APP
              </button>
            </Media>
          </div>
        </div>
        <div
          className="container-fluid"
          style={{ height: '100%', paddingTop: '5vh' }}
        >
          <Media query="(max-width: 767px)">
            <img
              src="../../assets/images/amealio/new/mobileshowcase.png"
              width="100%"
            />
          </Media>
          <Media query="(min-width: 768px)">
            <img
              src="../../assets/images/amealio/new/webshowcase.png"
              width="100%"
            />
          </Media>
        </div>
      </div>
      <div className="bg-amealio">
        <div className="row share-pd w-100">
          <div className="col-6 col-xs-5 col-md-6 sharing-align">
            <p className="share-text-css text-light fade-in-down"> Share</p>
          </div>
          <div className="col-6 col-xs-7 col-md-6 p-0">
            <section class="animation">
              <div class="first heading-h1 text-light">
                <div>Meals </div>
              </div>
              <div class="second heading-h1 text-light">
                <div>Memories </div>
              </div>
              <div class="third heading-h1 text-light">
                <div>Experiences </div>
              </div>
            </section>
            {/* <p className="heading-h1 text-light"> <span style={{opacity:"0.4"}}>Meals</span> <br /> Memories  <br /> <span style={{opacity:"0.4"}}>Experiences</span></p> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Foodtogether;
