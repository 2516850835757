import React from "react";
const Payment = () => {
  return (
    <section className="explore-section">
      <div className="container">
        <div className="row explore-thirdblock">
          <div className="col-md-6 explore-content5">
            <h2 className="f24-h5">Payments made Easier</h2>
            <p className="f16-p3">
              Discover exciting offers & promotions and make
              <br />
              transactions happen in under a minute. All you got
              <br />
              to do is talk!
            </p>
          </div>
          <div className="col-md-6 explore-content6">
            <img
              src="../assets/images/explore/group68589.png"
              alt=""
              width="100%"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Payment;
